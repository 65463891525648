import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Nav from '../../../navbar';
function Buyleads() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [product, setproduct] = useState([]);
  const [productselect, setproductSelect] = useState();

  const [pid, setpid] = useState('');
  const [units, setUnits] = useState('');
  const [ppunit, setPpunit] = useState('');
  const [amount, setAmount] = useState('');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);

      getProduct(v[0].id);
    }
  }, []);

  const getProduct = async uid => {
    const data = new FormData();
    data.append('val', 'view');
    data.append('uid', uid);

    axios({
      method: 'post',
      url: '/pro/inventory/submit_productinfo.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('product list ', s.data);
        setproduct(s.data);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const addProduct = async () => {
    const data = new FormData();
    data.append('status', 'active');
    data.append('val', 'purchase');
    data.append('uid', uid);
    data.append('pid', productselect);
    data.append('unit', units);
    data.append('ppunit', ppunit);
    data.append('amount', amount);

    axios({
      method: 'post',
      url: '/pro/inventory/purchase_products.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        if (s.data[0].code === 'pass') {
          // alert('thanks');
          navigate('/stocklevels');
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <body>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}
              <div style={{marginLeft:"20px", marginTop:"10px"}} >
            <Link to="/inventory">
            <button
            className="btn-secondary px-2"  >
            <i style={{paddingRight:"10px"}} className="ti-arrow-left"></i>
              Back
            </button>
            </Link>
            </div>
              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>Purchase Product</h3>
                  </div>

                  {/* Form Start */}

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-title">
                          <h5>Purchase New Stock</h5>
                        </div>
                        <div className="card-body">
                          <div className="basic-elements">
                            <div className="row">
                              <div className="col-4 form-group">
                                <label>Product </label>
                                <select
                                  className="form-control"
                                  value={productselect}
                                  onChange={ve => {
                                    //setpid()
                                    setproductSelect(ve.target.value);
                                  }}>
                                  <option>Select Product</option>
                                  {product.map((v, i) => (
                                    <option key={i} value={v.id}>
                                      {v.p_name}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="col-4 form-group">
                                <label>Product Units </label>
                                <input
                                  value={units}
                                  onChange={v => {
                                    setUnits(v.target.value);
                                    setAmount(
                                      Number(v.target.value) * Number(ppunit),
                                    );
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>

                              <div className="col-4 form-group">
                                <label>Price Per Unit </label>
                                <input
                                  value={ppunit}
                                  onChange={v => {
                                    setPpunit(v.target.value);
                                    setAmount(
                                      Number(v.target.value) * Number(units),
                                    );
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 form-group">
                                <label>Total Amount </label>
                                <input
                                  value={amount}
                                  disabled
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>

                            <div
                              className="btn btn-primary"
                              onClick={() => {
                                productselect==""||units=="" ||  ppunit==""?
                                alert('Please Fill the Exact Form '):
                                addProduct();
                              }}>
                              Purchase Stock
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Form ends here..  */}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
