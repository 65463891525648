import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';

import {Link} from 'react-router-dom';
function Mainpage() {
  const [uid, setUid] = useState('');
  const [requirment, setRequirment] = useState('');
  const [frequency, setfrequency] = useState('');
  const [quantity, setQuantity] = useState('');
  const [unit, setunit] = useState();

  const [array, setArray] = useState([]);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      getproductofinrest(v[0].id);
    }
  }, []);

  const getproductofinrest = async uid => {
    const data = new FormData();
    data.append('uid', uid);

    axios({
      method: 'post',
      url: '/pro/profile/profile_view_productofinterest.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('product of interset  ', s.data);
        const a = s.data;
        setArray(a);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  //add data
  const addIntrest = async () => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('req', requirment);
    data.append('fre', frequency);
    data.append('unit', unit);
    data.append('qu', quantity);

    axios({
      method: 'post',
      url: '/pro/profile/profile_productofinterest.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        if (s.data[0].code === 'inserted') {
          alert('thanks');
        } else {
          alert('Internet issue');
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Product of Interest</h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                      <Link to="/newinterest">
                        <span>
                          <button
                            style={{borderRadius: '18px', color: 'white'}}
                            className="bg-primary px-4 ">
                            New Products of Interest
                          </button>
                        </span>
                      </Link>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Requirement</th>
                            <th>Frequency</th>
                            <th>Quantity</th>
                            <th>Quantity Unit</th>
                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          {array.length == 0 ? (
                            <>
                              {' '}
                              <tr>
                                <td
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                  colSpan={10}>
                                  No Record to show{' '}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                          {array.map((v, i) => (
                            <>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{v.req}</td>
                                <td>{v.fre}</td>
                                <td>{v.qu}</td>
                                <td>{v.unit}</td>
                                <td>
                                  <label
                                    style={{color: 'red', cursor: 'pointer'}}>
                                    {' '}
                                    Delete
                                  </label>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
