import { useEffect, useState } from "react";
import Whatsapp from "./whatsapp";
import Nav from "./navbar";
import { Carousel, Button } from "react-bootstrap";
import React from "react";
import Footer from "./footer";
import axios from "axios";

function App() {
  const [profile, setAllProfile] = useState([
    {
      description: "",
      companyid: "",
      impexpno: "",
      companybranch: "",
      bank: "",
      taxno: "",
      ntnno: "",
      productionunits: "",
      affiliatemembership: "",
      compbranch: "",
      ownercnic: "",
      infrastructuresize: "",
      arnno: "",
      fromday: "",
      today: "",
      fromtime: "",
      totime: "",
      states: "",
      includecities: "",
      excludecities: "",
      tradetocities: "",
      tradefromcities: "",
      modeofpay: "",
      shipmentmode: "",
    },
  ]);

  const [uid, setUid] = useState("");
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      getProfile(v[0].id);
    }
  }, []);
  const changeAray = (i, changes) => {
    const oldArr = [...profile];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    setAllProfile(oldArr);
  };
  const getProfile = async (uid) => {
    const data = new FormData();

    data.append("uid", "10000008");
    //data.append('array', JSON.stringify(profile));

    axios({
      method: "post",
      url: "/pro/profile/profile_view_company.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        if (s.data[0].code == "old") {
          setAllProfile(JSON.parse(s.data[0].aray));
          //console.log(s.data)
        }
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  return (
    <body>
      <Nav />
      <Whatsapp />
      <div>
        <div className="container">
          <h2 style={{ paddingTop: "30px", marginBottom: "30px" }}>
            Arham Textiles Pvt. Ltd
          </h2>
          <hr />

          <div className="row">
            <div className="col-8 mx-3 my-2">
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    height={400}
                    src="https://alahdeen.com/b2b/pro/cat/images/1000.jpg"
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>
                      Nulla vitae elit libero, a pharetra augue mollis interdum.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    height={400}
                    src="https://alahdeen.com/b2b/pro/cat/images/1000.jpg"
                    alt="Second slide"
                  />

                  <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    height={400}
                    className="d-block w-100"
                    src="https://alahdeen.com/b2b/pro/cat/images/1000.jpg"
                    alt="Third slide"
                  />

                  <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>
                      Praesent commodo cursus magna, vel scelerisque nisl
                      consectetur
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>

            <div
              style={{
                borderLeft: "2px solid grey",
              }}
              className="col-3 px-5 my-4 mx-4"
            >
              {" "}
              <h5 className="mb-4">{profile[0].companybranch}</h5>
              <p> {profile[0].compbranch}</p>
              <p> {profile[0].ntnno}</p>
              <p> {profile[0].fromday}</p>
              <p> {profile[0].totime}</p>
              <p> {profile[0].modeofpay}</p>
              <p> {profile[0].infrastructuresize}</p>
              <p> {profile[0].impexpno}</p>
              <center>
                <button className="btn-info my-3 px-2 py-2">
                  Contact Supplier
                </button>
              </center>
            </div>
          </div>

          <section className="padding-bottom-sm my-5">
            <header className=" section-heading heading-line">
              <h4 className=" title-section text-uppercase">Our Products</h4>
            </header>

            <div className="row mx-3 my-4">
              <div
                style={{ cursor: "pointer" }}
                className="col-md-3 col-sm-6 card-product-grid card-sm"
              >
                <article className="card card-post">
                  <img
                    heigt={100}
                    width={100}
                    src="assets/images/posts/12.jpg"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h6 className="title text-center">Product Name </h6>

                    <div className="text-center my-1">
                      <button
                        style={{ borderRadius: "15px" }}
                        className="btn-success mt-2 px-3 "
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </article>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="col-md-3 col-sm-6 card-product-grid card-sm"
              >
                <article className="card card-post">
                  <img
                    heigt={100}
                    width={100}
                    src="assets/images/posts/12.jpg"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h6 className="title text-center">Product Name </h6>

                    <div className="text-center my-1">
                      <button
                        style={{ borderRadius: "15px" }}
                        className="btn-success mt-2 px-3 "
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </article>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="col-md-3 col-sm-6 card-product-grid card-sm"
              >
                <article className="card card-post">
                  <img
                    heigt={100}
                    width={100}
                    src="assets/images/posts/12.jpg"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h6 className="title text-center">Product Name </h6>

                    <div className="text-center my-1">
                      <button
                        style={{ borderRadius: "15px" }}
                        className="btn-success mt-2 px-3 "
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </article>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="col-md-3 col-sm-6 card-product-grid card-sm"
              >
                <article className="card card-post">
                  <img
                    heigt={100}
                    width={100}
                    src="assets/images/posts/12.jpg"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h6 className="title text-center">Product Name </h6>

                    <div className="text-center my-1">
                      <button
                        style={{ borderRadius: "15px" }}
                        className="btn-success mt-2 px-3 "
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </section>
          <section className="padding-bottom-sm my-5">
            <div className="row mx-3 my-2">
              <div
                style={{ cursor: "pointer" }}
                className="col-md-3 col-sm-6 card-product-grid card-sm"
              >
                <article className="card card-post">
                  <img
                    heigt={100}
                    width={100}
                    src="assets/images/posts/12.jpg"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h6 className="title text-center">Product Name </h6>

                    <div className="text-center my-1">
                      <button
                        style={{ borderRadius: "15px" }}
                        className="btn-success mt-2 px-3 "
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </article>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="col-md-3 col-sm-6 card-product-grid card-sm"
              >
                <article className="card card-post">
                  <img
                    heigt={100}
                    width={100}
                    src="assets/images/posts/12.jpg"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h6 className="title text-center">Product Name </h6>

                    <div className="text-center my-1">
                      <button
                        style={{ borderRadius: "15px" }}
                        className="btn-success mt-2 px-3 "
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </article>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="col-md-3 col-sm-6 card-product-grid card-sm"
              >
                <article className="card card-post">
                  <img
                    heigt={100}
                    width={100}
                    src="assets/images/posts/12.jpg"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h6 className="title text-center">Product Name </h6>

                    <div className="text-center my-1">
                      <button
                        style={{ borderRadius: "15px" }}
                        className="btn-success mt-2 px-3 "
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </article>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="col-md-3 col-sm-6 card-product-grid card-sm"
              >
                <article className="card card-post">
                  <img
                    heigt={100}
                    width={100}
                    src="assets/images/posts/12.jpg"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h6 className="title text-center">Product Name </h6>

                    <div className="text-center my-1">
                      <button
                        style={{ borderRadius: "15px" }}
                        className="btn-success mt-2 px-3 "
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </section>

          <div className="row">
            <div className="col-6 my-1">
              <iframe src="https://www.youtube.com/watch?v=S2Oh4cqEmOg"></iframe>
              <video loop autoPlay>
                <source
                  src="https://www.youtube.com/watch?v=S2Oh4cqEmOg"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="col-6 my-2">
              <center>
                <h2>About Arham Textile</h2>

                <p>{profile[0].description}</p>
              </center>
            </div>
          </div>
          <section className="padding-bottom-sm my-1">
            <header className=" section-heading heading-line">
              <h4 className=" title-section text-uppercase">Location</h4>
            </header>
          </section>

          <section className="padding-bottom-sm my-5">
            <header className=" section-heading heading-line">
              <h4 className=" title-section text-uppercase">
                Similar Products
              </h4>
            </header>

            <div className="row mx-3 my-4">
              <div
                style={{ cursor: "pointer" }}
                className="col-md-3 col-sm-6 card-product-grid card-sm"
              >
                <article className="card card-post">
                  <img
                    heigt={100}
                    width={100}
                    src="assets/images/posts/12.jpg"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h6 className="title text-center">Product Name </h6>

                    <div className="text-center my-1">
                      <button
                        style={{ borderRadius: "15px" }}
                        className="btn-success mt-2 px-3 "
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </article>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="col-md-3 col-sm-6 card-product-grid card-sm"
              >
                <article className="card card-post">
                  <img
                    heigt={100}
                    width={100}
                    src="assets/images/posts/12.jpg"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h6 className="title text-center">Product Name </h6>

                    <div className="text-center my-1">
                      <button
                        style={{ borderRadius: "15px" }}
                        className="btn-success mt-2 px-3 "
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </article>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="col-md-3 col-sm-6 card-product-grid card-sm"
              >
                <article className="card card-post">
                  <img
                    heigt={100}
                    width={100}
                    src="assets/images/posts/12.jpg"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h6 className="title text-center">Product Name </h6>

                    <div className="text-center my-1">
                      <button
                        style={{ borderRadius: "15px" }}
                        className="btn-success mt-2 px-3 "
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </article>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="col-md-3 col-sm-6 card-product-grid card-sm"
              >
                <article className="card card-post">
                  <img
                    heigt={100}
                    width={100}
                    src="assets/images/posts/12.jpg"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h6 className="title text-center">Product Name </h6>

                    <div className="text-center my-1">
                      <button
                        style={{ borderRadius: "15px" }}
                        className="btn-success mt-2 px-3 "
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </body>
  );
}

export default App;
