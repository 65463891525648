import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Markdown } from 'react-bootstrap-icons';
function Mainpage() {
  const [uid, setUid] = useState('');
  const [directorList, setdirectorlist] = useState([]);
  const [alert3, setAlert3] = useState(false);

  const [name, setName] = useState('');
  const [designation, setdesignation] = useState('');
  const [description, setdescription] = useState('');

  const [img, setImage] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      viewDirector(v[0].id);
    }
  }, []);

  const viewDirector = async uid => {
    const data = new FormData();
    data.append('uid', uid);

    axios({
      method: 'post',
      url: '/pro/profile/profile_view_director.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('director list ', s.data);
        const a = s.data;
        
        if(s.data[0].code=="pass"){
          setdirectorlist(a);
        }else{
          setdirectorlist("");
        }
        console.log(directorList.length);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  //delete director...
  const deleteDirector = async (did) => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('did', did);

    axios({
      method: 'post',
      url: '/pro/profile/delete_director.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('director list ', s.data);
        const a = s.data;
        if(a[0].code == "pass"){
          viewDirector(uid)
        }
        //setdirectorlist(a);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  //add data
  const addDirector = async () => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('name', name);
    data.append('designation', designation);
    data.append('description', description);
    data.append('img', img);

    axios({
      method: 'post',
      url: '/pro/profile/profile_director.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        if (s.data[0].code === 'inserted') {
          alert('thanks');
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  //.......data
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Director Profile</h4>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="text-right">
                        {/* <input
                          style={{borderRadius: '18px', paddingLeft: '8px'}}
                          placeholder="search"
                        /> */}
                        <span>
                          <button
                            onClick={() => {
                              navigate('/adddirector');
                            }}
                            style={{borderRadius: '18px', color: 'white'}}
                            className="bg-primary mx-3 px-4 ">
                            New Director
                          </button>
                        </span>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Photo</th>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Description</th>
                            <th>controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                          {
                          directorList.length < 1 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                  colSpan={6}>
                                  No Record to show
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {directorList.map((v, i) => (
                                <tr>
                                  {/* <td>{v.counter}</td> */}
                                  <td>{i + 1}</td>
                                  <td style={{width: '20%'}}>
                                    <img
                                      style={{width: "20%", height: "25%", width:"120px"}}
                                      src={v.img}
                                    />
                                  </td>
                                  
                                 

                                  <td>{v.name}</td>
                                  <td>{v.designation}</td>
                                  <td>{v.description}</td>
                                  <td>
                                  <button
                                style={{borderRadius: '16px', marginRight:3,}}
                                className="px-4 btn-primary"
                                onClick={() => {
                                  localStorage.setItem("editdri",JSON.stringify(directorList[i]))
                                  
                                  navigate('/`editdirector`');
                                 
                                }}>
                               <i className='ti-pencil'></i>
                              </button>
                              <button
                                style={{borderRadius: '16px'}}
                                className="px-4 btn-primary"
                                onClick={() => {
                                  setAlert3(!alert3);
                                }}>
                               <i className='ti-trash'></i>
                              </button>
                              {alert3 ? (
                                <>
                                  {' '}
                                  <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText="Confirm!"
                                    confirmBtnBsStyle="primary"
                                    title="Are you sure?"
                                    onConfirm={() => {
                                      setAlert3(!alert3);
                                      deleteDirector(v.id);
                                    }}
                                    onCancel={() => {
                                      setAlert3(!alert3);
                                    }}
                                    focusCancelBtn>
                                    
                                    <br />
                                  </SweetAlert>
                                </>
                              ) : (
                                <></>
                              )}
                                  </td>
                                  {/* <td><label
                                     className='ml-1'
                                      style={{color: 'red', cursor: 'pointer'}}
                                      onClick={() => {
                                       
                                        deleteDirector(v.id);
                                      }}>
                                   <i className='ti-trash'></i>
                                    </label></td> */}
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
