import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';

function Mainpage() {
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
      navigate('/login');
    } else {
      getTrans(v[0].id);
    }
  }, []);

  const [array, setArray] = useState([]);

  const navigate = useNavigate();
  const getTrans = async id => {
    const data = new FormData();

    data.append('id', id);

    axios({
      method: 'post',
      url: '/pro/blance_sheet.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);

        setArray(s.data);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Transactions History</h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                      <input
                        style={{borderRadius: '18px', paddingLeft: '8px'}}
                        placeholder="search"
                      />
                      <span>
                        <button
                          style={{borderRadius: '18px', color: 'white'}}
                          className="bg-primary mx-3 px-4 ">
                          Search
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Transaction ID</th>
                            <th>Date </th>

                            <th>Description</th>

                            <th>Debit</th>
                            <th>Credit</th>
                            <th>Balance</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {array.map((v, i) => (
                            <tr>
                              <td>{v.id}</td>
                              <td>{v.date}</td>

                              <td>
                                {v.payment}
                                <br />
                                {v.bank_name} {v.account}
                                <br />
                                {v.title}
                                <br />
                                {v.sendid}
                              </td>

                              <td>{new Intl.NumberFormat('en-pk').format(v.debit)}</td>
                              <td>{new Intl.NumberFormat('en-pk').format(v.credit)}</td>
                              <td>{new Intl.NumberFormat('en-pk').format(v.blance)}</td>
                              <td>{i == 0 ? 'A/C Opening Balance  ' : v.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
