import Nav from '../../../navbar';

function Mainpage() {
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <div className='container'>
            <section id="main-content">
            <div className='card'>

              <h3 className='text-center'>Web Marketing</h3>
              <p className='text-center'>we offer Premium Web Marketing and Web Development Services</p>

              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="row">
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Complete Website Development </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Complete Website Design </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Complete UX/UI Design </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Social Media Handeling </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
              <div className="row">
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Search Engine Optimization (SEO)</div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Complete Website Design </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Complete UX/UI Design </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Social Media Handeling </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
                  
              
              
            
          </div>
          </div>
            </section>
          
            <div className='card'>
            <section id="main-content">

              <h3 className='text-center'>Digital Marketing</h3>
              <p className='text-center'>Are you looking for an effective way to grow your business brand awareness, number of new
customers, and website traffic? Then you should be on social media! Check out our Social Media
Marketing Services and build a strong Social Media presence. Contact us to get Started</p>

              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="row">
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Social Media Marketing </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Complete Website Design </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Latest Trend Sharing </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Social Media Handeling </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
              <div className="row">
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Social Media Marketing</div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Social Media Marketing </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Social Media Marketing </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Social Media Handeling </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
                  
              
              
            
          </div>
            </section>
            </div>
            <div className='card'>
            <section id="main-content">

              <h3 className='text-center'>Graphic Designing Services</h3>
              <p className='text-center'>Design Your Idea From Us. We offer Graphic Designing Services. Contact Now and make an effective design that will grow your business growth</p>

              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="row">
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Logo Design </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Banners Design </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Social Media Posts </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Social Media Posts </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
              <div className="row">
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Social Media Marketing</div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Social Media Marketing </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Social Media Marketing </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Social Media Handeling </div>
                      <div className="stat-text"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
                  
              
              
            
          </div>
            </section>
            </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
