import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Nav from "../../../navbar";

function Buyleads() {
  const navigate = useNavigate();

  const [invoice, setInvoice] = useState([]);
  const [uid, setUid] = useState("");

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      ViewInvoice(v[0].id);
    }
  }, []);

  const ViewInvoice = async (uid) => {
    const data = new FormData();
    data.append("uid", uid);
    data.append("run", "uid");

    axios({
      method: "post",
      url: "/pro/khata/khata_invoice_view.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("khata list ", s.data);
        const a = s.data;

      localStorage.setItem("InvoiceList",JSON.stringify(a))
        
        setInvoice(a);

      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  return (
    <body>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Manage Invoices  </h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                      <input
                        style={{ borderRadius: "18px", paddingLeft: "8px" }}
                        placeholder="search"
                      />
                      <span>
                        <Link to="/newinvoice">
                          <button
                            style={{ borderRadius: "18px", color: "white" }}
                            className="bg-primary mx-3 px-4 "
                          >
                            New Invoice
                          </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Invoice No.</th>
                            <th>Date</th>
                            <th>Created For</th>
                            <th>Contact No.</th>
                            <th>Invoice Email</th>
                            <th>Comments</th>
                            <th>Amount</th>
                            <th>Control</th>
                            {/* <th>Control</th> */}
                          </tr>
                        </thead>
                        <tbody>

                        {invoice.length === 0 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                  colSpan={10}>
                                  No Record to show{' '}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                          {invoice.map((v, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{v.iid}</td>
                              <td>{v.invoicedate}</td>
                              <td>{v.user[0].name}</td>
                              <td>{v.user[0].phone}</td>
                              <td>{v.user[0].email}</td>
                             

                              <td>{v.comments}</td>
                              <td>{new Intl.NumberFormat('en-pk').format(v.total==""?0:v.total)}</td>
                              <td> 

   {
  v.status==="active"?<>
  <a href={"/invoiceprint?id="+v.khata_id} target="_blank">
                                <button style={{borderRadius:"15px"}} className="btn-primary px-2">Print</button>
                              </a>
                              </>:<>
                              <button onClick={()=>{
                                localStorage.setItem("Draft_Invoice",v.khata_id)
                                navigate("/invoicedraft")
                              }}  style={{borderRadius:"15px"}} className="btn-primary px-2">Draft</button>
                              </>
  }

                              
                              </td>
                              
                            </tr>
))}
</>)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
