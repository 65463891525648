import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';

function Mainpage() {
  const navigate = useNavigate();
  const [warehouse, setWarehouse] = useState([{
    id:"",
    type:"",
    areasqft:"",
    rentpermonth:"",
    shared:"",
    city:"",
    locality:"",
    map:"",
    latitude:"",
    longitude:"",
    room:"",
    fan:"",
    light:"",
    washroom:"",
    parking:"",
    shelves:"",
    eletricalbackup:"",
    wastedisposal:"",
    refrigeration:"",
    safety:"",
    security:"",
    basement:"",
  }]);
  const [listcities, setCitiesList] = useState([]);
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
     
    } else {
      setUid(v[0].id)
      getCities()

  
    }
  }, []);
  const getCities = async () => {
    const data = new FormData();
  
        axios({
          method: "post",
          url: "/pro/auth/cities_list.php",
          data: data,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((s) => {
            setCitiesList(s.data)
          })
          .catch((s) => {
            //navigate("/")
          });
      
    
  };
  const [view, setview] = useState('1');
  
  const [uid, setUid] = useState('');
  const [type, settype] = useState('Sheltered');
  const [areasqft, setAreasqft] = useState('');
  const [rentpermonth, setrentpermonth] = useState('');
  const [shared, setshared] = useState(false);
  const [city, setcity] = useState('');
  const [locality, setlocality] = useState('');
  const [map, setmap] = useState('');
  const [latitude, setlatitude] = useState('');
  const [longitude, setlongitude] = useState('');
  const [room, setroom] = useState('');
  const [fan, setfan] = useState('');
  const [light, setlight] = useState('');
  const [washroom, setwashroom] = useState('');
  const [parking, setparking] = useState('');
  const [shelves, setshelves] = useState('');
  const [eletricalbackup, seteletricalbackup] = useState(false);
  const [wastedisposal, setwastedisposal] = useState(false);
  const [refrigeration, setrefrigeration] = useState(false);
  const [safety, setsafty] = useState(false);
  const [security, setsecurity] = useState(false);
  const [basement, setbasement] = useState(false);
  const [img, setImg] = useState();
  const [imga, setImga] = useState();
  const [imgb, setImgb] = useState();
  const [imgc, setImgc] = useState();
  const [extra, setextra] = useState([]);

  const saveWarehouse = async ()=> {

    const data = new FormData();
    data.append('uid',uid );
    data.append('type',type );
    data.append('areasqft',areasqft );
    data.append('rentpermonth',rentpermonth );
    data.append('shared',shared );
    data.append('city', city);
    data.append('locality', locality);
    data.append('map', map);
    data.append('latitude', latitude);
    data.append('longitude', longitude);
    data.append('room', room );
    data.append('fan', fan);
    data.append('light', light);
    data.append('washroom', washroom);
    data.append('parking', parking);
    data.append('shelves', shelves);
    data.append('electrical_backup', eletricalbackup );
    data.append('waste_disposal', wastedisposal );
    data.append('refrigeration',refrigeration );
    data.append('safety',safety );
    data.append('security',security );
    data.append('basement', basement);
    data.append('img',img );
    data.append('img_a',imga );
    data.append('img_b',imgb );
    data.append('img_c',imgc );
    data.append('array',JSON.stringify(extra));
        axios({ 
          method: 'post',
          url: '/pro/warehouse.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log("warehousr add  ",s.data)
            const a=s.data;
           navigate('/warehouselist')
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>Add Warehouse</h3>
                  </div>

                  {/* Form Start */}
                 {
                   view=="1"?<> <div className="row">
                   <div className="col-lg-12">
                     <div className="card">
                       <div className="card-title">
                         <h5>Basic Info</h5>
                       </div>
                       <div className="card-body">
                         <div className="basic-elements">
                           
                             <div className="col-lg-12">
                               <div className="row">
                                 <div className="form-group">
                                   <label>Select Warehouse Type</label>
                                   <select 
                                 
                                 value={type}
                                 onChange={(v)=>{
                                   settype(v.target.value)

                                 }}
                                 
                                 
                                 className="form-control">
                                     <option>Sheltered</option>
                                     <option>Shop</option>
                                     <option>Cold Storage</option>
                                     <option>Apartment</option>
                                     <option>Plaza</option>
                                     <option>Single Story</option>
                                     <option>Double Story</option>
                                   </select>
                                 </div>
                               </div>
                               <div className="row">
                                 <div className="col-6 form-group">
                                   <label>Area in ft². (Unit)</label>
                                   <input  value={areasqft}
                                 onChange={v => {
                                   setAreasqft(v.target.value);
                                 }} className="form-control" type="number" />
                                 </div>
                                 <div className="col-6 form-group">
                                   <label>Rent Per Month  </label>
                                   <input  value={rentpermonth}
                                 onChange={v => {
                                   setrentpermonth(v.target.value);
                                 }} className="form-control" type="number" />
                                 </div>
                               </div>
                               <div className="my-3 form-group">
                                 <input  
                                 onClick={v => {
                                   setshared(!shared);
                                 }} className="checkbox" type="checkbox" />
                                 <label className="mx-4">Can be Shared  </label>
                               </div>
                             </div>
                             <div onClick={()=>{
                              type=='' || areasqft==''|| rentpermonth==''?
                              alert("Please Fill the Form"):
                              

                                setview("2")
                              }
                             } className="btn btn-success">Next</div>
                        
                         </div>
                       </div>
                     </div>
                   </div>
                 </div></>:<></>
                 }


{
  view=="2"?<><div className="col-lg-12">
  <div className="card">
    <div className="card-title">
      <h5>Location Details</h5>
    </div>
    <div className="card-body">
      <div className="basic-elements">
        
          <div className="col-lg-12">
            <div className="row">
              <div className="form-group">
                <label>Location Details</label>
                <select  value={city}
                onChange={v => {
                  setcity(v.target.value);
                }} className="form-control">
                  {
                        listcities.map((v,i)=>(
                          <option>{v.city}</option>

                        ))
                      }
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label>Address</label>
                <input className="form-control" type="text"  value={locality}
                onChange={v => {
                  setlocality(v.target.value);
                }} />
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12 form-group">
                <label>
                  Choose Warehouse Location on Map
                </label>
                <input  className="form-control" type="text" />
              </div>
            </div> */}
          </div>
          <div onClick={()=>{
                               setview("1")
                             }} className="btn btn-success mx-3">Previous</div>
          <div onClick={()=>{
            city=="" || locality=="" ?alert('Please Fill the Exact Form'):
                               setview("3")
                             }} className="btn btn-success">Next</div>
       
      </div>
    </div>
  </div>
</div></>:<></>
}
{
  view=="3"?<>
   {/* 3rd Page Start */}
   <div className="col-lg-12">
                    <div className="card">
                      <div className="card-title">
                        <h5>Features</h5>
                      </div>
                      <div className="card-body">
                        <div className="basic-elements">
                          
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-4 form-group">
                                  <label>Rooms</label>
                                  <input  value={room}
                                  onChange={v => {
                                    setroom(v.target.value);
                                  }} className="form-control" type="number" />
                                </div>
                                <div className="col-4 form-group">
                                  <label>Fans</label>
                                  <input value={fan}
                                  onChange={v => {
                                    setfan(v.target.value);
                                  }}  className="form-control"  type="number" />
                                </div>
                                <div className="col-4 form-group">
                                  <label>Lights</label>
                                  <input className="form-control"   value={light}
                                  onChange={v => {
                                    setlight(v.target.value);
                                  }} type="number" />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4 form-group">
                                  <label>Washrooms</label>
                                  <input className="form-control"   value={washroom}
                                  onChange={v => {
                                    setwashroom(v.target.value);
                                  }} type="number" />
                                </div>
                                <div className="col-4 form-group">
                                  <label>Parking Spaces</label>
                                  <input className="form-control"   value={parking}
                                  onChange={v => {
                                    setparking(v.target.value);
                                  }} type="number" />
                                </div>
                                <div className="col-4 form-group">
                                  <label>Shelves</label>
                                  <input className="form-control" value={shelves} 
                                  onChange={v => {
                                    setshelves(v.target.value);
                                  }} type="number"   />
                                </div>
                              </div>
                              <h5 className="my-4">Additional Features</h5>
                              <div className="row">
                                <div className="col-4 form-group">
                                  <input onClick={v => {
                                    seteletricalbackup(!eletricalbackup);
                                  }} type="checkbox" />
                                  <label className="mx-3">Electrical Backup</label>
                                </div>
                                <div className="col-4 form-group">
                                  <input onClick={v => {
                                    setwastedisposal(!wastedisposal);
                                  }} type="checkbox" />
                                  <label className="mx-3">Waste Disposal</label>
                                </div>
                                <div className="col-4 form-group">
                                  <input onClick={v => {
                                    setrefrigeration(!refrigeration);
                                  }} type="checkbox" />
                                  <label className="mx-3">
                                    Refrigeration Facility (Cold Storage)
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4 form-group">
                                  <input type="checkbox" />
                                  <label onClick={() => {
                                    setsafty(!safety);
                                  }} className="mx-3">Safety</label>
                                </div>
                                <div className="col-4 form-group">
                                  <input onClick={v => {
                                    setsecurity(!security);
                                  }} type="checkbox" />
                                  <label className="mx-3">Security</label>
                                </div>
                                <div className="col-4 form-group">
                                  <input onClick={v => {
                                    setbasement(!basement);
                                  }} type="checkbox" />
                                  <label  className="mx-3">Basement</label>
                                </div>
                              </div>
                              <div onClick={()=>{
                               setview("2")
                             }} className="btn btn-success my-3 ">Previous</div>
                              <div onClick={()=>{
                                room=="" ? alert("please Fill the Exact Form"):
                               setview("4")
                             }} className="btn btn-success my-3 mx-3 ">Next</div>
                            </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 3rd Page End */}
                  </>:<></>
}
{
  view=="4"?<>                  {/* 4th page Start */}
  <div className="col-lg-12">
    <div className="card">

      <div className="card-title">
        <h5>Upload Images</h5>
      </div>
      <div className="card-body">
        <div className="basic-elements">
          
            <div className="col-lg-12">
              <div
                className="row my-4"><label>Feature Images</label>
                <input type="file"  onChange={v => {
        setImg(v.target.files[0]);
      }}   />
                <input type="file"  onChange={v => {
        setImga(v.target.files[0]);
      }}   />
                <input type="file"  onChange={v => {
        setImgb(v.target.files[0]);
      }}   />
                <input type="file"  onChange={v => {
        setImgc(v.target.files[0]);
      }}   />
              </div>
              <div onClick={()=>{
                            setview("3")
                             }} className="btn btn-success my-3 ">Previous</div>
              <div onClick={()=>{
                 img==""|| imga==""|| imgb==""|| imgc==""  ? alert("Please Select Images")
               : saveWarehouse();
              }} className="btn btn-success my-3 mx-3 "> Save Now</div>
            </div>
          
        </div>
      </div>
    </div>
  </div>
  {/* 4th page End */}</>:<></>
}
                  

                 


                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
