import React from "react";

function whatsapp() {
  return (
    <>
      <div style={{ position: "fixed", bottom: "22px", right: "10px",  zIndex: 1200  }}>
        <a href="https://api.whatsapp.com/send?phone=923064444110">
        {/* <a href="https://api.whatsapp.com/send?phone=923090744443"> */}
          <img height={70} src="https://alahdeen.com/pro/image/whatsapp.png" />
        </a>
      </div>
    </>
  );
}

export default whatsapp;
