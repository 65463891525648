import { useState, useEffect } from "react";
import Nav from "../../../navbar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
function Mainpage() {
  const navigate = useNavigate();
  const [wid, setWid] = useState();
  const [uid, setUid] = useState();
  const [booking, setBooking] = useState([]);
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);

     Booked(v[0].id);
      }
    
  }, []);

  const Booked = async (id) => {
    const data = new FormData();

    data.append("uid", id);
    // data.append("uid", mid);

    // data.append('array', JSON.stringify(client));

    axios({
      method: "post",
      url: "/pro/warehouse_bookings.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log(s.data);
        setBooking(s.data);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };



  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>
                      Booked Warehouse
                    </h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                      <input
                        style={{ borderRadius: "18px", paddingLeft: "8px" }}
                        placeholder="search"
                      />
                      <span>
                        <button
                          style={{ borderRadius: "18px", color: "white" }}
                          className="bg-primary mx-3 px-4 "
                        >
                          Search
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                          <th>ID</th>
                            <th>Image</th>
                            <th>City</th>
                            <th>Area</th>
                            <th>Locality</th>
                            <th>type</th>

                            <th>Rent Amount</th>
                            <th>Start Date</th>
                            <th>End Date</th>

                            <th>Room</th>
                            <th>Parking</th>
                            {/* <th>Time</th> */}
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {booking.length == 0 ? (
                            <>
                              {" "}
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={10}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}

                          {booking.map((v, i) => (
                            <tr>
                            <td>{v.id}</td>
                            <td>
                              <img
                                style={{ width: "20%", height: "25%", width:"120px" }}
                                src={v.imagea}
                              />
                            </td>
                            <td>{v.city}</td>
                            <td>{v.areasqft}</td>
                            <td>{v.locality}</td>
                            <td>{v.type}</td>
                            <td>{v.rentpermonth}</td>
                            <td>{v.start}</td>
                            <td>{v.end}</td>
                            <td>{v.room}</td>
                            <td>{v.parking}</td>
                          
                            {/* <td>{v.time}</td> */}
                            <td><button  className="btn-success px-2">Booked</button></td>

                          

                            
                          </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
