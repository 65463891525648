import React from 'react'
import Nav from '../../../navbar';
import {useNavigate} from 'react-router-dom';
import {useState, useEffect} from 'react';
import axios from 'axios';



function Sendquote() {
  const navigate = useNavigate();
    const [view, setView]= useState(1)
    const [img, setImg]= useState('')
    const [name, setName]= useState('')
    const [qty, setQty]= useState('')
    const [unit, setUnit]= useState('')
    const [desc, setDesc]= useState('')
    const [disc, setDisc]= useState('')
    const [del, setDel]= useState('')
    const [type, setType]= useState('')
    const [pay, setPay]= useState('')
    const [tax, setTax]= useState('')
    const [ship, setShip]= useState('')
    const [add, setAdd]= useState('')
    const [primary, setPrimary]= useState('')
    const [alternate, setAlternate]= useState('')
    const [contact, setContact]= useState('')
    const [address, setAddress]= useState('')
    const [two, setTwo]= useState('')
    const [uid, setUid] = useState('');
    
    useEffect(() => {
        const v = JSON.parse(localStorage.getItem('USER'));
        if (v === null) {
        } else {
          setUid(v[0].id);
          const two = localStorage.getItem("leadid")
          setTwo(two);
        }
      }, []);

      


      const Sendquote = async () => {
        const data = new FormData();
        data.append('uid', uid);
        data.append('qid', two);
        data.append('name', name);
        data.append('qty', qty);
        data.append('unit', unit);
        data.append('desc', desc);
        data.append('disc', disc);
        data.append('dperiod', del);
        data.append('type', type);
        data.append('pterms', pay);
        data.append('atax', tax);
        data.append('stax', ship);
        data.append('adesc', add);
        data.append('pemail', primary);
        data.append('aemail', alternate);
        data.append('cno', contact);
        data.append('address', address);
        data.append('extra', "");
        data.append('img', img);
    
        axios({
          method: 'post',
          url: '/pro/send_quote.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
         
            // alert('Send Quote Send Successfully')
         if(s.data===""){
          

         }else if (s.data[0].code=== "validity"){
            alert('Your Package is Expire Please Buy another Package')
            console.log(s.data)
            navigate('/subscription')
          }
          else if (s.data[0].code=== "lead"){
            alert("You have not enough lead to send Quote please buy new leads")
            console.log(s.data)
            navigate('/subscription')
          }
          else if (s.data[0].code === "pass"){
          
            console.log('Successfull')
            console.log(s.data)
            navigate('/buyleads')
          }
          else {
            alert("something Went Wrong")
            console.log(s.data)
          }
          })
          .catch(s => {
            console.log('here lead_view_all ', s);
          });
      };
  return (
    <body>
        <Nav/>
  <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Send Quote </h4>
                  </div>
                  {view===1?<>

                    <div style={{paddingLeft:"90px", border:"2px dotted black", height:"80px" , padding:"25px"}} className='row  my-3'>
                <input  onChange={v => {
                setImg(v.target.files[0]);
               }} type='file' />

                </div>
                <br/>
                  <div className="row">
                              <div className="col-4 form-group">
                                <label>Name of Product</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Title"
                                  value={name}
                                  onChange={v => {
                                    setName(v.target.value);
                                  }}
                                />
                              </div>
                              <div className="col-4 form-group">
                                <label>Quantity</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Quantity"
                                  value={qty}
                                  onChange={v => {
                                    setQty(v.target.value);
                                  }}
                                />
                              </div>
                              <div className="col-1 form-group">
                                <label>Unit</label>
                               <select className='form-control'>

                                <option >Pieces</option>
                                <option >Box</option>
                                <option >Bags</option>
                               </select>
                              </div>
                              <div className="col-3 form-group">
                                <label>Price</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  
                                  value={pay}
                                  onChange={v => {
                                    setPay(v.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div className='row'>
                           
                            
                              <div className="col-6 form-group">
                                <label>Description</label>
                                <textarea
                                  className="form-control"
                                
                                  placeholder="Enter Description here"
                                  value={desc}
                                  onChange={v => {
                                    setDesc(v.target.value);
                                  }}
                                />
                              </div>
                              <div className="col-6 form-group">
            <label>Discount %</label>
            <input
              className="form-control"
              type="text"
             
              value={disc}
              onChange={v => {
                setDisc(v.target.value);
              }}
            />
          </div>

                            </div>

                          
                         
                            <div className="row">
        
          <div className="col-5 form-group">
            <label>Delivery Period</label>
            <input
              className="form-control"
              type="text"
              
              value={del}
              onChange={v => {
                setDel(v.target.value);
              }}
            />
          </div>
          <div className="col-1 form-group">
            <label>Type</label>
           <select className='form-control'>

            <option >Days</option>
            <option >Week</option>
            <option >Month</option>
           </select>
          </div>
          <div className="col-6 form-group">
            <label>ApplicableTax %</label>
            <input
              className="form-control"
              type="text"
             
              value={tax}
              onChange={v => {
                setTax(v.target.value);
              }}
            />
          </div>
        </div>
        <div className="row">
        
        <div className="col-6 form-group">
            <label>Additional Information</label>
            <textarea
              className="form-control"
              type="text"
              placeholder="Enter Description here"
              value={add}
              onChange={v => {
                setAdd(v.target.value);
              }}
            ></textarea>
          </div>
          <div className="col-6 form-group">
            <label>Shipping Charges</label>
            <input
              className="form-control"
              type="text"
             
              value={ship}
              onChange={v => {
                setShip(v.target.value);
              }}
            />
          </div>
          </div>
    

       

        <div className="row">
          <div className="col-6 form-group">
            <label>Primary Email</label>
            <input
              className="form-control"
              type="text"
             
              value={primary}
              onChange={v => {
                setPrimary(v.target.value);
              }}
            />
          </div>
          <div className="col-6 form-group">
            <label>Alternate Email</label>
            <input
              className="form-control"
              type="text"
              
              value={alternate}
              onChange={v => {
                setAlternate(v.target.value);
              }}
            />
          </div>
    
        </div>
        <div className="row">
          <div className="col-6 form-group">
            <label>Contact No.</label>
            <input
              className="form-control"
              type="text"
              
              value={contact}
              onChange={v => {
                setContact(v.target.value);
              }}
            />
          </div>
          <div className="col-6 form-group">
            <label>Address</label>
            <input
              className="form-control"
              type="text"
              
              value={address}
              onChange={v => {
                setAddress(v.target.value);
              }}
            />
          </div>
          </div>
        

        <center>
      
        <button onClick={()=>(

         
        Sendquote()
        )
        } 
        className='btn-success px-4 py-2 my-4'> Submit </button>

        </center>



                  </>:null
                 }
                        {view===2?<>


<br/>
<div className="row">
          <div className="col-6 form-group">
            <label>Discount %</label>
            <input
              className="form-control"
              type="text"
             
              value={disc}
              onChange={v => {
                setDisc(v.target.value);
              }}
            />
          </div>
          <div className="col-5 form-group">
            <label>Delivery Period</label>
            <input
              className="form-control"
              type="text"
              
              value={del}
              onChange={v => {
                setDel(v.target.value);
              }}
            />
          </div>
          <div className="col-1 form-group">
            <label>Type</label>
           <select className='form-control'>

            <option >Days</option>
            <option >Week</option>
            <option >Month</option>
           </select>
          </div>
        </div>
        <div className="row">
        
          <div className="col-6 form-group">
            <label>ApplicableTax %</label>
            <input
              className="form-control"
              type="text"
             
              value={tax}
              onChange={v => {
                setTax(v.target.value);
              }}
            />
          </div>
          <div className="col-6 form-group">
            <label>Shipping Charges</label>
            <input
              className="form-control"
              type="text"
             
              value={ship}
              onChange={v => {
                setShip(v.target.value);
              }}
            />
          </div>
          </div>
        <div className='row'>
       
       
          <div className="col-12 form-group">
            <label>Additional Information</label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter Description here"
              value={add}
              onChange={v => {
                setAdd(v.target.value);
              }}
            />
          </div>
        </div>

        <center>
        <button onClick={()=>(
            setView(1)
        )
        } className='btn-success mx-3 px-4 py-2 my-4'> Back </button>
        <button onClick={()=>(
            setView(3)
        )
        } className='btn-success px-4 py-2 my-4'> Next </button>

        </center>

</>:null
}
{view===3?<>


<br/>
<div className="row">
          <div className="col-6 form-group">
            <label>Primary Email</label>
            <input
              className="form-control"
              type="text"
             
              value={primary}
              onChange={v => {
                setPrimary(v.target.value);
              }}
            />
          </div>
          <div className="col-6 form-group">
            <label>Alternate Email</label>
            <input
              className="form-control"
              type="text"
              
              value={alternate}
              onChange={v => {
                setAlternate(v.target.value);
              }}
            />
          </div>
    
        </div>
        <div className="row">
          <div className="col-6 form-group">
            <label>Contact No.</label>
            <input
              className="form-control"
              type="text"
              
              value={contact}
              onChange={v => {
                setContact(v.target.value);
              }}
            />
          </div>
          <div className="col-6 form-group">
            <label>Address</label>
            <input
              className="form-control"
              type="text"
              
              value={address}
              onChange={v => {
                setAddress(v.target.value);
              }}
            />
          </div>
          </div>
        

        <center>
        <button onClick={()=>(
         
          setView(2)
        )
        } className='btn-success px-4 py-2 mx-2 my-4'> Back </button>
        <button onClick={()=>(

         
        Sendquote()
        )
        } 
        className='btn-success px-4 py-2 my-4'> Submit </button>

        </center>

</>:null
}   
                  </div>
                  </div>
</section>
</div>

</div></div>
        
    </body>
  )
}

export default Sendquote