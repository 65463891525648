import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Nav from "../../../navbar2";

function Buyleads() {
  const history = useNavigate();

  const [productList, setProductList] = useState([]);
  const [uid, setUid] = useState("");

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      Viewpurchase(v[0].id);
    }
  }, []);

  const Viewpurchase = async (uid) => {
    const data = new FormData();
    data.append("uid", uid);
    data.append("val", "uid");

    axios({
      method: "post",
      url: "/pro/khata/khata_purchase_view.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("PO list ", s.data);
        const a = s.data;
        console.log('list Purchase Order',a)
        // if (a[0].code != 'fail') {
          setProductList(a);
        // }
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  return (
    <body>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Manage Purchase Order </h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                      <input
                        style={{ borderRadius: "18px", paddingLeft: "8px" }}
                        placeholder="search"
                      />
                      <span>
                        <Link to="/adpurchaseorder">
                          <button
                            style={{ borderRadius: "18px", color: "white" }}
                            className="bg-primary mx-3 px-4 "
                          >
                         Add Purchase Order 
                          </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>PO No</th>
                          <th>PO Date</th>
                          <th>Delivery Date</th>
                          <th>PO Details</th>
                          {/* <th>Payment Details</th> */}
                          <th>Total Amount</th>
                          <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productList.map((v, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{v.po}</td>
                              <td>{v.invoicedate}</td>
                              <td>{v.deldate}</td>
                              <td>{v.po}</td>
                              <td>{new Intl.NumberFormat('en-pk').format(v.total)}</td>
                              <td>
                              <label
                                      style={{color: 'red', cursor: 'pointer'}}
                                     >
                                      Delete
                                    </label>

                              </td>
                             
                              {/* <td>{v.iid}</td>
                              <td>{v.invoicedate}</td>
                              <td>{JSON.parse(v.cinfo)[0].name}</td>
                              <td>{JSON.parse(v.cinfo)[0].email}</td>

                              <td>{v.comments}</td>
                              <td>{v.total}</td> */}
                              {/* <td>Return</td> */}
                              {/* <td>{v.invoicedate}</td> */}

                              {/* <td> {JSON.parse.array[0].name}</td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
