import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';

function Mainpage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("")
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [uid, setUid] = useState('');
  const [uid2c, setUid2c] = useState('');
  const [array, setarray] = useState([]);
  const [view, setView] = useState(false);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
    }
  }, []);

  const getdetail = async () => {
    const data = new FormData();
  
    data.append('email', email);
   
    // data.append('uid', uid);
    // data.append('array', JSON.stringify(client));

    axios({
      method: 'post',
      url: '/pro/khata/khata_getdatabyemail.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);
if(s.data[0].code=="pass")
{
  if(s.data[0].uid2c==uid)
  {
 alert("Enter Other User Email")
  }else{
    setPhone(s.data[0].phone);
    setAddress(s.data[0].add);
    setName(s.data[0].name);
    setUid2c(s.data[0].uid2c);
    setView(true);

    // 
    setarray(s.data)
  }
 
}else{
  alert("user does not exist")
  setView(false);
}
       
       
        
        // navigate('/newinvoice');
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };


  const saveData = async () => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('cid', uid2c);
    data.append('array', JSON.stringify(array));
    data.append('val', 'add_client');

    axios({
      method: 'post',
      url: '/pro/khata/manage_khata.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {

        console.log(s.data);
        if(s.data[0].code=="empty")
        {
 
  alert('please enter Right email')
 
}
else if(s.data[0].code=="already"){
 alert('Client already Existed')
}else if (s.data[0].code=="pass"){
  
  navigate('/newinvoice');
  
 }else{
  alert("`Something` Went Wrong.. Please Try Again")
 }


       
       
        
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  // const addClicent = async () => {
  //   const data = new FormData();
  //   data.append('email', email);
  //   data.append('uid', email);
  //   data.append('array', JSON.stringify(client));

  //   axios({
  //     method: 'post',
  //     url: '/pro/khata/khata_client_add.php',
  //     data: data,
  //     headers: {'Content-Type': 'multipart/form-data'},
  //   })
  //     .then(s => {
  //       console.log(s.data);
  //       navigate('/newinvoice');
  //     })
  //     .catch(s => {
  //       console.log('here catch =>>', s);
  //     });
  // };


  // const getdetail = async () => {
  //   const data = new FormData();

  //   data.append('email', email);

  //   axios({
  //     method: 'post',
  //     url: '/pro/khata/khata_getdatabyemail.php',
  //     data: data,
  //     headers: {'Content-Type': 'multipart/form-data'},
  //   })
  //     .then(s => {
  //       console.log(s.data);

  //       if (s.data[0].code === 'pass') {
  //         setname(s.data[0].name);
  //       } else {
  //         setMsg('Enter Right Wallet ?');
  //         setAlert(true);
  //       }
  //     })
  //     .catch(s => {
  //       console.log('here catch =>>', s);
  //     });
  // };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title text-center">
                    <h3>New Client</h3>
                  </div>

                  {/* Form Start */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-title">
                          {/* <h5>Invite New Client </h5> */}
                        </div>

                        
                        <div className="card-body">
                          <div className="basic-elements">



                            
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Email</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={email}
                                  onChange={e => {
                                    setEmail(e.target.value)
                                  }}
                                />
                              </div>

{
  view? <div className="col-6 form-group">
  <label>Business Name</label>
  <input
  disabled
    className="form-control"
    value={name}
   
    type="text"
  />
</div>:""
}
                             
                            </div>

                            <div className="row">
                            {
  view? <div className="col-6 form-group">
                                <label>Address</label>
                                <input
                                  disabled
                                  className="form-control"
                                  type="text"
                                  value={address}
                                
                                />
                              </div>:""
}
{
  view?  <div className="col-6 form-group">
  <label>Phone</label>
  <input
    disabled
    className="form-control"
    type="number"
    value={phone}
   
  />
</div>:""
}
                             
                            </div>
                          
{view?<> <div
                              onClick={() => saveData()}
                              className="btn btn-primary my-4 ">
                             Add Client
                            </div></>:<> <div
                              onClick={() => getdetail()}
                              className="btn btn-primary my-4 text-center">
                              Save
                            </div></>}
                           
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
