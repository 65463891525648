import Nav from "./navbar";
import Footer from "./footer";
import { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import Whatsapp from "./whatsapp";
import Load from "./load";

function Main() {

  const [allcats, setAllcats] = useState([]);
  
  useEffect(() => {
    getServercats();
  }, []);

  const getServercats = async () => {
    const data = new FormData();

    data.append("id", "0");
    //data.append('array', JSON.stringify(profile));

    axios({
      method: "post",

      url: "/mainsite/suball.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllcats(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  return (
    <div style={{ backgroundColor: "#F4F5F8" }}>
      <Nav />
      <Whatsapp />
    
      <div className="container">
        {/* <article className="my-4">
          <img src="assets/images/banners/fulsize.png" />
        </article> */}
        <section className="padding-bottom  no-gutters">
          {allcats.map((v, i) => (
            <>
            

              <div className="card card-home-category">
                <div className="row ">
                <div className="col-md-3">
                    <div
                    
                      style={{ backgroundColor: "#d5dff7" }}
                      className="home-category-banner"
                    >
                       <h3 className="title-section" style={{fontSize:"20px"}}>{v.title}</h3>
                      {/* <h5>Medical Appliances</h5> */}
                      <a
                      
                        href={"sub?id=" + v.id + "&s=" + v.title}
                        className="btn btn-outline-primary rounded-pill mt-3"
                        onClick={() => {
                          localStorage.setItem("title", v.title);
                        }}
                      >
                        View All
                      </a>
                      <img  src={v.img} style={{height:"300px", width:"100%"}} className="img-bg" />
                    </div>
                  </div>
                  <div className="col-md-9">
                    <ul className="row no-gutte0rs bordered-cols">
                      {v.code == "sub" ? 
                      <>
                          {v.child.map((vv, ii) => (
                            <>
                              <li className="col-6 col-lg-3 col-md-4">
                                <a
                                  onClick={() => {
                                    localStorage.setItem("title", vv.title);
                                  }}
                                  href={"sub?id=" + vv.id + "&s=" + vv.title}
                                  className="item"
                                >
                                  <div className="card-body">
                                   
                                    <img
                                      style={{height:"150px", width:"100%"}}
                                      className="text-center "
                                     
                                      src={vv.img}
                                    />
                                     <h6
                                      style={{ color: "black" }}
                                      className="mt-3 text-center"
                                    >
                                      {vv.title}
                                    </h6>
                                  </div>
                                </a>
                              </li>
                            </>
                          ))}
                      </>
                      : 
                        <>
                          {v.child.map((vv, ii) => (
                            ii<= 7 ? 
                            <>
 
                        <li className="col-6 col-lg-3 col-md-4">
                                <a
                                 href={"product?id=" + vv.id + "&s=" + vv.name}
                                 
                                  // onClick={() => {
                                  //   localStorage.setItem("title", vv.title);
                                  // }}
                                
                                  className="item"
                                >
                                  <div className="card-body">
                                   
                                    <img
                                      style={{height:"150px", width:"100%"}}
                                      className="text-center "
                                     
                                      src={vv.img}
                                    />
                                     <h6
                                      style={{ color: "black" }}
                                      className="mt-3 text-center"
                                    >
                                      {vv.name}
                                    </h6>
                                    <figcaption className="info-wrap text-center ">
                                        <Button
                                          variant="primary"
                                          className="my-2 "
                                        >
                                          Get Quote
                                        </Button>
                                      </figcaption>
                                  </div>
                                </a>
                              </li>

                              {/* <li className="col-6 col-lg-3 col-md-4">
                                <a
                                  href={"product?id=" + vv.id + "&s=" + vv.name}
                                  className="item"
                                >
                                  <center>
                                    <div className="card-body">
                                      <h6 className="title">{vv.name}</h6>
                                      <img
                                        className="img-sm float-right"
                                        src={vv.img}
                                      />
                                
                                      <figcaption className="info-wrap ">
                                        <Button
                                          variant="primary"
                                          className="my-2 "
                                        >
                                          Get Quote
                                        </Button>
                                      </figcaption>
                                      
                                    </div>
                                  </center>
                                </a>
                              </li> */}
                            </>:<></>
                          ))}
                      </>
                      



                      }
                     
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ))}
        </section>

        {/* <div className="row padding-bottom">
            <div className="col-6">
              <img
                height={270}
                width="100%"
                src="assets/images/banners/slide3.jpg"
              />{" "}
            </div>
            <div className="col-6">
              <img
                width="100%"
                height={270}
                src="assets/images/banners/slide3.jpg"
              />{" "}
            </div>
          </div> */}

        <article className="my-4">
          <img src="assets/images/banners/ad-sm.png" className="w-100" />
        </article>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
