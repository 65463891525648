import React from 'react';
import Nav from '../../../navbar';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

import {useState, useEffect} from 'react';
import { set } from 'firebase/database';
function Employee() {
  const navigate = useNavigate();

  const [product, setproduct] = useState([]);
  const [employee, setEmployee] = useState('');
  const [salary, setSalary] = useState('');
  const [tax, setTax] = useState('');
  const [month, setMonth] = useState('January');
  const [bonus, setBonus] = useState('');
  const [date, setDate] = useState('');
  const [income, setIncome] = useState('');
  const [uid, setUid] = useState('');
  const [e_id, setE_id] = useState('');



  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      getProduct(v[0].id);
    }
  }, []);


  const getProduct = async uid => {
    const data = new FormData();
    data.append('val', 'view');
    data.append('uid', uid);

    axios({
      method: 'post',
      url: 'pro/office/manage_employe.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('product list ', s.data);
        setproduct(s.data);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };


  const addpayroll = async () => {
    const data = new FormData();
    data.append('val', 'in');
    data.append('uid', uid);
    data.append('eid', e_id);
    data.append('status', 'active');
    data.append('employee', employee);
    data.append('pay', salary);
    data.append('tax', tax);
    data.append('month', month);
    data.append('bonus', bonus);
    data.append('date', date);
    data.append('total', income);

    axios({
      method: 'post',
      url: 'pro/office/manage_payroll.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);
        navigate('/payrolllist');
        //if (s.data[0].code === 'pass') {
        // alert('thanks');
        //}
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  return (
    <body>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Payroll</h4>
                  </div>

                  <div className="card-body">
                    <div className="row ">
                      <div className="col-6 form-group">
                        <label>Select Employee </label>
                        <select
                          value={employee}
                          onChange={v => {

                            console.log(v.target.value)
                           if(v.target.value=="val")
                           {
                            setEmployee("");
                            setE_id("")
                            setSalary("")
                            setTax("")
                            setIncome("")
                           }else{
                            setEmployee(v.target.value);
                            setE_id(product[v.target.value].id)
                            setSalary(product[v.target.value].pay)
                            setTax(product[v.target.value].tax)
                            setIncome(Number(product[v.target.value].pay)-Number(product[v.target.value].tax))
                            setBonus("")
                            
                           }
                          }}
                          className="form-control">
                          <option value="val">Select Employee  </option>
                          {product.map((v, i) => (
                            <option key={i} value={i}>
                              {v.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 form-group">
                        <label>Monthly Salary</label>
                        <input
                          value={salary}
                          onChange={v => {
                            setSalary(v.target.value);
                            setIncome((Number(bonus)+Number(v.target.value))-Number(tax))
                          }}
                          type="number"
                          className="form-control"
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Tax Amount (%)</label>
                        <input
                          value={tax}
                          onChange={v => {
                            setTax(v.target.value);

                            setIncome((Number(salary)-Number(v.target.value))+Number(bonus))



                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-6 form-group">
                        <label>Month</label>
                        <select
                          value={month}
                          onChange={v => {
                            setMonth(v.target.value);
                          }}
                          className="form-control">
                          <option>January</option>
                          <option>February</option>
                          <option>March</option>
                          <option>April</option>
                          <option>May</option>
                          <option>June</option>
                          <option>July</option>
                          <option>August</option>
                          <option>September</option>
                          <option>October</option>
                          <option>November</option>
                          <option>December</option>
                          
                        </select>
                      </div>
                      <div className="col-6 form-group">
                        <label>Monthly Bonus</label>
                        <input
                          value={bonus}
                          onChange={v => {
                            setBonus(v.target.value);
                            setIncome((Number(salary)+Number(v.target.value))-Number(tax))
                          }}
                          type="taxt"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 form-group">
                        <label>Total Income (%)</label>
                        <input
                          value={income}
                          onChange={v => {
                            setIncome(v.target.value);
                          }}
                          type="text"
                          className="form-control"
                          readOnly
                        />
                      </div>

                      <div className="col-6 form-group">
                        <label>Date</label>
                        <input
                          value={date}
                          onChange={v => {
                            setDate(v.target.value);
                          }}
                          type="date"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div>
                      <center>
                        <button
                          onClick={() => {
                            addpayroll();
                          }}
                          className="bg-success px-4 py-1 mt-3">
                          {' '}
                          Pay
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Employee;
