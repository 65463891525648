import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import {Link} from 'react-router-dom';
import {
  PlusCircleFill,
  PlusCircleDotted,
  Trash,
  Trash3,
} from 'react-bootstrap-icons';
import Nav from '../../../navbar';

function Mainpage() {
  const history = useNavigate();
  const [image, setImage] = useState('');
  const [titel, settitel] = useState('');
  const [uid, setUid] = useState('');
  const [des, setDes] = useState('');
  const navigate = useNavigate();
  const [alert, setAlert] = useState('');
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
    }
  }, []);
  const addCertificat = async () => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('img', image);
    data.append('title', titel);
    data.append('des', des);

    axios({
      method: 'post',
      url: '/pro/profile/profile_certificatereward.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('here then =>>', s.data);
        if (s.data[0].code == 'pass') {
          alert('save');
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Certification & Awards </h4>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div
                        className="row my-4"
                        style={{
                          height: '100px',
                          border: '1px dotted black',
                        }}>
                        <label className="my-2">
                          Upload Certificate & Awards as Image
                        </label>
                        <input
                          onChange={v => {
                            setImage(v.target.files[0]);
                          }}
                          type="file"
                        />
                      </div>

                      <div className="row">
                        <div className="col-12 form-group">
                          <label>Certificate Title</label>
                          <input
                            className="form-control"
                            placeholder="Enter Certificate's Title"
                            onChange={v => {
                              settitel(v.target.value);
                            }}
                            value={titel}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 form-group">
                          <label>Certificate Description</label>
                          <textarea
                            className="form-control"
                            rows={10}
                            onChange={v => {
                              setDes(v.target.value);
                            }}
                            value={des}
                            placeholder="Enter Detailed Description"></textarea>
                        </div>
                      </div>
                    </div>
                    {/* <div style={{marginTop: '18px'}} className="form-group">
                      <button
                        onClick={() => {
                          addCertificat();
                        }}
                        className="btn btn-success mx-2">
                        Submit
                      </button>
                    </div> */}

                    <div
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                      className="form-group">
                      <button
                        className=" btn btn-primary px-5 mt-4"
                        onClick={() => {
                          addCertificat();
                          setAlert(!alert);
                        }}>
                        Add Now
                      </button>
                      {alert ? (
                        <>
                          {' '}
                          <SweetAlert
                            success
                            confirmBtnText="Confirm"
                            confirmBtnBsStyle="success"
                            title="Director Saved"
                            onConfirm={() => {
                              setAlert(!alert);
                              history('/certificate');
                            }}
                            onCancel={() => {
                              setAlert(!alert);
                            }}
                            focusCancelBtn>
                            <br />
                          </SweetAlert>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
