import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Nav from "../../../navbar";
function Buyleads() {
  const [uid, setUid] = useState("");
  const [iid, setIid] = useState("");
  const [price, setPrice] = useState("");
  const [product, setproduct] = useState([]);
  const [productselect, setproductSelect] = useState("");
  const [productid, setproductId] = useState("");
  const [units, setUnits] = useState("");
  const [ppunit, setPpunit] = useState("");
  const [amount, setAmount] = useState("");
  const [sold, setSold] = useState("");
  const [productList, setProductList] = useState([]);
  const [tamount, setTamount] = useState("");

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);

      getProduct(v[0].id);
      getInvoiceid(v[0].id);
    }
  }, []);

  const getInvoiceid = async (uid) => {
    const data = new FormData();
    data.append("uid", uid);

    axios({
      method: "post",
      url: "/pro/inventory/invoice.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Invoice ID ", s.data);
        setIid(s.data[0].in);
        viewInvoiceItems(s.data[0].in);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const updateInvoiceId = async () => {
    const data = new FormData();
    data.append("iid", iid);

    axios({
      method: "post",
      url: "/pro/inventory/updateinvoice.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Invoice Updated", s.data);
        if(s.data[0].code=="pass"){
          getInvoiceid(uid);
        }
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const delItem = async (id) => {
    const data = new FormData();
    data.append("iid", iid);
    data.append("id", id);

    axios({
      method: "post",
      url: "/pro/inventory/del_items.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("deleted ", s.data);
        //viewInvoiceItems(iid);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const getProduct = async (uid) => {
    const data = new FormData();
    data.append("val", "view");
    data.append("uid", uid);

    axios({
      method: "post",
      url: "/pro/inventory/submit_productinfo.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("product list ", s.data);
        setproduct(s.data);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
 const totalamount = async (am)=>{
  am.map((v,i)=>{
    setTamount(v.tam)
  })
 }
  const viewInvoiceItems = async (iid) => {
    const data = new FormData();
    data.append("iid", iid);

    axios({
      method: "post",
      url: "/pro/inventory/display_invoice_items.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Item lists ", s.data);
        const a = s.data;
        setProductList(a);
        totalamount(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const addProduct = async () => {
    const data = new FormData();
    data.append("uid", uid);
    data.append("pid", productid);
    data.append("iid", iid);
    data.append("units", units);
    data.append("ppunit", ppunit);
    data.append("amount", amount);

    axios({
      method: "post",
      url: "/pro/inventory/invoice_items.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("sale invoice ", s.data);
        //alert(productselect)
        if (s.data[0].code === "pass") {
          viewInvoiceItems(iid);
        }
        if (s.data[0].code === "stock") {
          const msg = s.data[0].msg;
          alert(msg);
        }
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  return (
    <body>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}
            <div style={{marginLeft:"20px"}} >
            <Link to="/inventory">
            <button
            className="btn-secondary px-2"  >
            <i style={{paddingRight:"10px"}} className="ti-arrow-left"></i>
              Back
            </button>
            </Link>
            </div>
            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>Sale</h3>
                  </div>

                  {/* Form Start */}

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-title">
                          <h5>Add New Sale </h5>
                        </div>
                        <div className="card-body">
                          <div className="basic-elements">
                          <div className="col-6 form-group">
                                <label>Price Per Unit </label>
                                <input
                                  value={price}
                                  disabled
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            <div className="row">
                              <div className="col-4 form-group">
                                <label>Product </label>
                                <select
                                  className="form-control"
                                  value={productselect}
                                  onChange={(ve) => {
                                    //setpid()

                                    setproductSelect(ve.target.value);
                                    setproductId(product[ve.target.value].id);
                                    setPrice(product[ve.target.value].ppunit);
                                    setAmount(
                                      Number(product[ve.target.value].ppunit) *
                                        Number(units)
                                    );
                                    setPpunit(product[ve.target.value].ppunit);
                                  }}
                                >
                                  <option>Select Product</option>
                                  {product.map((v, i) => (
                                    <option key={i} value={i}>
                                      {v.p_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-2 form-group">
                                <label>Sold Price </label>
                                <input
                                  value={sold}
                                  onChange={(v)=>{
                                    setSold(v.target.value)
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-2 form-group">
                                <label>Product Units </label>
                                <input
                                  value={units}
                                  onChange={(v) => {
                                    setUnits(v.target.value);
                                    setAmount(sold * Number(v.target.value));

                                    // setAmount(Number(v.target.value)*Number(ppunit))
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                           
                              <div className="col-2 form-group">
                                <label>Amount </label>
                                <input
                                  value={amount}
                                  disabled
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>

                            <div
                              className="btn btn-primary"
                              onClick={() => {
                                if(productid!="" || iid!="" || units!="" || ppunit!="" || amount!=""){
                                  addProduct();
                                }
                                
                              }}
                            >
                               Add Product
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-title">
                          <h4>
                            <b>Invoice Details</b>
                          </h4>
                        </div>
                        <div className="row">
                          <div className="col-lg-9 col-sm-1"></div>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-hover ">
                              <thead>
                                <tr>
                                  <th>ID</th>
                                  <th>Product Name</th>
                                  <th>Units</th>
                                  <th>Price Per Unit</th>
                                  <th>Amount</th>

                                  <th>control</th>
                                </tr>
                              </thead>
                              <tbody>
                                {productList.length === 0 ? (
                                  <>
                                    <tr>
                                      <td
                                        style={{
                                          justifyContent: "center",
                                          alignItems: "center",
                                          textAlign: "center",
                                        }}
                                        colSpan={10}
                                      >
                                        No Record to show{" "}
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  <>
                                    {productList.map((v, i) => (
                                      <tr>
                                        <td>{v.count}</td>
                                        <td>{v.name}</td>
                                        <td>{v.units}</td>
                                        <td>{v.ppunit}</td>
                                        <td>{v.amount}</td>
                                        <td
                                          onClick={() => {
                                            delItem(v.id);
                                          }}
                                        >
                                          <label style={{color:"red", cursor:"pointer"}}>Delete</label>
                                        </td>
                                      </tr>
                                      
                                    )
                                    )}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div>
                        
                         
                            <label>Total Amount: {tamount}</label>
                          
                        </div>
                        <div
                              className="btn btn-primary"
                              onClick={() => {
                                if(productList!="" ){
                                  //addProduct();
                                  updateInvoiceId();
                                }
                                
                              }}
                            >
                               SAVE INVOICE
                            </div>
                        <div className="col-lg-3 col-sm-10">
                          {/* <div className="btn btn-primary" onClick={
                                ()=>{
                                  saveInvoice(iid)
                                }
                              } >
                                Save Invoice
                              </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Form ends here..  */}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
