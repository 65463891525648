import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Login() {



  const navigate = useNavigate();
  const [error, seterror] = useState("");
  const [name, setName] = useState("");
  const [otp, setOTP] = useState("");



  const [img, setImg] = useState("");
 




  useEffect(() => {

 
      
      
  }, []);

  
  
   const VarifyOTP=()=>{
    const data = new FormData();
    // alert(otp)
    data.append("otp", otp);
    data.append("status", "2");

    axios({
      method: "post",
      url: "/pro/auth/verify_user.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("===>  ",s.data)
        if (s.data[0].code == "pass") {
          alert("Thank you!");
          navigate("/login");
        } else if (s.data[0].code == "fail") {
          alert("OTP Worng !");
          seterror("name")

         
        }
        else {
          alert ("OTP Expired");
          
        }
      })
      .catch((s) => {
        
      });
  }


  return (
    <body
      style={{
        backgroundImage: `url("https://alahdeen.com/pro/image/test.jpg")`,
       // backgroundImage: `url("https://alahdeen.com/image/signup.png")`,
     
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        
      }}
    >
      <div  className="unix-login">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="login-content">
                <div className="login-logo">
                  {/* <a href="index.html">
                    <span>Alahdeen</span>
                  </a> */}
                </div>
                <div style={{opacity: 0.9}} className="login-form">
                  <h4 className="text-uppercase">
                    {/* <i>Registeration Form</i> */}
                  </h4>
                  <center>
                    <img
                   
                      onClick={() => {
                        navigate("/");
                        // window.open("https://alahdeen.com/", "_blank");
                      }}
                      style={{
                        // marginTop: "20px",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      src="assets/images/logob.png"
                      height={90}
                    />
                  </center>


                  <div className="row">
                  <div className= {error =="name"?"form-group col-12 has-error":"form-group col-12"} >
                    <label>One Time Password (OTP) </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="6 Digit Code"
                      value={otp}
                      onChange={(v) => {
                        if(v.target.value.length<7)
                        {
                          setOTP(v.target.value);
                        }
                        
                      }}
                    />
                  </div>
                  
                  </div>

                    <button
                    type="submit"
                    onClick={() => {
                    
                      if(otp.length<6)
                      {
                        alert("Worng OTP")
                      }else{
                        VarifyOTP()
                      }
                    }}

                    className="btn btn-primary btn-flat m-b-30 m-t-30"
                  >
                    Sign up
                  </button>

                  <div className="register-link m-t-15 text-center">
                   
                      <p style={{ color: "blue",cursor:"pointer" }}>
                        Again send OTP ?
                      </p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Login;
