
import React from 'react'
import Nav from "./navbar";
import axios from "axios";
import { useState, useEffect } from "react";
import "./index.css"
function Cat() {

const [vendor, setVendor] = useState([])
  useEffect(() => {
    const aa = localStorage.getItem("USER");



    const search = window.location.search;
    const params = new URLSearchParams(search);

    const spplier = params.get("name");

    ShowData(spplier);

  }, []);



  const ShowData = async (spplier) => {
    const data = new FormData();
    data.append("key", spplier);
    data.append("val", "supplier");
    axios({
      method: "post",
      url: "/pro/manage_search.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
       
        console.log("Markets Data", s.data);
        setVendor(s.data)
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  return (
    <div>
      <Nav/>




      <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
<div class="container">
    
    <h3 className='text-center'></h3>
    
    <div class="row">
   
      {
      vendor.map((v, i)=>(
<>
{i <= 120?<>

  <div  class="col-lg-4">
            <div  class="card m-b-30">
                <div style={{height:"200px"}} class="card-body">
                    <div class="media">
                        <img class="d-flex mr-3 rounded-circle img-thumbnail thumb-lg" src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="Generic placeholder image" />
                        <div class="media-body">
                            <h5 class="mt-0 mb-3 text-center font-18 mb-1">{v.cname}</h5>
                            <p class="text-muted text-center font-14">{v.add === ""?<></>:<>{v.add}</>}</p>
                            {/* <p class="text-muted text-center font-14">{v.city}</p> */}
                            <p class="text-muted text-center font-14">Contact: {v.phone === ""?<></>:<>{v.phone}</>}</p>
                            <p class="text-muted text-center font-14"> <a href={'companydetail?business='+v.id}> Visite Profile</a> </p>
                            {/* <ul class="social-links list-inline mb-0">
                                <li class="list-inline-item">
                                    <a title="" data-placement="top" data-toggle="tooltip" class="tooltips" href="" data-original-title="Facebook"><i class="fa fa-facebook"></i></a>
                                </li>
                                <li class="list-inline-item">
                                    <a title="" data-placement="top" data-toggle="tooltip" class="tooltips" href="" data-original-title="Twitter"><i class="fa fa-twitter"></i></a>
                                </li>
                                <li class="list-inline-item">
                                    <a title="" data-placement="top" data-toggle="tooltip" class="tooltips" href="" data-original-title="1234567890"><i class="fa fa-phone"></i></a>
                                </li>
                                <li class="list-inline-item">
                                    <a title="" data-placement="top" data-toggle="tooltip" class="tooltips" href="" data-original-title="@skypename"><i class="fa fa-skype"></i></a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
</>:<></>

}
     

</>

      ))}
       
        {/* <!-- end col --> */}
       
        {/* <!-- end col --> */}
    </div>
    {/* <!-- end row --> */}
  
    {/* {/* <!-- end row --> */} 
</div>
    </div>
  )
}

export default Cat