import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

function Mainpage() {
  const [allused, setAllused] = useState([]);
  const [allPurchased, setpurchased] = useState([]);



  const navigate = useNavigate();

  const allPurchasedPackages = async (id) => {

    const data = new FormData();
    data.append('uid', id);


    axios({
      method: 'post',
      url: '/pro/usedpackages_all.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);

        if (s.data == '') {
          console.log('data. s is empty packages.js');
        } else {
          if (s.data[0].msg == 'pass') {
            setpurchased(s.data)
            
          } 
        }
      })
      .catch(s => {
        console.log('catch issue  packages.js');
      });
  };
  const usedpackages = async (id) => {

    const data = new FormData();
    data.append('uid', id);


    axios({
      method: 'post',
      url: '/pro/usedpackages.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);

        if (s.data == '') {
          console.log('data. s is empty packages.js');
        } else {
          if (s.data[0].msg == 'pass') {
            setAllused(s.data)
            
          } 
        }
      })
      .catch(s => {
        console.log('catch issue  packages.js');
      });
  };
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
      navigate('/login');
    } else {

    
      const a = localStorage.getItem('package_select')
    
      usedpackages(v[0].id)
      allPurchasedPackages(v[0].id)

    }
  }, []);
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Subscription</h4>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Used Leads</th>
                            <th>Remaining Leads</th>
                            <th>Total Lead</th>
                            <th>Validity</th>
                            <th>Last Update</th>
                          </tr>
                        </thead>
                        <tbody>
                     {
                       allused.length===0?<>     <tr>
                       <td
                         style={{
                           justifyContent: 'center',
                           alignItems: 'center',
                           textAlign: 'center',
                         }}
                         colSpan={10}>
                         No Record to show{' '}
                       </td>
                     </tr></>:
                     <tr>
                       <td>{allused[0].id}</td>
                       <td>{allused[0].used}</td>
                       <td>{Number(allused[0].total)-Number(allused[0].used)}</td>
                       <td>{allused[0].total}</td>
                       <td>{allused[0].validity}</td>
                       <td>{allused[0].time}</td>
                     </tr>
                     }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* 2nd Table  */}

            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Subscriptions Order History</h4>
                  </div>
              
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Plan</th>
                            {/* <th>Product Showcase</th>
                            <th>Product Posting</th>
                            <th>Bonus leads</th> */}
                 
                            <th>Total Amount</th>
                            <th>Validity	</th>
                            
                            <th>Start At</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       allPurchased.length===0?<>     <tr>
                       <td
                         style={{
                           justifyContent: 'center',
                           alignItems: 'center',
                           textAlign: 'center',
                         }}
                         colSpan={10}>
                         No Record to show{' '}
                       </td>
                     </tr></>:
                    <>
                    {
                      allPurchased.map((v,i)=>(
                      
                        <tr>
                            {
                           
                        }
                          <td>{i+1} </td>
                          <td>{JSON.parse(v.array).title} </td>
                          <td>{JSON.parse(v.array).purchase_price} </td>
                          <td>{JSON.parse(v.array).month} Month </td>
                         
                          <td>{v.time} </td>
                        </tr>
                      ))

                    }
                    </>
}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
