import { useState, useEffect } from "react";
import Nav from "../../../navbar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
function Mainpage() {
  const navigate = useNavigate();
  const [wid, setWid] = useState();
  const [uid, setUid] = useState();
  const [booking, setBooking] = useState([]);
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);

      const wid = localStorage.getItem("wid");

      setWid(wid);
      if (uid == "" && wid == "") {
      } else {
        Booking(v[0].id, localStorage.getItem("wid"));
      }
    }
  }, []);
  const Booking = async (mid, mwid,bookerid) => {
    const data = new FormData();

    data.append("wid", mwid);
    data.append("uid", mid);
    data.append("booker",bookerid);

    // data.append('array', JSON.stringify(client));

    axios({
      method: "post",
      url: "/pro/warehouse_req_lists.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log(s.data);
        setBooking(s.data);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const booked_warehouse = async (wuid, w,b) => {
    const data = new FormData();

     data.append("wid", w);
     data.append("uid", wuid);
     data.append("bookerid", b);

     axios({
       method: "post",
       url: "/pro/warehouse_booking.php",
       data: data,
       headers: { "Content-Type": "multipart/form-data" },
     })
       .then((s) => {
         console.log(s.data);
         //setBooking(s.data);
         navigate("/warehouselist");
       })
       .catch((s) => {
         console.log("here catch =>>", s);
       });
  };

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>
                      Booking Requests
                    </h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                      <input
                        style={{ borderRadius: "18px", paddingLeft: "8px" }}
                        placeholder="search"
                      />
                      <span>
                        <button
                          style={{ borderRadius: "18px", color: "white" }}
                          className="bg-primary mx-3 px-4 "
                        >
                          Search
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Booking ID</th>
                            <th>Warehouse ID</th>
                            <th>Request By</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Amount</th>
                            <th>Booking Type</th>
                            <th>Status</th>

                            <th>Created At</th>
                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          {booking.length == 0 ? (
                            <>
                              {" "}
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={10}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}

                          {booking.map((v, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{v.rid}</td>
                              <td>{v.wid}</td>
                              <td>{v.bookedby}</td>
                              <td>{v.strtdate}</td>
                              <td>{v.endate}</td>
                              <td>{v.bookingam}</td>
                              <td>{v.type}</td>
                              <td>{v.status}</td>
                              <td>{v.time}</td>
                              <td>
                                <button
                                  onClick={() => {
                                    if(v.bookerid!="" && uid !="" && v.wid!=""){
                                      booked_warehouse(uid,v.wid,v.bookerid);
                                    }else{

                                    }
                                    
                                  }}
                                  className="btn-success"
                                >
                                  Accept
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
