import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
function Mainpage() {
  const navigate = useNavigate();
  const[uid, setUid]=useState('')
  const[name, setname]=useState('')
  
  const[allchallan, setAllchallan]=useState([])

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      setname(v[0].name);
      getchallan(v[0].id);
    }
  }, []);
 
 
 
  const getchallan = async (id) => {
    const data = new FormData();
    data.append("uid", id);
    data.append("val", "view_challan");
    axios({
      method: "post",
      url: "/pro/khata/manage_challan.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllchallan(s.data);
        console.log("all deliverychallan", s.data);
       
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
   };
 



  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Manage Delivery Challans</h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                      <input
                        style={{borderRadius: '18px', paddingLeft: '8px'}}
                        placeholder="search"
                      />

                      <span>
                        <button
                          onClick={() => {
                            navigate('/adddeliverychallan');
                          }}
                          style={{borderRadius: '18px', color: 'white'}}
                          className="bg-primary mx-3 px-4 ">
                          Add Challan
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>From</th>
                            <th>To</th>
                            <th>Challan Date</th>
                            <th>Items Included</th>
                            <th>No of Pieces</th>
                            <th>Approx Weight</th>
                            <th>Bilty No</th>
                            <th>Courier Name</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>  
                          {allchallan.map((v, i) => (
                        <>
                        <tr  >
                          <td className='text-uppercase'>{name}</td>
                          <td>{v.username}</td>
                          <td>{JSON.parse(v.challanarray)[0].challandate}</td>
                          <td>{JSON.parse(v.challanarray)[0].item}</td>
                          <td>{JSON.parse(v.challanarray)[0].piece}</td>
                          <td>{JSON.parse(v.challanarray)[0].weight}</td>
                          <td>{JSON.parse(v.challanarray)[0].builtyno}</td>
                          <td>{JSON.parse(v.challanarray)[0].courier}</td>
                          <td style={{color:"red", fontWeight:"bolder"}}>Sent</td>
                        </tr>

                        </>
                          
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
