import Nav from '../../../navbar';
import {Link, useNavigate} from 'react-router-dom';

import SweetAlert from 'react-bootstrap-sweetalert-te';
import {useState, useEffect} from 'react';
import axios from 'axios';

function Mainpage() {
  const [uid, setuid] = useState('');
  const [title, setTitle] = useState('');

  const [alert2, setAlert2] = useState();
  const [extra, setExtra] = useState([]);
  const [file, setfile] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setuid(v[0].id);
    }
  }, []);

  const addcat = async () => {
    // e.preventDefault();

    if (title === '' || file === '') {
      alert('Fill the form');
    } else {
      const data = new FormData();
      data.append('name', title);
      data.append('uid', uid);
      data.append('extra', extra);
      data.append('file', file);

      axios({
        method: 'post',
        url: '/pro/catlog_upload.php',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'},
      })
        .then(s => {
          console.log(s.data);

          if (s.data[0].code === 'pass') {
            setAlert2(true);
            navigate('/catalog');
          } else {
             setAlert2(true);
            // alert('Uploaded');
             //
          }
        })
        .catch(s => {
          //navigate("/")
        });
    }
  };

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>Add Catalog</h3>
                  </div>

                  {/* Form Start */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-title"></div>
                        <div className="card-body">
                          <div className="basic-elements">
                            <div className="row">
                              <div className="col-12 form-group">
                                <label>Title</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Title"
                                  value={title}
                                  onChange={v => {
                                    setTitle(v.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="py-1 px-3col-12 form-group">
                                <label>File</label>
                                <input
                                  className="form-control"
                                  type="file"
                                  placeholder="Select Pdf"
                                  onChange={v => {
                                    setfile(v.target.files[0]);
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                              }}
                              className="form-group">
                              <button
                                className=" btn btn-primary px-5"
                                onClick={() => {
                                  addcat();
                                }}>
                                Save{' '}
                              </button>
                              {alert2 ? (
                                <>
                                  {' '}
                                  <SweetAlert
                                    success
                                    confirmBtnText="Thanks !"
                                    confirmBtnBsStyle="success"
                                    timeout={2000}
                                    title="Profile Saved"
                                    onConfirm={() => {
                                      setAlert2(!alert2);
                                    }}
                                    onCancel={() => {
                                      setAlert2(!alert2);
                                    }}
                                    focusCancelBtn>
                                    <br />
                                  </SweetAlert>
                                </>
                              ) : (
                                <></>
                              )}{' '}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
