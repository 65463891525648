import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "react-bootstrap";
import Navmain from './header';
// import Navmain from '../components/landingpage/navbar';
import axios from "axios";
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import {Helmet} from "react-helmet";
import Footer from './footer';
import Whatsapp from '../components/landingpage/whatsapp'
function Home() {

  const navigate = useNavigate();
  const [allcats, setAllcats] = useState([]);
  const [user, setUser] = useState([]);
  const [alert1, setAlert1] = useState();
  const [uid, setUid] = useState();
  const [lead, setLead] = useState([]);
  const [promotion, setPromotion] = useState([]);
  const [searched, setSearched] = useState([]);
  const [trending, setTrending] = useState([]);
  const [trade, setTrade] = useState([]);
  const [city, setCity] = useState("Karachi");
  const [listcities, setCitiesList] = useState([]);

  const [main, setMainCat] = useState([]);
  const [error, seterror] = useState(false);
  const [cat, setCat] = useState("");
  

  useEffect(() => {
    // getCities();
    getServercats()
    lead_view_all()
    Viewpromotion()
    ViewSearched()
    ViewTrending()
    Tradeshow()
    getMainCat()
    // const aa = localStorage.getItem("USER");



    const search = window.location.search;
    const params = new URLSearchParams(search);

    const spplier = params.get("name");

    ShowData(spplier);

    const aa = localStorage.getItem("USER");
    if (aa != null) {
      const as = JSON.parse(aa);
      setUid(as[0].id);
       setUser(as);
    }
  }, []);


  function isNotFloatOrAlpha(value) {
    // Check if value is a float
    const isFloat = typeof value === 'number' && !Number.isInteger(value);
    
    // Check if value is an alphabetical letter
    const isAlpha = typeof value === 'string' && /^[a-zA-Z]$/.test(value);
    
    // Return true if neither condition is true
    return !isFloat && !isAlpha;
  }

  




  
  const getMainCat = async () => {
    const data = new FormData();
    data.append('uid', 'ss');

    axios({
      method: 'post',
      url: '/pro/cat/maincat.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('Main cat list ', s.data);
        const a = s.data;
        setMainCat(a);
        console.log("maincat news",s.data)
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };


  const [sheet, setSheet] = useState(
    [ {
      id: 1,
      name:"",
      qty:"",
      quantity:"",
      piece:"Pieces",
      price:"",
      des: "",
      status: "normal",
      cat: ""
    }
  ]);

  const getServercats = async () => {
    const data = new FormData();

    data.append("uid", "");
    

    axios({
      method: "post",
      url: "/mainsite/cat_set_by_market.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllcats(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const Viewpromotion = async () => {
    const data = new FormData();

  
 

    axios({
      method: "post",
      url: "/pro/promotional_products.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Promotion", s.data)
        setPromotion(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const ViewSearched = async () => {
    const data = new FormData();
    axios({
      method: "post",
      url: "/pro/mostsearch.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log('searched', s.data);
        setSearched(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };



  
  const ViewTrending = async () => {
    const data = new FormData();

    //data.append('array', JSON.stringify(profile));

    axios({
      method: "post",
      url: "/pro/trending.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log('trending', s.data);
        setTrending(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const Tradeshow = async () => {
    const data = new FormData();

    data.append("val", "last");
  

    axios({
      method: "post",
      url: "/pro/tradeshow/manage_trade.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log('tradeshow', s.data);
        setTrade(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const add_lead = async (sheet,uid,c) => {

      const data = new FormData();

      data.append("uid",uid);
      data.append("sheet",JSON.stringify(sheet));
      data.append("cat",c);
      data.append("img", "");

      axios({
        method: "post",
        url: "/pro/lead_add.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((s) => {
          
          setAlert1(!alert1);
          navigate('/product-buy-request')
        })
        .catch((s) => {
          // console.log("here catch =>>", s);
        });
   
  };
  const lead_view_all = async id => {
    const data = new FormData();
    data.append('uid', id);

    axios({
      method: 'post',
      url: '/pro/lead_view_all.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('lead list ', s.data);
        const a = s.data;
        setLead(a);
      })
      .catch(s => {
        // console.log('here lead_view_all ', s);
      });
  };
  const changeArayquote = (i, changes) => {
    const oldArr = [...sheet];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    setSheet(oldArr);
  };
  const [vendor, setVendor] = useState([])


  const ShowData = async (spplier) => {
    const data = new FormData();
    data.append("key", spplier);
    data.append("val", "supplier");
    axios({
      method: "post",
      url: "/pro/vender.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
       
        console.log("Markets Data", s.data);
        setVendor(s.data)
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  return (
<>
<Navmain />
<Whatsapp/>
<Helmet>
      <title>The Leading B2B Marketplace in Pakistan | Alahdeen</title>
      <meta name="description" content="Pakistan's Largest B2B Platform Connecting Wholesalers & Retailers on Alahdeen. Get Wholesale Quotes & Source Products. Unlimited Quantities. Start Now!" />
      <meta name="keywords" content="B2B marketplace, Marketplace Pakistan, Pakistan marketplace, Pakistan wholesale market, Wholesale market, B2B wholesale, Wholesale products" />
    </Helmet>
<main className="main">

<section className="section-box pt-50 bg-home9">
  <div className="container">
    <div className='row'>
  <div className="col-lg-3 order-last order-lg-first">
          <div className="sidebar-border mb-0">
            <div className="sidebar-head">
              <h6 className="color-gray-900">Product Categories</h6>
            </div>
            <div className="sidebar-content">
              <ul className="list-nav-arrow">
                {/* 1 */}
              <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Apparel & Garments"
                            );
                            window.location.assign(
                              "/sub?id=7&s=Apparel%20&%20Garments"
                            );
                          }}
                        >
                          Apparel & Garments
                        </a>
                      </li>
                      {/* 2 */}
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem("title", " Food & Beverages");
                            window.location.assign(
                              "/sub?id=3&s=Food%20&%20Beverages"
                            );
                          }}
                        >
                          Food & Beverages
                        </a>
                      </li>
                      {/* 3 */}
                      
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Electronics & Electrical"
                            );
                            window.location.assign(
                              "/sub?id=8&s=Electronics%20&%20Electrical"
                            );
                          }}
                        >
                          Electronics & Electrical
                        </a>
                      </li>
                      {/* 4 */}
                      {/* 5 */}
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Building Construction"
                            );
                            window.location.assign(
                              "/sub?id=6&s=Building%20&%20Construction"
                            );
                          }}
                        >
                          Building Construction
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Building Construction"
                            );
                            window.location.assign(
                              "/sub?id=27&s=Computer & IT Solutions"
                            );
                          }}
                        >
                          Computer & IT Solutions
                        </a>
                      </li>
                      {/* 6 */}

                      <li  style={{ cursor: "pointer" }}>
                        <a
                       
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Drugs & Pharmaceuticals"
                            );
                            window.location.assign(
                              "/sub?id=1&s=Drugs%20&%20Pharmaceuticals"
                            );
                          }}
                        >
                          Drugs & Pharmaceuticals
                        </a>
                      </li>
{/* 7 */}
             

                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Industrial Plants & Machinery"
                            );
                            window.location.assign(
                              "/sub?id=4&s=Industrial%20Plants%20&%20Machinery"
                            );
                          }}
                        >
                          Industrial Plants & Machinery
                        </a>
                      </li>
                      {/* 8 */}
                    
{/* 9 */}
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Hospital & Diagnostics"
                            );
                            window.location.assign(
                              "/sub?id=2&s=Hospital%20&%20Diagnostics"
                            );
                          }}
                        >
                          Hospital & Diagnostics
                        </a>
                      </li>
                      <li style={{ cursor: "pointer", color:'blue' }}>
                        <a
                          onClick={() => {
                           navigate("/main")
                          }}
                        >
                        All Categories
                        </a>
                      </li>
                   
                      {/* <li className="has-submenu">
                        <a href="#">More items</a>
                        <ul className="submenu">
                          <li>
                            <a href="#">Submenu name</a>
                          </li>
                          <li>
                            <a href="#">Great submenu</a>
                          </li>
                          <li>
                            <a href="#">Another menu</a>
                          </li>
                          <li>
                            <a href="#">Some others</a>
                          </li>
                        </ul>
                      </li> */}
                    </ul>
               
              
            </div>
          </div>
       
        
        
        </div>

<div className='col-9'>
  <div className='row'>
  <div className='col-8'>
<div id="carouselExampleSlidesOnly" class="carousel slide mt-3" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <a href="/sub?id=7&s=Apparel%20&%20Garments">
      <img height={400} src="https://alahdeen.com/assets/images/banner/1.png" class="d-block w-100" alt="..."/>
      </a>
    </div>
    <div class="carousel-item">
    <a href="/sub?id=7&s=Apparel%20&%20Garments">
      <img  height={400} src="https://alahdeen.com/assets/images/banner/2.png" class="d-block w-100" alt="..."/>
      </a>
    </div>
    <div class="carousel-item">
    <a href="/sub?id=7&s=Apparel%20&%20Garments">
      <img  height={400} src="https://alahdeen.com/assets/images/banner/3.png" class="d-block w-100" alt="..."/>
      </a>
    </div>
    <div class="carousel-item">
    <a href="/sub?id=7&s=Apparel%20&%20Garments">
      <img  height={400} src="https://alahdeen.com/assets/images/banner/4.png" class="d-block w-100" alt="..."/>
      </a>
    </div>
  </div>
</div>
</div>
<div className="col-lg-4 order-last order-lg-last">
          <div className="sidebar-border mb-0">
            {/* <div className="sidebar-head">
            </div> */}
            <div className="sidebar-content">
              <h4 className="color-gray-900 text-center">Get Quote</h4>
             
              
              <div>
              <div style={{fontSize:"12px",color:"red"}}>{error==false?"":sheet[0].name==""?"Error: Name of Product":"" }</div>
              <div className="form-group">
                        <input
                          style={{ borderColor: error==false?"transparent":sheet[0].name==""?"red":"transparent" }}
                          className="form-control"
                          placeholder="Name of Product"
                          type="text"
                          value={sheet[0].name}
                          
                          onChange={(e) => {
                            changeArayquote(0, { name: e.target.value });
                          }}
                        />
                      
                      </div>

                      <div>
                        <div className='row' >
                        <div className='col' style={{fontSize:"12px",color:"red"}}>{error==false?"":sheet[0].qty==""?"Error: Quantity":Number(sheet[0].qty)<1?"Error: Quantity":"" }</div>
                          <div className='col' style={{fontSize:"12px",color:"red"}}>{error==false?"":sheet[0].piece==""?"Error: Pices Unit":sheet[0].piece==""?"Error: Pices Unit":"" }</div>
                        </div>
                      </div>

                      <div  className="form-group">
                        <div  className="input-group">
                          <input
                       
                            className="form-control"
                            placeholder="Quantity"
                            type="number"
                            value={sheet[0].qty}
                            onChange={(e) => {
                              changeArayquote(0, { qty: e.target.value,quantity: e.target.value});
                            }}
                          />
                          
<div className="form-control">
{/* <i className="ti ti-plus"></i> */}
                          <select
                          // style={{borderColor:"transparent", fontSize:"16px"}}
                          style={{ borderColor: error==false?"transparent":sheet[0].piece==""?"red":"transparent" }}
                            value={sheet[0].piece}
                            onChange={(e) => {
                              changeArayquote(0, { piece: e.target.value });
                            }}
                            className=" mx-1 "
                          >
                            {/* <option>Unit</option> */}

                            <option >Units</option>
                            <option>Pieces</option>
                            <option>Grams</option>
                            <option>Kilograms</option>
                            <option>Tons</option>
                            <option>Litres</option>
                          </select>
                          
                          </div>
                        </div>
                      </div>
        
                      
                      <div className="row" >

                      <div style={{fontSize:"12px",color:"red"}}>{error==false?"":cat==""?"Error: Category":"" }</div>
                              <div className="col-12 form-group">
                                
                                <select
                             
                                  value={cat}
                                  onChange={ve => {
                                    setCat(ve.target.value)
                                  }}
                                  className="form-control">
                                  <option>Select Main Category</option>

                                  {main.map((v, i) => (
                                    <option value={v.id}>{v.title}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                      <div className="row">
                      <div style={{fontSize:"12px",color:"red"}}>{error==false?"":sheet[0].price==""?"Error: Price":Number(sheet[0].price)<1?"Error: Price":""}</div>
                        <div className="col-12 form-group">
                          <input
                            className="form-control"
                            placeholder="Approx Price Rs"
                            type="number"
                            value={sheet[0].price}
                            
                          style={{ borderColor: error==false?"transparent":sheet[0].price==""?"red":"transparent" }}
                            onChange={(e) => {
                              changeArayquote(0, { price: e.target.value });
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                      <div style={{fontSize:"12px",color:"red"}}>{error==false?"":sheet[0].des==""?"Error: Requirement Description":"" }</div>
                        <textarea

                          className="form-control"
                          placeholder="Requirement Description"
                          maxLength={100}
                          
                          onChange={(e) => {
                            changeArayquote(0, { des: e.target.value });
                          }}
                        >{sheet[0].des}</textarea>
                      </div>
                      <div
                        onClick={() => {
                          if(sheet[0].name==='')
                          {
                           
                            seterror(true)
                          }else if(sheet[0].qty===''|| Number(sheet[0].qty) <1 )
                          {
                            seterror(true)
                            
                          }else if(sheet[0].piece==''||Number(sheet[0].piece)<1)
                          {
                            seterror(true)
                          }else if(cat=='')
                            {
                              seterror(true)
                            }else if(sheet[0].des=="")
                              {
                                seterror(true)
                              }else if(sheet[0].price==''|| Number(sheet[0].price)<1)
                              {
                                seterror(true)
                              }else{
                            add_lead(sheet,uid,cat)
                          }

                          
                          
                        }}
                        className="form-group text-center"
                      >
                        {/* <button className="btn btn-primary">Get quote</button> */}

                        <button
                          className="btn btn-primary"
                          // onClick={() => {
                            // setAlert(!alert);
                          // }}
                        >
                          Get Quote
                        </button>
                        {alert1 ? (
                          <>
                            {" "}
                            <SweetAlert
                              success
                              confirmBtnText="Thanks !"
                              confirmBtnBsStyle="primary"
                              timeout={2000}
                              title="Your Requirement Successfully Sent to the Seller"
                              onConfirm={() => {
                                setAlert1(!alert1);
                              }}
                              onCancel={() => {
                                setAlert1(!alert1);
                              }}
                              focusCancelBtn
                            >
                              <br />
                            </SweetAlert>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
              
              </div>
            </div>
          </div>
       
        
        
        </div>
        </div>
     
        </div>
</div>



</div>
</section>

{/* cat show 1 start */}
<section className=" pt-4 bg-home9 no-gutters">

         

{allcats.map((v, i) => (
  <>

    {/* <header 
     
     
    className="section-heading heading-line">
      <h4 className="title-section text-uppercase">{v.title}</h4>
    </header> */}
<div className='container'>
    <div className="card mb-5 card-home-category">
      <div className="row ">
        <div className="col-md-3">
        {i===0?<>
          <div
          // rgb(203,220,223)
            style={{ backgroundColor: "#EDDFEE" }}
            className="home-category-banner"
          >
             <h3 className="title-section " style={{fontSize:"20px"}} >{v.title}</h3>
            {/* <h5>Medical Appliances</h5> */}
            <a
            
              href={"sub?id=" + v.id + "&s=" + v.title}
              className="btn btn-outline-primary rounded-pill mt-3"
              onClick={() => {
                localStorage.setItem("title", v.title);
              }}
            >
              View All
            </a>
            <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
          </div>
        </>:
        i===1?
        <>
        <div
          // rgb(203,220,223)
            style={{ backgroundColor: "#eaeaed" }}
            className="home-category-banner"
          >
             <h3 className="title-section " style={{fontSize:"20px"}}>{v.title}</h3>
            {/* <h5>Medical Appliances</h5> */}
            <a
            
              href={"sub?id=" + v.id + "&s=" + v.title}
              className="btn btn-outline-primary rounded-pill mt-3"
              onClick={() => {
                localStorage.setItem("title", v.title);
              }}
            >
              View All
            </a>
            <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
          </div>
        </>

        :
        i===2?<>

<div
          // rgb(203,220,223)
            style={{
              // eaeaed
              backgroundColor: "#F1E7DE" }}
            className="home-category-banner"
          >
             <h3 className="title-section " style={{fontSize:"20px"}}>{v.title}</h3>
            {/* <h5>Medical Appliances</h5> */}
            <a
            
              href={"sub?id=" + v.id + "&s=" + v.title}
              className="btn btn-outline-primary rounded-pill mt-3"
              onClick={() => {
                localStorage.setItem("title", v.title);
              }}
            >
              View All
            </a>
            <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
          </div>

        </>:
        
        i===3?<>

        <div
                            // rgb(203,220,223)
                              style={{ backgroundColor: "#F7EEE9" }}
                              className="home-category-banner"
                            >
                               <h3 className="title-section " style={{fontSize:"20px"}}>{v.title}</h3>
                              {/* <h5>Medical Appliances</h5> */}
                              <a
                              
                                href={"sub?id=" + v.id + "&s=" + v.title}
                                className="btn btn-outline-primary rounded-pill mt-3"
                                onClick={() => {
                                  localStorage.setItem("title", v.title);
                                }}
                              >
                                View All
                              </a>
                              <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
                            </div>
        
                          </>:
        i===4?<>

        <div
                            // rgb(203,220,223)
                              style={{ backgroundColor: "#fde5be" }}
                              className="home-category-banner"
                            >
                               <h3 className="title-section " style={{fontSize:"20px"}}>{v.title}</h3>
                              {/* <h5>Medical Appliances</h5> */}
                              <a
                              
                                href={"sub?id=" + v.id + "&s=" + v.title}
                                className="btn btn-outline-primary rounded-pill mt-3"
                                onClick={() => {
                                  localStorage.setItem("title", v.title);
                                }}
                              >
                                View All
                              </a>
                              <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
                            </div>
        
                          </>:
                           
                           i===5?<>

                           <div
                                               // rgb(203,220,223)
                                                 style={{ backgroundColor: "#B3D0F3" }}
                                                 className="home-category-banner"
                                               >
                                                  <h3 className="title-section " style={{fontSize:"20px"}}>{v.title}</h3>
                                                 {/* <h5>Medical Appliances</h5> */}
                                                 <a
                                                 
                                                   href={"sub?id=" + v.id + "&s=" + v.title}
                                                   className="btn btn-outline-primary rounded-pill mt-3"
                                                   onClick={() => {
                                                     localStorage.setItem("title", v.title);
                                                   }}
                                                 >
                                                   View All
                                                 </a>
                                                 <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
                                               </div>
                           
                                             </>:
                                             i===6?<>

                                             <div
                                                                 // rgb(203,220,223)
                                                                   style={{ backgroundColor: "#B3D0F3" }}
                                                                   className="home-category-banner"
                                                                 >
                                                                    <h3 className="title-section " style={{fontSize:"20px"}}>{v.title}</h3>
                                                                   {/* <h5>Medical Appliances</h5> */}
                                                                   <a
                                                                   
                                                                     href={"sub?id=" + v.id + "&s=" + v.title}
                                                                     className="btn btn-outline-primary rounded-pill mt-3"
                                                                     onClick={() => {
                                                                       localStorage.setItem("title", v.title);
                                                                     }}
                                                                   >
                                                                     View All
                                                                   </a>
                                                                   <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
                                                                 </div>
                                             
                                                               </>:i===5?<>

<div
                    // rgb(203,220,223)
                      style={{ backgroundColor: "#B3D0F3" }}
                      className="home-category-banner"
                    >
                       <h3 className="title-section " style={{fontSize:"20px"}}>{v.title}</h3>
                      {/* <h5>Medical Appliances</h5> */}
                      <a
                      
                        href={"sub?id=" + v.id + "&s=" + v.title}
                        className="btn btn-outline-primary rounded-pill mt-3"
                        onClick={() => {
                          localStorage.setItem("title", v.title);
                        }}
                      >
                        View All
                      </a>
                      <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
                    </div>

                  </>:i===7?<>

<div
                    // rgb(203,220,223)
                      style={{ backgroundColor: "#B3D0F3" }}
                      className="home-category-banner"
                    >
                       <h3 className="title-section " style={{fontSize:"20px"}}>{v.title}</h3>
                      {/* <h5>Medical Appliances</h5> */}
                      <a
                      
                        href={"sub?id=" + v.id + "&s=" + v.title}
                        className="btn btn-outline-primary rounded-pill mt-3"
                        onClick={() => {
                          localStorage.setItem("title", v.title);
                        }}
                      >
                        View All
                      </a>
                      <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
                    </div>

                  </>:

        <>
        
        </>
        
        }
        
         
        </div>
        <div className="col-md-9">
          <ul className="row no-gutte0rs bordered-cols">
            {v.code == "sub" ? (
              <>
                {v.child.slice(0, 8).map((vv, ii) => (
                  <>
                    <li key={ii} className="col-6 col-lg-3 col-md-4">
                      <a
                        onClick={() => {
                          localStorage.setItem("title", vv.title);
                        }}
                        href={"sub?id=" + vv.id + "&s=" + vv.title}
                        className="item"
                      >
                        <div className="card-body">
                          <img

                          style={{height:"100px", width:"100px"}}
                            className="text-center "
                            src={vv.image_path}
                          />
                          <h6
                            style={{ color: "black" }}
                            className="mt-2 "
                          >
                            {vv.title}
                          </h6>
                        </div>
                      </a>
                    </li>
                  </>
                ))}
              </>
            ) : (
              <>
                {v.child.slice(0, 8).map((vv, ii) => (
                  <>
                    <li key={ii} className="col-6 col-lg-3 col-md-4">
                      <a
                        href={"product?id=" + vv.id + "&s=" + vv.name}
                        className="item"
                      >
                        <center>
                          <div className="card-body">
                            <img
                               style={{height:"100px", width:"100px"}}
                               className="text-center "
                              src={vv.img}
                            />
                              <h6
                            style={{ color: "black" }}
                            className="mt-5 text-center"
                          >
                            {vv.title}
                          </h6>
                            <figcaption className="info-wrap">
                              <Button
                                variant="primary"
                                className="my-2 mx-4"
                              >
                                Get Quote
                              </Button>
                            </figcaption>
                          </div>
                        </center>
                      </a>
                    </li>
                  </>
                ))}
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
    </div>
  </>
))}
<div className="row">
  <div className="text-center">
    <button
      onClick={() => {
        navigate("/main");
      }}
      style={{ borderRadius: "20px" }}
      className="btn-primary px-3 py-2 "
    >All Categories
      <i className="ti-arrow-right mx-3"> </i>
    </button>
  </div>
</div>
</section>

{/* cat show 1 end */}




  <div className="bg-home9">
    <section className="section-box mt-50">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 mb-20">
            <div className="block-sale-1">
              <div className="row">
                <div className="col-lg-8 col-sm-8 col-8">
                 
                  <span className="font-sm text-uppercase color-brand-3">
                    Most Viewed
                  </span>
                  <h3 className="mb-10">Apparel & Garments</h3>
                  <a className="btn btn-brand-2 btn-arrow-right" href="https://alahdeen.com/sub?id=7&s=Apparel%20&%20Garments">
                    View More
                  </a>
                </div>
                <div className="col-lg-4 col-sm-4 col-4">
                  <img src="assetsss/imgs/page/homepage7/sale1.png" alt="Ecom" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-20">
            <div className="block-sale-1 bg-4">
              <div className="row">
                <div className="col-lg-8 col-sm-8 col-8">
                <span className="font-sm text-uppercase color-brand-3">
                    Trending
                  </span>
                  <h3 className="mb-10">Sports & Good Products</h3>
                  <a className="btn btn-brand-2 btn-arrow-right" href="https://alahdeen.com/sub?id=31&s=Sports%20Goods%20Toys%20&%20Games">
                    View More
                  </a>
                </div>
                <div className="col-lg-4 col-sm-4 col-4">
                  <img src="assetsss/imgs/page/homepage7/sale2.png" alt="Ecom" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-20">
            <div className="block-sale-1 bg-10">
              <div className="row">
                <div className="col-lg-8 col-sm-8 col-8">
                  <strong className="font-sm color-danger font-bold text-uppercase">
                    HOT ITEMS
                  </strong>
                  <h3 className="mb-10">Food & Beverages</h3>
                  <a className="btn btn-brand-2 btn-arrow-right" href="https://alahdeen.com/sub?id=3&s=Food%20&%20Beverages">
                    View More
                  </a>
                </div>
                <div className="col-lg-4 col-sm-4 col-4">
                  <img src="assetsss/imgs/page/homepage7/sale3.png" alt="Ecom" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>


  <section className="section-box mt-50">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="box-slider-item">
            <div className="head">
              <h5>Most Searched Products</h5>
            </div>
            <div className="content-slider">
              <div className="box-swiper">
                <div className="swiper-container swiper-best-seller">
                  <div className="swiper-wrapper pt-5">
                    <div className="swiper-slide">
                    {searched.slice(0,4).map((v,i)=>(
                      <>
<a href={"/product?id="+v.pid+"&s="+v.title}>
                      <div className="card-grid-style-2 card-grid-none-border hover-up">
                        <div className="image-box">
                          {/* <span className="label bg-brand-2">-17%</span> */}
                          <a>
                            <img
                              src={v.img}
                              alt="Ecom"
                            />
                          </a>
                        </div>
                        <div className="info-right">
                          <span className="font-xs color-gray-500">{v.title}</span>
                          <br />
                         
                          <div className="rating">
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <span className="font-xs color-gray-500">
                              {" "}
                              (65)
                            </span>
                          </div>
                          <div className="price-info">
                            <strong className="font-lg-bold color-brand-3 price-main">
                          {v.price}
                            </strong>
                           
                          </div>
                        </div>
                      </div>
                      </a>

                    </>))}
                    </div>


                  
                  </div>
                </div>
                <div className="swiper-button-next swiper-button-next-style-2 swiper-button-next-bestseller" />
                <div className="swiper-button-prev swiper-button-prev-style-2 swiper-button-prev-bestseller" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="box-slider-item">
            <div className="head">
              <h5>Featured products</h5>
            </div>
            <div className="content-slider">
              <div className="box-swiper">
                <div className="swiper-container swiper-featured">
                  <div className="swiper-wrapper pt-5">
                  <div className="swiper-slide">
                    {promotion.slice(0,4).map((v,i)=>(
                      <>
                      <a href={"/product?id="+v.pid+"&s="+v.title}>
                      <div className="card-grid-style-2 card-grid-none-border hover-up">
                        <div className="image-box">
                          {/* <span className="label bg-brand-2">-17%</span> */}
                        
                            <img
                              src={v.img}
                              alt="Ecom"
                            />
                        
                        </div>
                        <div className="info-right">
                          <span className="font-xs color-gray-500">{v.title}</span>
                          <br />
                         
                          <div className="rating">
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <span className="font-xs color-gray-500">
                              {" "}
                              (65)
                            </span>
                          </div>
                          <div className="price-info">
                            <strong className="font-lg-bold color-brand-3 price-main">
                          {v.price}
                            </strong>
                            <span class="color-gray-500 price-line">{v.pro_price}</span>
                          </div>
                        </div>
                      </div>
                      </a>

                    </>))}
                    </div>
                  </div>
                </div>
                <div className="swiper-button-next swiper-button-next-style-2 swiper-button-next-featured" />
                <div className="swiper-button-prev swiper-button-prev-style-2 swiper-button-prev-featured" />
              </div>
            </div>
          </div>
        </div>
        
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="box-slider-item">
            <div className="head">
              <h5>Trending Products</h5>
            </div>
            <div className="content-slider">
              <div className="box-swiper">
                <div className="swiper-container swiper-mostviewed">
                  <div className="swiper-wrapper pt-5">
                  <div className="swiper-slide">
                    {trending.slice(0,4).map((v,i)=>(
                      <>
                      <a href={"/product?id="+v.pid+"&s="+v.title}>
                      <div className="card-grid-style-2 card-grid-none-border hover-up">
                        <div className="image-box">
                          <span className="label bg-brand-2">-17%</span>
                          
                            <img
                              src={v.img}
                              alt="Ecom"
                            />
                          
                        </div>
                        <div className="info-right">
                          <span className="font-xs color-gray-500">{v.title}</span>
                          <br />
                         
                          <div className="rating">
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <img
                              src="assetsss/imgs/template/icons/star.svg"
                              alt="Ecom"
                            />
                            <span className="font-xs color-gray-500">
                              {" "}
                              (65)
                            </span>
                          </div>
                          <div className="price-info">
                            <strong className="font-lg-bold color-brand-3 price-main">
                          {v.price}
                            </strong>
                           
                          </div>
                        </div>
                      </div>
                      </a>
                    </>))}
                    </div>
                  </div>
                </div>
                <div className="swiper-button-next swiper-button-next-style-2 swiper-button-next-mostviewed" />
                <div className="swiper-button-prev swiper-button-prev-style-2 swiper-button-prev-mostviewed" />
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </section>

  <div className='bg-home9 pt-100'>
<div className='container'>
 <div className='row '>
<div className='col-6   '>
<div className="container my-4">
  {/* <h2 className="text-center mb-4">Buy Leads</h2> */}
  <div class="row"> 
                    <div class="col-lg-10"> 
                      <h5>Buy Leads</h5>
                    </div>
                    <div class="col-lg-2"> 
                  See All
                    </div>
                  </div>
 
  <div id="imageCarousel" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        <div className="row">
          {/* First Slide */}
      
          <div className="col-sm-2">
            <img src="http://alahdeen.com/assets/images/logos/logo.png" className="d-block w-100" alt="Image 1" />
            <p className="text-center">Dell Laptop</p>
          </div>
          <div className="col-sm-2">
            <img src="http://alahdeen.com/assets/images/logos/logo.png" className="d-block w-100" alt="Image 1" />
            <p className="text-center">Dell Laptop</p>
          </div>
          <div className="col-sm-2">
            <img src="http://alahdeen.com/assets/images/logos/logo.png" className="d-block w-100" alt="Image 1" />
            <p className="text-center">Dell Laptop</p>
          </div>
          <div className="col-sm-2">
            <img src="http://alahdeen.com/assets/images/logos/logo.png" className="d-block w-100" alt="Image 1" />
            <p className="text-center">Dell Laptop</p>
          </div>
          <div className="col-sm-2">
            <img src="http://alahdeen.com/assets/images/logos/logo.png" className="d-block w-100" alt="Image 1" />
            <p className="text-center">Dell Laptop</p>
          </div>
          <div className="col-sm-2">
            <img src="http://alahdeen.com/assets/images/logos/logo.png" className="d-block w-100" alt="Image 1" />
            <p className="text-center">Dell Laptop</p>
          </div>
       
         
       
          {/* Add more items similarly */}
        </div>
      </div>
    
      {/* Add more slides as needed */}
    </div>
    {/* Controls */}
    <a
      className="carousel-control-prev"
      href="#imageCarousel"
      role="button"
      data-slide="prev"
    >
      <span className="carousel-control-prev-icon" aria-hidden="true" />
      <span className="sr-only">Previous</span>
    </a>
    <a
      className="carousel-control-next"
      href="#imageCarousel"
      role="button"
      data-slide="next"
    >
      <span className="carousel-control-next-icon" aria-hidden="true" />
      <span className="sr-only">Next</span>
    </a>
  </div>
</div>

</div>
<div  className='col-6  '>


<div className="container  my-4">
<div class="row"> 
                    <div class="col-lg-7"> 
                      <h5>Recent Sellers</h5>
                    </div>
                    <div class="col-lg-5"> 
                  
                    </div>
                  </div>
  {/* <h2 className="text-center mb-4">Recent  Seller</h2> */}
  <div id="imageCarousel" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        <div className="row">
          {/* First Slide */}
          {
      vendor.slice(6, 12).map((v, i)=>(<>
          <div className="col-sm-2">
            <img src="http://alahdeen.com/assets/images/logos/logo.png" className="d-block w-100" alt="Image 1" />
            <p className="text-center">{(v.cname || "").substring(0, 20)}</p>
          </div>
          </>))}
          {/* Add more items similarly */}
        </div>
      </div>
      <div className="carousel-item">
        <div className="row">
          {/* Second Slide */}
          {
      vendor.slice(13, 19).map((v, i)=>(<>
          <div className="col-sm-2">
            <img src="http://alahdeen.com/assets/images/logos/logo.png" className="d-block w-100" alt="Image 1" />
            <p className="text-center">{(v.cname || "").substring(0, 20)}</p>
          </div>
          </>))}
          {/* Add more items similarly */}
        </div>
      </div>
      {/* Add more slides as needed */}
    </div>
    {/* Controls */}
    <a
      className="carousel-control-prev"
      href="#imageCarousel"
      role="button"
      data-slide="prev"
    >
      <span className="carousel-control-prev-icon" aria-hidden="true" />
      <span className="sr-only">Previous</span>
    </a>
    <a
      className="carousel-control-next"
      href="#imageCarousel"
      role="button"
      data-slide="next"
    >
      <span className="carousel-control-next-icon" aria-hidden="true" />
      <span className="sr-only">Next</span>
    </a>
  </div>
</div>







</div>
 </div>
 </div>
 </div>

  <div className="bg-home9 pb-60">
    <section className="section-box mt-40">
      <div className="container">
        <div className="banner-ads-3">
          <h5 className="mb-5 color-gray-900">70% off on limited chairs</h5>
          <p className="font-base color-gray-900 mb-10">
            Free shipping available for purchases more than $80.
          </p>
          <a className="btn btn-brand-3">View Products </a>
        </div>
      </div>
    </section>
  </div>


  <section className="section-box mt-90 mb-50">
    <div className="container">
      <ul className="list-col-5">
        <li>
          <div className="item-list">
            <div className="icon-left">
              <img src="assetsss/imgs/template/delivery.svg" alt="Ecom" />
            </div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">45000+ Products</h5>
              {/* <p className="font-sm color-gray-500">Access Products Across Pakistan</p> */}
            </div>
          </div>
        </li>
        <li>
          <div className="item-list">
            <div className="icon-left">
              <img src="assetsss/imgs/template/support.svg" alt="Ecom" />
            </div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">Support 24/7</h5>
              {/* <p className="font-sm color-gray-500">Shop with an expert</p> */}
            </div>
          </div>
        </li>
        <li>
          <div className="item-list">
            <div className="icon-left">
              <img src="assetsss/imgs/template/voucher.svg" alt="Ecom" />
            </div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">Gift voucher</h5>
              {/* <p className="font-sm color-gray-500">Refer a friend</p> */}
            </div>
          </div>
        </li>
        <li>
          <div className="item-list">
            <div className="icon-left">
              <img src="assetsss/imgs/template/return.svg" alt="Ecom" />
            </div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">
                Live Chatbot
              </h5>
              {/* <p className="font-sm color-gray-500">Free return over $200</p> */}
            </div>
          </div>
        </li>
        <li>
          <div className="item-list">
            <div className="icon-left">
              <img src="assetsss/imgs/template/secure.svg" alt="Ecom" />
            </div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">Buy Leads</h5>
              {/* <p className="font-sm color-gray-500">100% Protected</p> */}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>

  <div
    className="modal fade"
    id="ModalQuickview"
    tabIndex={-1}
    aria-hidden="true"
    style={{ display: "none" }}
  >
    <div className="modal-dialog modal-xl">
      <div className="modal-content apply-job-form">
        <button
          className="btn-close"
          type="button"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
        <div className="modal-body p-30">
          <div className="row">
            <div className="col-lg-6">
              <div className="gallery-image">
                <div className="galleries-2">
                  <div className="detail-gallery">
                    <div className="product-image-slider-2">
                      <figure className="border-radius-10">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-1.jpg"
                          alt="product image"
                        />
                      </figure>
                      <figure className="border-radius-10">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-2.jpg"
                          alt="product image"
                        />
                      </figure>
                      <figure className="border-radius-10">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-3.jpg"
                          alt="product image"
                        />
                      </figure>
                      <figure className="border-radius-10">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-4.jpg"
                          alt="product image"
                        />
                      </figure>
                      <figure className="border-radius-10">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-5.jpg"
                          alt="product image"
                        />
                      </figure>
                      <figure className="border-radius-10">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-6.jpg"
                          alt="product image"
                        />
                      </figure>
                      <figure className="border-radius-10">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-7.jpg"
                          alt="product image"
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="slider-nav-thumbnails-2">
                    <div>
                      <div className="item-thumb">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-1.jpg"
                          alt="product image"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="item-thumb">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-2.jpg"
                          alt="product image"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="item-thumb">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-3.jpg"
                          alt="product image"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="item-thumb">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-4.jpg"
                          alt="product image"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="item-thumb">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-5.jpg"
                          alt="product image"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="item-thumb">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-6.jpg"
                          alt="product image"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="item-thumb">
                        <img
                          src="assetsss/imgs/page/product/img-gallery-7.jpg"
                          alt="product image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-tags">
                <div className="d-inline-block mr-25">
                  <span className="font-sm font-medium color-gray-900">
                    Category:
                  </span>
                  <a className="link" href="#">
                    Smartphones
                  </a>
                </div>
                <div className="d-inline-block">
                  <span className="font-sm font-medium color-gray-900">
                    Tags:
                  </span>
                  <a className="link" href="#">
                    Blue
                  </a>
                  ,
                  <a className="link" href="#">
                    Smartphone
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="product-info">
                <h5 className="mb-15">
                  SAMSUNG Galaxy S22 Ultra, 8K Camera &amp; Video, Brightest
                  Display Screen, S Pen Pro
                </h5>
                <div className="info-by">
                  <span className="bytext color-gray-500 font-xs font-medium">
                    by
                  </span>
                  <a
                    className="byAUthor color-gray-900 font-xs font-medium"
                    href="shop-vendor-list.html"
                  >
                    {" "}
                    Ecom Tech
                  </a>
                  <div className="rating d-inline-block">
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <img src="assetsss/imgs/template/icons/star.svg" alt="Ecom" />
                    <span className="font-xs color-gray-500 font-medium">
                      {" "}
                      (65 reviews)
                    </span>
                  </div>
                </div>
                <div className="border-bottom pt-10 mb-20" />
                <div className="box-product-price">
                  <h3 className="color-brand-3 price-main d-inline-block mr-10">
                    $2856.3
                  </h3>
                  <span className="color-gray-500 price-line font-xl line-througt">
                    $3225.6
                  </span>
                </div>
                <div className="product-description mt-10 color-gray-900">
                  <ul className="list-dot">
                    <li>8k super steady video</li>
                    <li>Nightography plus portait mode</li>
                    <li>50mp photo resolution plus bright display</li>
                    <li>Adaptive color contrast</li>
                    <li>premium design &amp; craftmanship</li>
                    <li>Long lasting battery plus fast charging</li>
                  </ul>
                </div>
                <div className="box-product-color mt-10">
                  <p className="font-sm color-gray-900">
                    Color:
                    <span className="color-brand-2 nameColor">Pink Gold</span>
                  </p>
                  <ul className="list-colors">
                    <li className="disabled">
                      <img
                        src="assetsss/imgs/page/product/img-gallery-1.jpg"
                        alt="Ecom"
                        title="Pink"
                      />
                    </li>
                    <li>
                      <img
                        src="assetsss/imgs/page/product/img-gallery-2.jpg"
                        alt="Ecom"
                        title="Gold"
                      />
                    </li>
                    <li>
                      <img
                        src="assetsss/imgs/page/product/img-gallery-3.jpg"
                        alt="Ecom"
                        title="Pink Gold"
                      />
                    </li>
                    <li>
                      <img
                        src="assetsss/imgs/page/product/img-gallery-4.jpg"
                        alt="Ecom"
                        title="Silver"
                      />
                    </li>
                    <li className="active">
                      <img
                        src="assetsss/imgs/page/product/img-gallery-5.jpg"
                        alt="Ecom"
                        title="Pink Gold"
                      />
                    </li>
                    <li className="disabled">
                      <img
                        src="assetsss/imgs/page/product/img-gallery-6.jpg"
                        alt="Ecom"
                        title="Black"
                      />
                    </li>
                    <li className="disabled">
                      <img
                        src="assetsss/imgs/page/product/img-gallery-7.jpg"
                        alt="Ecom"
                        title="Red"
                      />
                    </li>
                  </ul>
                </div>
                <div className="box-product-style-size mt-10">
                  <div className="row">
                    <div className="col-lg-12 mb-10">
                      <p className="font-sm color-gray-900">
                        Style:
                        <span className="color-brand-2 nameStyle">S22</span>
                      </p>
                      <ul className="list-styles">
                        <li className="disabled" title="S22 Ultra">
                          S22 Ultra
                        </li>
                        <li className="active" title="S22">
                          S22
                        </li>
                        <li title="S22 + Standing Cover">
                          S22 + Standing Cover
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-12 mb-10">
                      <p className="font-sm color-gray-900">
                        Size:
                        <span className="color-brand-2 nameSize">512GB</span>
                      </p>
                      <ul className="list-sizes">
                        <li className="disabled" title="1GB">
                          1GB
                        </li>
                        <li className="active" title="512 GB">
                          512 GB
                        </li>
                        <li title="256 GB">256 GB</li>
                        <li title="128 GB">128 GB</li>
                        <li className="disabled" title="64GB">
                          64GB
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="buy-product mt-5">
                  <p className="font-sm mb-10">Quantity</p>
                  <div className="box-quantity">
                    <div className="input-quantity">
                      <input
                        className="font-xl color-brand-3"
                        type="text"
                        defaultValue={1}
                      />
                      <span className="minus-cart" />
                      <span className="plus-cart" />
                    </div>
                    <div className="button-buy">
                      <a className="btn btn-cart" href="shop-cart.html">
                        Add to cart
                      </a>
                      <a className="btn btn-buy" href="shop-checkout.html">
                        Buy now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className='container'>
  <>
  <p>Pakistan’s Top B2B Marketplace for Wholesale Shopping Solutions&nbsp;</p>
  <p>
    Are you searching for the best B2B Marketplace in Pakistan? No need to look
    around anymore! Yes, you have got it right. Alahdeen is your one-stop
    solution for all the wholesale needs.
  </p>
  <p>
    Alahdeen has established itself as a superior one in the B2B wholesale
    market for years. And today, we are Pakistan's largest B2B platform with a
    wide network spreading across Islamabad, Lahore, Peshawar, and Karachi
    wholesale markets online.
  </p>
  <p>
    It's high time to avail specialized and affordable shopping experience with
    our diverse range of product categories. Whether you are looking to expand
    your product offering or supply chain efficiency, we have the perfect
    solution for retailers and wholesalers. We indeed offer the quote without
    any minimum order quantities.
  </p>
  <p>
    Additionally, we offer the best possible bulk online shopping experience
    with an easy and smooth online website where you can expect simple
    navigation and a trusted payment procedure. With a commitment to having a
    high digital platform, Alahdeen also brings excellent showcasing
    opportunities and helps the number of wholesalers or retailers for a
    guaranteed convenient shopping experience!&nbsp;
  </p>
  <p>
    How Alahdeen Becomes the Ultimate Solution for Pakistan's Wholesale
    Suppliers
  </p>
  <p>
    At Alahdeen our primary goal is to revolutionize the traditional procurement
    practices. Our advanced B2B platform takes extensive pride in offering over
    52 product categories with top-tier brands for enhanced reliability and
    flexibility.&nbsp;
  </p>
  <p>
    We proudly maintain the largest wholesale inventory across diverse sectors
    including industrial, electronics, building constructions, apparel &amp;
    garment, foods &amp; beverages, chemicals dyes &amp; solvents, Furniture
    &amp; Supplies, Automobile parts &amp; spares, textile yarn &amp; fabrics,
    book &amp; stationary, engineering services, herbal &amp; ayurvedic
    products, paper products, bags belts &amp; wallets, IT &amp; telecom
    service, product rental &amp; leasing, education training, travel tourism
    &amp; hotels, R&amp;D testing labs, drugs &amp; pharmaceuticals and much
    more.
  </p>
  <p>
    Trust us to enhance your procurement experience with high-quality products
    and endless convenience!
  </p>
  <p>Get Quality Services With Our Advanced B2B Platform&nbsp;</p>
  <p>
    Alahdeen holds the top-quality services and exceptional value to meet your
    unique quotation needs. We offer a range of services to ensure that every
    service we undertake exceeds your expectations. We have the most flexible
    pay-with-us feature for a smooth advertising service. Our diverse range of
    services also provides inventory management, allowing you to be in the top
    place to increase your revenue.
  </p>
  <p>
    Look more to know why top brands choose the Alahdeen B2B marketplace for
    reliable and affordable service. As with us, you don't have to face any
    problems. Here we take pride in delivering top-quality services in
    comparison to the other B2B platforms.
  </p>
  <p>
    Logistics: Our logistic services are designed to enhance the movement of
    goods from one point to another with precision and reliability.
    Additionally, we offer shipping locally or internationally.&nbsp;
  </p>
  <p>
    Pay With Us: Experience the easiest payment solutions with our flexible and
    smooth payment methods.
  </p>
  <p>
    Warehouse: Store your inventory in our advanced and secure system, we
    prioritize 24/7 monitoring and strict quality control service.
  </p>
  <p>
    Office Management: Improve your office tasks with our efficient office
    management services.
  </p>
  <p>
    Inventory Management: Gain control over your inventory with our advanced
    management tool and strategies such as product movement, stock levels, sales
    and return, product pricing, and reports to help you prevent stockouts and
    overstock situations.
  </p>
  <p>
    Advertise With Us: Enhance your brand visibility and reach of the targeted
    audience through our versatile advertising service. Whether you are looking
    for web marketing, conventional marketing, social media marketing, or
    graphic design handling we have the perfect solution for your needs.&nbsp;
  </p>
  <p>Why Choose Us Over Others?&nbsp;</p>
  <p>
    For wholesale and retailers, Alahdeen is renowned as a modern B2B platform
    designed to fulfill your shopping needs. Forget about the traditional
    platform, and extended quotation procedure. We offer a powerful hub for
    crystal-clear business needs.
  </p>
  <p>
    Enjoy more than 52 product categories while searching through popular
    brands. This will ensure a reliable and secure procedure and help you grow
    your potential customers, order closure, and assist you in making the right
    business.&nbsp;
  </p>
  <p>Largest Wholesale Products In Pakistan&nbsp;</p>
  <p>
    With the number of products available at Alahdeen, we offer smooth and
    hassle-free services. Whether you are looking for industrial, apparel, or
    electronic needs, it is the right and reliable place to fulfill your
    needs.&nbsp;
  </p>
  <p>
    You can almost find everything here! We have a variety of products in bulk
    at very affordable rates. Not only this, we also offer the versatility of
    product stocks to bring more growth to your business needs.&nbsp;
  </p>
  <p>24/7 Customer Assistance</p>
  <p>
    We have a dedicated team that continually answers concerns and provides a
    timely response. Whether you are concerned about the product, order, or
    platform functionalities, we are here to address and satisfy the customer's
    requirements.
  </p>
  <p>
    <br />
  </p>
  <p>Quotation for Every Need</p>
  <p>
    Alahdeen not only enhances the purchasing experience but also understands
    that every business has unique needs, which is why we offer a flexible
    quotation procedure to meet your financial and operational needs.
  </p>
  <p>
    <br />
  </p>
  <p>Flexible and Secure Platform</p>
  <p>
    Online platforms often become tricky to handle, but with a reliable digital
    platform like ours, you can always stay in peace. We prioritize flexibility
    in payment terms as well as strict security measures to ensure a safe and
    reliable business environment.
  </p>
  <p>Partner With Alahdeen &amp; Boost Your Shopping Experience Today!&nbsp;</p>
  <p>
    Alahdeen stands out among other B2B platforms when it comes to wholesale
    purchases. Alongside modern advancement, we continue to boost Pakistan
    wholesale suppliers' needs, providing affordable pricing and exceptional
    services. So what are you waiting for? Partner with us and achieve greater
    efficiency in sourcing products. It's time to showcase yourself with
    Alahdeen where we are ready to meet any business challenge!
  </p>
  <p>FAQs&nbsp;</p>
  <p>1. How can I register on the Alahdeen B2B marketplace?</p>
  <p>
    Registration on the Alahdeen B2B marketplace is a simple process. You can
    easily register on the website by filling out the form with the business
    details and contact information. Once the information is submitted, our team
    will contact you shortly. On approval, you will receive login credentials to
    access our platform and begin exploring our exceptional service.
  </p>
  <p>2, How do we secure your personal information?</p>
  <p>
    We keep the customer's security in the first place. We do prioritize the
    strict industry-related protocols to safeguard all data transmitted through
    our platform. Additionally, we regularly update the security measures to
    reduce the risk of unauthorized access.
  </p>
  <p>
    <br />
  </p>
  <p>Do we offer different subscriptions or service plans?</p>
  <p>
    At Alahdeen, we offer a flexible subscription plan. Whether you are a small
    or big retailer or wholesaler, we are here to provide a scalable and
    cost-effective quotation service. Contact our team and get the best possible
    subscription plan according to your needs and preferences.
  </p>
  <p>
    How does Alahdeen maintain the quality of the products sourced through
    different networks?
  </p>
  <p>
    We maintain strict quality control protocols to ensure the products are
    designed to meet the high standards. Because, we partner with reputable
    suppliers and conduct a vetting process to verify product quality, and
    authenticity within the industry standard.
  </p>
  <p>What is Alahdeen and what does it offer?</p>
  <p>
    Alahdeen is an emerging B2B platform that connects buyers with a variety of
    suppliers offering a wide range of products at affordable price ranges.
    However, you can purchase in bulk and easily request a quote directly
    through our website.
  </p>
  <p>
    <br />
  </p>
  <p>
    <br />
  </p>
</>

  </div>
</main>
<div>


</div>
<Footer/>
</>
  )
}

export default Home