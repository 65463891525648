import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom'; 
import {
  PlusCircleFill,
  PlusCircleDotted,
  Trash,
  Trash3,
 
} from "react-bootstrap-icons";

function Mainpage() {

  const navigate = useNavigate();
  const [con_volume, setConvolume] = useState('');
  const [array, setArray] = useState([]);
  const [uid, setUid] = useState('');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id) 
      getRequest(v[0].id)
     
    }
  }, []);
  const getRequest = async (uid)=> {

    const data = new FormData();
    data.append('status', "uid");
    data.append('uid', uid);
    data.append('val', "pending");

    
        axios({ 
          method: 'post',
          url: '/pro/logistic/view_requests.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log("request book user",s.data)
            if(s.data[0].code=="all")
            {
              setArray(s.data)
              localStorage.setItem('UserRequest', JSON.stringify(s.data));
            }
            
                  })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Pending Requests </h4>
                  </div>
                  
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>From City</th>
                            <th>To City</th>
                            <th>Use Type</th>
                            <th>vehicle type</th>
                            <th> type</th>
                            <th>Sender Name</th>
                            <th>Receiver Name</th>
                            <th>Weight</th>
                            <th>Volume</th>
                            <th>Departure Date</th>
                            <th>Departure Time</th>
                            <th>Fare </th>
                            <th>Driver Requests </th>
                            
                           
                          </tr>
                        </thead>
                        <tbody>
{
  array.length==0?<>                          <tr>
  <td
    style={{
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    }}
    colSpan={11}>
    No Record to show{' '}
  </td>
</tr></>:<>

{
  array.map((v,i)=>(
    <>
    <tr>
  <td>{i+1}</td>
  <td>{v.frm_city}</td>
  <td>{v.to_city}</td>
  <td>{v.log_type}</td>
  <td>{v.vehicle}</td>
  <td>{v.vehicle_type}</td>
  <td>{v.sender_name}</td>
  <td>{v.receiever_name}</td>
  <td>{v.con_weight}</td>
  <td>{v.con_volume}</td>
  <td>{v.depart_date}</td>
  <td>{v.depart_time}</td>
  <td>{v.fare}</td>
  
  {/* <td><button onClick={()=>{
     localStorage.setItem('UserRequestId',String(i));
    navigate("/rebid")
  }} className='btn-primary' > Requests </button> </td>
 */}

  <td>
  <div
                   style={{cursor:"pointer"}}    
                       onClick={()=>{
                        localStorage.setItem('UserRequestId',String(i));
                       navigate("/rebid")
                     }}      className="widget-header mr-3">
                         <a  className="widget-view">
                           <div className="icon-area text-center">
                             <i
                               style={{ color: "blue" }}
                               className="fa fa-bell"
                             ></i>
                             <span className="notify">0</span>
                             {/* <span className="notify">1</span> */}
                           </div>{" "}
                           <small className="text">
                             {" "}
                             <strong> Requests </strong>
                           </small>
                         </a>
                       </div>
  </td>
  
</tr>
    </>
  ))
}

</>
}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
