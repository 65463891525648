import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Nav from '../../../navbar';
function Buyleads() {
  const [uid, setUid] = useState('');
  const [lead, setLead] = useState([]);


  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
     
    } else {
      setUid(v[0].id)
      lead_view_all(v[0].id)
   

    }
  }, []);
  const lead_view_all = async (id)=> {

    const data = new FormData();
    data.append('uid', id);

    
        axios({ 
          method: 'post',
          url: '/pro/view_product_user.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log("view_product_user",s.data)
            const a=s.data;
            setLead(a)
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
  return (
    
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Product Direct Requests</h4>
                  </div>
                  {/* <div className="row">
                    <div className="col-lg-9 col-sm-1"></div>
                    <div className="col-lg-3 col-sm-10">
                      <input
                        style={{borderRadius: '18px', paddingLeft: '8px'}}
                        placeholder="search"
                      />
                      <span>
                        <button
                          style={{borderRadius: '18px', color: 'white'}}
                          className="bg-primary mx-3 px-4 ">
                          Search
                        </button>
                      </span>
                    </div>
                  </div> */}
                  <div className="card-body">
                    <div className="table-responsive"> {
                       lead.length===0?<></>:<>
                           {
                             lead.map((z,yyi)=>(
                            <>

{
    z.child.length>0?<>
      <h3>Product :  {z.title}</h3>                            
<table className="table table-hover ">
{
    z.child.length>0?<>
                  <thead>
                          <tr>
                            <th>#</th>
                   
                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Unit</th>
                            <th>Price</th>
                            <th>Description</th>
                            <th>Time</th>
                            <th>File</th>
                            <th>Status</th>
                            <th>Controls</th>
                             </tr>
                        </thead>
                        
                            </>:<></>
}

<tbody>
                             {
                                z.child.map((v,ii)=>(
                                    <>
                                         <tr>
                              <td>{v.pid}</td>
                              
                              <td>{JSON.parse(v.sheet)[0].name} </td>
                              <td>{JSON.parse(v.sheet)[0].qty} </td>
                              <td>{JSON.parse(v.sheet)[0].price} </td>
                              <td>{JSON.parse(v.sheet)[0].piece} </td>
                              <td>{JSON.parse(v.sheet)[0].des} </td>
                              <td>{v.time} </td>
                              <td><a href= {v.file} target="_blank" download={v.lead_id} >Download </a> </td>
                              <td>Active</td> 
                              <td>
                                <button
                                  style={{borderRadius: '14px'}}
                                  className="btn-primary">
                                  Contact Buyer
                                </button>
                              </td>
                            </tr>
                                    
                                    </>
                                ))
                            }
                             </tbody>
                         </table>
    </>:<></>
}
                            </>
                              
                        
                             
                              
                            
                          
                             ))
                           }
                          </>
                     }
                       
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
