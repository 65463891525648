import Nav from '../../../navbar';
import {Link} from 'react-router-dom';
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

function App() {
  const history = useNavigate();

  const [uid, setUid] = useState('');
  const [viewList, setViewList] = useState([]);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      viewProduct(v[0].id);
    }
  }, []);

  const viewProduct = async id => {
    const data = new FormData();
    data.append('uid', id);
    data.append('val', 'view');

    axios({
      method: 'post',
      url: '/pro/office/manage_expense.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
       console.log('expanse list ', s.data, id);
        const a = s.data;
        setViewList(a);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const deleteproduct = async id => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('eid', id);
    data.append('val', 'delete');

    axios({
      method: 'post',
      url: '/pro/office/manage_expense.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('status list ', s.data);
        viewProduct(uid);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <body>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Bill & Expenditures</h4>
                  </div>
                  <div className="row">
                    {/* <div className="col-lg-9 col-sm-1"></div> */}
                    <div className="col-lg-12 col-sm-12 text-right">
                      {/* <input
                      style={{borderRadius: '18px', paddingLeft: '8px'}}
                      placeholder="search"
                    /> */}
                      <span>
                        <button
                          style={{borderRadius: '18px', color: 'white'}}
                          className="bg-primary mx-3 px-4 ">
                          <Link style={{color: 'white'}} to="/adexpense">
                            {' '}
                            Add Expense
                          </Link>
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Expense Name</th>
                            <th>Bill No</th>
                            <th>Amount</th>
                            <th>Expense Month</th>
                            <th> Date</th>
                            <th> Description</th>
                            <th> File</th>
                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          {viewList.length === 0 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                  colSpan={10}>
                                  No Record to show{' '}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {viewList.map((v, i) => (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{v.name}</td>
                                  <td>{v.billno}</td>
                                  <td>{v.expam}</td>
                                  <td>{v.month}</td>
                                  <td>{v.date}</td>
                                  <td>{v.des}</td>

                                  <td>
                                  
                                  {
                                    v.img===""?<>--------</>:<a href={v.img} download="w3logo" target="_blank" >
                                    Download
                                  </a>
                                  }

                                    {/* {v.img} */}
                                  </td>

                                  <td>
                                   
                                    
                                    <label
                                      style={{color: 'red', cursor: 'pointer'}}
                                      onClick={() => {
                                        deleteproduct(v.id);
                                      }}>
                                      Delete
                                    </label>

                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}

export default App;
