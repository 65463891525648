import React from "react";
import Footer from "./footer";
import Nav from "./navbar";
import { Tab, Tabs } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";
import { Dropdown, DropdownButton } from "react-bootstrap";


function App() {
  const navigate = useNavigate();
  const [uid, setUid] = useState("");

  const [view, setview] = useState("");
  const [warehouse, setwarehouse] = useState([]);
  const [warehouseType, setWarehouseType] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setcities] = useState([]);
  // const [warehouse, setwarehouse] = useState([]);

  useEffect(() => {
    warehouse_view();
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      citieslist();
    }
  }, []);
  const citieslist = async () => {
    const data = new FormData();

    data.append("wtype", warehouseType);

    axios({
      method: "post",
      url: "/pro/auth/allcity_search.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log(s.data);
       setcities(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const warehouse_view = async () => {
    const data = new FormData();

    data.append("wtype", "view");

    axios({
      method: "post",
      url: "/mainsite/warehouseby_types.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setwarehouse([]);
        // console.log("warehouse list ", s.data);
        const a = s.data;
        setwarehouse(a);
        localStorage.setItem("ware", JSON.stringify(s.data));
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const warehousebytype = async (warehouseType) => {
    const data = new FormData();

    data.append("wtype", warehouseType);

    axios({
      method: "post",
      url: "/mainsite/warehouseby_types.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setwarehouse([]);
        // console.log("warehouse list ", s.data);
        const a = s.data;
        setwarehouse(a);
        localStorage.setItem("ware", JSON.stringify(s.data));
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const warehousebycity = (c) => {
    const data = new FormData();

    data.append("wtype", "city");
    data.append("city", c);

    axios({
      method: "post",
      url: "/mainsite/warehouseby_types.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setwarehouse([]);
        // console.log("warehouse City list ", s.data);
        const a = s.data;
        setwarehouse(a);
        localStorage.setItem("ware", JSON.stringify(s.data));
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  return (
    <body>
      <Nav />
      <div className="container">
        <section className="card px-3 padding-bottom">
          <div className="row">
            <img
              width="100%"
              height="300px"
              src="assets/images/posts/inventory.png"
              className="card-img-top"
            />
          </div>
          <header className="section-heading">
            <h2 className="text-center text-uppercase mt-5">Warehouse</h2>
          </header>
          <center className="mb-5">
            <div className="col-xl-8 col-lg-8 col-md-6">
              <form action="#" className="search-header">
                <div className="input-group w-100">
                  <select
                    value={warehouseType}
                    onChange={(v) => {
                      setWarehouseType(v.target.value);
                      warehousebytype(v.target.value);
                    }}
                    className="custom-select border-right"
                    name="category_name"
                  >
                    <option>Sheltered</option>
                    <option>Shop</option>
                    <option>Cold Storage</option>
                    <option>Apartment</option>
                    <option>Plaza</option>
                    <option>Single Story</option>
                    <option>Double Story</option>
                  </select>
                  <select
                    value={city}
                    onChange={(v) => {
                      setCity(v.target.value);
                      warehousebycity(v.target.value);
                    }}
                    className="custom-select border-right"
                    name="category_name"
                  >
                    {cities.map((v, i) => (
                    <option>{v.name}</option>
                    ))
                    }
                  </select>

                  {/* <div className="input-group-append"
                  >
                    <button
                      className="btn btn-primary"
                      type="submit"
                      
                      onClick={(v) => {
                        warehousebycity();
                      }}
                    >
                      <i className="fa fa-search"></i> Search City
                    </button>
                  </div> */}
                </div>
              </form>
            </div>
          </center>

          <div className="row my-4">
            {warehouse.map((v, i) => (
              <div
                style={{ cursor: "pointer" }}
                className="col-md-3 col-sm-6 card-product-grid card-sm"
              >
                <article className="card card-post">
                  <img
                    heigt={100}
                    width={100}
                    src={v.imagea}
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h6 className="">{v.type} </h6>
                    <p className="small text-muted text-uppercase">
                      {v.locality} <br />
                      {v.city} <br /> Rent:{v.areasqft}{" "}
                      <span className="text-right text-muted ml-3">
                        {" "}
                        {v.room} Rooms{" "}
                      </span>
                    </p>
                    <p className="small text-uppercase ">
                      Area: {v.rentpermonth}
                    </p>
                    <div className="text-center my-1">
                      <button
                        style={{ borderRadius: "15px" }}
                        onClick={() => {
                          localStorage.setItem("wareitem", i);
                          // console.log(i);
                          navigate("/single");
                        }}
                        className="btn-success px-3 ">
                        Book Now
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            ))}
          </div>
          {/* <div className="row">
            <DropdownButton
              variant="outline-secondary"
              title="Type"
              id="input-group-dropdown-1">
              <Dropdown.Item href="#">Action</Dropdown.Item>

              <Dropdown.Divider />
              <Dropdown.Item href="#">Separated link</Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              variant="outline-secondary"
              title="Select City"
              id="input-group-dropdown-1">
              <Dropdown.Item href="#">Action</Dropdown.Item>

              <Dropdown.Divider />
              <Dropdown.Item href="#">Separated link</Dropdown.Item>
            </DropdownButton>
          </div> */}
          {/* Tabs Start */}
          {/* <Tabs
            onClick={() => {
              warehousebytype(warehouseType);
            }}
            defaultActiveKey="second">
            <Tab
              eventKey="first"
              title="Sheltered"
              onClick={() => {
                warehousebytype('Apartment');
              }}>
              <div className="row my-4">
                {warehouse.map(v => (
                  <div
                    style={{cursor: 'pointer'}}
                    className="col-md-3 col-sm-6 card-product-grid card-sm">
                    <article className="card card-post">
                      <img
                        heigt={100}
                        width={100}
                        src="assets/images/posts/12.jpg"
                        className="card-img-top"
                      />
                      <div className="card-body">
                        <h6 className="title">{v.type} </h6>
                        <p className="small text-muted">
                          Karim Bux Road Samanabad Lahore <br />
                          {v.city} <br /> Area:{v.areasqft}{' '}
                          <span className="text-right text-muted mx-5">
                            {' '}
                            3 Rooms{' '}
                          </span>
                        </p>
                        <p className="small text-uppercase ">Rent: 55000</p>
                        <div className="text-center my-1">
                          <button
                            style={{borderRadius: '15px'}}
                            className="btn-success px-3 ">
                            Book Now
                          </button>
                        </div>
                      </div>
                    </article>
                  </div>
                ))}
              </div>
              <div className="row my-4">
                <div
                  style={{cursor: 'pointer'}}
                  className="col-md-3 col-sm-6 card-product-grid card-sm">
                  <article className="card card-post">
                    <img
                      heigt={100}
                      width={100}
                      src="assets/images/posts/12.jpg"
                      className="card-img-top"
                    />
                    <div className="card-body">
                      <h6 className="title">Cold Storage </h6>
                      <p className="small text-muted">
                        Karim Bux Road Samanabad Lahore <br />
                        Lahore <br /> Area: 550 Sqft{' '}
                        <span className="text-right text-muted mx-5">
                          {' '}
                          3 Rooms{' '}
                        </span>
                      </p>
                      <p className="small text-uppercase ">Rent: 55000</p>
                      <div className="text-center my-1">
                        <button
                          style={{borderRadius: '15px'}}
                          className="btn-success px-3 ">
                          Book Now
                        </button>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </Tab>
            <Tab
              onClick={v => {
                setWarehouseType('Shop');
              }}
              eventKey="second"
              title="Open Air">
              <div className="row my-4">
                <div className="col-md-3 col-sm-6">
                  <article className="card card-post">
                    <img
                      heigt={100}
                      width={100}
                      src="assets/images/posts/11.jpg"
                      className="card-img-top"
                    />
                    <div className="card-body">
                      <h6 className="title">Cold Storage </h6>
                      <p className="small text-muted">
                        Karim Bux Road Samanabad Lahore <br />
                        Lahore <br /> Area: 550 Sqft
                      </p>
                      <p className="small text-uppercase ">Rent: 55000</p>
                      <div className="text-center my-1">
                        <button
                          style={{borderRadius: '15px'}}
                          className="btn-success px-3 ">
                          Book Now
                        </button>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </Tab>
            <Tab
              onClick={v => {
                setWarehouseType('');
              }}
              eventKey="third"
              title="Cold Storage">
              <div className="row my-4">
                <div className="col-md-3 col-sm-6">
                  <article className="card card-post">
                    <img
                      heigt={100}
                      width={100}
                      src="assets/images/posts/13.jpg"
                      className="card-img-top"
                    />
                    <div className="card-body">
                      <h6 className="title">Cold Storage </h6>
                      <p className="small text-muted">
                        Karim Bux Road Samanabad Lahore <br />
                        Lahore <br /> Area: 550 Sqft
                      </p>
                      <p className="small text-uppercase ">Rent: 55000</p>
                      <div className="text-center my-1">
                        <button
                          style={{borderRadius: '15px'}}
                          className="btn-success px-3 ">
                          Book Now
                        </button>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </Tab>
          </Tabs>{' '} */}
          {/* {/* Tabs End */}
        </section>
      </div>
      <Footer />
    </body>
  );
}

export default App;
