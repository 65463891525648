import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import Nav from '../../../navbar';


function Mainpage() {
  const navigate = useNavigate();
  // const [alert1, setAlert1] = useState('false');
  const [main, setMainCat] = useState([]);
  const [sub, setsubCat] = useState([]);
  const [micro, setMicroCat] = useState([]);
  const [cat, setuserCat] = useState('');
  const [mainSingle, setMainsingle] = useState('');
  const [uid, setUid] = useState('');
  const [name, setName] = useState('');
  const [video, setVideo] = useState('');
  const [price, setPrice] = useState('');
  const [code, setCode] = useState('');
  const [min, setMin] = useState('');
  const [delivery, setDelivery] = useState('');
  const [desc, setDesc] = useState('');
  const [vkey, setVkey] = useState('');
  const [extra, setExtra] = useState([{dimensions:"",quility_awad:""}]);
  const [dkey, setDkey] = useState('');
  const [pdf, setPdf] = useState("");
  const [img, setImage] = useState("");
  const [imga, setImage_a] = useState("");
  const [imgb, setImage_b] = useState("");
  const [imgview, setImageView] = useState("");
  const [imgaview, setImage_aView] = useState("");
  const [imgbview, setImage_bView] = useState("");
  const [Cata, setCatA] = useState();

  const changeArayExtra = (i, changes) => {
    const oldArr = [...extra];
    const oldObj = oldArr[i];
    oldArr[i] = {...oldObj, ...changes};
    setExtra(oldArr);
  };
 

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      getMainCat();
    }
  }, []);


  const validation=()=>{

    if(img==="")
    {
      alert("Select Feature Image!")
    }else if(name==="")
    {
      alert("Enter Name!")
    }else if(price==="")
    {
      alert("Enter Price!")
    }else if(cat==="")
    {
      alert("Select Catgoeries!")
    } else if(desc==="")
    {
      alert("Enter Description!")
    } else{
      addProduct();
    } 
    
  }

  const getMainCat = async () => {
    const data = new FormData();
    data.append('uid', 'ss');

    axios({
      method: 'post',
      url: '/pro/cat/maincat.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('Main cat list ', s.data);
        const a = s.data;
        setMainCat(a);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const getSub = async (id, txt) => {
    console.log('id get sub is ', id);

    const data = new FormData();
    data.append('id', id);

    axios({
      method: 'post',
      url: '/pro/cat/sub.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        const a = s.data;
        if (a.length === 0) {
          setuserCat(id);
        }

        if (txt === 'main') {
          setsubCat(a);
        } else {
          setMicroCat(a);
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const addProduct = async () => {
    const data = new FormData();
    data.append('name', name);
    data.append('link', video);
    data.append('price', price);
    data.append('code', code);
    data.append('des', desc);
    data.append('qty', min);
    data.append('dry', delivery);
    data.append('extra', JSON.stringify(extra));
    data.append('cat', cat);
    data.append('uid', uid);
    data.append('vkey', "vkey");
    data.append('dkey', "dkey");
    data.append('pdf', pdf);
    data.append('img', img);
    data.append('img_a', imga);
    data.append('img_b', imgb);

    axios({
      method: 'post',
      url: '/pro/add_product.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        if (s.data[0].code === 'pass') {
          // setAlert1('true');
          navigate("/productlist")
          // alert('thanks');

        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  function isImageFile(file) {
    // Check if the file is defined and its type starts with "image/"
    return file && file.type.startsWith("image/");
  }
  const text_type=(input)=>{
    // Regular expression to check if the string contains only alphabetic characters
  const regex = /^[A-Za-z\s]+$/;

  // Test the input against the regular expression
  return regex.test(input);

  }



  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>Add Product</h3>
                  </div>

                  {/* Form Start */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="">
                       
                        <div className="card-body">
                          <div className="basic-elements">
                            <div
                              className="row my-4"
                              style={{
                                height: '100px',
                                border: '1px dotted black',
                              }}>
                              <label className="my-2">
                              Thumbnail Image Upload *
                              </label>
                              <input
                                onChange={v => {
                                  
                                  if(isImageFile(v.target.files[0]))
                                  {
                                    setImage(v.target.files[0]);
                                    const file = v.target.files[0];
           // Create an object URL for the image file to display
           const url = URL.createObjectURL(file);
           setImageView(url);

                                  }else{

                                    alert("Select Image only. ")
                                  }
                                  
                                }}
                                type="file"
                              />
                            </div>
                            <div className='col-lg-12'>
   {imgview && (
<>
<h5>Thumbnail Image Preview</h5>
<img src={imgview} alt="Preview" style={{ maxWidth: '300px', maxHeight: '300px' }} />
</>
)}
   </div>

   {/* 2nd start */}
   <div className='row'>
  <div className='col-lg-6'>
   <div
     className="row my-4"
     style={{
       height: '100px',
       border: '1px dotted black',
     }}>
     <label className="my-2">
       2nd Image other Side of Product*
     </label>
     <input
       onChange={v => {
         
         if(isImageFile(v.target.files[0]))
         {
           setImage_a(v.target.files[0]);
           const file = v.target.files[0];
           // Create an object URL for the image file to display
           const url = URL.createObjectURL(file);
           setImage_aView(url);

         }else{

           alert("Select Image only. ")
         }
         
       }}
       type="file"
     />
   </div>
   <div className='col-lg-6'>
   {imgaview && (
<>
<h5>2nd Image Preview</h5>
<img src={imgaview} alt="Preview" style={{ maxWidth: '300px', maxHeight: '300px' }} />
</>
)}
   </div>
   </div>
  </div>
  
<div className='row'>
                          
  <div className='col-lg-6'>
                            <div
                              className="row my-4"
                              style={{
                                height: '100px',
                                border: '1px dotted black',
                              }}>
                              <label className="my-2">
                                Last Image Product*
                              </label>
                              <input
                                onChange={v => {
                                  
                                  if(isImageFile(v.target.files[0]))
                                  {
                                    setImage_b(v.target.files[0]);
                                    const file = v.target.files[0];
                                    // Create an object URL for the image file to display
                                    const url = URL.createObjectURL(file);
                                    setImage_bView(url);

                                  }else{

                                    alert("Select Image only. ")
                                  }
                                  
                                }}
                                type="file"
                              />
                            </div>
                            <div className='col-lg-6'>
                            {imgbview && (
        <>
          <h5>Last Image Preview</h5>
          <img src={imgbview} alt="Preview" style={{ maxWidth: '300px', maxHeight: '300px' }} />
        </>
      )}
                            </div>
                            </div>
                           </div>
   {/* 2nd End */}
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Product Name * </label>
                                <input
                                  value={name}
                                  onChange={v => {
                                    if(v.target.value.length<80)
                                    {
                                      setName(v.target.value);
                                    }else{
                                      alert("Name Limit 80 character only")
                                    }
                                    
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Product Price *</label>
                                <input
                                  value={price}
                                  onChange={v => {
                                    if(v.target.value.length<10)
                                    {
                                      
                                       
                                      if( !text_type(v.target.value))
                                        {
                                          setPrice(v.target.value);
                                          
                                        }else if(v.target.value=="")
                                        {
                                          setPrice(v.target.value);
                                        }


                                    }else{
                                      alert("Price Over Limit")
                                    }
                                  }}
                                  className="form-control"
                                  type="number"
                                />
                              </div>
                            
                            </div>

                            <div className="row">
                            {/* <div className="col-4 form-group">
                                <label>Product Video Link</label>
                                <input
                                  value={video}
                                  onChange={v => {
                                    if(v.target.value.length<200)
                                    {
                                      setVideo(v.target.value);
                                    }else{
                                      alert("Lin Limit over")
                                    }


                                    setVideo(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-4 form-group">
                                <label>Product Catalog</label>
                                <input
                                  className="form-control"
                                  onChange={v => {
                                    setPdf(v.target.files[0]);
                                  }}
                                  type="file"
                                />
                              </div> */}
                                <div className="col-4 form-group">
                                <label>Product Code (Optional)</label>
                                <input
                                  value={code}
                                  onChange={v => {
                                    if(v.target.value.length<20)
                                    {
                                      setCode(v.target.value);
                                    }else{
                                      alert("Product Code Limit 20 character only")
                                    }


                                    
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>


                              <div className="col-4 form-group">
                                <label>Estimated Delivery Time (Optional)</label>
                                <input
                                  value={delivery}
                                  onChange={v => {
                                    if(v.target.value.length<20)
                                    {
                                      setDelivery(v.target.value);
                                    }else{
                                      alert("Estimated Time over Limit")
                                    }
                                    setDelivery(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>

                              <div className="col-4 form-group">
                                <label>Minimum Order Qty (Optional)</label>
                                <input
                                  value={min}
                                  onChange={v => {
                                    if(v.target.value.length<20)
                                    {
                                      setMin(v.target.value);
                                    }else{
                                      alert("Minimum Oder Qty Over Limit ")
                                    }
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                           
                           

                            <div className="row">
                              <div className="col-12 form-group">
                                <label>Select Main Category</label>
                                <select
                                  value={mainSingle}
                                  onChange={ve => {
                                    setsubCat([]);
                                    setMicroCat([]);
                                    setuserCat('');

                                    getSub(ve.target.value, 'main');
                                    setMainsingle(ve.target.value);
                                    setCatA(ve.target.value)
                                  }}
                                  className="form-control">
                                  <option>Select Main Category *</option>

                                  {main.map((v, i) => (
                                    <option value={v.id}>{v.title}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {sub.length === 0 ? (
                              <></>
                            ) : (
                              <div className="mx-2row">
                                <div className="col-12 form-group">
                                  <label>Select Sub Category *</label>
                                  <select
                                    onChange={v => {
                                      setMicroCat([]);
                                      getSub(v.target.value, 'sub');
                                      setuserCat('');
                                    }}
                                    className="form-control">
                                    <option>Select Sub Category *</option>

                                    {sub.map((v, i) => (
                                      <option value={v.id}>{v.title}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            )}

                            {micro.length === 0 ? (
                              <></>
                            ) : (
                              <>
                                <div className="mx-4 row">
                                  <div className="col-12 form-group">
                                    <label>Select Micro Category </label>
                                    <select
                                      onChange={v => {
                                        setuserCat(v.target.value);
                                      }}
                                      className="form-control">
                                      <option>Select Micro Category</option>

                                      {micro.map((v, i) => (
                                        <option value={v.id}>{v.title}</option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </>
                            )}

                           
{/* <div className=" my-4 row">
                              <div className="col-6 form-group">
                                <label>Brand (Optional)</label>
                                <input
                                  value={dkey}
                                  maxLength={20}
                                  onChange={v => {

                                   
                                      setDkey(v.target.value);
                                   
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Verification (Optional) </label>

                                <select  className="form-control" onChange={v => {
                                  
                                  setVkey(v.target.value);
                                
                                
                              }} value={vkey}>
                                  <option>No</option>
                                  <option>Yes</option>
                                </select>
                               
                              </div>
                              
                              
                            </div>
                           
<div className=" my-4 row">
                              <div className="col-6 form-group">
                                <label>Dimensions (Height  Width Depth) (Optional)</label>
                                <input
                                  value={extra[0].dimensions}
                                  maxLength={30}
                                  onChange={v => {

                                    changeArayExtra(0,{dimensions: v.target.value})
                                      
                                   
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Quality (E.G Awards, Reviews) (Optional) </label>

                                <input
                                    value={extra[0].quility_awad}
                                    maxLength={30}
                                    onChange={v => {
                                    changeArayExtra(0,{quility_awad: v.target.value})
                                        }}
                                  className="form-control"
                                  type="text"
                                />
                               
                              </div>
                              
                              
                            </div> */}

                            <div className="row">
                              <label>Product Description *</label>
                              <textarea
                                value={desc}
                                onChange={v => {
                                
                                    setDesc(v.target.value);
                                

                                }}
                                className="form-control"
                                rows={3}
                              />
                            </div>
                            
                            {/* <div className="row my-2">
                              <div className="col-6 form-group">
                                <label>More Images</label>
                                <input
                                  className="form-control"
                                  onChange={v => {
                                    setImage_a(v.target.files[0]);
                                  }}
                                  type="file"
                                />
                              </div>
                           
                              <div className="col-6 form-group">
                              <label>More Images</label>
                                <input
                                  className="form-control"
                                  onChange={v => {
                                    setImage_b(v.target.files[0]);
                                  }}
                                  type="file"
                                />
                              </div>
                            </div> */}

                            <div
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                              }}
                              className="form-group">
                              <button
                                className=" btn btn-primary px-5 mt-4"
                                onClick={() => {

                                  validation()


                                    
                                      



                                }}>
                                Save Product
                              </button>
                             {/* {alert1 === 'true' ? 
                              
                           
                                  <SweetAlert
                                    success
                                    confirmBtnText="Confirm"
                                    confirmBtnBsStyle="success"
                                    title="Product Added Successfuly"
                                  
                                   
                                    focusCancelBtn>
                                    
                                  </SweetAlert>
                              
                              : (
                                <></>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
