import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Nav from "../../../navbar2";
function Buyleads() {
  const navigate = useNavigate();
  const [buylead, setBuylead] = useState([]);
  const [uid, setUid] = useState('');
  const [status, setStatus] = useState('');
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      buyleads_buyer(v[0].id);
     
    }
  }, []);
  const buyleads_buyer = async (id) => {
    const data = new FormData();
    data.append("uid", id);

    axios({
      method: "post",
      url: "/pro/lead_view_uid.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("buyleads List ", s.data);
        const a = s.data;
        setBuylead(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const Sold = async (id) => {
    const data = new FormData();
    data.append("id", id);

    axios({
      method: "post",
      url: "/pro/buyer_lead_status_update.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Active Status Check List ", s.data);
        const a = s.data;
        //setBuylead("");
       buyleads_buyer(uid);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const Active = async (id) => {
    const data = new FormData();
    data.append("id", id);

    axios({
      method: "post",
      url: "/pro/generalleadstatus.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Active Status Check List ", s.data);
        const a = s.data;
        //setBuylead("");
       buyleads_buyer(uid);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Product Buy Requests </h4>

                  </div>



                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Unit</th>
                            <th>Approx Price</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>No. Of Request</th>
                            <th>Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {buylead.length == 0 ? (
                            <>

                             
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={10}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>

                            </>
                          ) : (
                            <></>
                          )}

                          {buylead.map((v, i) => (
                             <>
                             {v.status== ""?
                              <tr>
                              <td>{i + 1}</td>
                              <td>{JSON.parse(v.sheet)[0].name}</td>
                              <td>{JSON.parse(v.sheet)[0].qty}</td>
                              <td>{JSON.parse(v.sheet)[0].piece}</td>
                              <td>{JSON.parse(v.sheet)[0].price}</td>
                              <td>{JSON.parse(v.sheet)[0].des}</td>
                              <td>
                                <p  onClick={()=>{Active(v.id)}} style={{ color: "red", cursor:"pointer" }}>
                                 {v.status==""?<u>Active</u>:<>Sold</> } 
                                </p>
                              </td>

                              {/* <td>
                              <div className="icon-area">
                                
                                <button onClick={()=>{                         
                                localStorage.setItem(
                                  'leadid',
                                  v.id,
                                );
                                navigate('/requestno')
                              }} className="btn-success">Quotes </button>
                           
                              </div>
                              </td> */}
                              <td>

                             <div style={{cursor:"pointer"}} className="widget-header mr-3">
                               <a onClick={()=>{                         
                                localStorage.setItem(
                                  'leadid',
                                  v.id,
                                );
                                navigate('/requestno')
                              }}  className="widget-view">
                               <div className="icon-area">
                                 <i style={{color: '#0077b6'}} className="fa fa-bell"></i>

                               <span  className="notify">{v.count}</span>
                              </div>
                              <small className="text">
                                 <strong>Requests</strong>{' '}
                               </small>
                              </a>
                              </div>


                              </td>




                              <td>{v.time}</td>
                            </tr> :<></>
                            }
                            </>
                          
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Purchased Buy Requests </h4>

                  </div>



                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Unit</th>
                            <th>Approx Price</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Request</th>
                         
                            <th>Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {buylead.length == 0 ? (
                            <>

                             
                              <tr >
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={10}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>

                            </>
                          ) : (
                            <></>
                          )}

                          {buylead.map((v, i) => (
                             <>
                             {v.status== "sold"?
                              <tr style={{backgroundColor:"#caf0f8"}}>
                              <td>{i + 1}</td>
                              <td>{JSON.parse(v.sheet)[0].name}</td>
                              <td>{JSON.parse(v.sheet)[0].qty}</td>
                              <td>{JSON.parse(v.sheet)[0].piece}</td>
                              <td>{JSON.parse(v.sheet)[0].price}</td>
                              <td>{JSON.parse(v.sheet)[0].des}</td>
                              <td>
                                <p onClick={()=>{Sold(v.id)}} style={{ color: "red" }}>
                                 {v.status==""?<u>Active</u>:<>Sold</> } 
                                </p>
                              </td>

                              {/* <td>
                              <div className="icon-area">
                                
                                <button onClick={()=>{                         
                                localStorage.setItem(
                                  'leadid',
                                  v.id,
                                );
                                navigate('/requestno')
                              }} className="btn-success">Quotes </button>
                           
                              </div>
                              </td> */}
                          

                          <td>

<div style={{cursor:"pointer"}} className="widget-header mr-3">
  <a onClick={()=>{                         
   localStorage.setItem(
     'leadid',
     v.id,
   );
   navigate('/requestno')
 }}  className="widget-view">
  <div className="icon-area">
    <i style={{color: '#0077b6'}} className="fa fa-eye"></i>

  {/* <span  className="notify">{v.count}</span> */}
 </div>
 <small className="text">
    <strong>Requests</strong>{' '}
  </small>
 </a>
 </div>


 </td>


                              <td>{v.time}</td>
                            </tr> :<></>
                            }
                            </>
                          
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Sold Product History */}
          
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
