import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {
  PlusCircleFill,
  PlusCircleDotted,
  Trash,
  Trash3,
} from 'react-bootstrap-icons';
import Nav from '../../../navbar';

function Mainpage() {
  const navigate = useNavigate();
  const [image, setImage] = useState('');
  const [titel, settitel] = useState('');
  const [des, setDes] = useState('');
  const [uid, setUid] = useState('');
  const [array, setArray] = useState([]);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      getAllCertificat(uid);
    }
  }, []);
  const getAllCertificat = async uid => {
    const data = new FormData();
    data.append('uid', uid);

    axios({
      method: 'post',
      url: '/pro/profile/profile_view_certificate.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('here then =>>', s.data);
        setArray(s.data);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Certification & Awards </h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                      <span>
                        <button
                          onClick={() => {
                            navigate('/newcertificate');
                          }}
                          style={{borderRadius: '18px', color: 'white'}}
                          className="bg-primary mx-3 px-4 ">
                          newcertificate
                        </button>
                      </span>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Image</th>
                            <th>Title</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {array.length == 0 ? (
                            <>
                              {' '}
                              <tr>
                                <td
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                  colSpan={10}>
                                  No Record to show{' '}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                          {array.map((v, i) => (
                            <>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{v.certificate}</td>
                                <td>{v.title}</td>
                                <td>{v.description}</td>

                                <td></td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
