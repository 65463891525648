import React from 'react'
import Nav from './navbar'

function Blogdetail() {
  return (
    <>
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
<Nav/>

<div id="main-content" className="blog-page">
 
        <div className="container">
            <div className="row clearfix">
                <div className="col-lg-8 col-md-12 left-box">
         <h3 className='text-center my-3'><i><u>How to Choose a Deposition Reporters Services</u></i></h3>
                    <div className="card single_post">
                        <div className="body">
                            <div className="img-post">
                                <img className="d-block img-fluid" src="assets/images/posts/test.jpg" alt="First slide"/>
                            </div>
                            <h3 className='text-center my-4'>Blog Title</h3>
                            <div className='row'>
                            <h4 className=' mt-3'>Posted: 3 days Ago</h4>
                            <p className=' mb-4'>Written By : Admin</p>
                            <br/>
                            </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's sta
                        ndard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining esse
                        ntially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and
                         more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply du
                         mmy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500
                         s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuri
                         es, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of L
                         etraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including version
                         s of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard d
                         ummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centu
                        ries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </div>                        
                    </div>
                    <div className="card">
                    <div className="card">
                            <div >
                                <h2>Leave a reply <small>Your email address will not be published. Required fields are marked*</small></h2>
                            </div>
                            <div className="body">
                                <div className="comment-form">
                                    <form className="row clearfix">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Your Name"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Email Address"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <textarea rows="4" className="form-control no-resize" placeholder="Please type what you want..."></textarea>
                                            </div>
                                            <button type="submit" className="btn btn-block btn-primary">SUBMIT</button>
                                        </div>                                
                                    </form>
                                </div>
                            </div>
                        </div>
                            <div >
                                <h2>Comments </h2>
                            </div>
                            <div className="body">
                                <ul className="comment-reply list-unstyled">
                                    <li className="row clearfix">
                                        <div className="icon-box col-md-2 col-4"><img className="img-fluid img-thumbnail" src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Awesome Image"/></div>
                                        <div className="text-box col-md-10 col-8 p-l-0 p-r0">
                                            <h5 className="m-b-0">Gigi Hadid </h5>
                                            <p>Why are there so many tutorials on how to decouple WordPress? how fast and easy it is to get it running (and keep it running!) and its massive ecosystem. </p>
                                            <ul className="list-inline">
                                                <li><a href="javascript:void(0);">Mar 09 2018</a></li>
                                                <li><a href="javascript:void(0);">Reply</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="row clearfix">
                                        <div className="icon-box col-md-2 col-4"><img className="img-fluid img-thumbnail" src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="Awesome Image"/></div>
                                        <div className="text-box col-md-10 col-8 p-l-0 p-r0">
                                            <h5 className="m-b-0">Christian Louboutin</h5>
                                            <p>Great tutorial but few issues with it? If i try open post i get following errors. Please can you help me?</p>
                                            <ul className="list-inline">
                                                <li><a href="javascript:void(0);">Mar 12 2018</a></li>
                                                <li><a href="javascript:void(0);">Reply</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="row clearfix">
                                        <div className="icon-box col-md-2 col-4"><img className="img-fluid img-thumbnail" src="https://bootdey.com/img/Content/avatar/avatar4.png" alt="Awesome Image"/></div>
                                        <div className="text-box col-md-10 col-8 p-l-0 p-r0">
                                            <h5 className="m-b-0">Kendall Jenner</h5>
                                            <p>Very nice and informative article. In all the years I've done small and side-projects as a freelancer, I've ran into a few problems here and there.</p>
                                            <ul className="list-inline">
                                                <li><a href="javascript:void(0);">Mar 20 2018</a></li>
                                                <li><a href="javascript:void(0);">Reply</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>                                        
                            </div>
                        </div>
                    
                </div>
                <div className="col-lg-4 col-md-12 right-box">
                    <div className="card">
                        <div className="body search">
                            <div className="input-group m-b-0">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fa fa-search"></i></span>
                                </div>
                                <input type="text" className="form-control" placeholder="Search..."/>                                    
                            </div>
                        </div>
                    </div>
                   
                    <div className="card">
                        <div >
                            <h2 className='text-center'>Popular Posts</h2>  
                            <hr/>                      
                        </div>
                        <div className="body widget popular-post">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single_post">
                                        <div className="img-post">
                                            <img style={{height:"150px",width:'auto'}} src="assets/images/posts/test2.jpg" alt="Awesome Image"/>                                        
                                        </div>                                            
                                        <p className="m-b-0">Apple Introduces Search Ads Basic</p>
                                        <span className='text-muted'>jun 22, 2018</span>
                                    </div>
                                    <div className="single_post">
                                        <div className="img-post">
                                            <img style={{height:"150px",width:'auto'}} src="assets/images/posts/test3.jpg" alt="Awesome Image"/>                                            
                                        </div>
                                        <p className="m-b-0">new rules, more cars, more races</p>
                                        <span>jun 8, 2018</span>
                                    </div>
                                    <div className="single_post">
                                        <div className="img-post">
                                            <img style={{height:"150px",width:'auto'}} src="assets/images/posts/test3.jpg" alt="Awesome Image"/>                                            
                                        </div>
                                        <p className="m-b-0">new rules, more cars, more races</p>
                                        <span>jun 8, 2018</span>
                                    </div>
                                    <div className="single_post">
                                        <div className="img-post">
                                            <img style={{height:"150px",width:'auto'}} src="assets/images/posts/test3.jpg" alt="Awesome Image"/>                                            
                                        </div>
                                        <p className="m-b-0">new rules, more cars, more races</p>
                                        <span>jun 8, 2018</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                    <div className="card">
                        <div >
                            <h2>Email Newsletter <br/> <small>Get our products/news earlier than others, let’s get in touch.</small></h2>
                        </div>
                        <div className="body widget newsletter">                        
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Enter Email"/>
                                <div className="input-group-append">
                                    <span className="input-group-text"><i className="icon-paper-plane"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    
    
    </>
  )
}

export default Blogdetail