import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom'; 
import {
  PlusCircleFill,
  PlusCircleDotted,
  Trash,
  Trash3,
 
} from "react-bootstrap-icons";
import { Button } from 'bootstrap';

function Mainpage() {
  const navigate = useNavigate()
  const [con_volume, setConvolume] = useState('');
  const [array, setArray] = useState([]);
  const [Driver, setDriver] = useState([]);
  const [bid, setBid] = useState([]);
  const [index, setIndex] = useState(0);
  const [view, setView] = useState(true);
  const [uid, setUid] = useState('');
  const [pid, setPid] = useState('');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id) 
      const as=JSON.parse(localStorage.getItem('UserRequest'))
      setArray(as)
      console.log("hi",as)
      const i= (localStorage.getItem('UserRequestId'));
      console.log("new" ,i)
      setIndex(i)
      getallDriver(as[i].rid)
      setPid(as[i].rid)
      // alert(pid)
     
    }
  }, []);

  const AceptDriver = async (fare,did)=> {

    const data = new FormData();
    data.append('pid',pid);
    data.append('type',"accept");
    data.append('fare',fare);
    data.append('did',did);

        axios({ 
          method: 'post',
          url: '/pro/logistic/accept_bids.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log("all book",s.data)
            
            if(s.data[0].code=="booked")
            {
              navigate('/request');
              //setDriver(s.data)
            }
            
                  })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };



  const RebidRequest = async ()=> {

    const data = new FormData();
    data.append('pid',pid);
    data.append('type',"update");
    data.append('fare',bid);

        axios({ 
          method: 'post',
          url: '/pro/logistic/accept_bids.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
              console.log("issue no 3",s.data,"  req id ",pid)
           
            if(s.data[0].code=="update")
            {
              alert("Updated")
              getRequest(uid)
            }
            
                  })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
  const getallDriver = async (postid)=> {

    const data = new FormData();
    data.append('pid',postid);
    console.log(postid)

        axios({ 
          method: 'post',
          url: '/pro/logistic/view_offers.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log("all offers",s.data)
            if(s.data[0].code=="pass")
            {
              setDriver(s.data)
            }
            
                  })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

  const getRequest = async (uid)=> {

    const data = new FormData();
    data.append('status', "uid");
    data.append('uid', uid);
    data.append('val', "pending");

    
        axios({ 
          method: 'post',
          url: '/pro/logistic/view_requests.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log("request book user",s.data)
            if(s.data[0].code=="all")
            {
              setArray(s.data)
           
            }
            
                  })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */} 
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Requests  <button onClick={()=>{setView(!view)}} className='btn-primary' >{view?"Hide":"Show"} <span className="sidebar-collapse-icon ti-angle-down"></span></button> </h4>
                  </div>
                  
               {
                   array.length>0?<>
                     {
                         view?<> <div className="card-body">
                

                         {/* row 2 */}
                         <div className='row'>
                       
                        
                         <div className="col-lg-3" style={{fontSize:"20px",color:"darkblue",fontWeight:"bold"}}>
                         Sender Info
                        </div>
                         <div className="col-lg-3">
                         {array[index].sender_name}
                        
                        </div>
                         <div className="col-lg-3">
                        
                         {array[index].s_phone}
                         
                        
                        </div>
                         <div className="col-lg-3">
                         
                         {array[index].s_address}
                        </div>
                      <hr />
         
                         </div>
                         {/* row 2 */}
                         <div className='row'>
                       
                        
                         <div className="col-lg-3" style={{fontSize:"20px",color:"darkblue",fontWeight:"bold"}}>
                         Receiver Info
                        </div>
                         <div className="col-lg-3">
                         {array[index].receiever_name}
                        
                        </div>
                         <div className="col-lg-3">
                        
                         {array[index].r_phone}
                         
                        
                        </div>
                         <div className="col-lg-3">
                         
                         {array[index].r_address}
                        </div>
                      
         
                         </div>
         
         
         
                         <hr />
                         <div className='row'>
                         <div className="col-lg-2" style={{fontSize:"20px",color:"darkblue",fontWeight:"bold"}}>
                         From City
                        </div>
                         <div className="col-lg-2">
                         {array[ index].frm_city}
                        </div>
                         <div className="col-lg-2" style={{fontSize:"20px",color:"darkblue",fontWeight:"bold",}} >
                         To City
                        </div>
                         <div className="col-lg-2">
                         {array[index].to_city}
                        </div>
                         <div className="col-lg-2" style={{fontSize:"20px",color:"darkblue",fontWeight:"bold"}} >
                         Use Type
                        </div>
                         <div className="col-lg-2">
                         {array[index].log_type}
                        </div>
         
                         </div>
                         <hr />
                         {/* row 3 */}
                         <div className='row'>
                         <div className="col-lg-2" style={{fontSize:"20px",color:"darkblue",fontWeight:"bold"}} >
                         Departure Date
                        </div>
                         <div className="col-lg-2">
                         {array[ index].depart_date}
                        </div>
                         <div className="col-lg-2" style={{fontSize:"20px",color:"darkblue",fontWeight:"bold"}} >
                         Departure Time
                        </div>
                         <div className="col-lg-2" >
                         {array[index].depart_time}
                        </div>
                         <div className="col-lg-2" style={{fontSize:"20px",color:"darkblue",fontWeight:"bold"}} >
                        Fare 
                        </div>
                         <div className="col-lg-2" style={{fontSize:"24px",color:"black",fontWeight:"bold"}} >
                         {array[index].fare} Rs.
                        </div>
         
                         </div>
                        
         
             
                      
                     </div></>:<></>
}</>:<></>
               }
                </div>
              </div>
            </section>
       <div className='row'>
           <div className='col-6'> 
               <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Rider offers </h4>
                  </div>
                  
                  <div className="card-body">
                  <div className="table-responsive">
                      <table className="table">
                        <thead>
               
                      <tr>
                          <th>Driver Name </th>
                          <th>Vehicle</th>
                          <th>No. Plates</th>
                          <th>Fare </th>
                          <th>control </th>
                      </tr>
                      </thead>
                  {
                       Driver.map((v,i)=>(
                           <>
                           <tr>
                               <td>{v.dname}</td>
                               <td>{v.vehicle}</td>
                               <td>{v.nplate}</td>
                               <td>{v.fare}</td>
                               <td> <button className='btn-secondary' style={{background:"darkblue",color:"white"}}  onClick={()=>{
AceptDriver(v.fare,v.did)
                               }} > Accept </button></td>
                           </tr>
                           </>

                       ))
                   }
                  </table>
                  </div>
                  </div>
                </div>
              </div>
            </section></div>
           <div className='col-6'>
           <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Change Bid Request </h4>
                  </div>
                  
                  <div className="card-body">
                      <div className='row'>
                          <div className='col-6' >
                              <label>Bid Amount</label>
                          </div>
                          <div className='col-6' >
                              <input className="form-control" type={"number"} placeholder='Amount' onChange={(v)=>{
                                  setBid(v.target.value)
                              }}  />
                          </div>

                      </div>
                      
                      <div style={{marginTop:"20px",marginBottom:"20px"}} className='row'>
                          <button onClick={()=>{
                              RebidRequest()
                          }} className='btn-primary' >Rebid</button>

                      </div>
                      
          
                   
                  </div>
                </div>
              </div>
            </section>
           </div>

       </div>

          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
