import React from 'react'
import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

function Bulkproduct() {
    const[uid, setUid]=useState('')
    const[sheet, setSheet]=useState('')
    const[sheetStatus, setSheetStatus]=useState('1')

    const[matter, setMatter]=useState([])


    useEffect(() => {
        const v = JSON.parse(localStorage.getItem('USER'));
        if (v === null) {
        } else {
          setUid(v[0].id);
          ViewMatter(v[0].id);
        }
      }, []);
    

      const addsheet = async id => {
        const data = new FormData();
        data.append('uid', id);
        data.append('status', 'status');
        data.append('val', 'in');
        data.append('img', sheet);
        
        axios({
          method: 'post',
          url: '/pro/products/manage_bulk_product.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
              console.log('hi')
            if (s.data[0].code === 'pass') {

              ViewMatter(uid);
              setSheetStatus("1")
              //window.location.reload();
             
    
            }
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };


      const ViewMatter = async d => {
        const data = new FormData();
        data.append('uid', d);
        data.append('val', 'view');
    
        axios({
          method: 'post',
          url: '/pro/products/manage_bulk_product.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log('Matter Sheet List ', s.data);
            const a = s.data;
            setMatter(a);
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };


      // Function will execute on click of button
    const onButtonClick = () => {
      // using Java Script method to get PDF file
      fetch('assets/images/Alahdeen_Template.csv').then(response => {
          response.blob().then(blob => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);
              // Setting various property values
              let alink = document.createElement('a');
              alink.href = fileURL;
              alink.download = 'Alahdeen_Template.csv';
              alink.click();
          })
      })
  }

    
  return (
  <>
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
               
                  
                    {
                      sheetStatus=="1"?<div style={{border:"1px solid black", padding:"25px"}}>
                    
                      <div className="form-group col-6">
                     
    
                          <label> Upload CSV <b > <a  onClick={onButtonClick} class="btn btn-success" 
       style={{color:"white"}}   > <i class="fa fa-download"></i> Download Template</a> </b> </label>
                          <input 
                          type='file'
                          onChange={v => {
                            setSheet(v.target.files[0]);
                          }}
                          className="form-control" />
    
                        <div style={{marginTop:"10px"}}>
                        <button  onClick={()=>{
                            if(uid!== ""){
                              setSheetStatus("2")
                                addsheet(uid)
                            }
                            
                        }} className="btn-primary px-3 mx-3 my-2">Save</button>
                        </div>
                        </div>
                        </div>:<> 
                     



 <h3>Uploading.................................</h3>  </>
                    }





                  <div  style={{marginTop:"35px"}} className="card-body">
                  <div className="card-title">
                    <h4>MatterSheet List</h4>
                  </div>
                  <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>File</th>
                            <th>Time</th>
                            <th>Status</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          {matter.length === 0 ? (
                            <>
                              <tr>
                                {' '}
                                <td
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                  colSpan={6}>
                                  No Record to show{' '}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                            {matter.map((v,i)=>(
 
 <tr>
    <td> {i+1}</td>
    <td> {v.name}</td>
    <td> {v.time}</td>
    <td> {v.status}</td>
    
    {/* <td> {v.status}</td> */}
    {/* <td> <button className='btn-danger'>Delete</button></td> */}


 </tr>

                            ))}
                           
                            
                            </>
                          )}
                          </tbody>
                          </table>
</div>

          
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  
  
  
  
  
  </>
  )
}

export default Bulkproduct




