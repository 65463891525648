import Nav from "../../../navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  PlusCircleFill,
  PlusCircleDotted,
  Trash,
  Trash3,
} from "react-bootstrap-icons";

function Mainpage() {
  const navigate = useNavigate();
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      
      setSelectedUser("a");
      const draft=localStorage.getItem("Draft_Invoice")
      ViewInvoice(draft,v[0].id)
      
    }
  }, []);




  const ViewInvoice = async (id,uidd) => {
   

    const data = new FormData();
    data.append("id", id);

    axios({
      method: "post",
      url: "/pro/khata/print.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("khata print  ", s.data);
        const a = s.data;
        if(a.length<1)
        {
          navigate("/")
        }else{
          setWhatsapp(a[0].whatsapp)
          setinvoicedate(a[0].invoicedate)
          setInvoiceno(a[0].iid)

          setDuedate(a[0].duedate)
          setDeldate(a[0].deldate)
          setComments(a[0].comments)

          setInvoice(JSON.parse(a[0].array))
          settrans(JSON.parse(a[0].array_term))

          
          setCid(a[0].rcid)
          getallClient(uidd,cid)
          
          
          const ss=JSON.parse(a[0].array);
          const oldArr = [...ss];
          const subTotalgst = oldArr.reduce((n, { gstamount }) => n + gstamount, 0);
          const subTotalamount = oldArr.reduce((n, { amount }) => n + amount, 0);
          const subTotal = oldArr.reduce((n, { total }) => n + total, 0);
          const subTotaltax = oldArr.reduce((n, { tax }) => n + Number(tax), 0);

          settotalTax(subTotaltax);
          setGrandTotal(subTotal);
          settotalAmount(subTotalamount);
          setgsttotal(subTotalgst);

          

        }
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const [name, setName] = useState("");
  const [selectedUser, setSelectedUser] = useState("a");
  const [cid, setCid] = useState("");
  const [allclient, setAllClient] = useState([]);
  const [totalAmount, settotalAmount] = useState("");
  const [gsttotal, setgsttotal] = useState("");
  const [totalTax, settotalTax] = useState("");
  const [GrandTotal, setGrandTotal] = useState("");
  const [duedate, setDuedate] = useState("");

  const [uid, setUid] = useState("");
  const [deldate, setDeldate] = useState("");
  const [invoicedate, setinvoicedate] = useState("");
  const [invoiceno, setInvoiceno] = useState("");

  const [comments, setComments] = useState("");
  const [mail, setMail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [phone, setPhone] = useState("");
  const [client, setClient] = useState([]);
  const [trans, settrans] = useState([{ id: Math.random(), value: "" }]);
  const [invoice, setInvoice] = useState([
    {
      id: Math.random(),
      name: "",
      code: "",
      quantity: 1,
      rate: 1,
      amount: 1,
      gst: 0,
      gstamount: 0,
      tax: 0,
      total: 1,
      status: "normal",
    },
  ]);
  const addInvoice = () => {
    const oldArr = [...invoice];
    let newObj = {
      id: Math.random(),
      name: "",
      code: "",
      quantity: 0,
      rate: 0,
      amount: 0,
      gst: 0,
      gstamount: 0,
      tax: 0,
      total: 0,
      status: "normal",
    };
    oldArr.push(newObj);
    setInvoice(oldArr);
  };



  const changeArayInvoices = (i, changes) => {
    const oldArr = [...invoice];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };

    const am = oldArr[i].quantity * oldArr[i].rate;

    oldArr[i].amount = am;

    oldArr[i].gstamount = am*oldArr[i].gst;


    oldArr[i].total = oldArr[i].gst * am + am + Number(oldArr[i].tax);

    setInvoice(oldArr);


    const subTotalgst = oldArr.reduce((n, { gstamount }) => n + gstamount, 0);

    const subTotalamount = oldArr.reduce((n, { amount }) => n + amount, 0);

    const subTotal = oldArr.reduce((n, { total }) => n + total, 0);

    const subTotaltax = oldArr.reduce((n, { tax }) => n + Number(tax), 0);

    settotalTax(subTotaltax);
    setGrandTotal(subTotal);
    settotalAmount(subTotalamount);
    setgsttotal(subTotalgst);
  };





  const delInvoice = (id) => {

    let oldInvoice = [...invoice];
    const oldArr = oldInvoice.filter((word) => word.id !== id);
    setInvoice(oldArr);

    const subTotalgst = oldArr.reduce((n, { gstamount }) => n + gstamount, 0);
    const subTotalamount = oldArr.reduce((n, { amount }) => n + amount, 0);
    const subTotal = oldArr.reduce((n, { total }) => n + total, 0);
    const subTotaltax = oldArr.reduce((n, { tax }) => n + Number(tax), 0);

    settotalTax(subTotaltax);
    setGrandTotal(subTotal);
    settotalAmount(subTotalamount);
    setgsttotal(subTotalgst);
  };



  const changeArayTerm = (i, changes) => {
    const oldArr = [...trans];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    settrans(oldArr);
  };
  
  const delTerm = (id) => {
    let oldInvoice = [...trans];
    const result = oldInvoice.filter((word) => word.id !== id);
    settrans(result);
  };

  const addTerm = () => {
    const oldArr = [...trans];
    let newObj = {
      id: Math.random(),
      value: "",
    };
    oldArr.push(newObj);
    settrans(oldArr);
  };

  const getallClient = async (uid,cid) => {
    const data = new FormData();
    // data.append('uid', uid);
    data.append("uid", uid);
    data.append("val", "view_client");
    

    axios({
      method: "post",
      url: "/pro/khata/manage_khata.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllClient(s.data);
        console.log("get uid all client",s.data);
    
        for(var i=0;i<s.data.length;i++)
        {
            if(s.data[i].cid==cid)
            {
                setSelectedUser(i)   
            }
        }

      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const NewDraft = async () => {
    const data = new FormData();
    data.append("val", "draft");
    data.append("status", "draft");
    data.append("cid", cid);
    data.append("uid", uid);
    data.append("iid", invoiceno);
    data.append("invoicedate", invoicedate);
    data.append("deldate", deldate);
    data.append("duedate", duedate);
    data.append("chatid", client);
    data.append("array", JSON.stringify(invoice));
    
    data.append("tarray", JSON.stringify(trans));
    data.append("comments", comments);
    data.append("email", '');
    data.append("whatsapp", '');
    data.append("phone", '');
    data.append("total", GrandTotal);
    
    axios({
      method: "post",
      url: "/pro/khata/manage_khata.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        if (cid==''){
       alert('please Select client First')
        }else{
          console.log("Draft list ", s.data);

          //navigate("/khatamanageinvoice");
          
        }
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };


  const Newinvoice = async () => {
    const data = new FormData();
    data.append("val", "add_invoice");
    data.append("status", "active");
    data.append("cid", cid);
    data.append("uid", uid);
    data.append("iid", invoiceno);
    data.append("invoicedate", invoicedate);
    data.append("deldate", deldate);
    data.append("duedate", duedate);
    data.append("chatid", client);
    data.append("array", JSON.stringify(invoice));
    data.append("status", "active");
    data.append("tarray", JSON.stringify(trans));
    data.append("comments", comments);
    data.append("email", '');
    data.append("whatsapp", '');
    data.append("phone", '');
    data.append("total", GrandTotal);
    
    axios({
      method: "post",
      url: "/pro/khata/manage_khata.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        if (cid==''){
       alert('please Select client First')
        }else{
          console.log("Main cat list ", s.data);
      
          navigate("/khatamanageinvoice");
          
        }
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <center>
                      <h2 className="my-4">Draft Invoice </h2>
                    </center>
                  </div>
                  {/* <div className="container"> */}
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Invoice Number</label>
                        <input
                          value={invoiceno}
                          onChange={(v) => {
                            setInvoiceno(v.target.value);
                          }}
                          style={{
                            width: "60%",
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Invoice Date</label>
                        <input
                          type="date"
                          id="fname"
                          className="form-control form-control-sm"
                          value={invoicedate}
                          onChange={(v) => {
                            setinvoicedate(v.target.value);
                          }}
                        />{" "}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Payment Due Date</label>
                        <input
                          type="date"
                          id="fname"
                          className="form-control form-control-sm"
                          value={duedate}
                          onChange={(v) => {
                            setDuedate(v.target.value);
                          }}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Delivery Date</label>
                        <input
                          value={deldate}
                          onChange={(v) => {
                            setDeldate(v.target.value);
                          }}
                          type="date"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  {/* </div> */}

                  <div className="row">
                    <div className="col-6">
                      {" "}
                      <div>
                        <label className="form-group">Billed To </label>
                        <Link to="/clientAddform">
                          <button className="btn-primary mx-5 px-2">
                            <i className="ti ti-plus px-2"></i>New Client 
                          </button>
                        </Link>
                        <select
                          value={selectedUser}
                          onChange={(v) => {
                            if (v.target.value == "a") {
                            } else {
                              setSelectedUser(v.target.value);
                              setCid(allclient[v.target.value].cid)
                            
                            

                            }
                          }}
                          className="form-control"
                        >
                          {/* <option>Asad</option> */}
                          <option value={"a"}>Select Client</option>

                          {allclient.map((v, i) => (
                            <option key={i} value={i}>
                              {JSON.parse(v.array)[0].name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-6">
                      {allclient.map((v, i) => (
                        <>
                          {i === Number(selectedUser) ? (
                            <>
                              
                              <label className="form-group">Email</label>
                              <input
                                disabled
                                className="form-control"
                                type="text"
                                value={JSON.parse(v.array)[0].email}
                              />
                            </>
                          ) : (
                            <> </>
                          )}
                        </>
                      ))}
                    </div>
                  </div>

                  <div style={{ marginTop: "15px" }} className="row">
                    <div className="col-6">
                      {allclient.map((v, i) => (
                        <>
                          {i === Number(selectedUser) ? (
                            <>
                              {" "}
                              <label className="form-group">Contact No</label>
                              <input
                                className="form-control"
                                type="text"
                                disabled
                                value={JSON.parse(v.array)[0].phone}
                              />
                            </>
                          ) : (
                            <> </>
                          )}
                        </>
                      ))}
                    </div>
                    <div className="col-6">
                      {allclient.map((v, i) => (
                        <>
                          {i === Number(selectedUser) ? (
                            <>
                              {" "}
                              <label className="form-group">Address</label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                value={JSON.parse(v.array)[0].add}
                              />
                            </>
                          ) : (
                            <> </>
                          )}
                        </>
                      ))}
                    </div>
                  </div>

                  <div style={{ marginTop: "50px" }} className="card-body">
                    <h5>Product Details</h5>
                    <br />
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Product Name</th>
                            <th>Product code</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th>GST %</th>
                            <th>GST Amount</th>
                            <th>Tax</th>
                            <th>Total</th>
                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoice.map((v, i) => (
                            <>
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  <input
                                    type="txt"
                                    id="fname"
                                    className="form-control form-control-sm"
                                    value={v.name}
                                    onChange={(v) => {
                                      changeArayInvoices(i, {
                                        name: v.target.value,
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="txt"
                                    id="fname"
                                    className="form-control form-control-sm"
                                    value={v.code}
                                    onChange={(v) => {
                                      changeArayInvoices(i, {
                                        code: v.target.value,
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    id="fname"
                                    className="form-control form-control-sm"
                                    value={v.quantity}
                                    onChange={(v) => {
                                      changeArayInvoices(i, {
                                        quantity: v.target.value,
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    id="fname"
                                    className="form-control form-control-sm"
                                    value={v.rate}
                                    onChange={(v) => {
                                      changeArayInvoices(i, {
                                        rate: v.target.value,
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    disabled
                                    id="fname"
                                    className="form-control form-control-sm"
                                    value={v.amount}
                                  />
                                </td>
                                <td>
                                  <select
                                    onChange={(v) => {
                                      const a = v.target.value;
                                      if (a == "1") {
                                        changeArayInvoices(i, { gst: 0.01 });
                                      } else if (a == "2") {
                                        changeArayInvoices(i, { gst: 0.02 });
                                      } else if (a == "3") {
                                        changeArayInvoices(i, { gst: 0.03 });
                                      } else if (a == "4") {
                                        changeArayInvoices(i, { gst: 0.04 });
                                      } else if (a == "5") {
                                        changeArayInvoices(i, { gst: 0.05 });
                                      } else if (a == "6") {
                                        changeArayInvoices(i, { gst: 0.06 });
                                      } else if (a == "7") {
                                        changeArayInvoices(i, { gst: 0.07 });
                                      } else if (a == "8") {
                                        changeArayInvoices(i, { gst: 0.08 });
                                      } else if (a == "9") {
                                        changeArayInvoices(i, { gst: 0.09 });
                                      } else if (a == "10") {
                                        changeArayInvoices(i, { gst: 0.1 });
                                      } else if (a == "11") {
                                        changeArayInvoices(i, { gst: 0.11 });
                                      } else if (a == "12") {
                                        changeArayInvoices(i, { gst: 0.12 });
                                      } else if (a == "13") {
                                        changeArayInvoices(i, { gst: 0.13 });
                                      } else if (a == "14") {
                                        changeArayInvoices(i, { gst: 0.14 });
                                      } else if (a == "15") {
                                        changeArayInvoices(i, { gst: 0.15 });
                                      } else if (a == "16") {
                                        changeArayInvoices(i, { gst: 0.16 });
                                      } else if (a == "17") {
                                        changeArayInvoices(i, { gst: 0.17 });
                                      } else if (a == "18") {
                                        changeArayInvoices(i, { gst: 0.18 });
                                      } else if (a == "19") {
                                        changeArayInvoices(i, { gst: 0.19 });
                                      } else if (a == "20") {
                                        changeArayInvoices(i, { gst: 0.2 });
                                      } else if (a == "21") {
                                        changeArayInvoices(i, { gst: 0.21 });
                                      }
                                    }}
                                    className="form-control "
                                  >
                                    <option>Select GST </option>
                                    <option value="1">1%</option>
                                    <option value="2">2%</option>
                                    <option value="3">3%</option>
                                    <option value="4">4%</option>
                                    <option value="5">5%</option>
                                    <option value="6">6%</option>
                                    <option value="7">7%</option>
                                    <option value="8">8%</option>
                                    <option value="9">9%</option>
                                    <option value="10">10%</option>
                                    <option value="11">11%</option>
                                    <option value="12">12%</option>
                                    <option value="13">13%</option>
                                    <option value="14">14%</option>
                                    <option value="15">15%</option>
                                    <option value="16">16%</option>
                                    <option value="17">17%</option>
                                    <option value="18">18%</option>
                                    <option value="19">19%</option>
                                    <option value="20">20%</option>
                                    <option value="21">21%</option>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    id="fname"
                                    className="form-control form-control-sm"
                                    value={v.gstamount}
                                    disabled
                                    onChange={(v) => {
                                      changeArayInvoices(i, {
                                        gstamount: v.target.value,
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    id="fname"
                                    className="form-control form-control-sm"
                                    value={v.tax}
                                    onChange={(v) => {
                                      changeArayInvoices(i, {
                                        tax: v.target.value,
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    disabled
                                    id="fname"
                                    className="form-control form-control-sm"
                                    value={v.total}
                                  />
                                </td>

                                <td>
                                  {invoice.length < 2 ? (
                                    <></>
                                  ) : (
                                    <Trash
                                      color="black"
                                      size={25}
                                      onClick={() => delInvoice(v.id)}
                                    />
                                  )}
                                </td>
                              </tr>
                              {invoice.length == i + 1 ? (
                                <>
                                  <th > <li style={{cursor:"pointer"}}>
                        <PlusCircleFill
                          color="gray"
                          size={30}
                          onClick={() => {
                            addInvoice();
                          }}
                        />
                      </li></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                
                                  <th>
                                    Total Amount <br /> {totalAmount}
                                  </th>
                                  <th></th>
                                  <th>
                                    Total GST <br /> {(Number(gsttotal))}
                                  </th>
                                  <th>
                                    Total Tax <br /> {totalTax}
                                  </th>
                                  <th>
                                    Grand Total <br />
                                    {GrandTotal}
                                  </th>
                                </>
                              ) : (
                                <> </>
                              )}
                            </>
                          ))}
                        </tbody>
                      </table>
                      {/* <li>
                        <PlusCircleFill
                          color="gray"
                          size={30}
                          onClick={() => {
                            addInvoice();
                          }}
                        />
                      </li> */}
                    </div>
                  </div>
                  <div className="card-title">
                    <h4> Terms and Conditions </h4>
                  </div>
                  <div className="card-body">
                    {trans.map((v, i) => (
                      <>
                        <tr>
                          <th>{i + 1}</th>
                          <td>
                            <input
                              type="txt"
                              className="form-control form-control-sm"
                              value={v.value}
                              onChange={(vv) =>
                                changeArayTerm(i, { value: vv.target.value })
                              }
                            />
                          </td>
                          <td>
                            {" "}
                            {trans.length < 2 ? (
                              <></>
                            ) : (
                              <Trash3
                                color="black"
                                size={25}
                                onClick={() => delTerm(v.id)}
                              />
                            )}
                          </td>
                        </tr>
                        {trans.length === i + 1 ? (
                          <tr>
                            <td>
                              <PlusCircleDotted
                                color="gray"
                                size={30}
                                onClick={() => {
                                  addTerm();
                                }}
                              />{" "}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </div>
                  <hr />
                  <center>
                    <h5>
                      Write Comments (optional)
                      <input
                        className="mx-3"
                        value={comments}
                        onChange={(v) => {
                          setComments(v.target.value);
                        }}
                        style={{
                          border: "none",
                          borderBottom: "1px solid black",
                          width: "40%",
                        }}
                      />

                    </h5>
                  </center>
                  <hr />
                  <br />
                  <br />
                  <center>
                    <button
                     style={{margin:"20px"}}
                      className="btn-success btn-lg px-5"
                      onClick={(v) => {
                        Newinvoice();
                       
                      }}
                    >
                      Send
                    </button>

                    <button
                    style={{margin:"20px"}}
                      className="btn-warning btn-lg px-5"
                      onClick={(v) => {
                        if(cid=="")
                        {
                          alert("First Select Client then Save Draft")

                        }else{
                          NewDraft()
                        }
                      
                       
                      }}
                    >
                      Save Draft
                    </button>
                  </center>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
