import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Nav from '../../../navbar';
import SweetAlert from 'react-bootstrap-sweetalert';
function Buyleads() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [index, setIndex] = useState();
  const [lead, setLead] = useState([]);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      lead_view_all(v[0].id);
    }
  }, []);

  const checkvalidity = (lead_id) => {
    
    
    localStorage.setItem(
      'leadid',
      lead_id,
    );
    // navigate('/chat');
    navigate('/sendquote');
  }



  const lead_view_all = async id => {
    const data = new FormData();
    data.append('uid', id);

    axios({
      method: 'post',
      url: '/pro/sendlead.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('lead list ', s.data);
        const a = s.data;
        setLead(a);
      })
      .catch(s => {
        console.log('here lead_view_all ', s);
      });
  };

  // const viewProduct = async id => {
  //   const data = new FormData();
  //   data.append('uid', id);
  //   data.append('val', 'view');

  //   axios({
  //     method: 'post',
  //     url: '/pro/inventory/submit_productinfo.php',
  //     data: data,
  //     headers: {'Content-Type': 'multipart/form-data'},
  //   })
  //     .then(s => {
  //       console.log('product list ', s.data);
  //       const a = s.data;
  //       if (a[0].code != 'fail') {
  //         setProductList(a);
  //         localStorage.setItem('ProductListInventory', JSON.stringify(s.data));
  //       }
  //     })
  //     .catch(s => {
  //       console.log('here catch =>>', s);
  //     });
  // };

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Send Quote Requests</h4>
                  </div>
                  {/* <div className="row">
                    <div className="text-right">
                      <input
                        style={{borderRadius: '18px', paddingLeft: '8px'}}
                        placeholder="search"
                      />
                      <span>
                        <button
                          style={{borderRadius: '18px', color: 'white'}}
                          className="bg-primary mx-3 px-4 ">
                          Search
                        </button>
                      </span>
                    </div>
                  </div> */}


                  <div className="card-body">
                  <div className="table-responsive">
                  
                  <table className="table table-hover ">
                        <thead>
                          <tr>
                          <th>#</th>

                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Price</th>
                            <th>Discount %</th>
                            <th>Qty</th>
                            <th>Description</th>
                            <th>Delivery Time </th>
                         
                            <th>Applicable Tax %</th>
                            <th>Shipping Tax %</th>
                            <th>Date</th>
                            <th>Created At</th>
                          </tr>
                        </thead>
                        
                        <tbody>
                        {lead.length === 0 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                  colSpan={13}>
                                  No Record to show{' '}
                                </td>
                              </tr>
                            </>
                          ) : (
 <>
 
{lead.map((v,i)=>(
<tr>

                             <td>{v.id}</td>
                           <td style={{width: '20%'}}>
                                    <img
                                      style={{width: "20%", height: "25%", width:"120px"}}
                                      src={v.img.length<=1?"https://alahdeen.com/pro/product_img/image.jpg":v.img}
                                    
                                    />
                                  </td>
                                  <td>{v.name}</td>
                                  <td>{v.payment}</td>
                                  <td>{v.discount}</td>
                                  <td>{v.qty}</td>
                                  <td>{v.des}</td>
                                  <td>{v.delivery}</td>
                                  <td>{v.app_tax}</td>
                                  <td>{v.ship_tax}</td>
                                  <td>{v.time}</td>
                                  <td><button 
                                  className='btn-primary'
                                  onClick={()=>{

                                    
                                    setIndex(i)
                                    setAlert(true)
                                  }}
                                  >
                                    Details</button>
                                    
                             
                                    
                                    </td>



</tr>


)
)}
 
 </>                           
                          )
}


{alert ? (
                                <>
                                  {' '}
                                  <SweetAlert
                                    
                                    confirmBtnText="OK"
                                    cancelBtnText="Confirm"
                                    confirmBtnBsStyle="success"
                                    title="Get Quote Details"
                                    
                                    onConfirm={() => {
                                 
                                      setAlert(!alert);
                                     
                                    }}
                                    onCancel={() => {
                                      setAlert(!alert);
                                    }}
                                    focusCancelBtn>
                                    <br />
                                    
                                      <label >Lead Id :  
                                     {JSON.parse(lead[index].sheet)[0].id}  </label><br/>
                                      <label >Product Name :   
                                      {JSON.parse(lead[index].sheet)[0].name} </label><br/>
                                      <label >Product Qty :    {JSON.parse(lead[index].sheet)[0].quantity}  </label><br/>
                                      <label >Product Price :   {JSON.parse(lead[index].sheet)[0].price}  </label><br/>
                                      <label >Description :    {JSON.parse(lead[index].sheet)[0].des}  </label><br/>
                                     
                                    {/* <input value={prom} onChange={(v)=>{setProm(v.target.value)}} className='form-control'/> */}
                                   
                                    
                                    {/* <div className='row'>
                                    <div className='col-6'>
                                      <label>Start Date</label>
                                    <input value={start} onChange={(v)=>{setStart(v.target.value)}} className='form-control' type='date'/>
                                    </div>
                                    <br />
                                    
                                    <div className='col-6'>
                                      <label>End Date</label>
                                    <input  value={end} onChange={(v)=>{setEnd(v.target.value)}} className='form-control' type='date'/>
                                    </div>
                                    </div> */}
                                    <br/>
                                  </SweetAlert>
                                </>
                              ) : (
                                <></>
                              )}                        
</tbody>


 </table>
 
  </div>
</div>             



                            
             
</div>
                

                
                {/* <div className="card-body">
                <div className="table-responsive">
                <table className="table table-hover ">
                        <thead>
                          <tr>
                          <th>#</th>

                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Price</th>
                            <th>Discount %</th>
                            <th>Qty</th>
                            <th>Description</th>
                            <th>Delivery Time </th>
                         
                            <th>Applicable Tax %</th>
                            <th>Shipping Tax %</th>
                            <th>Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lead.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {lead.map((v, i) => (
                                <tr>
                                  <td>{v.id}</td>
                                  <td>{JSON.parse(v.sheet)[0].name} </td>
                                  <td>{JSON.parse(v.sheet)[0].qty} </td>
                                  <td>{JSON.parse(v.sheet)[0].price} </td>
                                  <td>{JSON.parse(v.sheet)[0].piece} </td>
                                  <td>{JSON.parse(v.sheet)[0].des} </td>
                                  <td>{v.time} </td>
                                  {/* <td>
                                    <a
                                      href={v.file}
                                      target="_blank"
                                      download={v.lead_id}>
                                      Download{' '}
                                    </a>{' '}
                                  </td> */}
                                   
                                     </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
