import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom'; 
import {
  PlusCircleFill,
  PlusCircleDotted,
  Trash,
  Trash3,
 
} from "react-bootstrap-icons";
import ValidationMessage from 'react-bootstrap-sweetalert-te/lib/dist/ValidationMessage';

function Mainpage() {
const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [logtype, setLogtype] = useState('Inner City');
  const [vehicle, setVehicle] = useState('');
  const [frm_city, setfrmcity] = useState("Lahore");
  const [to_city, setTocity] =useState ("");
  const [listcities, setCitiesList] = useState([]);

  const [distance, setdistance] = useState('');
  const [sender_name, setSendername] = useState('');
  const [sender_phone, setSenderphone] = useState('');
  const [sender_address, setSenderaddress] = useState('');
  const [receiever_name, setReceievername] = useState();
  const [r_phone, setRphone] = useState('');
  const [r_address, setr_address] = useState('');
  const [frm_map, setFrmmap] = useState('');
  const [to_map, setTomap] = useState('');

  const [toLat, settoLat] = useState('');
  const [toLong,settoLong] = useState(""); 
  const [fromlat, setfromlat] = useState('');
  const [fromlong,setfromlong] = useState(""); 
  const [weight,setWeight] = useState(); 
  const [volume,setVolume] = useState(); 
  const [frm, setfrm] = useState('');
  const [to,setto] = useState(""); 

  const [vehicletype, setTVehicletype] = useState('Full Vehicle');
  const [ddate, setDdate] = useState('');
  const [dtime, setDtime] = useState('');
  const [offers, setOffers] = useState('');
  const [comment, setComment] = useState('');
  const [con_type, setContype] = useState('');
  const [con_weight, setconweight] = useState('');
  const [con_volume, setConvolume] = useState('');
  const [array, setArray] = useState([{
    id: 1,
    name: '',
    packing: '',
    packetnum: '',
    description: '',
    status: 'normal',
  }]);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id) 
      getCities();
     
    }
  }, []);
;

const getCities = async () => {
  const data = new FormData();

      axios({
        method: "post",
        url: "/pro/auth/cities_list.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((s) => {
          setCitiesList(s.data)
        })
        .catch((s) => {
          //navigate("/")
        });
    
  
};

  const adPost = async ()=> {

    const data = new FormData();
    data.append('uid', uid);
    data.append('array', JSON.stringify(array));
    data.append('fare', offers);
    data.append('comments', comment);
    data.append('depart_time', dtime);
    data.append('depart_date', ddate);
    data.append('con_volume', "");
    data.append('con_weight', "");
    data.append('vehicle_type',vehicletype);
    data.append('r_address',r_address);
    data.append('r_phone',r_phone);
    data.append('receiever_name',receiever_name);
    data.append('s_address',sender_address);
    data.append('s_phone',sender_phone);
    data.append('sender_name',sender_name);
    data.append('con_weight',weight);
    data.append('con_volume',volume);
    
    data.append('distance',"");

    data.append('to_address',to);
    data.append('to_long',toLong);
    data.append('to_lat',toLat);

    data.append('frm_lat',fromlat);
    data.append('frm_long',fromlong);
    data.append('frm_address',frm);



    data.append('frm_city',frm_city);
    data.append('to_city',to_city);

    data.append('vehicle',vehicle);
    data.append('log_type',logtype);
    
        axios({ 
          method: 'post',
          url: '/pro/logistic/submit_request.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log("Driver list ",s.data)
          
            if(s.data[0].code=="new")
            {
            
             navigate('/bookingrequestuser')


            }else{
              alert(s.data[0].code)
            }
          
                  })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
      

      const Validate = ()=>{
 if (sender_name=== "" || sender_address==="" || sender_phone==="" ||receiever_name=== "" || r_address===""||  r_phone==="" ||  ddate=== ""|| dtime=="" ||  offers==="" || frm_city==="" || vehicletype==""    ){
    alert("please Fill The Form")
  }else{
  adPost()
  
  }


      }
      const changeAray = (i, changes) => {
        const oldArr = [...array];
        const oldObj = oldArr[i];
        oldArr[i] = { ...oldObj, ...changes };
        setArray(oldArr);

      };
      const del = (id) => {
        let oldInvoice = [...array];
        const result = oldInvoice.filter((word) => word.id !== id);
         setArray(result);

      };
      const add = () => {
        const oldArr = [...array];
        let newObj = {
          id: Math.random(),
          value: "",
        };
        oldArr.push(newObj);

        setArray(oldArr);

      };
      
      
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title my-4">
                    <h3>Shipping Request </h3>
                  </div>

                  <div style={{flexDirection:"column"}} className='row'>
                    <div className='col-12'>


                     
                      <img onClick={(()=>{setVehicle("bus")})} height="175px" style={{backgroundColor:vehicle==""?"#77aaff":"#fff" }} src='https://alahdeen.com/pro/images/bike.png'/>
                      <img height="175px" style={{backgroundColor:vehicle==""?"#77aaff":"#fff" }} src='https://alahdeen.com/pro/images/bike.png'/>
                      <img height="175px" style={{backgroundColor:vehicle==""?"#77aaff":"#fff" }} src='https://alahdeen.com/pro/images/bike.png'/>
                      <img height="175px" style={{backgroundColor:vehicle==""?"#77aaff":"#fff" }} src='https://alahdeen.com/pro/images/bike.png'/>
                      <img height="175px" style={{backgroundColor:vehicle==""?"#77aaff":"#fff" }} src='https://alahdeen.com/pro/images/bike.png'/>
                      <img height="175px" style={{backgroundColor:vehicle==""?"#77aaff":"#fff" }} src='https://alahdeen.com/pro/images/bike.png'/>
                      <img height="175px" style={{backgroundColor:vehicle==""?"#77aaff":"#fff" }} src='https://alahdeen.com/pro/images/bike.png'/>
                  
                  </div>
                
                  </div>
                  <div className="row">
                    <div className="col-12 form-group">
                      <label>Select Delivery Type</label>
                      <select className="form-control"
                      value={logtype}
                      onChange={(v)=>{
                        if(v.target.value=="Inner City")
                        {
                          setTocity("")
                        }else{
                          setLogtype(v.target.value)
                        }
                        
                      
                      }} 
                      >
                        <option>Select Type</option>
                        <option>Inner City</option>
                        <option>Inter City</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group">
                      <label>{logtype=="Inner City"?<>Shipping City </>:<>Pick-Up City  </>}</label>
                      {/* <input className="form-control" type="text"  value={frm_city} onChange={(v)=>{setfrmcity(v.target.value)  }} /> */}

                    <select className="form-control" value={frm_city} onChange={(v)=>{setfrmcity(v.target.value)  }} >
                    {
                        listcities.map((v,i)=>(
                          <option>{v.city}</option>

                        ))
                      }
                    </select>

                    </div>


                    {
                      logtype=="Inner City"?<></>:<> <div className="col-6 form-group">
                      <label>Drop-Off City</label>
                      {/* <input className="form-control" type="text" value={to_city} onChange={(v)=>{setTocity(v.target.value)  }} /> */}
                      <select className="form-control" value={to_city} onChange={(v)=>{  if(frm_city==v.target.value){
                        setLogtype("Inner City")
                      }else{
                        setTocity(v.target.value)
                      }  }}>
                    {
                        listcities.map((v,i)=>(
                          <option>{v.city}</option>

                        ))
                      }
                    </select>
                    </div></>
                    }
                   
                  </div>
                  <div className="row">
                    <div className="col-6 form-group">
                      <label>Shipper Name </label>
                      <input className="form-control" type="text" value={sender_name} onChange={(v)=>{setSendername(v.target.value)  }}/>
                    </div>
                    <div className="col-6 form-group">
                      <label>Receiver Name</label>
                      <input className="form-control" type="text" value={receiever_name} onChange={(v)=>{setReceievername(v.target.value)  }} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group">
                      <label>Shipper Contact No.</label>
                      <input className="form-control" type="text" value={sender_phone} onChange={(v)=>{setSenderphone(v.target.value)  }}/>
                    </div>
                    <div className="col-6 form-group">
                      <label>Receiver Contact No.</label>
                      <input className="form-control" type="text" value={r_phone} onChange={(v)=>{setRphone(v.target.value)  }}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group">
                      <label>Shipper Address</label>
                      <input className="form-control" type="text" value={sender_address} onChange={(v)=>{setSenderaddress(v.target.value)  }}/>
                    </div>
                    <div className="col-6 form-group">
                      <label>Receiver Address</label>
                      <input className="form-control" type="text" value={r_address} onChange={(v)=>{setr_address(v.target.value)  }}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group">
                      <label>Volume</label>
                      <input className="form-control" type="number" value={volume} onChange={(v)=>{setVolume(v.target.value)  }}/>
                    </div>
                    <div className="col-6 form-group">
                      <label>Weight</label>
                      <input className="form-control" type="text" value={weight} onChange={(v)=>{setWeight(v.target.value)  }}/>
                    </div>
                  </div>
{/* 
                  <div className="row">
                    <div className="col-6 form-group">
                      <label> From Location <button className="btn btn-primary" >Get Location</button> </label>
                      <input className="form-control" disabled type="text" value={frm}  />
                    </div>
                    <div className="col-6 form-group">
                      <label> To Location <button className="btn btn-primary" >Get Location</button></label>
                      <input className="form-control" disabled type="text" value={to}  />
                    </div>
                  </div> */}



                  <div className="row">
                    <div className="col-12 form-group">
                      <label>Vehicle Type </label>
                      <select className="form-control" value={vehicletype} onChange={(v)=>{setTVehicletype(v.target.value)  }}>
                        <option>Full Vehicle </option>
                        <option>Shared</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group">
                      <label>Departure Date</label>
                      <input className="form-control" type="date" value={ddate} onChange={(v)=>{setDdate(v.target.value)  }}/>
                    </div>
                    <div className="col-6 form-group">
                      <label>Departure Time</label>
                      <input className="form-control" type="time" value={dtime} onChange={(v)=>{setDtime(v.target.value)  }}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 form-group">
                      <label>Bid Offers</label>
                      <input className="form-control" type="text" value={offers} onChange={(v)=>{setOffers(v.target.value)  }}  />
                    </div>
                    <div className="col-6 form-group">
                      <label>Comments & Wishes</label>
                      <input className="form-control" type="text" value={comment} onChange={(v)=>{setComment(v.target.value)  }}/>
                    </div>
                  </div>
                  <div className="card-title my-4">
                    <h6>Product Description</h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Product Name</th>
                            <th>Type of Packing</th>
                            <th>No. of Packets</th>
                            <th>Description</th>
                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>

{
  array.map((v,i)=>(
    <tr>
    <td>{i+1}</td>
    <td><input type="txt"  className="form-control form-control-sm" value={v.name} onChange={(vv)=>changeAray(i,{name:vv.target.value})} /></td>
  <td><select value={v.packing} onChange={(vv)=>changeAray(i,{packing:vv.target.value})}  className="form-control" ><option></option> <option>Box</option><option>Bag</option><option>others</option></select></td>
  <td><input type="number"  className="form-control form-control-sm" value={v.packetnum} onChange={(vv)=>changeAray(i,{packetnum:vv.target.value})} /></td>
  <td><input type="txt"  className="form-control form-control-sm" value={v.description} onChange={(vv)=>changeAray(i,{description:vv.target.value})} /></td>
  <td> {
           array.length<2?<></>: <Trash3 color="black" size={25} onClick={() => del(v.id)} />
         }</td>
  </tr>
  ))
} </tbody>
                      </table>

                      { array.length>0?  
 <div  onClick={() => {
  add();
}} className="btn btn-success"> <PlusCircleDotted
              color="white"
              size={30} /> Products </div>:<></>}
                     
                    </div>
                  </div>
                  <br />
                 
             
                 <center> <div onClick={
                 
                 Validate
                 } className="btn btn-primary col-2 my-2 ">Submit</div></center>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );

 
}
export default Mainpage;
