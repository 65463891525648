import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Nav from '../../../navbar';


function Mainpage() {
  const navigate = useNavigate();

  // const [alert1, setAlert1] = useState('false');

  const [main, setMainCat] = useState([]);
  const [sub, setsubCat] = useState([]);
  const [micro, setMicroCat] = useState([]);
  const [cat, setuserCat] = useState('');

  const [mainSingle, setMainsingle] = useState('');
  const [subSingle, setSubsingle] = useState('');
  const [microSingle, setMicrosingle] = useState('');

  const [pid, setPid] = useState('');

  const [uid, setUid] = useState('');
  const [name, setName] = useState('');
  const [video, setVideo] = useState('');
  const [price, setPrice] = useState('');
  const [code, setCode] = useState('');
  const [min, setMin] = useState('');
  const [delivery, setDelivery] = useState('');
  const [desc, setDesc] = useState('');
  const [vkey, setVkey] = useState('');
  const [extra, setExtra] = useState([]);
  const [dkey, setDkey] = useState('');

  const [img, setImage] = useState();
  const [pdf, setPdf] = useState();
  const [imga, setImage_a] = useState();
  const [imgb, setImage_b] = useState();

  const [imgN, setImageN] = useState("");
  const [pdfN, setPdfN] = useState("");
  const [imgaN, setImage_aN] = useState("");
  const [imgbN, setImage_bN] = useState("");

  const [cid, setcid] = useState();

  const [cids, setcids] = useState([]);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {

      setUid(v[0].id);
      const pid = localStorage.getItem("ProductEditId");
      setPid(pid)
      getProductByid(localStorage.getItem("ProductEditId"))
      console.log("product id",pid);
      
      
    }
  }, []);

  const UploadImages = async (img,type) => {
    const data = new FormData();
    data.append('val', "update_image");
    data.append('pid', pid);
    data.append('img', img);
    data.append('type', type);

    axios({
      method: 'post',
      url: '/pro/product_manage.php',
      data: data,
      headers: {'Content-Type':'multipart/form-data'},
    })
      .then(s => {
        console.log("image change", s.data)
        setImageN("")
        setPdfN("")
        setImage_aN("")
        setImage_bN("")

        getProductByid(localStorage.getItem("ProductEditId"))
        
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };


  const getCats_Product = async (cid) => {
    const data = new FormData();
    data.append('val', "cat");
    data.append('catid', cid);

    axios({
      method: 'post',
      url: '/pro/product_manage.php',
      data: data,
      headers: {'Content-Type':'multipart/form-data'},
    })
      .then(s => {

        console.log('cat ids', s.data);
        setcids(s.data)
        const cc=s.data;

        if(cc.length==1)
        {
          getMainCat()
          setMainsingle(s.data[0].cat)
          
          

        }else if(cc.length==2)
        {
          getMainCat()
          setMainsingle(s.data[0].cat)

          getSub(s.data[1].cat,"main")
          setSubsingle(s.data[0].cat)

        }else if(cc.length==3){

          getMainCat()
          setMainsingle(s.data[2].cat)

          getSub(s.data[2].cat,"main")
          setSubsingle(s.data[1].cat)

          getSub(s.data[1].cat,"sub")
          setuserCat(s.data[0].cat)


          



        }
        
      


        
      })
      .catch(s => {
        console.log('here catch =>>', s.message);
      });
  };



  const getProductByid = async (id) => {
    const data = new FormData();
    data.append('val', "viewbyid");
    data.append('pid', id);

    axios({
      method: 'post',
      url: '/pro/product_manage.php',
      data: data,
      headers: {'Content-Type':'multipart/form-data'},
    })
      .then(s => {

        console.log('Edit product details', s.data);

        setName(s.data[0].title)
        setVideo(s.data[0].link)
        setPrice(s.data[0].price)
        setCode(s.data[0].code)
        setDelivery(s.data[0].delivery)
        setVideo(s.data[0].link)
        setDesc(s.data[0].des)
        setMin(s.data[0].qty)
        setDelivery(s.data[0].dlivry)
        setVkey(s.data[0].vkey)
        setDkey(s.data[0].dkey)

        setImage(s.data[0].img)
        setPdf(s.data[0].pdf)
        setImage_a(s.data[0].img_a)
        setImage_b(s.data[0].img_b)

        setcid(s.data[0].cat)
        getCats_Product(s.data[0].cat)
        
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const getProductByid_update = async (id) => {
    const data = new FormData();
    data.append('val', "viewbyid");
    data.append('pid', id);

    axios({
      method: 'post',
      url: '/pro/product_manage.php',
      data: data,
      headers: {'Content-Type':'multipart/form-data'},
    })
      .then(s => {

        //console.log('Main cat list ', s.data);

        setName(s.data[0].title)
        setVideo(s.data[0].link)
        setPrice(s.data[0].price)
        setCode(s.data[0].code)
        setDelivery(s.data[0].delivery)
        setVideo(s.data[0].link)
        setDesc(s.data[0].des)
        setMin(s.data[0].qty)
        setDelivery(s.data[0].dlivry)
        setVkey(s.data[0].vkey)
        setDkey(s.data[0].dkey)
        setImage(s.data[0].img)

      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const getMainCat = async () => {
    const data = new FormData();
    data.append('uid', 'ss');

    axios({
      method: 'post',
      url: '/pro/cat/maincat.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('Main cat list ', s.data);
        const a = s.data;
        setMainCat(a);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };


  const getSub = async (id, txt) => {
    console.log('id get sub is ', id);

    const data = new FormData();
    data.append('id', id);

    axios({
      method: 'post',
      url: '/pro/cat/sub.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        const a = s.data;
        if (a.length == 0) {
          setuserCat(id);
        }

        if (txt == 'main') {
          setsubCat(a);
        } else {
          setMicroCat(a);
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const addProduct = async () => {
    const data = new FormData();
    data.append('val', "update");
    data.append('pid', pid);
    data.append('name', name);
    data.append('link', video);
    data.append('price', price);
    data.append('code', code);
    data.append('des', desc);
    data.append('qty', min);
    data.append('dry', delivery);
    data.append('extra', JSON.stringify(extra));
    data.append('cat', cat);
    data.append('uid', uid);
    data.append('vkey', vkey);
    data.append('dkey', dkey);
    // data.append('pdf', pdf);
    // data.append('img', img);
    // data.append('img_a', imga);
    // data.append('img_b', imgb);

    axios({
      method: 'post',
      url: '/pro/product_manage.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        if (s.data[0].code === 'pass') {
          // setAlert1('true');
          navigate("/productlist")
          // alert('thanks');

        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-title">
                          <h2>Edit Product Details</h2>
                        </div>
                       
                        <div className="card-body">
                          <div className="basic-elements">
                          
                            <div className="row">
                              <div className="col-8 form-group">
                                <label>Product Name </label>
                                <input
                                  value={name}
                                  onChange={v => {
                                    if(v.target.value.length<80)
                                    {
                                      setName(v.target.value);
                                    }else{
                                      alert("Name Limit 80 character only")
                                    }
                                    
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-3 form-group">
                                <label>Product Code</label>
                                <input
                                  value={code}
                                  onChange={v => {
                                    if(v.target.value.length<20)
                                    {
                                      setCode(v.target.value);
                                    }else{
                                      alert("Product Code Limit 20 character only")
                                    }


                                    
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>

                           
                            </div>

                            <div className="row">
                              <div className="col-3 form-group">
                                <label>Product Price</label>
                                <input
                                  value={price}
                                  onChange={v => {
                                    if(v.target.value.length<10)
                                    {
                                      setPrice(v.target.value);
                                    }else{
                                      alert("Price Over Limit")
                                    }
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-7 form-group">
                                <label>Product Video Link</label>
                                <input
                                  value={video}
                                  onChange={v => {
                                    if(v.target.value.length<200)
                                    {
                                      setVideo(v.target.value);
                                    }else{
                                      alert("Lin Limit over")
                                    }


                                    setVideo(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                             
                            </div>
                            <div className="row">
                              <div className="col-3 form-group">
                                <label>Minimum Order Qty </label>
                                <input
                                  value={min}
                                  onChange={v => {
                                    if(v.target.value.length<20)
                                    {
                                      setMin(v.target.value);
                                    }else{
                                      alert("Minimum Oder Qty Over Limit ")
                                    }
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-3 form-group">
                                <label>Estimated Delivery Time</label>
                                <input
                                  value={delivery}
                                  onChange={v => {
                                    if(v.target.value.length<20)
                                    {
                                      setDelivery(v.target.value);
                                    }else{
                                      alert("Estimated Time over Limit")
                                    }
                                    setDelivery(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-3 form-group">
                                <label>Detail Key</label>
                                <input
                                  value={dkey}
                                  onChange={v => {

                                    if(v.target.value.length<20)
                                    {
                                      setDkey(v.target.value);
                                    }else{
                                      alert("Product Code Limit 20 character only")
                                    }
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>

                              <div className="col-3 form-group">
                                <label>Key Value</label>
                                <input
                                  value={vkey}
                                  onChange={v => {
                                    if(v.target.value.length<20)
                                    {
                                      setVkey(v.target.value);
                                    }else{
                                      alert("Product Key Limit 20 character only")
                                    }

                                    
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              
                            </div>

                            <div className="row">
                              <label>Product Description</label>
                              <textarea
                                value={desc}
                                onChange={v => {
                                  if(v.target.value.length<400)
                                  {
                                    setDesc(v.target.value);
                                  }else{
                                    alert("Product Description Limit 400 character only")
                                  }

                                }}
                                className="form-control"
                                rows={4}
                              />
                            </div>

                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Select Main Category</label>
                                <select
                                  value={mainSingle}
                                  onChange={ve => {
                                    setsubCat([]);
                                    setMicroCat([]);
                                    setuserCat('');

                                    getSub(ve.target.value, 'main');
                                    setMainsingle(ve.target.value);
                                  }}
                                  className="form-control">
                                  

                                  {main.map((v, i) => (
                                    <option value={v.id}>{v.title}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {sub.length == 0 ? (
                              <></>
                            ) : (
                              <div className="mx-2row">
                                <div className="col-6 form-group">
                                  <label>Select Sub Category</label>
                                  <select
                                  value={subSingle}
                                    onChange={v => {
                                      setMicroCat([]);
                                      getSub(v.target.value, 'sub');
                                      setuserCat('');
                                      setSubsingle(v.target.value)
                                    }}
                                    className="form-control">
                                    <option>Select Sub Category</option>

                                    {sub.map((v, i) => (
                                      <option value={v.id}>{v.title}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            )}

                            {micro.length == 0 ? (
                              <></>
                            ) : (
                              <>
                                <div className="mx-4 row">
                                  <div className="col-6 form-group">
                                    <label>Select Micro Category </label>
                                    <select
                                    value={cat}
                                      onChange={v => {
                                        setuserCat(v.target.value);
                                      }}
                                      className="form-control">
                                      <option>Select Micro Category</option>

                                      {micro.map((v, i) => (
                                        <option value={v.id}>{v.title}</option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </>
                            )}

                          
                            <div
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                marginTop:"20px"
                              }}
                              className="form-group">
                              <button
                                className=" btn btn-primary px-5 mt-8"
                                onClick={() => {
                                  if(name=="")
                                  {
                                    alert("Enter Name")
                                  }else if(price=="")
                                  {
                                    alert("Enter Price") 
                                  }else if(code=="")
                                  {
                                    alert("Enter Code")
                                  }else if(cat=="")
                                  {
                                    alert("Select Cat Must")
                                    
                                  }else if(desc==""){
                                    alert("Enter description")
                                  }else{
                                    addProduct();
                                  }
                                }}>
                                Update Product
                              </button>
                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>


             <div className='card'>
              <h2>Image Section</h2>
             <div class="row">

            <div class="col-md-3">
              <div class="card mb-4 box-shadow">
                <h3>Feature</h3>
              <center>
              <img class="card-img-top" style={{width:"300px",height:"300px"}}  src={img}  />
              </center>
                <div class="card-body">
                  <p class="card-text"> Feature Image Change<br/> <input type="file" onChange={v => {
                                  setImageN(v.target.files[0]);
                                }} /> </p>
                  {
                   imgN ==""?<></>:<div class="d-flex justify-content-between align-items-center">
                   <div class="btn-group" onClick={()=>{
                    UploadImages(imgN,"2")
                   }}>
                     <button type="button" class="btn btn-sm btn-outline-secondary">Upload Now</button>
                   
                   </div>
                   
                 </div> 
                  }
                </div>
              </div>
            </div>



            <div class="col-md-3">
              <div class="card mb-4 box-shadow">
                <h3>PDF</h3>
              <center>
              {/* <img class="card-img-top" style={{width:"300px",height:"300px"}}  src={"https://alahdeen.com/pro/extra_imges_/pdflogo.png"}  /> */}
              <img class="card-img-top" style={{width:"300px",height:"300px"}}  src={"https://alahdeen.com/pro/extra_imges_/Adobe_PDF.svg.png"}  />
              </center>
                <div class="card-body">
                  <p class="card-text"> PDF Change<br/> <input type="file" onChange={v => {
                                  setPdfN(v.target.files[0]);
                                }} /> </p>
                  {
                   pdfN ==""?<></>:<div class="d-flex justify-content-between align-items-center">
                   <div onClick={()=>{
                    UploadImages(pdfN,"1")
                   }} class="btn-group">
                     <button type="button" class="btn btn-sm btn-outline-secondary">Upload Now</button>
                   
                   </div>
                   
                 </div> 
                  }
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card mb-4 box-shadow">
                <h3>Side A</h3>
              <center>
              <img class="card-img-top" style={{width:"300px",height:"300px"}}  src={imga}  />
              </center>
                <div class="card-body">
                  <p class="card-text"> Side A Image Change<br/> <input type="file" onChange={v => {
                                  setImage_aN(v.target.files[0]);
                                }} /> </p>
              {
                   imgaN ==""?<></>:<div class="d-flex justify-content-between align-items-center">
                   <div onClick={()=>{
                    UploadImages(imgaN,"3")
                   }}  class="btn-group">
                     <button type="button" class="btn btn-sm btn-outline-secondary">Upload Now</button>
                   
                   </div>
                   
                 </div> 
                  }
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card mb-4 box-shadow">
                <h3>Side B</h3>
              <center>
              <img class="card-img-top" style={{width:"300px",height:"300px"}}  src={imgb}  />
              </center>
                <div class="card-body">
                  <p class="card-text"> Side B Image Change<br/> <input type="file" onChange={v => {
                                  setImage_bN(v.target.files[0]);
                                }} /> </p>
                  {
                   imgbN ==""?<></>:<div class="d-flex justify-content-between align-items-center">
                   <div onClick={()=>{
                    UploadImages(imgbN,"4")
                   }}  class="btn-group">
                     <button type="button" class="btn btn-sm btn-outline-secondary">Upload Now</button>
                   
                   </div>
                   
                 </div> 
                  }
                </div>
              </div>
            </div>
            
           
          

    
            
         
          </div>

</div>



              </div>
             </div>
              
                  

     </div>
    

    </body>
  );
}
export default Mainpage;
