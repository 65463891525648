import Nav from "./navbar2";
import { useState, useEffect } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import Whatsapp from "../components/landingpage/whatsapp";
import {
  BarChart,
  Line,
  XAxis,
  YAxis,
  Bar,
  Legend,
  Tooltip,
  CartesianGrid,
} from "recharts";
function Mainpage() {
  const navigate = useNavigate();
  const [allviewMsg, setAllViewMsg] = useState([]);
  const [chat, setChat] = useState([]);
  const [chatid, setChatid] = useState("");
  const [uid, setUid] = useState("");
  const [lead, setLead] = useState([]);
  const [uiduser, setuiduser] = useState("");
  const [buylead, setBuylead] = useState([]);
 
  const [status, setStatus] = useState('');
  useEffect(() => {
    // const search = window.location.search;
    // const params = new URLSearchParams(search);

    // console.log(params.get('name'))
    // console.log(params.get('fname'))
    // console.log(params.get('age'))
    // console.log(params.get('address'))

    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
   
      buyleads_buyer(v[0].id);
      Status_update();
      const uiduser = localStorage.getItem("chatuserid");
      // console.log(uiduser);
      setuiduser(uiduser);
      viewchat(v[0].id, uiduser);
      Viewchatbox(v[0].id);
    }
  }, []);
  const buyleads_buyer = async (id) => {
    const data = new FormData();
    data.append("uid", id);

    axios({
      method: "post",
      url: "/pro/lead_view_uid.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("buyleads List ", s.data);
        const a = s.data;
        setBuylead(a);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const Status_update = async (id) => {
    const data = new FormData();
    data.append("uid", id);

    axios({
      method: "post",
      url: "/pro/lead_update.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("buyleads List ", s.data);
        const a = s.data;
        setStatus(a);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const viewchat = async (id, i) => {
    const data = new FormData();
    data.append("one", id);
    data.append("two", i);

    axios({
      method: "post",
      url: "/pro/chatbox/chatbox.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("chat list ", s.data);
        const a = s.data;
        if (a[0].code == "pass") {
          // setProductList(a);

          localStorage.setItem("ProductListInventory", JSON.stringify(s.data));
        }
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const Viewchatbox = async (id) => {
    const data = new FormData();
    data.append("uid", id);

    axios({
      method: "post",
      url: "/pro/chatbox/view_inbox.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("chatbox view ", s.data);
        const a = s.data;
        setChat(a);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const viewinbox = async (chatid) => {
    const data = new FormData();
    data.append("status", "view");
    data.append("cid", chatid);

    axios({
      method: "post",
      url: "/pro/chatbox/chat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("chatbox view ", s.data);
        setAllViewMsg(s.data);
        // console.log(chatid);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const Active = async (id) => {
    const data = new FormData();
    data.append("id", id);

    axios({
      method: "post",
      url: "/pro/generalleadstatus.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log(id)
        // console.log("Active Status Check List ", s.data);
        const a = s.data;
      
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const data = [
    {
      name: "",
      uv: 4000,
      pv: 2400,
    },
    {
      name: "",
      uv: 3000,
      pv: 1398,
    },
    {
      name: "",
      uv: 2000,
      pv: 9800,
    },
    {
      name: "",
      uv: 2780,
      pv: 3908,
    },
    {
      name: " ",
      uv: 1890,
      pv: 4800,
    },
    {
      name: " ",
      uv: 2390,
      pv: 3800,
    },
    {
      name: "",
      uv: 3490,
      pv: 4300,
    },
  ];

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}
            {/* <Whatsapp /> */}
            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div>
                  <div className="card-title">
                    {/* <h3>Dashboard</h3> */}
                    <br />
                    <br />
                  </div>
                  {/* Ad Start */}
                  {/* <section id="main-content">
                    <div className="card">
                      <div className="row">
                        <img
                          height={250}
                          src="https://alahdeen.com/pro/image/b1.png"
                        />
                      </div>
                    </div>
                  </section> */}
                  {/* Ad End */}




                  {/* <section style={{ marginBottom: "80px" }} id="main-content">
                    <div className="row">
                      <BarChart
                        className="mx-5"
                        width={500}
                        height={250}
                        data={data}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                     
                        <Bar dataKey="pv" fill="#82ca9d" />
                      </BarChart>

                      <BarChart
                        className="mx-5"
                        width={500}
                        height={250}
                        data={data}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="pv" fill="#8884d8" />
                     
                      </BarChart>
                    </div>
                  </section> */}

                  <section id="main-content">
                    <div className="row">
                      <div className="col-1">

                      </div>
                      <div
                        style={{ height: "630px", overflowY: "auto" }}
                        className="card col-10" >
                        <center>
                          <h4 className="my-2">Product Buy Requests</h4>
                        </center>
                        <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Unit</th>
                            <th>Approx Price</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>No. Of Request</th>
                            <th>Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {buylead.length == 0 ? (
                            <>

                             
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={10} > No Record to show </td>
                              </tr>

                            </>
                          ) : (
                            <></>
                          )}

                          {buylead.map((v, i) => (
                             <>
                             {v.status== ""?
                              <tr>
                              <td>{i + 1}</td>
                              <td>{JSON.parse(v.sheet)[0].name}</td>
                              <td>{JSON.parse(v.sheet)[0].qty}</td>
                              <td>{JSON.parse(v.sheet)[0].piece}</td>
                              <td>{JSON.parse(v.sheet)[0].price}</td>
                              <td>{JSON.parse(v.sheet)[0].des}</td>
                              <td>
                                <p  onClick={()=>{Active(v.id)}} style={{ color: "red", cursor:"pointer" }}>
                                 {v.status==""?<u>Active</u>:<>Sold</> } 
                                </p>
                              </td>

                              {/* <td>
                              <div className="icon-area">
                                
                                <button onClick={()=>{                         
                                localStorage.setItem(
                                  'leadid',
                                  v.id,
                                );
                                navigate('/requestno')
                              }} className="btn-success">Quotes </button>
                           
                              </div>
                              </td> */}
                              <td>

                             <div style={{cursor:"pointer"}} className="widget-header mr-3">
                               <a onClick={()=>{                         
                                localStorage.setItem(
                                  'leadid',
                                  v.id,
                                );
                                navigate('/requestno')
                              }}  className="widget-view">
                               <div className="icon-area">
                                 <i style={{color: '#0077b6'}} className="fa fa-bell"></i>

                               <span  className="notify">{v.count}</span>
                              </div>
                              <small className="text">
                                 <strong>Requests</strong>{' '}
                               </small>
                              </a>
                              </div>


                              </td>




                              <td>{v.time}</td>
                            </tr> :<></>
                            }
                            </>
                          
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                      </div>

                      {/* <div className=" col-md-3 col-lg-5 col-xl-3 mb-4 mb-md-0">
                        <div className="card">
                          <center>
                            <h5 className="font-weight-bold mb-3  text-center">
                              Inbox
                            </h5>
                          </center>
                          <div className="">
                            <div style={{ height: "530px", overflowY: "auto" }}   className="card-body">
                              <ul
                             
                                className="list-unstyled mb-0"
                              >
                                <li
                                  style={{ backgroundColor: "#eee" }}
                                  className="card p-2 border-bottom"
                                >
                                  {chat.map((v, i) => (
                                    <>
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          navigate("/chat");
                                        }}
                                        className="d-flex justify-content-between"
                                      >
                                        <div className="d-flex flex-row">
                                          <img
                                            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-8.webp"
                                            alt="avatar"
                                            className="rounded-circle d-flex align-self-center me-3 "
                                            width="60"
                                          />
                                          <div className="pt-1">
                                            <p className="fw-bold mb-0">
                                              {v.name}
                                            </p>
                                            <p className="small text-muted">
                                              Hello, Are you there?
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                      <hr />
                                    </>
                                  ))}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}

                    </div>
                  </section>
                  {/* Premium Services Start */}

                  {/* Premium Services End */}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
