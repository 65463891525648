import {Link, useNavigate} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';
import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';

function Ad() {
  const navigate = useNavigate();
  const[uid, setUid]= useState('');
  const[bnkname, setBnkname]= useState('');
  const[title, setTitle]= useState('');
  const[acc, setAcc]= useState('');
  const[name, setName]= useState('');
  const[email, setEmail]= useState('');
  const[contact, setContact]= useState('');
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
    }
  }, []);

  const addProduct = async () => {
    const data = new FormData();
    data.append('status', 'active');
    data.append('val', 'in');
    data.append('uid', uid);
    

    axios({
      method: 'post',
      url: '/pro',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        if (s.data[0].code === 'pass') {
          //productslist
          navigate('/productpricing');
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <div>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Add Benificiary</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6 form-group">
                        <label>Bank Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Bank Name"
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Account Title</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Account Title"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 form-group">
                        <label>Account Number</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Account Number"
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Nick Name"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email "
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Contact Number</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Nick Name"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: '15px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}>
                      <button className="btn-primary px-3">Save</button>
                      <button className="btn-primary px-3 mx-3 ">Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Ad;
