import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Nav from '../../../navbar';

function Mainpage() {
  const [uid, setUid] = useState('');
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      viewProduct(v[0].id);
    }
  }, []);
  const viewProduct = async id => {
    const data = new FormData();
    data.append('uid', id);

    axios({
      method: 'post',
      url: '/pro/view_product_user.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('product list ', s.data);
        const a = s.data;
        setProductList(a);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Matter Sheet List</h4>
                  </div>
                  <div className="row">
                    {/* <div className="col-lg-9 col-sm-1"></div> */}
                    <div className="text-right">
                      <input
                        style={{borderRadius: '18px', paddingLeft: '8px'}}
                        placeholder="search"
                      />
                      <span>
                        <button
                          style={{borderRadius: '18px', color: 'white'}}
                          className="bg-primary mx-3 px-4 ">
                          Search
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Featured Image</th>
                            <th>Title</th>
                            <th>code</th>
                            <th>Price</th>

                            <th>Category</th>
                            <th>Status</th>
                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productList.length === 0 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                  colSpan={10}>
                                  No Record to show{' '}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {productList.map((v, i) => (
                                <tr>
                                  <td>{v.counter}</td>
                                  <td style={{width: '20%'}}>
                                    <img
                                      style={{width: '20%', height: '25%'}}
                                      src={v.img}
                                    />
                                  </td>
                                  <td>{v.title}</td>
                                  <td>{v.code}</td>
                                  <td>{v.price}</td>
                                  <td>{v.cat}</td>
                                  <td>{v.status}</td>
                                  <td>Edit | Delete</td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
