import react from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import Nav from "./navbar";
import Footer from "./footer";
import Whatsapp from "./whatsapp";
import Load from "./load"
import Card from 'react-bootstrap/Card';

function Subcat() {
  const [allcats, setAllcats] = useState([]);
  const [load, setLoad] = useState([]);
  const [title, setTitle] = useState();

  const [keyword, setkeyword] = useState();



  const [listcities, setCitiesList] = useState([]);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const getCities = async () => {
    const data = new FormData();
  
        axios({
          method: "post",
          url: "/pro/auth/allcity_search.php",
          data: data,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((s) => {
            setCitiesList(s.data)
          })
          .catch((s) => {
           
          });
  };


  useEffect(() => {
    getServercats()
    getCities()
  }, []);

  const items = [
    {name: 'Punjab' },
    {name: 'Khyber Pakhtunkhwa'},
    {name: 'Sindh'},
    {name: 'Balochistān' },
    {name: 'Gilgit-Baltistan'}
  ]

  const getServercats = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id");
    const city = params.get("c");
    const product = params.get("product");
    const spplier = params.get("supplier");

    const cityy = params.get("city");
    const pro = params.get("pro");
    

      if(product != null && cityy ==null && pro == null )
     {

      setkeyword(product)
      setTitle(product)

       const data = new FormData();
       data.append("key", product);
       data.append("val", "product");
      
   
       axios({
         method: "post",
         url: "/pro/manage_search.php",
         data: data,
         headers: { "Content-Type": "multipart/form-data" },
       })
         .then((s) => {
           setAllcats(s.data);
           console.log(s.data,"/pro/manage_search.php");
         })
         .catch((s) => {
            console.log("here catch =>>", s.message);
         });
 
     }else if(spplier !=null )
     {
      setTitle(spplier)
      setkeyword(spplier)
       const data = new FormData();
       data.append("key", spplier);
       data.append("val", "company");
      
   
       axios({
         method: "post",
         url: "/pro/manage_search.php",
         data: data,
         headers: { "Content-Type": "multipart/form-data" },
       })
         .then((s) => {
           setAllcats(s.data);
          //  console.log(s.data,"/pro/manage_search.php");
         })
         .catch((s) => {
          //  console.log("here catch =>>", s);
         });
 
     }else if(product != null && cityy != null )
     {
      setTitle(product+" "+ cityy)
      setkeyword(product)
       const data = new FormData();
       data.append("key", cityy);
       data.append("key1",product);
       data.append("val", "advance");
      
   
       axios({
         method: "post",
         url: "/pro/manage_search.php",
         data: data,
         headers: { "Content-Type": "multipart/form-data" },
       })
         .then((s) => {
           setAllcats(s.data);
          //  console.log(s.data,"/pro/manage_search.php");
         })
         .catch((s) => {
          //  console.log("here catch =>>", s);
         });
 
     
     }else if(product != null && pro != null )
     {
      setTitle(product+" "+ pro)
      setkeyword(product)
       const data = new FormData();
       data.append("key", pro);
       data.append("key1",product);
       data.append("val", "pro_advance");
      
   
       axios({
         method: "post",
         url: "/pro/manage_search.php",
         data: data,
         headers: { "Content-Type": "multipart/form-data" },
       })
         .then((s) => {
           setAllcats(s.data);
          //  console.log(s.data,"/pro/manage_search.php");
         })
         .catch((s) => {
          //  console.log("here catch =>>", s);
         });
 
     }
     

  };



  const getServercatsUpdate = async (id) => {
    const data = new FormData();

    data.append("id", id);

    axios({
      method: "post",

      url: "/mainsite/suball.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllcats(s.data);
        // console.log(s.data);
      })
      .catch((s) => {
        // console.log("here catch", s);
      });
  };

  return (
    <div style={{backgroundColor:"#F4F5F8"}}>
      <Nav />
   
      <div style={{marginTop:"20px"}} className="container-fluid">
        <div className="row">
          <div className="col-1"> 

          </div>
          <div className="col-8" >
          <div className="container">
          <section className="padding-bottom-sm">
            <header className="section-heading heading-line">
              <h4 className="title-section text-uppercase">
                {title}
              </h4>
            </header>
            <div 
             
             className="row row-sm">
              {allcats.length==0?<>Not Found</>:<></>}
              {allcats.length > 0
                ? allcats.map((v, i) => (
                    <> 
                      {
                        <>
                          <div className="  col-xl-2 col-lg-3 col-md-4 col-6">
                          <div style={{height:"250px"}} className="card" >
                          <a
                                href={"product?id="+ v.pid +"&s=" + v.title}
                                className="img-wrap"
                              >
                            <div  className="card-h-100 card-sm card-product-grid">
                                <img height="80px" width="80px"  src={v.img} />
                                <div className=" info-wrap" style={{
                                whiteSpace: "nowrap",
                                width: "150px", 
                                overflow: "hidden",
                                textOverflow:"ellipsis"}} >{v.title}  </div>
                              <div style={{paddingBottom:"10px"}}  className="text-center info-wrap">
                                    <Button
                                      variant="primary"
                                      className="my-1" >
                                      Get Quote
                                    </Button>
                                  </div>
                            </div>
                            </a>
                            </div>
                          </div>
                        </>
                      }
                    </>
                  ))
                :  ""}
            </div>       
          </section>
        </div>

          </div>
          <div className="col-3" >
        
    
           
         <div  className="my-5">
        <Card  style={{ width: '100%' }}>
        <Card.Header className="text-center"><h4>Provinces</h4></Card.Header>
        <Card.Body>
        <div className="row">
     {
              items.map((v,i)=>(
             <div className="col-12" >
               {
                i<400?<a href={"search?product=" +keyword +"&pro="+v.name}>{v.name}
                
             </a>:""
               }
              
                </div>
               
              ))
            }
     </div>
                     
                   
        </Card.Body>
      </Card>
        </div>
         <div style={{marginTop:"0px"}}>
        <Card  style={{ width: '100%' }}>
        <Card.Header className="text-center"><h4>City</h4></Card.Header>
        <Card.Body>
        <div  style={{ height: "500px", overflowY: "auto", width:"100%" }}>
     <div className="row">
     {
              listcities.map((v,i)=>(
             <div className="col-6" >
               {
                i<50?<a href={"search?product=" +keyword +"&city="+v.name}>  {v.name}</a>:""
               }
               
                </div>
               
              ))
            }
     </div>
                     
                  </div> 
        </Card.Body>
      </Card>
       
      <br />
        </div>
     
    

             
            
          </div>

        </div>
       
      </div>
      <Footer />
    </div>
  );
}
export default Subcat;
<a
  rel="nofollow"
  
  ads-data="t:117,clc:4,clt:1,hrty:1,aid:NGTfRQJMWaIt,st:8,a:1"
  target="_blank"
  href="https://activity.made-in-china.com/show/PQJaCmUDzEcx/AQETbJuxenkm"
  className="banner-wrap swiper-slide J-probe-banner-item"
  title="Live Show"
  alt="Live Show"
  pic1024="https://image.made-in-china.com/152f0j10NGRfuQaMoYVU/banner1024X300.jpg"
  pic1440="https://image.made-in-china.com/151f0j10NGRfuQaMoYVU/banner990X400.jpg"
  cz-position="top"
  cz-num="1"
  sensors-data="bId:1111021804,bName:Live Show"
  style='background-image: url("https://image.made-in-china.com/151f0j10NGRfuQaMoYVU/banner990X400.jpg"); width: 848px; height: 400px;'
  faw-id="1fubrqvtra81"
></a>;
