import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../../../navbar2";
function Buyleads() {
    const navigate = useNavigate();
  const [buylead, setBuylead] = useState([]);
  const [uid, setUid] = useState();
  const [two, setTwo] = useState();
  const [pid, setPid] = useState();
 

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      const two = localStorage.getItem("leadid")
      setTwo(two);
      Viewquote(two);
      
     
    }
  }, []);


  const Viewquote = async (two) => {
    const data = new FormData();
    data.append("id", two);

    axios({
      method: "post",
      url: "/pro/sendleadsbyqid.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("buyleads List ", s.data);
        setBuylead(s.data)
       
        const b = s.data[0].uid
        setPid(b)
        
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  


 

  const chatinbox = async (chatid,msg) => {
    //setMsg("");
    const data = new FormData();
    data.append("uid", uid);
    data.append("status", "in");
    data.append("cid", chatid);
    data.append("msg", msg);
   

    axios({
      method: "post",
      url: "/pro/chatbox/chat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("chatbox Message ", s.data);

        if (chatid !== "") {
          localStorage.setItem("piduser", chatid);
          navigate("/chat");
        }
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };



  const Getchatid = async (one,two,msg) => {
    const data = new FormData();
    data.append("one", one);
    data.append("two", two );

    axios({
      method: "post",
      url: "/pro/chatbox/chatbox.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
       

              
         if(s.data[0].code=="pass")
         {
          chatinbox(s.data[0].chatid, msg)
          localStorage.setItem('piduser',s.data[0].chatid);
          navigate('/chat')

         }else{

         }                           
       
      
      
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

 
  
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Quote List  </h4>
                  </div>
                  {/* <div className="row">
                    <div className="text-right">
                      <input
                        style={{ borderRadius: "18px", paddingLeft: "8px" }}
                        placeholder="search"
                      />
                      <span>
                        <button
                          style={{ borderRadius: "18px", color: "white",backgroundColor:"#00b4d8" }}
                          className=" mx-3 px-4 "
                        >
                          Search
                        </button>
                      </span>
                    </div>
                  </div> */}
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>

                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Price</th>
                            <th>Discount %</th>
                            <th>Qty</th>
                            <th>Description</th>
                            <th>Delivery Time </th>
                         
                            <th>Applicable Tax %</th>
                            <th>Shipping Tax %</th>
                            <th>Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {buylead.length == 0 ? (
                            <>
                              {" "}
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={10}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}

                          {buylead.map((v, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td> <img
                                      style={{width: "20%", height: "100px", width:"120px"}}
                                      src={v.img}
                                      
                                    /></td>
                              <td>{v.name}</td>
                              <td>{v.payment}</td>
                              <td>{v.discount}</td>
                              <td>{v.qty}</td>
                              <td>{v.des}</td>
                              <td>{v.delivery}</td>
                              <td>{v.app_tax}</td>
                              <td>{v.ship_tax}</td>
                           
                              <td><button onClick={()=>{
                           const msg= "Hi, I want to Negotiate with you on your that Send Quote Details:  "  +  "Product Name:  " + v.name + ", Product Price:  " + v.payment + ", Quantity:  " + v.qty + ", Description:  " + v.des 
                                    Getchatid(v.uid,uid,msg )
                                

                              }} style={{borderRadius:"13px",backgroundColor:"#00b4d8", color:"white"}} className=" px-2">Contact Now</button></td>



                              {/* <td>{JSON.parse(v.sheet)[0].name}</td>
                              <td>{JSON.parse(v.sheet)[0].qty}</td>
                              <td>{JSON.parse(v.sheet)[0].piece}</td>
                              <td>{JSON.parse(v.sheet)[0].price}</td>
                              <td>{JSON.parse(v.sheet)[0].des}</td> */}
                            
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
