import Nav from '../../../navbar';

function Mainpage() {
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3> Advertise With Us</h3>
                  </div>

                  <div className="card-body">
                    <div className="card-title my-4">
                      <h6>Homepage Banner</h6>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Placement</th>
                            <th>Dimensions</th>
                            <th>Size</th>
                            <th>Duration</th>
                            <th>Starting Date</th>
                            <th>Banner Location</th>
                            <th>Charges Per Day</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                              }}
                              colSpan={10}>
                              No Record to show{' '}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* 2nd */}
                  <div className="card-body">
                    <div className="card-title my-4">
                      <h6>Category Page Banner</h6>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Placement</th>
                            <th>Dimensions</th>
                            <th>Size</th>
                            <th>Duration</th>
                            <th>Starting Date</th>
                            <th>Banner Location</th>
                            <th>Charges Per Day</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                              }}
                              colSpan={10}>
                              No Record to show{' '}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* 3rd */}
                  <div className="card-body">
                    <div className="card-title my-4">
                      <h6>Main Page Banners</h6>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Placement</th>
                            <th>Dimensions</th>
                            <th>Size</th>
                            <th>Duration</th>
                            <th>Starting Date</th>
                            <th>Banner Location</th>
                            <th>Charges Per Day</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                              }}
                              colSpan={10}>
                              No Record to show{' '}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
