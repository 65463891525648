import {Link, useNavigate} from 'react-router-dom';
import Nav from '../../../navbar2';
import {useState, useEffect} from 'react';
import axios from 'axios';

function Mainpage() {
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
      navigate('/login');
    } else {
      seWallet(v[0].id);
      getBlance(v[0].id);
    }
  }, []);

  const [debit, setDebit] = useState('');
  const [datedebit, setDateDebit] = useState('');
  const [credit, setCredit] = useState('');
  const [creditdate, setCreditdate] = useState('');
  const [blance, setBlance] = useState('');
  const [date, setDate] = useState('');
  const [wallet, seWallet] = useState('');

  const navigate = useNavigate();
  const getBlance = async id => {
    const data = new FormData();

    // localStorage.setItem("LOCAL_KEY", JSON.stringify(json));
    data.append('id', id);

    axios({
      method: 'post',
      url: '/pro/wallet_last.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);
        if (s.data[0].code === 'pass') {
          setBlance(s.data[0].blance);
          setDate(s.data[0].date);

          setDebit(s.data[0].debit);
          setDateDebit(s.data[0].datedebit);

          setCredit(s.data[0].credit);
          setCreditdate(s.data[0].creditdate);
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="card">
                <div className="card-title mx-3 my-4">
                  <h2 className="my-3">E-Wallet </h2>
                  <span>Wallet ID:{wallet}</span>
                </div>

                <div id="main-content">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="card">
                        <div className="stat-widget-one">
                          <div className="stat-icon dib">
                            <i className="ti-wallet color-success border-success"></i>
                          </div>
                          <div className="stat-content dib">
                            <div className="card-title">Available Balance</div>

                            <div className="stat-digit">{blance}</div>
                            <p>{date}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="card">
                        <div className="stat-widget-one">
                          <div className="stat-icon dib">
                            <i className="ti-layout-grid2 color-pink border-pink"></i>
                          </div>
                          <div className="stat-content dib">
                            <div className="card-title">Last Debit</div>
                            <div className="stat-digit">{debit}</div>
                            <p>{datedebit}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="card">
                        <div className="stat-widget-one">
                          <div className="stat-icon dib">
                            <i className="ti-link color-danger border-danger"></i>
                          </div>
                          <div className="stat-content dib">
                            <div className="card-title">Last Credit</div>
                            <div className="stat-digit">{credit}</div>
                            <p>{creditdate}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="main-content">
              <div className="card">
                <h4 className="my-4"> Features of E-Wallet</h4>
                <div className="row">
                  <div className="col-lg-3">
                    <Link to="/wallethistory">
                      <div className="card bg-primary">
                        <div className="stat-widget-six">
                          <div className="stat-icon">
                            <i className="ti-stats-up"></i>
                          </div>
                          <div className="stat-content">
                            <div className="text-left dib">
                              <div
                                style={{marginTop: '10px'}}
                                className="stat-heading">
                                Transaction History
                              </div>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-3">
                    <Link to="/d">
                      <div className="card bg-success">
                        <div className="stat-widget-six">
                          <div className="stat-icon">
                            <i className="ti-bolt-alt"></i>
                          </div>
                          <div className="stat-content">
                            <div className="text-left dib">
                              <div
                                style={{marginTop: '10px'}}
                                className="stat-heading">
                                Add Money
                              </div>
                              <br />
                              {/* <div className="stat-text">1</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-3">
                    <Link to="/withdraw">
                      <div className="card bg-danger">
                        <div className="stat-widget-six">
                          <div className="stat-icon">
                            <i className="ti-stats-up"></i>
                          </div>
                          <div className="stat-content">
                            <div className="text-left dib">
                              <div
                                style={{marginTop: '10px'}}
                                className="stat-heading">
                                Send Money
                              </div>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-3">
                    <Link to="/b">
                      <div className="card" style={{backgroundColor: '#693599'}}>
                        <div className="stat-widget-six">
                          <div className="stat-icon">
                            <i className="ti-bolt-alt text-white"></i>
                          </div>
                          <div className="stat-content">
                            <div className="text-left dib">
                              <div
                                style={{marginTop: '10px'}}
                                className="stat-heading text-white">
                                Benificiary
                              </div>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            <section id="main-content">
              <div className="card">
                <div className="row">
                  <img
                    height={250}
                    src="https://alahdeen.com/pro/image/banner.png"
                  />
                </div>
              </div>
            </section>
            <div className="row">
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-title pr"></div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-title pr">
                    <h4>All Exam Result</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table student-data-table m-t-20">
                        <thead>
                          <tr>
                            <th>
                              <label>
                                <input type="checkbox" />
                              </label>
                              Exam Name
                            </th>
                            <th>Subject</th>
                            <th>Grade Point</th>
                            <th>Percent Form</th>
                            <th>Percent Upto</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Class Test</td>
                            <td>Mathmatics</td>
                            <td>4.00</td>
                            <td>95.00</td>
                            <td>100</td>
                            <td>20/04/2017</td>
                          </tr>
                          <tr>
                            <td>Class Test</td>
                            <td>Mathmatics</td>
                            <td>4.00</td>
                            <td>95.00</td>
                            <td>100</td>
                            <td>20/04/2017</td>
                          </tr>
                          <tr>
                            <td>Class Test</td>
                            <td>English</td>
                            <td>4.00</td>
                            <td>95.00</td>
                            <td>100</td>
                            <td>20/04/2017</td>
                          </tr>
                          <tr>
                            <td>Class Test</td>
                            <td>Bangla</td>
                            <td>4.00</td>
                            <td>95.00</td>
                            <td>100</td>
                            <td>20/04/2017</td>
                          </tr>
                          <tr>
                            <td>Class Test</td>
                            <td>Mathmatics</td>
                            <td>4.00</td>
                            <td>95.00</td>
                            <td>100</td>
                            <td>20/04/2017</td>
                          </tr>
                          <tr>
                            <td>Class Test</td>
                            <td>English</td>
                            <td>4.00</td>
                            <td>95.00</td>
                            <td>100</td>
                            <td>20/04/2017</td>
                          </tr>
                          <tr>
                            <td>Class Test</td>
                            <td>Mathmatics</td>
                            <td>4.00</td>
                            <td>95.00</td>
                            <td>100</td>
                            <td>20/04/2017</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {/* <!-- /# column --> */}
              </div>
            </div>
            {/* Table with slider start */}
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
