import Nav from "../../../navbar2";
import { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
function Mainpage() {
  const [uid, setUid] = useState();
  const [request, setRequest] = useState([]);
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      Booking(v[0].id);
    }
  }, []);

  const Booking = async (myid) => {
    const data = new FormData();

    data.append("val", "byid");
    data.append("uid", myid);

    // data.append('array', JSON.stringify(client));
    axios({
      method: "post",
      url: "/mainsite/warehouse_requests.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log(s.data);
        setRequest(s.data);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Warehouse Booking Requests</h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                      <input
                        style={{ borderRadius: "18px", paddingLeft: "8px" }}
                        placeholder="search"
                      />
                      <span>
                        <button
                          style={{ borderRadius: "18px", color: "white" }}
                          className="bg-primary mx-3 px-4 "
                        >
                          Search
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Warehouse Owner</th>
                            <th>Request ID</th>
                            <th>Warehouse Type</th>

                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Amount</th>

                            <th>Created At </th>
                            <th>Status </th>
                            <th>Actions </th>
                          </tr>
                        </thead>
                        <tbody>
                          {request.length == 0 ? (
                            <>
                              {" "}
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={10}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}

                          {request.map((v, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{v.owner}</td>
                              <td>{v.id}</td>
                              {/* <td>{v.wid}</td> */}
                              <td>Cold Storage</td>
                              <td>{v.strtdate}</td>
                              <td>{v.endate}</td>
                              <td>{v.bookingam}</td>
                              <td>{v.time}</td>
                              <td>{v.status}</td>
                              <td>
                                <p style={{ color: "red", cursor: 'pointer', fontSize: 12}}>Withdraw Request</p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
