import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "react-bootstrap";
import Nav from "./navbar";
import Services from "./ourservices";
import Footer from "./footer";
import axios from "axios";
import Whatsapp from "./whatsapp";
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import {Helmet} from "react-helmet";

function Homepage() {



  const navigate = useNavigate();
  const [allcats, setAllcats] = useState([]);
  const [user, setUser] = useState([]);
  const [alert1, setAlert1] = useState();
  const [uid, setUid] = useState();
  const [lead, setLead] = useState([]);
  const [promotion, setPromotion] = useState([]);
  const [searched, setSearched] = useState([]);
  const [trending, setTrending] = useState([]);
  const [trade, setTrade] = useState([]);
  const [city, setCity] = useState("Karachi");
  const [listcities, setCitiesList] = useState([]);

  const [main, setMainCat] = useState([]);
  const [error, seterror] = useState(false);
  const [cat, setCat] = useState("");
  
  
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };
 
  const getCities = async () => {
    const data = new FormData();
  
        axios({
          method: "post",
          url: "/pro/auth/cities_list.php",
          data: data,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((s) => {
            setCitiesList(s.data)
          })
          .catch((s) => {
            
          });
      
    
  };


  useEffect(() => {
    getCities();
    getServercats()
    lead_view_all()
    Viewpromotion()
    ViewSearched()
    ViewTrending()
    Tradeshow()
    getMainCat()

    const aa = localStorage.getItem("USER");
    if (aa != null) {
      const as = JSON.parse(aa);
      setUid(as[0].id);
       setUser(as);
    }
  }, []);

  const getMainCat = async () => {
    const data = new FormData();
    data.append('uid', 'ss');

    axios({
      method: 'post',
      url: '/pro/cat/maincat.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('Main cat list ', s.data);
        const a = s.data;
        setMainCat(a);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };


  const [sheet, setSheet] = useState(
    [ {
      id: 1,
      name:"",
      qty:"",
      quantity:"",
      piece:"Pieces",
      price:"",
      des: "",
      status: "normal"
    }
  ]);

  const getServercats = async () => {
    const data = new FormData();

    data.append("uid", "");
    

    axios({
      method: "post",
      url: "/mainsite/maineight.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllcats(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const Viewpromotion = async () => {
    const data = new FormData();

  
 

    axios({
      method: "post",
      url: "/pro/promotional_products.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        
        setPromotion(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const ViewSearched = async () => {
    const data = new FormData();
    axios({
      method: "post",
      url: "/pro/mostsearch.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log('searched', s.data);
        setSearched(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };



  
  const ViewTrending = async () => {
    const data = new FormData();

    //data.append('array', JSON.stringify(profile));

    axios({
      method: "post",
      url: "/pro/trending.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log('trending', s.data);
        setTrending(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const Tradeshow = async () => {
    const data = new FormData();

    data.append("val", "last");
  

    axios({
      method: "post",
      url: "/pro/tradeshow/manage_trade.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log('tradeshow', s.data);
        setTrade(s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };


  const add_lead = async (sheet,uid,c) => {

      const data = new FormData();

      data.append("uid",uid);
      data.append("sheet",JSON.stringify(sheet));
      data.append("cat",c);
      data.append("img", "");

      axios({
        method: "post",
        url: "/pro/lead_add.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((s) => {
          
          setAlert1(!alert1);
          navigate('/product-buy-request')
        })
        .catch((s) => {
          // console.log("here catch =>>", s);
        });
   
  };
  const lead_view_all = async id => {
    const data = new FormData();
    data.append('uid', id);

    axios({
      method: 'post',
      url: '/pro/lead_view_all.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        // console.log('lead list ', s.data);
        const a = s.data;
        setLead(a);
      })
      .catch(s => {
        // console.log('here lead_view_all ', s);
      });
  };
  const changeArayquote = (i, changes) => {
    const oldArr = [...sheet];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    setSheet(oldArr);
  };


  return (
    <div style={{ backgroundColor: "#F4F5F8" }}>
      {/* <a  href="#as">First</a> */}
      <Nav />
      <Helmet>
            <title>Alahdeen | Best B2B Marketplace</title>
            <meta name="description" content="Alahdeen connects buyers and sellers, providing a complete package from online selling, managing inventory, and warehousing to logistics, and Products." />
            <meta name="keywords" content="Best b2b marketplace Pakistan, , keyword3" />
            <meta property="og:title" content="Alahdeen | B2B Marketplace" />
            <meta property="og:description" content="Alahdeen connects buyers and sellers, providing a complete package from online selling, managing inventory, and warehousing to logistics, and Products" />
            <meta property="og:image" content="https://alahdeen.com/assets/images/logos/logo.png" />
            <meta property="og:url" content="https://www.alahdeen.com/" />
            <meta property="og:type" content="business.business"/>
            <meta property="og:site_name" content="Alahdeen"/>
       
        </Helmet>

      <div
        style={{
          //  backgroundImage: "url('https://alahdeen.com/pro/image/bgaladhin2.png')",
           backgroundImage: "url('https://alahdeen.com/pro/image/bg111.png')",
    backgroundColor:"#D5DFF7", 
          // backgroundImage: "linear-gradient(#5436BA, #F4F5F8)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "stretch",
          width:"auto",
         
          height:"auto",
         
        }}
      >
      
        <center>
        
          <div style={{ color: "transparent"}}> 

           {/* <img
           
            className="col-12 col-md-12 col-sm-12"
           
            src="https://alahdeen.com/pro/images/img.png"
          />  */}
          </div>
        </center>

        <section
          style={{ paddingTop: "50px", paddingBottom:"80px" }}
          className="container  section-main padding-y"
        >
          <main
           style={{backgroundColor:"#ffffff99"}}
           className="row card">
            <div className="card-body">
              <div className="row">
              <aside className="col-3 flex-lg-grow-0">
                  <nav className=" nav-home-aside">
                    {/* <h4 className="title-category">
                      Categories{" "}
                      <i className="d-md-none icon fa fa-chevron-down"></i>
                    </h4> */}
                    <ul className="menu-category">
                      <li  style={{ cursor: "pointer" }}>
                        <a
                       
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Drugs & Pharmaceuticals"
                            );
                            window.location.assign(
                              "/sub?id=1&s=Drugs%20&%20Pharmaceuticals"
                            );
                          }}
                        >
                          Drugs & Pharmaceuticals
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Hospital & Diagnostics"
                            );
                            window.location.assign(
                              "/sub?id=2&s=Hospital%20&%20Diagnostics"
                            );
                          }}
                        >
                          Hospital & Diagnostics
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem("title", " Food & Beverages");
                            window.location.assign(
                              "/sub?id=3&s=Food%20&%20Beverages"
                            );
                          }}
                        >
                          Food & Beverages
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Industrial Plants & Machinery"
                            );
                            window.location.assign(
                              "/sub?id=4&s=Industrial%20Plants%20&%20Machinery"
                            );
                          }}
                        >
                          Industrial Plants & Machinery
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Industrial Supplies"
                            );
                            window.location.assign(
                              "/sub?id=5&s=Industrial%20Supplies"
                            );
                          }}
                        >
                          Industrial Supplies
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Building Construction"
                            );
                            window.location.assign(
                              "/sub?id=6&s=Building%20&%20Construction"
                            );
                          }}
                        >
                          Building Construction
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Apparel & Garments"
                            );
                            window.location.assign(
                              "/sub?id=7&s=Apparel%20&%20Garments"
                            );
                          }}
                        >
                          Apparel & Garments
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              " Electronics & Electrical"
                            );
                            window.location.assign(
                              "/sub?id=8&s=Electronics%20&%20Electrical"
                            );
                          }}
                        >
                          Electronics & Electrical
                        </a>
                      </li>
                      <h5
                        style={{ marginTop: "10px" }}
                        className="title-category"
                      >
                        <Link to="/main"> View All Categories</Link>

                        <i className="d-md-none icon fa fa-chevron-down"></i>
                      </h5>
                      {/* <li className="has-submenu">
                        <a href="#">More items</a>
                        <ul className="submenu">
                          <li>
                            <a href="#">Submenu name</a>
                          </li>
                          <li>
                            <a href="#">Great submenu</a>
                          </li>
                          <li>
                            <a href="#">Another menu</a>
                          </li>
                          <li>
                            <a href="#">Some others</a>
                          </li>
                        </ul>
                      </li> */}
                    </ul>
                  </nav>
                </aside>
                
                <div className="col-lg-6">
                  <div
                    id="carousel1_indicator"
                    className="slider-home-banner carousel slide"
                    data-ride="carousel"
                  >
                    <ol className="carousel-indicators">
                      <li
                        data-target="#carousel1_indicator"
                        data-slide-to="0"
                        className="active"
                      ></li>
                      <li
                        data-target="#carousel1_indicator"
                        data-slide-to="1"
                      ></li>
                      <li
                        data-target="#carousel1_indicator"
                        data-slide-to="2"
                      ></li>
                      <li
                        data-target="#carousel1_indicator"
                        data-slide-to="3"
                      ></li>
                    </ol>
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src="assets/images/banner/1.png"
                          alt="First slide"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="assets/images/banner/2.png"
                          alt="Second slide"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="assets/images/banner/3.png"
                          alt="Third slide"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="assets/images/banner/4.png"
                          alt="Four slide"
                        />
                      </div>
                    </div>
                    <a
                      className="carousel-control-prev"
                      href="#carousel1_indicator"
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href="#carousel1_indicator"
                      role="button"
                      data-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>
                <div className="col-3  d-lg-block flex-grow-1">
                  <aside className="special-home-right">
                    <div className=" card-body">
                      <h5 className="pb-2  text-center">
                        Get Quote
                      </h5>

                      <div className="form-group">
                        <input
                          style={{ borderColor: error==false?"transparent":sheet[0].name==""?"red":"transparent" }}
                          className="form-control"
                          placeholder="Name of Product"
                          type="text"
                          value={sheet[0].name}
                          
                          onChange={(e) => {
                            changeArayquote(0, { name: e.target.value });
                          }}
                        />
                      </div>
                      <div  className="form-group">
                        <div  className="input-group">
                          <input
                         style={{ borderColor: error==false?"transparent":sheet[0].qty==""?"red":"transparent" }}
                            className="form-control"
                            placeholder="Quantity"
                            type="number"
                            value={sheet[0].qty}
                            onChange={(e) => {
                              changeArayquote(0, { qty: e.target.value,quantity: e.target.value});
                            }}
                          />
<div className="form-control">
{/* <i className="ti ti-plus"></i> */}
                          <select
                          // style={{borderColor:"transparent", fontSize:"16px"}}
                          style={{ borderColor: error==false?"transparent":sheet[0].piece==""?"red":"transparent" }}
                            value={sheet[0].piece}
                            onChange={(e) => {
                              changeArayquote(0, { piece: e.target.value });
                            }}
                            className=" mx-1 "
                          >
                            {/* <option>Unit</option> */}

                            <option >Units</option>
                            <option>Pieces</option>
                            <option>Grams</option>
                            <option>Kilograms</option>
                            <option>Tons</option>
                            <option>Litres</option>
                          </select>
                          </div>
                        </div>
                      </div>
                      
                      <div className="row" >
                              <div className="col-12 form-group">
                                
                                <select
                                style={{ borderColor: error==false?"transparent":cat==""?"red":"transparent" }}
                                  value={cat}
                                  onChange={ve => {
                                    setCat(ve.target.value)
                                  }}
                                  className="form-control">
                                  <option>Select Main Category</option>

                                  {main.map((v, i) => (
                                    <option value={v.id}>{v.title}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                      <div className="row">
                        <div className="col-12 form-group">
                          <input
                            className="form-control"
                            placeholder="Approx Price Rs"
                            type="number"
                            value={sheet[0].price}
                            
                          style={{ borderColor: error==false?"transparent":sheet[0].price==""?"red":"transparent" }}
                            onChange={(e) => {
                              changeArayquote(0, { price: e.target.value });
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <textarea

                          className="form-control"
                          placeholder="Requirement Description"
                          maxLength={100}
                          
                          onChange={(e) => {
                            changeArayquote(0, { des: e.target.value });
                          }}
                        >{sheet[0].des}</textarea>
                      </div>
                      <div
                        onClick={() => {
                          if(sheet[0].name==='')
                          {
                           
                            seterror(true)
                          }else if(sheet[0].qty==='')
                          {
                            seterror(true)
                            
                          }else if(sheet[0].piece==='')
                          {
                            seterror(true)
                          }else if(sheet[0].price==='')
                          {
                            seterror(true)
                          }else{
                            add_lead(sheet,uid,cat)
                          }

                          
                          
                        }}
                        className="form-group text-center"
                      >
                        {/* <button className="btn btn-primary">Get quote</button> */}

                        <button
                          className="btn btn-primary"
                          // onClick={() => {
                            // setAlert(!alert);
                          // }}
                        >
                          Get Quote
                        </button>
                        {alert1 ? (
                          <>
                            {" "}
                            <SweetAlert
                              success
                              confirmBtnText="Thanks !"
                              confirmBtnBsStyle="primary"
                              timeout={2000}
                              title="Your Requirement Successfully Sent to the Seller"
                              onConfirm={() => {
                                setAlert1(!alert1);
                              }}
                              onCancel={() => {
                                setAlert1(!alert1);
                              }}
                              focusCancelBtn
                            >
                              <br />
                            </SweetAlert>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </main>
        </section>

      
        </div>

{/* <div style={{flexDirection:"row" , justifyContent:"space-between", marginBottom:"40px"}} className="card row">
<div className="col-4">Active Supplier</div>
<div className="col-4">Our Categories</div>
<div className="col-4">No of Request / Day</div>
</div> */}


 
        
    {/* Latest Product Start   */}
      {/* <div className="mx-3">
      <section class="padding-bottom-sm">
            <header class="section-heading heading-line">
              <h4 class="title-section text-uppercase">Latest Products</h4>
            </header>

            <div class="row row-sm">
              <div class="col-xl-2 col-lg-3 col-md-4 col-6">
                <div class="card card-product-grid">
                  <a href="#" class="text-center">
                    {' '}
                    <img src="assets/images/items/y.jpg" />{' '}
                  </a>
                  <figcaption class="info-wrap text-center">
                    <a href="#" class="">
                      Just another product name
                    </a>
                    <br/>
                    <button className="btn-primary my-2">Get Quote</button>
                
                  </figcaption>
                </div>
              </div>
              <div class="col-xl-2 col-lg-3 col-md-4 col-6">
                <div class="card card-product-grid">
                  <a href="#" class="text-center">
                    {' '}
                    <img src="assets/images/items/y.jpg" />{' '}
                  </a>
                  <figcaption class="info-wrap text-center">
                    <a href="#" class="">
                      Just another product name
                    </a>
                    <br/>
                    <button className="btn-primary my-2">Get Quote</button>
                 
                  </figcaption>
                </div>
              </div>
            
          

           
            </div>
          </section>
          </div> */}
          {/* Latest Product End */}
          
      {/* <div className="mx-1 row"> */}

      
{/*      
      <div style={{}} className="mx-1 col-12 " >
      <Services /> 
      
      </div> */}
      {/* </div> */}
   {/* Final Main Banner   */}
      {/* <div className="my-3  row">
          <img style={{height:"250px"  }} src="https://alahdeen.com/pro/image/b1.png" className=" col-12" />
        </div>  */}
          {/* <img src="assets/images/banners/haflsize2.png" className="col-6" /> */}
      
        
        
        
          <div className="shop-items">
    <div className="">
        <div  className="card">
      {/* <h3 className="text-center text-upercase">Recent Products</h3> */}
      <header className="section-heading heading-line ">
          <h3 className="title-section text-uppercase">Recent Products</h3>
        </header>
      <div className="row">

        {promotion.map((v,i)=>(
<>

        <div className="col-md-4 col-lg-2 col-sm-6">
          {/* Restaurant Item */}
          
          <div className="item">
            {/* Item's image */}
            <img
              className="img-responsive"
              height={200}
              src={v.img}
              alt
            />
            {/* Item details */}
            <div className="item-dtls">
              {/* product title */}
              <h4>
                <a  href={"/product?id="+v.pid+"&s="+v.title}>{v.title}</a>
              </h4>
              {/* price */}
              <span className="price lblue">{v.price}</span>
            </div>
            {/* add to cart btn */}
            <div className="ecom bg-lblue">
              <a className="btn" href={"/product?id="+v.pid+"&s="+v.title}>
               Get Quote
              </a>
            </div>
          </div>
        </div>
</>
        ))}
      </div>
      </div>
    </div>
  </div>
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
      <div className=" row ">

        <div  className=" col-lg-9 col-md-12 col-sm-12 ">
      <header className="section-heading heading-line ">
          <h3 className="title-section text-uppercase">MAIN CATEGORIES</h3>
        </header>
      {/* <header className="section-heading heading-line">
          <h4 className="title-section text-uppercase">Promotion Products</h4>
        </header>
        <div className="card card-home-category">
          <div className="row no-gutters">
            <div className="col-md-12">
              <ul className="row no-gutters bordered-cols">
               
                <li className="col-6 col-lg-2 col-md-4">
                  <a href="#" className="item">
                    <div className="card-body">
                      <h6 className="title">Disese Sense</h6>
                      <img
                        style={{marginLeft: '16px'}}
                        className="img-sm"
                        src="assets/images/items/8.jpg"
                      />
                    </div>
                  </a>
                </li>
                <li className="col-6 col-lg-2 col-md-4">
                  <a href="#" className="item">
                    <div className="card-body">
                      <h6 className="title">Disese Sense</h6>
                      <img
                        style={{marginLeft: '16px'}}
                        className="img-sm"
                        src="assets/images/items/8.jpg"
                      />
                    </div>
                  </a>
                </li>
                <li className="col-6 col-lg-2 col-md-4">
                  <a href="#" className="item">
                    <div className="card-body">
                      <h6 className="title">Sample</h6>
                      <img
                        style={{marginLeft: '16px'}}
                        className="img-sm "
                        src="assets/images/items/9.jpg"
                      />
                    </div>
                  </a>
                </li>
                <li className="col-6 col-lg-2 col-md-4">
                  <a href="#" className="item">
                    <div className="card-body">
                      <h6 className="title">Sample </h6>
                      <img
                        style={{marginLeft: '16px'}}
                        className="img-sm "
                        src="assets/images/items/10.jpg"
                      />
                    </div>
                  </a>
                </li>

                <li className="col-6 col-lg-2 col-md-4">
                  <a href="#" className="item">
                    <div className="card-body">
                      <h6 className="title">Sample </h6>
                      <img
                        style={{marginLeft: '16px'}}
                        className="img-sm "
                        src="assets/images/items/10.jpg"
                      />
                    </div>
                  </a>
                </li>

                <li className="col-6 col-lg-2 col-md-4">
                  <a href="#" className="item">
                    <div className="card-body">
                      <h6 className="title">Sample </h6>
                      <img
                        style={{marginLeft: '15px'}}
                        className="img-sm "
                        src="assets/images/items/10.jpg"
                      />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        {/* Latest Buy Request */}
{/* <div className="card">
<div style={{border: '1px solid black', padding:'5px'}} className="col-6">

  <h3 className="text-center" >Latest Buy Request</h3>
<div   style={{ height: "400px", overflowY: "auto" }} className="card-body">
                    <div className="table-responsive">
                      <table className="table table-sm table-hover">
                        <thead>
                          <tr>
                            <th>#</th>

                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Unit</th>
                            <th>Description</th>
                            <th>Time</th>
                            <th>Status</th>

                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lead.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {lead.map((v, i) => (
                                <tr>
                                  <td>{v.id}</td>
                                  <td>{JSON.parse(v.sheet)[0].name} </td>
                                  <td>{JSON.parse(v.sheet)[0].qty} </td>
                                  <td>{JSON.parse(v.sheet)[0].price} </td>
                                  <td>{JSON.parse(v.sheet)[0].piece} </td>
                                  <td>{JSON.parse(v.sheet)[0].des} </td>
                                  <td>{v.time} </td>
                               
                                  <td style={{color: 'blue'}}>Active</td>

                                  <td>
                                   <button
                                      style={{borderRadius: '14px'}}
                                      className="btn-primary"
                                      onClick={() => {
                                        localStorage.setItem(
                                          'chatuserid',
                                          v.uid,
                                        );
                                        navigate('/chat');
                                      }}>
                                      Contact Buyer
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
</div>
</div> */}


       

        <section className="padding-bottom  no-gutters">

         

          {allcats.map((v, i) => (
            <>
          
              {/* <header 
               
               
              className="section-heading heading-line">
                <h4 className="title-section text-uppercase">{v.title}</h4>
              </header> */}

              <div className="card mb-5 card-home-category">
                <div className="row ">
                  <div className="col-md-3">
                  {i===0?<>
                    <div
                    // rgb(203,220,223)
                      style={{ backgroundColor: "#EDDFEE" }}
                      className="home-category-banner"
                    >
                       <h3 className="title-section " style={{fontSize:"20px"}} >{v.title}</h3>
                      {/* <h5>Medical Appliances</h5> */}
                      <a
                      
                        href={"sub?id=" + v.id + "&s=" + v.title}
                        className="btn btn-outline-primary rounded-pill mt-3"
                        onClick={() => {
                          localStorage.setItem("title", v.title);
                        }}
                      >
                        View All
                      </a>
                      <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
                    </div>
                  </>:
                  i===1?
                  <>
                  <div
                    // rgb(203,220,223)
                      style={{ backgroundColor: "#eaeaed" }}
                      className="home-category-banner"
                    >
                       <h3 className="title-section " style={{fontSize:"20px"}}>{v.title}</h3>
                      {/* <h5>Medical Appliances</h5> */}
                      <a
                      
                        href={"sub?id=" + v.id + "&s=" + v.title}
                        className="btn btn-outline-primary rounded-pill mt-3"
                        onClick={() => {
                          localStorage.setItem("title", v.title);
                        }}
                      >
                        View All
                      </a>
                      <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
                    </div>
                  </>

                  :
                  i===2?<>

<div
                    // rgb(203,220,223)
                      style={{
                        // eaeaed
                        backgroundColor: "#F1E7DE" }}
                      className="home-category-banner"
                    >
                       <h3 className="title-section " style={{fontSize:"20px"}}>{v.title}</h3>
                      {/* <h5>Medical Appliances</h5> */}
                      <a
                      
                        href={"sub?id=" + v.id + "&s=" + v.title}
                        className="btn btn-outline-primary rounded-pill mt-3"
                        onClick={() => {
                          localStorage.setItem("title", v.title);
                        }}
                      >
                        View All
                      </a>
                      <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
                    </div>

                  </>:
                  
                  i===3?<>

                  <div
                                      // rgb(203,220,223)
                                        style={{ backgroundColor: "#F7EEE9" }}
                                        className="home-category-banner"
                                      >
                                         <h3 className="title-section " style={{fontSize:"20px"}}>{v.title}</h3>
                                        {/* <h5>Medical Appliances</h5> */}
                                        <a
                                        
                                          href={"sub?id=" + v.id + "&s=" + v.title}
                                          className="btn btn-outline-primary rounded-pill mt-3"
                                          onClick={() => {
                                            localStorage.setItem("title", v.title);
                                          }}
                                        >
                                          View All
                                        </a>
                                        <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
                                      </div>
                  
                                    </>:
                  i===4?<>

                  <div
                                      // rgb(203,220,223)
                                        style={{ backgroundColor: "#fde5be" }}
                                        className="home-category-banner"
                                      >
                                         <h3 className="title-section " style={{fontSize:"20px"}}>{v.title}</h3>
                                        {/* <h5>Medical Appliances</h5> */}
                                        <a
                                        
                                          href={"sub?id=" + v.id + "&s=" + v.title}
                                          className="btn btn-outline-primary rounded-pill mt-3"
                                          onClick={() => {
                                            localStorage.setItem("title", v.title);
                                          }}
                                        >
                                          View All
                                        </a>
                                        <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
                                      </div>
                  
                                    </>:
                                     i===5?<>

                                     <div
                                                         // rgb(203,220,223)
                                                           style={{ backgroundColor: "#B3D0F3" }}
                                                           className="home-category-banner"
                                                         >
                                                            <h3 className="title-section " style={{fontSize:"20px"}}>{v.title}</h3>
                                                           {/* <h5>Medical Appliances</h5> */}
                                                           <a
                                                           
                                                             href={"sub?id=" + v.id + "&s=" + v.title}
                                                             className="btn btn-outline-primary rounded-pill mt-3"
                                                             onClick={() => {
                                                               localStorage.setItem("title", v.title);
                                                             }}
                                                           >
                                                             View All
                                                           </a>
                                                           <img  src={v.image_path} style={{height:"300px", width:"100%"}} className="img-bg" />
                                                         </div>
                                     
                                                       </>:

                  <>
                  
                  </>
                  
                  }
                  
                   
                  </div>
                  <div className="col-md-9">
                    <ul className="row no-gutte0rs bordered-cols">
                      {v.code == "sub" ? (
                        <>
                          {v.child.map((vv, ii) => (
                            <>
                              <li className="col-6 col-lg-3 col-md-4">
                                <a
                                  onClick={() => {
                                    localStorage.setItem("title", vv.title);
                                  }}
                                  href={"sub?id=" + vv.id + "&s=" + vv.title}
                                  className="item"
                                >
                                  <div className="card-body">
                                    <img

                                    style={{height:"150px", width:"100%"}}
                                      className="text-center "
                                      src={vv.image_path}
                                    />
                                    <h6
                                      style={{ color: "black" }}
                                      className="mt-3 text-center"
                                    >
                                      {vv.title}
                                    </h6>
                                  </div>
                                </a>
                              </li>
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          {v.child.map((vv, ii) => (
                            <>
                              <li className="col-6 col-lg-3 col-md-4">
                                <a
                                  href={"product?id=" + vv.id + "&s=" + vv.name}
                                  className="item"
                                >
                                  <center>
                                    <div className="card-body">
                                      <img
                                         style={{height:"150px", width:"100%"}}
                                         className="text-center "
                                        src={vv.img}
                                      />
                                        <h6
                                      style={{ color: "black" }}
                                      className="mt-3 text-center"
                                    >
                                      {vv.title}
                                    </h6>
                                      <figcaption className="info-wrap">
                                        <Button
                                          variant="primary"
                                          className="my-2 mx-4"
                                        >
                                          Get Quote
                                        </Button>
                                      </figcaption>
                                    </div>
                                  </center>
                                </a>
                              </li>
                            </>
                          ))}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            
            </>
          ))}
          <div className="row">
            <div className="text-right">
              <button
                onClick={() => {
                  navigate("/main");
                }}
                style={{ borderRadius: "20px" }}
                className="btn-primary px-3 py-2 "
              >
                View All Categories
                <i className="ti-arrow-right mx-3"> </i>
              </button>
            </div>
          </div>
        </section>

 
       </div>
       <div className="col-lg-3 col-md-12 col-sm-12">
       
       {/* Promotion Products Start */}
   <div className="row">
       <div  className="col-12 col-sm-12 col-lg-12 col-md-6 ">
        <Card  style={{ width: '100%' }}>
        <h5 className="bg-blue text-center text-white mb-0 p-2">
        Promotions Products
                    </h5>
        {/* <Card.Header className="text-center"><h4>Promotions Products</h4></Card.Header> */}
        <div  style={{ height: "770px", overflowY: "auto", width:"100%" }}>
         <ul style={{flexDirection:"column"}}>  

         {promotion.map((v,i)=>(
<>

<div className="col-md  d-lg-block flex-grow-1">
                
                  <aside className="special-home-right">
                   
                  <a href={"/product?id="+v.pid+"&s="+v.title} >
                    <div className="card-banner border-bottom">
                      <div className="py-3" style={{width: "70%"}}>
                        <h6 className="card-title">{v.title}</h6>
                        <p > {v.pro_price}  <del style={{color:"red"}}>{v.price}</del></p>
                        <a href={"/product?id="+v.pid+"&s="+v.title} className="btn btn-secondary btn-sm">
                          {' '}
                         Get Quote
                        </a>
                      </div>
                      <img
                         src={v.img}
                        height="80"
                        width="90"
                        className="img-bg"
                      />
                    </div>
                  </a>
                  </aside>
                </div>


                {/* <li className="col-12 mt-4">
                  <a href={"/product?id="+v.pid+"&s="+v.title}  className="item">
                    <div  style={{flexDirection:"column"}} >
                     <div >
                      <img
                        
                        className="img-sm "
                        src={v.img}
                      />
                      </div>
                    <div className=" mt-1">
                       <h5 > {v.title} </h5>
                       <div >
                         <h5 > {v.pro_price}</h5>
                       </div>
                         <div > <del style={{color:"red"}}>{v.price}</del></div> 
                    
                       </div>
                      
                    </div>
                  </a>
                </li> */}
                {/* <hr/> */}

</>


        ) )}   
              
                
                </ul>
              </div>
      </Card>
       
      <br />


        </div>
        {/* <div id="as">
          ddddddddddddd
        </div> */}
    {/* Promotion Products End */}
      
        <div  className="col-12 col-sm-12 col-lg-12 col-md-6 ">
        <Card  style={{ width: '100%' }}>
        <Card.Header className="text-center"><h4>Latest Buy Request</h4></Card.Header>
        <Card.Body>
        <div  style={{ height: "430px", overflowY: "auto", width:"100%" }} className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Qty</th>
                            <th>Unit</th>
                          
                          
                          </tr>
                        </thead>
                        <tbody >
                          {lead.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {lead.map((v, i) => (
                                <tr onClick={()=>{navigate('/buyleads')}}>
                                 
                                  <td >{JSON.parse(v.sheet)[0].name}</td>
                                  <td>{JSON.parse(v.sheet)[0].qty} </td>
                                  
                                  
                               
                                  <td>{JSON.parse(v.sheet)[0].piece} </td>
                                 {/* <td>{v.date} </td>  */}
                                  {/* <td> 1 day ago </td>  */}
                                
                                </tr>
                              ))}
                            </>
                          )}

                        </tbody>
                      </table>
                     </div>
                     <center><p onClick={()=>{navigate('/buyleads')}} style={{color:"blue", cursor:"pointer", paddingTop:"18px"}}><u>View All </u></p></center>
                   
        </Card.Body>
      </Card>
       
      <br />
        </div>
      
{/* Trending Categories Start */}

        {/* <div  className="col-12 col-sm-12 col-lg-12 col-md-6">
        <Card  style={{ width: '100%' }}>
        <h5 className="bg-blue text-center text-white mb-0 p-2">
        Trending Products
                    </h5>
       
        <div  style={{ height: "660px", overflowY: "auto", width:"100%" }} >
         <ul style={{flexDirection:"column"}} className=" ">      */}
         {trending.map((v,i)=>(
<>

{/* <div className="col-md  d-lg-block flex-grow-1">
                
                  <aside className="special-home-right">
                   
                  <a href={"/product?id="+v.pid+"&s="+v.title} >
                    <div className="card-banner border-bottom">
                      <div className="py-3" style={{width: "70%"}}>
                        <h6 className="card-title">{v.title}</h6>
                        <p > {v.pro_price}  <del style={{color:"red"}}>{v.price}</del></p>
                        <a  href={"/product?id="+v.pid+"&s="+v.title} className="btn btn-secondary btn-sm">
                          {' '}
                         Get Quote
                        </a>
                      </div>
                      <img
                         src={v.img}
                        height="80"
                        width="90"
                        className="img-bg"
                      />
                    </div>
                  </a>
                  </aside>
                </div> */}


                {/* <li className="col-12 mt-4">
                  <a href={"/product?id="+v.pid+"&s="+v.title}  className="item">
                    <div  style={{flexDirection:"column"}} >
                     <div >
                      <img
                        
                        className="img-sm "
                        src={v.img}
                      />
                      </div>
                    <div className=" mt-1">
                       <h5 > {v.title} </h5>
                       <div >
                         <h5 > {v.pro_price}</h5>
                       </div>
                         <div > <del style={{color:"red"}}>{v.price}</del></div> 
                    
                       </div>
                      
                    </div>
                  </a>
                </li> */}
                {/* <hr/> */}

</>


        ) )}   
                {/* </ul>
              </div>
      </Card>
       
      <br />


        </div> */}
{/* Trending Product End */}


{/* Most Searched Products */}
{/* <div  className="col-12 col-sm-12 col-lg-12 col-md-6">
        <Card  style={{ width: '100%' }}>
        <h5 className="bg-blue text-center text-white mb-0 p-2">
        Most Searched Products
                    </h5>
        

        <div  style={{ height: "630px", overflowY: "auto", width:"100%" }}>
         <ul style={{flexDirection:"column"}} className=" ">      */}
         {searched.map((v,i)=>(
<>

{/* <div className="col-md  d-lg-block flex-grow-1">
                
                  <aside className="special-home-right">
                   
                  <a href={"/product?id="+v.pid+"&s="+v.title} >
                    <div className="card-banner border-bottom">
                      <div className="py-3" style={{width: "70%"}}>
                        <h6 className="card-title">{v.title}</h6>
                        <p > {v.pro_price}  <del style={{color:"red"}}>{v.price}</del></p>
                        <a href={"/product?id="+v.pid+"&s="+v.title} className="btn btn-secondary btn-sm">
                          {' '}
                         Get Quote
                        </a>
                      </div>
                      <img
                         src={v.img}
                        height="80"
                        width="90"
                        className="img-bg"
                      />
                    </div>
                  </a>
                  </aside>
                </div> */}


                {/* <li className="col-12 mt-4">
                  <a href={"/product?id="+v.pid+"&s="+v.title}  className="item">
                    <div  style={{flexDirection:"column"}} >
                     <div >
                      <img
                        
                        className="img-sm "
                        src={v.img}
                      />
                      </div>
                    <div className=" mt-1">
                       <h5 > {v.title} </h5>
                       <div >
                         <h5 > {v.pro_price}</h5>
                       </div>
                         <div > <del style={{color:"red"}}>{v.price}</del></div> 
                    
                       </div>
                      
                    </div>
                  </a>
                </li> */}
                {/* <hr/> */}

</>


        ) )}   
             
           
                {/* </ul>
              </div>
      </Card>
       
      <br />


        </div> */}
{/* Most Searched Products */}
</div>

        {/* Trade Shows Start */}
        
          {/* Trade Shows End */}
       </div>
      </div>

   
      <div className="row">
       
        {/* <div className="mb-5 row">
          <img style={{height:"250px"}} src="assets/images/banners/haflsize.png" className="col-md-6 col-lg-6 col-sm-12" />
          <img style={{height:"250px"}} src="assets/images/banners/haflsize2.png" className="col-md-6 col-lg-6 col-sm-12 " />
        </div> */}
        
          {/* <section className="padding-bottom mx-2">
          <header className="section-heading heading-line">
            <h4 className="title-section text-uppercase">Blogs</h4>
          </header>

          <div className="row">
            <div className="col-md-3 col-sm-6">
              <Link to='/blogdetail'>
              <article className="card card-post">
                <img src="assets/images/posts/b1.jpg" className="card-img-top" />
                <div className="card-body">
                  <h6 className="title">Expo 2022 </h6>
                  <p className="small text-uppercase text-muted">12-03-2022</p>
                </div>
              </article>
              </Link>
            </div>
            <div className="col-md-3 col-sm-6">
            <Link to='/blogdetail'>
              <article className="card card-post">
                <img src="assets/images/posts/22.jpg" className="card-img-top" />
                <div className="card-body">
                  <h6 className="title">Expo 2022 Trade Show</h6>
                  <p className="small text-uppercase text-muted">12-03-2022</p>
                </div>
              </article>
              </Link>
            </div>
            <div className="col-md-3 col-sm-6">
            <Link to='/blogdetail'>
              <article className="card card-post">
                <img src="assets/images/posts/3.jpg" className="card-img-top" />
                <div className="card-body">
                  <h6 className="title">Mall of Lahore</h6>
                  <p className="small text-uppercase text-muted">12-03-2022</p>
                </div>
              </article>
              </Link>
            </div>
            <div className="col-md-3 col-sm-6">
            <Link to='/blogdetail'>
              <article className="card card-post">
                <img src="assets/images/posts/2.jpg" className="card-img-top" />
                <div className="card-body">
                  <h6 className="title">Cannt Lahore</h6>
                  <p className="small text-uppercase text-muted">12-03-2022</p>
                </div>
              </article>
              </Link>
            </div>
          </div>
        </section> */}
        </div>
     <div className="container">
      <h2>Online B2B Marketplace in Pakistan</h2>
      <p>In the digital era, the evolution of business-to-business (B2B) transactions in Pakistan has taken a significant leap forward, primarily fueled by the rise of online B2B marketplaces. These digital platforms are rewriting the rules of commerce, connecting B2B companies across industries, revolutionizing procurement processes, and introducing advanced leads management systems.</p>
      <br/>
      <h3>The Landscape of B2B Companies in Pakistan</h3>
      <br/>
      <p>Pakistan's business landscape is witnessing a paradigm shift as B2B companies increasingly recognize the trans formative power of online marketplaces. From textile giants to tech startups, businesses of all sizes are diving into the realm of online B2B commerce, embracing the convenience and efficiency it brings to their operations.
<br/>
<br/>
This shift is not just about adopting a new way of doing business; it's a strategic move towards enhancing competitiveness and tapping into a broader market. The diversity of B2B companies in Pakistan actively engaging in online marketplaces showcases the versatility and adaptability of these platforms.
</p>
<br/>
<h3>Example of B2B Ecommerce in Action: Textile Industry</h3>
<br/>
<p>A shining example of the impact of online B2B ecommerce in Pakistan can be observed in the country's textile industry. Historically, textile transactions were complex, involving intricate supply chains and time-consuming negotiations. However, the emergence of B2B marketplaces has streamlined these processes, catalyzing a digital transformation within the industry.
<br/>
<br/>
These platforms serve as virtual showrooms for textile manufacturers, allowing them to exhibit their products, negotiate deals, and finalize transactions seamlessly. The integration of comprehensive product catalogs and transparent pricing structures has not only simplified the sourcing process but has also accelerated decision-making for businesses in the textile sector.
<br/>
<br/>
Moreover, B2B ecommerce isn't just a game-changer for large enterprises. Small and medium-sized enterprises (SMEs) in the textile industry are experiencing a leveling of the playing field, gaining access to a broader market and establishing connections with suppliers and distributors that were previously out of reach.
</p>
<br/>
<h3>Leads Management: The Backbone of B2B Success</h3>
<br/>
<p>Central to the efficacy of online B2B marketplaces is the implementation of advanced leads management systems. In a world where data is king, these systems enable businesses to navigate the digital landscape with precision, ensuring that every interaction is optimized for success.
<br/>
<br/>
Traditional leads management involved extensive manual efforts, often resulting in inefficiencies and missed opportunities. The introduction of digital tools and analytics in online B2B marketplaces has transformed leads management into a streamlined and automated process.
<br/>
<br/>
These platforms employ sophisticated algorithms that analyze user behavior, transaction history, and preferences to match buyers with the most relevant sellers. The result is a personalized and efficient approach to leads management that not only saves time but also ensures that businesses are connected with high-quality, qualified leads.
</p>
<br/>
<h2>The Dynamics of Online B2B Marketplaces in Pakistan</h2>
<br/><br/><br/>
<h3>Global Connectivity:</h3>
<br/>
<p>Online B2B marketplaces have shattered geographical barriers, enabling businesses in Pakistan to connect with partners, suppliers, and clients on a global scale. This expanded reach opens up new avenues for growth and diversification, propelling Pakistani businesses onto the international stage.</p>
<br/>
    <h3>Operational Efficiency
</h3><br/>
<p>Streamlining processes is a hallmark of online B2B marketplaces. From procurement to order fulfillment, these platforms enhance operational efficiency, allowing businesses to allocate resources more effectively and focus on innovation and strategic growth.
</p>
<br/>
<h3>Transparency in Transactions:</h3>
<br/>
<p>Transparency is a cornerstone of online B2B transactions. Detailed product information, transparent pricing, and secure payment gateways contribute to a trustworthy business environment. This transparency builds confidence among stakeholders, fostering stronger and more sustainable business relationships.</p>
<br/> <h3>Cost-Effective Marketing:</h3><br/>
    <p>Digital marketing within online B2B marketplaces offers a cost-effective alternative to traditional methods. Businesses can leverage online visibility, targeted advertising, and content marketing to maximize their outreach without incurring exorbitant costs.</p>
    <br/><h3>Challenges and Opportunities in the Online B2B Sphere</h3><br/>
   <p>While the benefits of online B2B marketplaces in Pakistan are evident, challenges persist. Security concerns, data privacy, and the need for digital literacy are hurdles that demand attention. However, these challenges present opportunities for growth. As businesses invest in robust cybersecurity measures, educational initiatives, and digital infrastructure, the online B2B landscape in Pakistan will continue to evolve and mature.
</p>
<br/>
<h3>Conclusion: Shaping the Future of B2B Commerce in Pakistan</h3>
<br/>
<p>The advent of online B2B marketplaces in Pakistan marks a pivotal moment in the country's business narrative. These platforms are not just facilitating transactions; they are empowering businesses to redefine how they connect, collaborate, and succeed in the digital age.
<br/>
<br/>
As Pakistani B2B companies embrace the digital transformation facilitated by online marketplaces, they position themselves at the forefront of innovation and competitiveness. The journey towards a digitally-driven B2B ecosystem is not only reshaping traditional practices but is also laying the foundation for a more interconnected, resilient, and prosperous business environment in Pakistan. The future of B2B commerce in Pakistan is online, and the possibilities are limitless.
</p>
     </div>
      <Footer />
      <Whatsapp />
    </div>
  );
}

export default Homepage;
