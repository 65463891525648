import {useState} from 'react';
import Nav from '../../../navbar';
import SweetAlert from 'react-bootstrap-sweetalert';
import {Link} from 'react-router-dom';
function Mainpage() {
  const [alert, setAlert] = useState('');
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Beneficiary List</h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                      <input
                        style={{borderRadius: '18px', paddingLeft: '8px'}}
                        placeholder="search"
                      />
                      <span>
                        <Link to="/adbenificiary">
                          <button
                            style={{borderRadius: '18px', color: 'white'}}
                            className="bg-primary mx-3 px-4 ">
                            Add Benificiary
                          </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Bank Name</th>
                            <th>Account Number</th>
                            <th>Account Title</th>
                            <th>Action</th>
                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Alahdeen</td>
                            <td>01212122</td>
                            <td>Ahmed Ali</td>
                            <td>
                              <button
                                style={{borderRadius: '16px'}}
                                className="px-4 btn-primary"
                                onClick={() => {
                                  setAlert(!alert);
                                }}>
                                Pay
                              </button>
                              {alert ? (
                                <>
                                  {' '}
                                  <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText="Confirm!"
                                    confirmBtnBsStyle="primary"
                                    title="Are you sure?"
                                    onConfirm={() => {
                                      setAlert(!alert);
                                    }}
                                    onCancel={() => {
                                      setAlert(!alert);
                                    }}
                                    focusCancelBtn>
                                    <input
                                      className="form-control"
                                      placeholder="Enter Amount"
                                    />
                                    <br />
                                  </SweetAlert>
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>
                              <i className="ti-check-box"> </i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
