import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import { GiCancel } from 'react-icons/gi';
import { FcRating } from 'react-icons/fc';
import Feedback from 'react-bootstrap/esm/Feedback';

function Mainpage() {
  const [array, setArray] = useState([]);
  const [uid, setUid] = useState('');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id) 
      getBookings(v[0].id)
     
    }
  }, []);

  const getBookings = async (id)=> {

    const data = new FormData();
    data.append('uid', id);
    
        axios({ 
          method: 'post',
          url: '/pro/logistic/driver_bookings.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log("Booked_Driver_Rides_List",s.data)
           if(s.data[0].code=="all")
           {
            setArray(s.data)
           }                                      

           
            
                  })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

  const getStartRider = async (fid,rid)=> {
    
    const data = new FormData();
    data.append('pid', fid);
    data.append('rid', rid);

        axios({ 
          method: 'post',
          url: '/pro/logistic/driver_start_ride.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log("driver_start_ride",s.data)
            
           if(s.data[0].code=="pass")
           {
            alert("Ride Started")
           }
           getBookings(uid)
           
            
                  })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
  const getEndRider = async (fid,rid)=> {

    const data = new FormData();
    data.append('pid', fid);
    data.append('rid', rid);

        axios({ 
          method: 'post',
          url: '/pro/logistic/complete_delivery.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log("driver_start_ride",s.data)
            getBookings(uid)
           if(s.data[0].code=="pass")
           {
            alert("Ride compelted")
           }

           
            
                  })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
  const getCancel_ = async (fid,rid)=> {

    const data = new FormData();
    data.append('pid', fid);
    data.append('rid', rid);

        axios({ 
          method: 'post',
          url: '/pro/logistic/complete_delivery.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log("driver_start_ride",s.data)
            getBookings(uid)
           if(s.data[0].code=="pass")
           {
            alert("Ride compelted")
           }

           
            
                  })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
  const sendFeedBack = async (uid,driverid,rid,rating)=> {

    console.log("rating ",rating)
    console.log("rid ",rid)
    console.log("driverid ",driverid)
    console.log("uid ",uid)

    const data = new FormData();
    data.append('rating', rating);
    data.append('comment', "abc");
    data.append('rid', rid);
    data.append('did', driverid);
    data.append('uid', uid);
    data.append('type', "driver");
    data.append('val', "user_feedback");

        axios({ 
          method: 'post',
          url: '/pro/logistic/manage_feedback.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log("driver_start_ride",s.data)
            getBookings(uid)
           if(s.data[0].code=="pass")
           {
            alert("Ride compelted")
           }

           
            
                  })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">


          <div className="container-fluid"> 
            <section id="main-content">
              <div className="col-lg-12">

<div className='card'>
<ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Booked</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Start Rides</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Completed</button>
  </li>
</ul>
<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
  <div >
                 
                  
                 <div className="card-body">
                   <div className="table-responsive">
                     <table className="table table-hover ">
                       <thead>
                         <tr>
                           <th>Sr No.</th>
                           <th> Type</th>
                           <th>Shipper Name</th>
                           <th>Shipper Phone</th>
                           <th>Shipper Address</th>
                           <th>Receiver Name</th>
                           <th>Receiver Phone</th>
                           <th>Receiver Address</th>
                           <th>Start Date & Time</th>
                           
                           <th>Fare </th>
                           <th>Controls </th>
                         </tr>
                       </thead>
                       <tbody>
                         {
                        
                           array.length<1?<> <tr>
                           <td
                             style={{
                               justifyContent: 'center',
                               alignItems: 'center',
                               textAlign: 'center',
                             }}
                             colSpan={9}>
                             No Record to show{' '}
                           </td>
                         </tr></>:<>
                         
                         {
                           array.map((v,i)=>(

                            v.reqstatus=="booked"?
<tr>
                           <td >
                             {i+1}
                           </td>
                           <td >
                             {v.vehicle_type} /
                             {v.log_type}
                           </td>
                           <td >
                             {v.sender_name}
                           </td>
                           <td >
                             {v.s_phone}
                           </td>
                           <td >
                             {v.s_address}
                           </td>
                           <td >
                             {v.receiever_name}
                           </td>
                           <td >
                             {v.r_phone}
                           </td>
                           <td >
                             {v.r_address}
                           </td>
                           <td >
                             {v.depart_date} / {v.depart_time}

                           </td>
                           <td >
                             {v.fare}
                           </td>
                           
                           <td >
                             
{
 v.reqstatus=="booked"?<> <button className='btn-primary' style={{borderRadius:"10px",marginRight:"20px"}} onClick={()=>{
   getStartRider(v.offerid,v.rid)
 

  }}   >Ride Start</button> <GiCancel size={20} color='#bd503a'  /> </>:v.reqstatus=="started"?<>  <button className='btn-primary' style={{borderRadius:"10px",marginRight:"20px"}} onClick={()=>{
   getEndRider(v.offerid,v.rid)

  }}   > Ride End</button>   </>:<> completed  </>
}
                            
                           



                           </td>
                         </tr>:<></>
                           ))
                         }
                         </>

                         }
                        
                       </tbody>
                     </table>
                   </div>
                 </div>
               </div>

               {/* 1 end */}
  </div>
  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    {/*  2 start */}
    <div >
                 
                  
                 <div className="card-body">
                   <div className="table-responsive">
                     <table className="table table-hover ">
                       <thead>
                         <tr>
                           <th>Sr No.</th>
                           <th> Type</th>
                           <th>Shipper Name</th>
                           <th>Shipper Phone</th>
                           <th>Shipper Address</th>
                           <th>Receiver Name</th>
                           <th>Receiver Phone</th>
                           <th>Receiver Address</th>
                           <th>Start Date & Time</th>
                           
                           <th>Fare </th>
                           <th>Controls </th>
                         </tr>
                       </thead>
                       <tbody>
                         {
                           array.length<1?<> <tr>
                           <td
                             style={{
                               justifyContent: 'center',
                               alignItems: 'center',
                               textAlign: 'center',
                             }}
                             colSpan={9}>
                             No Record to show{' '}
                           </td>
                         </tr></>:<>
                         
                         {
                           array.map((v,i)=>(

                            v.reqstatus=="started"?
<tr>
                           <td >
                             {i+1}
                           </td>
                           <td >
                             {v.vehicle_type} /
                             {v.log_type}
                           </td>
                           <td >
                             {v.sender_name}
                           </td>
                           <td >
                             {v.s_phone}
                           </td>
                           <td >
                             {v.s_address}
                           </td>
                           <td >
                             {v.receiever_name}
                           </td>
                           <td >
                             {v.r_phone}
                           </td>
                           <td >
                             {v.r_address}
                           </td>
                           <td >
                             {v.depart_date} / {v.depart_time}
                           </td>
                           <td >
                             {v.fare}
                           </td>
                           
                           <td >
                             
{
 v.reqstatus=="booked"?<> <button className='btn-primary' style={{borderRadius:"10px",marginRight:"20px"}} onClick={()=>{
   getStartRider(v.offerid,v.rid)

  }}   >Ride Start</button> <GiCancel size={20} color='#bd503a'  /> </>:v.reqstatus=="started"?<>  <button className='btn-primary' style={{borderRadius:"10px",marginRight:"20px"}} onClick={()=>{
   getEndRider(v.offerid,v.rid)

  }}   > Ride End</button>   </>:<>Completed  </>
}
                            
                           



                           </td>
                         </tr>:<></>
                           ))
                         }
                         </>

                         }
                        
                       </tbody>
                     </table>
                   </div>
                 </div>
               </div>
    {/* 2 end */}
  </div>
  <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
    {/* 3 start */}
    <div>
                 
                  
                 <div className="card-body">
                   <div className="table-responsive">
                     <table className="table table-hover ">
                       <thead>
                         <tr>
                           <th>Sr No.</th>
                           <th> Type</th>
                           <th>Shipper Name</th>
                           <th>Shipper Phone</th>
                           <th>Shipper Address</th>
                           <th>Receiver Name</th>
                           <th>Receiver Phone</th>
                           <th>Receiver Address</th>
                           <th>Start Date & Time</th>
                           
                           <th>Fare </th>
                           <th>Controls </th>
                         </tr>
                       </thead>
                       <tbody>
                         {
                           array.length<1?<> <tr>
                           <td
                             style={{
                               justifyContent: 'center',
                               alignItems: 'center',
                               textAlign: 'center',
                             }}
                             colSpan={9}>
                             No Record to show{' '}
                           </td>
                         </tr></>:<>
                         
                         {
                           array.map((v,i)=>(
                            

                            v.reqstatus=="delivered"?
<tr>
                           <td >
                             {i+1}
                           </td>
                           <td >
                             {v.vehicle_type} /
                             {v.log_type}
                           </td>
                           <td >
                             {v.sender_name}
                           </td>
                           <td >
                             {v.s_phone}
                           </td>
                           <td >
                             {v.s_address}
                           </td>
                           <td >
                             {v.receiever_name}
                           </td>
                           <td >
                             {v.r_phone}
                           </td>
                           <td >
                             {v.r_address}
                           </td>
                           <td >
                             {v.depart_date} / {v.depart_time}
                           </td>
                           <td >
                             {v.fare}
                           </td>
                           
                           <td style={{width:"120px"}} >
                             
{
 v.reqstatus=="delivered"?<> Completed
 <br/>
 <center>
{
  v.feedback=="true"?
  <> 
  { v.rating=="1"?<> <FcRating size={20} /> </>:v.rating=="2"?<><FcRating size={20} /><FcRating size={20} /></>:v.rating=="3"?<><FcRating size={20} /><FcRating size={20} /><FcRating size={20} /></>:v.rating=="4"?<><FcRating size={20} /><FcRating size={20} /><FcRating size={20} /><FcRating size={20} /> </>:v.rating=="5"?<> <FcRating size={20} /><FcRating size={20} /><FcRating size={20} /><FcRating size={20} /><FcRating size={20} /> </>:<> 
 </>  }  
    
    
    </>:<>  <p onClick={(d)=>{

         sendFeedBack(v.uid,v.driverid,v.rid,"5")

         }}>Rating </p></>
}

</center>
 </>:<>  <p onClick={(d)=>{
 
    sendFeedBack(v.uid,v.driverid,v.rid,"2")
    }}>Rating </p> </>
}
                            
                           



                           </td>
                         </tr>:<></>
                           ))
                         }
                         </>

                         }
                        
                       </tbody>
                     </table>
                    
                   </div>
                 </div>
               </div>
    {/* 3 end */}



    
  </div>
</div>

</div>


                {/* <div className="card">
                 
                  
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th> Type</th>
                            <th>Shipper Name</th>
                            <th>Shipper Phone</th>
                            <th>Shipper Address</th>
                            <th>Receiver Name</th>
                            <th>Receiver Phone</th>
                            <th>Receiver Address</th>
                            <th>Start Date & Time</th>
                            
                            <th>Fare </th>
                            <th>Controls </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            array.length<1?<> <tr>
                            <td
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                              }}
                              colSpan={9}>
                              No Record to show{' '}
                            </td>
                          </tr></>:<>
                          
                          {
                            array.map((v,i)=>(
<tr>
                            <td >
                              {i+1}
                            </td>
                            <td >
                              {v.vehicle_type} /
                              {v.log_type}
                            </td>
                            <td >
                              {v.sender_name}
                            </td>
                            <td >
                              {v.s_phone}
                            </td>
                            <td >
                              {v.s_address}
                            </td>
                            <td >
                              {v.receiever_name}
                            </td>
                            <td >
                              {v.r_phone}
                            </td>
                            <td >
                              {v.r_address}
                            </td>
                            <td >
                              {v.depart_date} / {v.depart_time}
                            </td>
                            <td >
                              {v.fare}
                            </td>
                            
                            <td >
                              
{
  v.reqstatus=="booked"?<> <button className='btn-primary' style={{borderRadius:"10px",marginRight:"20px"}} onClick={()=>{
    getStartRider(v.offerid,v.rid)

   }}   >Ride Start</button> <GiCancel size={20} color='#bd503a'  /> </>:v.reqstatus=="started"?<>  <button className='btn-primary' style={{borderRadius:"10px",marginRight:"20px"}} onClick={()=>{
    getEndRider(v.offerid,v.rid)

   }}   > Ride End</button>   </>:<> completed </>
}
                             
                            



                            </td>
                          </tr>
                            ))
                          }
                          </>

                          }
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> */}
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
