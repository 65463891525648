import Nav from '../../../navbar';

function Mainpage() {
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Upload Bulk Product</h4>
                  </div>

                  <div className="card-body">
                    <div className="col-6 form-group">
                      <label>Select Categories</label>
                      <select className="form-control">
                        <option>Drugs & Pharmaceuticals</option>
                        <option>IT & Electronics</option>
                        <option>Industrial Supplies</option>
                      </select>
                    </div>
                    <div className="form-group col-6">
                      <label> Upload CSV</label>
                      <input className="form-control" type="file" />
                    </div>
                    <button className="btn-primary px-3 mx-3 my-2">Save</button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
