import Nav from "../../../navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Mainpage() {
  const navigate = useNavigate();
  const [uid, setUid] = useState("");
  const [warehouse, setwarehouse] = useState([]);
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      MyWarehouse_all(v[0].id);
     
    }
  }, []);
  const MyWarehouse_all = async (id) => {
    const data = new FormData();
    data.append("uid", id);

    axios({
      method: "post",
      url: "/pro/warehouseby_types.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("warehouse list ", s.data);
        const a = s.data;
        setwarehouse(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  

  const Active_ = async (id) => {
    const data = new FormData();
    data.append("id", id);

    axios({
      method: "post",
      url: "/pro/warehouse_publish.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log(s.data);
        MyWarehouse_all(uid);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Warehouse List</h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                      <input
                        style={{ borderRadius: "18px", paddingLeft: "8px" }}
                        placeholder="search"
                      />
                      <span>
                        <button
                          style={{ borderRadius: "18px", color: "white" }}
                          className="bg-primary mx-3 px-4 "
                        >
                          Search
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Image</th>
                            <th>City</th>
                            <th>Area</th>
                            <th>Locality</th>
                            <th>type</th>

                            <th>Rent</th>

                            <th>Room</th>
                            <th>Parking</th>
                            <th>Status</th>
                            <th>Created At</th>
                            <th>No. of Requests</th>

                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          {warehouse.length == 0 ? (
                            <>
                              {" "}
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={10}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}

                          {warehouse.map((v, i) => (
<>
{
                            v.count== 0?
                            <tr>
                              <td>{v.id}</td>
                              <td>
                                <img
                                  style={{ width: "20%", height: "80px", width:"125px" }}
                                  src={v.imagea}
                                />
                              </td>
                              <td>{v.city}</td>
                              <td>{v.areasqft}</td>
                              <td>{v.locality}</td>
                              <td>{v.type}</td>
                              <td>{v.rentpermonth}</td>
                              <td>{v.room}</td>
                              <td>{v.parking}</td>
                              <td>
                              {v.status === "" ? (
                                  <>
                                    <p
                                    style={{color:"red",cursor: "pointer"}}
                                      
                                      onClick={() => {
                                        Active_(v.id);
                                      }}
                                    >Inactive </p>
                                  </>
                                ) : (
                                  <>
                                    <p 
                                     style={{color:"blue",cursor: "pointer"}}
                                      onClick={() => {
                                        Active_(v.id);
                                      }}
                                      
                                    > Active</p>
                                  </>
                                )}{" "}
                                </td>
                              <td>{v.time}</td>
                              <td>
                                {v.status == "booked" ? (
                                  <>booked</>
                                ) : (
                                  <>
                                    {" "}

                                
  <div
                   style={{cursor:"pointer"}}    
                   onClick={() => {
                    navigate("/warehousebook");
                    localStorage.setItem("wid", v.id);
                  }}     className="widget-header mr-3">
                         <a  className="widget-view">
                           <div className="icon-area text-center">
                             <i
                               style={{ color: "blue" }}
                               className="fa fa-bell"
                             ></i>
                             <span className="notify"> {v.count}</span>
                             {/* <span className="notify">1</span> */}
                           </div>{" "}
                           <small className="text">
                             {" "}
                             <strong> Requests </strong>
                           </small>
                         </a>
                       </div>
 
                                    {/* <button
                                      onClick={() => {
                                        navigate("/warehousebook");
                                        localStorage.setItem("wid", v.id);
                                      }}
                                      className="btn-primary"
                                    >
                                      {v.count}
                                    </button> */}
                                  </>
                                )}
                              </td>

                              <td>
                                {v.status === "" ? (
                                  <>
                                    <i
                                      className="ti-control-shuffle"
                                      onClick={() => {
                                        Active_(v.id);
                                      }}
                                    ></i>
                                  </>
                                ) : (
                                  <>
                                    <i
                                      onClick={() => {
                                        Active_(v.id);
                                      }}
                                      className="ti-eye"
                                    ></i>
                                  </>
                                )}{" "}
                                {/* <i className="ti-check-box"></i>
                                <i className="ti-trash"></i> */}
                              </td>
                            </tr>:
                            v.count >= 0?
                              <tr>
                              <td >{v.id}</td>
                              <td>
                                <img
                                  style={{ width: "20%", height: "80px", width:"125px" }}
                                  src={v.imagea}
                                />
                              </td>
                              <td>{v.city}</td>
                              <td>{v.areasqft}</td>
                              <td>{v.locality}</td>
                              <td>{v.type}</td>
                              <td>{v.rentpermonth}</td>
                              <td>{v.room}</td>
                              <td>{v.parking}</td>
                              <td>
                              {v.status === "" ? (
                                  <>
                                    <p
                                    style={{color:"red",cursor: "pointer"}}
                                      
                                      onClick={() => {
                                        Active_(v.id);
                                      }}
                                    >Inactive </p>
                                  </>
                                ) : (
                                  <>
                                    <p 
                                     style={{color:"blue",cursor: "pointer"}}
                                      onClick={() => {
                                        Active_(v.id);
                                      }}
                                      
                                    > Active</p>
                                  </>
                                )}{" "}
                                </td>
                              <td>{v.time}</td>
                              <td>
                                {v.status == "booked" ? (
                                  <>booked</>
                                ) : (
                                  <>
                                    {" "}
                                    <div
                   style={{cursor:"pointer"}}    
                   onClick={() => {
                    navigate("/warehousebook");
                    localStorage.setItem("wid", v.id);
                  }}     className="widget-header mr-3">
                         <a  className="widget-view">
                           <div className="icon-area text-center">
                             <i
                               style={{ color: "blue" }}
                               className="fa fa-bell"
                             ></i>
                             <span className="notify"> {v.count}</span>
                             {/* <span className="notify">1</span> */}
                           </div>{" "}
                           <small className="text">
                             {" "}
                             <strong> Requests </strong>
                           </small>
                         </a>
                       </div>
                                   
                                  </>
                                )}
                              </td>
      
                              <td>
                                {v.status === "" ? (
                                  <>
                                    <i
                                      className="ti-control-shuffle"
                                      onClick={() => {
                                        Active_(v.id);
                                      }}
                                    ></i>
                                  </>
                                ) : (
                                  <>
                                    <i
                                      onClick={() => {
                                        Active_(v.id);
                                      }}
                                      className="ti-eye"
                                    ></i>
                                  </>
                                )}{" "}
                                {/* <i className="ti-check-box"></i>
                                <i className="ti-trash"></i> */}
                              </td>
                            </tr>
                            :<></>
                              }
                              </>
  ))}
  
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
