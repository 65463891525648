import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Nav from "../../../navbar2";

function Buyleads() {
  const history = useNavigate();

  const [productList, setProductList] = useState([]);
  const [uid, setUid] = useState("");

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      viewProduct(v[0].id);
    }
  }, []);

  const viewProduct = async (uid) => {
    const data = new FormData();
    data.append("cid", uid);
    data.append("run", "cid");

    axios({
      method: "post",
      url: "/pro/khata/khata_invoice_view.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
    
      .then((s) => {
        // console.log("khata list ", s.data);
        const a = s.data;
        // if (a[0].code != 'fail') {
        setProductList(a);
        // }
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  return (
    <body>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Manage Invoices  </h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                      <input
                        style={{ borderRadius: "18px", paddingLeft: "8px" }}
                        placeholder="search"
                      />
                      <span>
                      
                          <button
                            style={{ borderRadius: "18px", color: "white" }}
                            className="bg-primary mx-3 px-4 "
                          >
                            Search
                          </button>
                      
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Invoice No.</th>
                            <th>Date</th>
                            <th>Created By</th>
                            <th>Contact No.</th>
                            <th>Invoice Email</th>
                            <th>Comments</th>
                            <th>Amount</th>
                            {/* <th>Control</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {productList.map((v, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{v.iid}</td>
                              <td>{v.invoicedate}</td>
                              <td>{v.user[0].name}</td>
                              <td>{v.user[0].phone}</td>
                              <td>{v.user[0].email}</td>
                             

                              <td>{v.comments}</td>
                              <td>{new Intl.NumberFormat('en-pk').format(v.total)}</td>
                              
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
