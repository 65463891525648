
import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
function Mainpage() {
  const [array, setArray] = useState([]);
  const[booking, setBooking] =useState ([])
  const [uid, setUid] = useState('');
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id) 
      getBookings(v[0].id)
     
    }
  }, []);



  const getBookings = async (id)=> {

    const data = new FormData();
    data.append('uid', id);
 
        axios({ 
          method: 'post',
          url: '/pro/logistic/view_bookings.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log("Booked_Rides_List",s.data)
           setBooking(s.data)
            
                  })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Booked Rides</h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                      <input
                        style={{borderRadius: '18px', paddingLeft: '8px'}}
                        placeholder="search"
                      />
                      <span>
                        <button
                          style={{borderRadius: '18px', color: 'white'}}
                          className="bg-primary mx-3 px-4 ">
                          Search
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                          <th>Sr No.</th>
                            <th>From City</th>
                            <th>To City</th>
                            <th>Use Type</th>
                            <th> Type</th>
                            <th>vehicle type</th>
                            
                            <th>Sender Name</th>
                            <th>Receiver Name</th>
                            <th>Weight</th>
                            <th>Volume</th>
                            <th>Departure Date</th>
                            <th>Departure Time</th>
                            <th>Fare </th>
                            <th>Driver </th>
                          </tr>
                        </thead>
                        <tbody>
                          {

                            booking.map((v,i)=>(
                              <>
                                <tr>
                              <td>
                              {i+1}
                              </td>
                              <td>{v.frm_city}</td>
                              <td>{v.to_city}</td>
                              <td>{v.vehicle_type}</td>
                              <td>{v.log_type}</td>
                              <td>{v.log_type}</td>
                              <td>{v.sender_name}</td>
                              <td>{v.receiever_name}</td>
                              <td>{v.con_weight}</td>
                              <td>{v.con_volume}</td>
                              <td>{v.depart_date}</td>
                              <td>{v.depart_time}</td>
                              <td>{v.fare}</td>
                              <td>{v.drivername}</td>
                              </tr>
                              </>

                            ))
                          }
                        

                            {/* <td
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                              }}
                              colSpan={10}>
                              No Record to show{' '}
                            </td> */}
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
