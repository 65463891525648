import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Signup from "./pages/Signup";
import Forgotten from "./pages/forget";
import Otp from "./pages/opt";




import Home from "./main/home";
import Vendor from "./main/vendor";
import Mains from "./main/cat";
import Sub from "./main/subcat";
import Vendor_Detail from "./main/vendor_detail";
import Product_Detail from "./main/product_detail";
 
// Seller Side
import Mainpage from "./pages/mainpage";
import Spinner from "./components/landingpage/load"
import Blogdetail from "./components/landingpage/blogdetail"
import Trade from "./components/landingpage/trade"

//profile
import Additional from "./pages/leads/seller/profile/additional";
import Profile from "./pages/leads/seller/profile/profile";
import CompanyImages from "./pages/leads/seller/profile/company_images";
import User from "./pages/leads/seller/profile/user";
import Certificate from "./pages/leads/seller/profile/certificate";
import Certificate_view from "./pages/leads/seller/profile/certificate_view";
import Productinterest from "./pages/leads/seller/profile/productinterest";
import Productinterestadd from "./pages/leads/seller/profile/productofinterestadd";
import Myproducts from "./pages/leads/seller/profile/myproducts";
import Director from "./pages/leads/seller/profile/director";
import Adddirector from "./pages/leads/seller/profile/director_add";
import Editdirector from "./pages/leads/seller/profile/editdirector";

// Warehouse
import Addwarehouse1 from "./pages/leads/seller/warehouse/Addwarehouse1";
import Warehousebooking from "./pages/leads/seller/warehouse/Warehousebooking";
import Warehouselist from "./pages/leads/seller/warehouse/Warehouselist";
import Wareinvoices from "./pages/leads/seller/warehouse/wareinvoices";

// Profile

// Packages
import Packages from "./pages/leads/seller/Subscription/Packages";
import Subscription from "./pages/leads/seller/Subscription/Subscription";
import BuyPackageNow from "./pages/leads/seller/Subscription/buypackage";

// Add Product
import Productlist from "./pages/leads/seller/Products/Productlist";
import AddProduct from "./pages/leads/seller/Products/AddProduct";
import ProductEdit from "./pages/leads/seller/Products/productedit";
import ManageLeadProduct from "./pages/leads/seller/Products/manage_lead";
import Bulkproduct from "./pages/leads/seller/Products/Bulkproduct";

// Add Catalog
import Catalog from "./pages/leads/seller/Catalog/Catalog";
import AdCatalog from "./pages/leads/seller/Catalog/AdCatalog";

// Buy Leads
import Buyleads from "./pages/leads/seller/Buyleads/Buyleads";

// Khata
import Khataclient from "./pages/leads/seller/khata/Khataclient";
import Newinvoice from "./pages/leads/seller/khata/newinvoice";
import ClientAddform from "./pages/leads/seller/khata/clientaddform";
import Khatadeliverychallan from "./pages/leads/seller/khata/Khatadeliverychallan";
import Khatamanageinvoice from "./pages/leads/seller/khata/Khatamanageinvoice";
import Adddeliverychallan from "./pages/leads/seller/khata/adddeliverychallan";
import ClientEditform from "./pages/leads/seller/khata/clienteditform";
import Addbalance from "./pages/leads/seller/khata/add_balance";
import Invoiceaddclient from "./pages/leads/seller/khata/Invoiceaddclient"
import Deliveryaddclient from "./pages/leads/seller/khata/Deliveryaddclient"
import History from "./pages/leads/seller/khata/khatahistory"
import Invoicedraft from "./pages/leads/seller/khata/invoicedraft"
import ChatboxInvoice from "./pages/leads/seller/khata/chatboxinvoice"

// Khata-inventory
import Productdefi from "./pages/leads/seller/khata/inventory/sproductdef";
import Productprice from "./pages/leads/seller/khata/inventory/sproductpricing";
import Stocklevel from "./pages/leads/seller/khata/inventory/sstocklevels";
import Productlists from "./pages/leads/seller/khata/inventory/sproductslist";
import Salesreturns from "./pages/leads/seller/khata/inventory/ssalesreturn";
import Reports from "./pages/leads/seller/khata/inventory/sreports";

// Mattersheet
import Matteradditionaldetails from "./pages/leads/seller/Mattersheet/Matteradditionaldetails";
import Mattersheetdetails from "./pages/leads/seller/Mattersheet/Mattersheetdetails";
import Mattersheetlist from "./pages/leads/seller/Mattersheet/Mattersheetlist";
import Mattersheetdirector from "./pages/leads/seller/Mattersheet/Mattersheetdirector";
import Upload from "./pages/leads/seller/Mattersheet/uploadproduct";
import Table from "./pages/leads/seller/Mattersheet/table";

// Logistics
import Request from "./pages/leads/seller/logistics/Request";
import Bookingrequest_byRider from "./pages/leads/seller/logistics/Bookingrequest_byRider";
import Bookingrequest_byUser from "./pages/leads/seller/logistics/Bookingrequest_byUser";
import Bookingrequest_byUser_rebid from "./pages/leads/seller/logistics/Pendingdriver";
import Bookingreceipt from "./pages/leads/seller/logistics/Bookingreceipt";
import Myrides from "./pages/leads/seller/logistics/myrides";
import Driverreg from "./pages/leads/seller/logistics/Driverreg";

// Wallet
import Wallet from "./pages/leads/seller/Wallet/Wallet";
import Wallethistory from "./pages/leads/seller/Wallet/Wallethistory";
import Benificiary from "./pages/leads/seller/Wallet/Benificiary";
import Topup from "./pages/leads/seller/Wallet/Topup";
import Withdraw from "./pages/leads/seller/Wallet/Withdraw";
import Adbeneficiary from "./pages/leads/seller/Wallet/adbeneficiary";

// Ad-ons
import Advertisewithus from "./pages/leads/seller/ad/advertisewithus";
import Orderlist from "./pages/leads/seller/ad/orderlist";
import Webmarketing from "./pages/leads/seller/ad/webmarketing";

// Buyer Side
import Mainpage2 from "./pages/mainpage2";
// Buy Leads
import Requestno from "./pages/leads/buyer/Buyrequest/Requestno";
import Leadsrequest from "./pages/leads/buyer/Buyrequest/Buyleads";
import Sendquote from "./pages/leads/seller/Buyleads/Sendquote";
import Sendrequest from "./pages/leads/seller/Buyleads/sendrequest";

// Khata

import InvoicePrint from "./pages/leads/seller/khata/Invoiceprint";
import Client from "./pages/leads/buyer/buyerkhata/Khataclient";
import Clientaddbuyer from "./pages/leads/buyer/buyerkhata/clientaddbuyer";
import Viewchallan from "./pages/leads/buyer/buyerkhata/Khatadeliverychallan";
import Invoice from "./pages/leads/buyer/buyerkhata/Khatamanageinvoice";
import Purchaseorder from "./pages/leads/buyer/buyerkhata/purchaseorder";
import AdPurchaseorder from "./pages/leads/buyer/buyerkhata/adpurchase";
import Purchaseclient from "./pages/leads/buyer/buyerkhata/Purchaseclient";

// Inventory
// './pages/leads/seller/inventory/sproductdef';
import Productdef from "./pages/leads/seller/inventory/productdef";
import Productpricing from "./pages/leads/seller/inventory/productpricing";
import Productslist from "./pages/leads/seller/inventory/productslist";
import Report from "./pages/leads/seller/inventory/reports";
import Salesreturn from "./pages/leads/seller/inventory/salesreturn";
import Stocklevels from "./pages/leads/seller/inventory/stocklevels";
import ProductEditInventory from "./pages/leads/seller/inventory/productEdit";
import Inventory from "./pages/leads/seller/inventory/inventory";
import Purchasereportdetail from "./pages/leads/seller/inventory/purchasereportdetail";
import Salereportdetail from "./pages/leads/seller/inventory/salereportdetail";

// Logistics
import Requestedbooking from "./pages/leads/buyer/warehouse/Bookingrequest";

// Warehouse
import Bookinginvoices from "./pages/leads/buyer/warehouse/Warehousebooking";
// import Receiptl from "./pages/leads/buyer/warehouse/Warehouselist";

// Wallet
import BWallet from "./pages/leads/buyer/wallet/bwallet";
import BWallethistory from "./pages/leads/buyer/wallet/bwallethistory";
import BBenificiary from "./pages/leads/buyer/wallet/bbenificiary";
import BTopup from "./pages/leads/buyer/wallet/btopup";
import BWithdraw from "./pages/leads/buyer/wallet/bwithdraw";

// Office Management
import Employee from "./pages/leads/seller/office/employee";
import Employee_form from "./pages/leads/seller/office/employee_form";
import Emp_update from "./pages/leads/seller/office/update";
import Payroll from "./pages/leads/seller/office/payroll";
import Payroll_form from "./pages/leads/seller/office/payroll_form";
import Officedashboard from "./pages/leads/seller/office/officedashboard";
import Bill from "./pages/leads/seller/office/expense";
import Adbill from "./pages/leads/seller/office/adexpense";
import Adcompany from "./pages/leads/seller/khata/addcompany";

import ChatBox from "./pages/leads/seller/chatbox/chatbox";

import Homepage from "./components/landingpage/Homepage";
import Subcat from "./components/landingpage/Subcat";
import Search from "./components/landingpage/search";
import Main from "./components/landingpage/Main";
// import Product from './components/landingpage/Product';
import Nav from "./components/landingpage/navbar";
import Companypage from "./components/landingpage/companypage";
import Web from "./components/landingpage/website";
import Warehouse from "./components/landingpage/warehouse";
import Book from "./components/landingpage/warehouse_booking";
import Single from "./components/landingpage/singlewarehouse";

import Final from "./components/landingpage/product_page";
import Supplier from "./components/landingpage/supplier";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/vendor" element={<Vendor />} />
          <Route path="/" element={<Home />} />
          <Route path="/main" element={<Mains />} />
          <Route path="/sub" element={<Sub />} />
          <Route path="/vendor-detail" element={<Vendor_Detail />} />
          <Route path="/product" element={<Product_Detail />} />
   
          <Route path="/supplier" element={<Supplier />} />
          
          {/* Profile */}
          {/* <Route path="/" element={<Myrides />} /> */}
          {/* <Route path="/signup" element={<Signup />} /> */}
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/bulkproduct" element={<Bulkproduct />} />
          <Route path="/salereportdetail" element={<Salereportdetail />} />
          <Route path="/purchasereportdetail" element={<Purchasereportdetail />} />
          <Route path="/wareinvoices" element={<Wareinvoices />} />
          <Route path="/requestno" element={<Requestno />} />
          <Route path="/sendrequest" element={<Sendrequest />} />
          <Route path="/sendquote" element={<Sendquote />} />
          <Route path="/trade" element={<Trade />} />
          <Route path="/blogdetail" element={<Blogdetail />} />
          <Route path="/spinner" element={<Spinner />} />
          {/* <Route path="/product" element={<Final />} /> */}
          <Route path="/companydetail" element={<Companypage />} />
          <Route path="/w" element={<Web />} />
          <Route path="/warehouse_management" element={<Warehouse />} />
          <Route path="/single" element={<Single />} />
          <Route path="/book" element={<Book />} />
          <Route path="/old" element={<Homepage />} />
          <Route path="/nav" element={<Nav />} />
          {/* <Route path="/main" element={<Main />} /> */}
          {/* <Route path="/sub" element={<Subcat />} /> */}
          <Route path="/search" element={<Search />} />
          <Route path="/x" element={<Upload />} />

          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotten" element={<Forgotten />} />

          <Route path="/otp" element={<Otp />} />

          <Route path="/seller" element={<Mainpage />} />

          {/* Wallet */}
          <Route path="/wallethistory" element={<Wallethistory />} />
          <Route path="/beni" element={<Benificiary />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/topup" element={<Topup />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/adbenificiary" element={<Adbeneficiary />} />
          {/* Warehouse */}
          <Route path="/warehouse1" element={<Addwarehouse1 />} />
          <Route path="/warehousebook" element={<Warehousebooking />} />
          <Route path="/warehouselist" element={<Warehouselist />} />
          {/* Packages */}
          <Route path="/packages" element={<Packages />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/buypackage" element={<BuyPackageNow />} />
          {/* Add Product */}
          <Route path="/productlist" element={<Productlist />} />
          <Route path="/addproduct" element={<AddProduct />} />
          <Route path="/productlead" element={<ManageLeadProduct />} />
          <Route path="/productedit" element={<ProductEdit />} />
          {/* Buy Leads */}
          <Route path="/buyleads" element={<Buyleads />} />
          {/* Add Catalog */}
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/adcatalog" element={<AdCatalog />} />
          {/* Khata */}
          <Route path="/invoiceprint" element={<InvoicePrint />} />
          <Route path="/history" element={<History />} />
          <Route path="/invoicedraft" element={<Invoicedraft />} />
          <Route path="/purchaseclient" element={<Purchaseclient />} />
          <Route path="/clientaddbuyer" element={<Clientaddbuyer />} />
          <Route path="/clientAddform" element={<ClientAddform />} />
          <Route path="/newinvoice" element={<Newinvoice />} />
          <Route path="/khataclient" element={<Khataclient />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/adddeliverychallan" element={<Adddeliverychallan />} />
          <Route path="/invoiceclient" element={<Invoiceaddclient />} />
          <Route path="/deliveryclient" element={<Deliveryaddclient />} />
          <Route
            path="/khatadeliverychallan"
            element={<Khatadeliverychallan />}
          />
          <Route path="/khatamanageinvoice" element={<Khatamanageinvoice />} />
          <Route path="/clienteditform" element={<ClientEditform />} />
          {/* Matter Sheet  */}
          <Route path="/mattersheetlist" element={<Mattersheetlist />} />
          <Route
            path="/mattersheetdirector"
            element={<Mattersheetdirector />}
          />
          <Route path="/table" element={<Table />} />
          {/* Seller Logistics */}
          <Route path="/request" element={<Request />} />
          <Route path="/bookingreceipt" element={<Bookingreceipt />} />
          <Route
            path="/bookingrequestrider"
            element={<Bookingrequest_byRider />}
          />
          <Route
            path="/bookingrequestuser"
            element={<Bookingrequest_byUser />}
          />
          <Route path="/rebid" element={<Bookingrequest_byUser_rebid />} />

          <Route path="/driver" element={<Driverreg />} />
          {/* Ad-ons */}
          <Route path="/advertisewithus" element={<Advertisewithus />} />
          <Route path="/orderlist" element={<Orderlist />} />
          <Route path="/webmarketing" element={<Webmarketing />} />
          <Route path="/addbalance" element={<Addbalance />} />

          <Route path="/buyer" element={<Mainpage2 />} />
          {/* Buy Request */}
          <Route path="/product-buy-request" element={<Leadsrequest />} />
          {/* Khata */}
          <Route path="/buyerclient" element={<Client />} />
          <Route path="/buyerchallan" element={<Viewchallan />} />
          <Route path="/buyer_invoice" element={<Invoice />} />
          <Route path="/purchaseorder" element={<Purchaseorder />} />
          <Route path="/adpurchaseorder" element={<AdPurchaseorder />} />
          <Route path="/requestedbooking" element={<Requestedbooking />} />
          <Route path="/bookinginvoices" element={<Bookinginvoices />} />
          {/* Inventory */}
          <Route path="/productdef" element={<Productdef />} />
          <Route path="/productpricing" element={<Productpricing />} />
          <Route path="/productslist" element={<Productslist />} />
          <Route path="/report" element={<Report />} />
          <Route path="/sales" element={<Salesreturn />} />
          <Route path="/stocklevels" element={<Stocklevels />} />
          <Route path="/producteditin" element={<ProductEditInventory />} />

          {/* Ewallet */}
          <Route path="/bwallethistory" element={<BWallethistory />} />
          <Route path="/bb" element={<BBenificiary />} />
          <Route path="/bwithdraw" element={<BWithdraw />} />
          <Route path="/bd" element={<BTopup />} />
          <Route path="/bwallet" element={<BWallet />} />
          {/* profile */}
          <Route path="/producte" element={<Myproducts />} />
          <Route path="/user" element={<User />} />
          <Route path="/interest" element={<Productinterest />} />
          <Route path="/newinterest" element={<Productinterestadd />} />
          <Route path="/director" element={<Director />} />
          <Route path="/adddirector" element={<Adddirector />} />
          <Route path="/editdirector" element={<Editdirector />} />
          <Route path="/companyimages" element={<CompanyImages />} />


          <Route path="/newcertificate" element={<Certificate />} />
          <Route path="/certificate" element={<Certificate_view />} />
          <Route path="/additional" element={<Additional />} />
          <Route path="/profile" element={<Profile />} />

          {/* Office Management */}
          <Route path="/employeelist" element={<Employee />} />
          <Route path="/newemployee" element={<Employee_form />} />
          <Route path="/updateemployee" element={<Emp_update />} />
          <Route path="/payrolllist" element={<Payroll />} />
          <Route path="/newpayroll" element={<Payroll_form />} />
          <Route path="/officedashboard" element={<Officedashboard />} />
          <Route path="/expense" element={<Bill />} />
          <Route path="/adexpense" element={<Adbill />} />
          <Route path="/adexpense" element={<Adbill />} />
          <Route path="/company" element={<Adcompany />} />
          <Route path="/chat" element={<ChatBox />} />
          <Route path="/chatboxinvoice" element={<ChatboxInvoice />} />
        </Routes>

        {/* <Route path="/productdefi" element={<Productdefi />} />ChatboxInvoice
          <Route path="/productprice" element={<Productprice />} />
          <Route path="/stocklevel" element={<Stocklevel />} />
          <Route path="/productlists" element={<Productlists />} />
          <Route path="/salesreturns" element={<Salesreturns />} />
          <Route path="/reports" element={<Reports />} /> */}
        {/* Buyer Side */}
      </BrowserRouter>
    </>
  );
}

export default App;
