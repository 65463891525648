import Nav from '../../../navbar';
function Mattersheetdetails() {
  return (
    <body>
      <div id="wrapper">
        <Nav />
        <div id="page-wrapper">
          <div className="bs-example4" data-example-id="simple-responsive-table">
            <div className="col-md-12">
              <div className="xs">
                <h3>Matter Sheet Details</h3>
                <div className="well1 white">
                  <div className="row">
                    <h4 style={{marginBottom: '30px', marginTop: '10px'}}>
                      <u>Personal Information</u>
                    </h4>
                  </div>
                  <div className="row">
                    <div
                      style={{marginBottom: '20px'}}
                      className="col-md-6 grid_box1">
                      <label>Name </label>
                      <input
                        type="text"
                        className="form-control1"
                        placeholder="Enetr Your Name"
                      />
                    </div>

                    <div style={{marginBottom: '20px'}} className="col-md-6">
                      <label>Designation</label>
                      <input
                        type="text"
                        className="form-control1"
                        placeholder="Enter Your Designation"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div
                      style={{marginBottom: '20px'}}
                      className="col-md-6 grid_box1">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control1"
                        placeholder="Enter your Email"
                      />
                    </div>

                    <div style={{marginBottom: '20px'}} className="col-md-6">
                      <label>Contact Number</label>
                      <input
                        type="text"
                        className="form-control1"
                        placeholder="Enter Contact Number"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <h4 style={{marginBottom: '30px', marginTop: '10px'}}>
                      <u>Company Information</u>
                    </h4>
                  </div>
                  <div className="row">
                    <div
                      style={{marginBottom: '20px'}}
                      className="col-md-6 grid_box1">
                      <label>Company Name </label>
                      <input
                        type="text"
                        className="form-control1"
                        placeholder="Enter your Company Name"
                      />
                    </div>

                    <div style={{marginBottom: '20px'}} className="col-sm-6">
                      <label>City</label>
                      <select
                        name="selector1"
                        id="selector1"
                        className="form-control1">
                        <option>Lahore</option>
                        <option>Gujranwala</option>
                        <option>Multan</option>
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div style={{marginBottom: '20px'}} className="col-sm-6">
                      <label>Address</label>
                      <input
                        type="text"
                        className="form-control1"
                        placeholder="Enter Your Address"
                      />
                    </div>
                    <div
                      style={{marginBottom: '20px'}}
                      className="col-md-6 grid_box1">
                      <label>Postal / Zip Code </label>
                      <input
                        type="text"
                        className="form-control1"
                        placeholder="Enter Shipper Address"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div style={{marginBottom: '20px'}} className="col-md-6">
                      <label>ALTERNATE WEBSITE</label>
                      <input
                        type="text"
                        className="form-control1"
                        placeholder="Enter Alternate Website"
                      />
                    </div>
                    <div style={{marginBottom: '20px'}} className="col-md-6">
                      <label>Company Contact No</label>
                      <input
                        type="text"
                        className="form-control1"
                        placeholder="Enter Contact Number"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      style={{marginBottom: '20px'}}
                      className="col-md-6 grid_box1">
                      <label>Year of Establishment </label>
                      <input
                        type="text"
                        className="form-control1"
                        placeholder="Enter Year of Establishment"
                      />
                    </div>
                    <div
                      style={{marginBottom: '20px'}}
                      className="col-md-6 grid_box1">
                      <label>No. Of Employees </label>
                      <input
                        type="text"
                        className="form-control1"
                        placeholder="Enter Number of Employees"
                      />
                    </div>
                  </div>
                  <div style={{marginTop: '30px'}} className="form-group">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                    <button type="reset" className="btn btn-default">
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </body>
  );
}
export default Mattersheetdetails;
