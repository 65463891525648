import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Nav from "../../../navbar";

function Buyleads() {
  const history = useNavigate();
  const [uid, setUid] = useState("");

  const [pname, setPname] = useState("");
  const [pcode, setPcode] = useState("");
  const [bcode, setBcode] = useState("");
  const [pcat, setPcat] = useState("");
  const [psubcat, setPsubcat] = useState("");
  const [bname, setBname] = useState("");
  const [punits, setPunits] = useState("");
  const [pgroup, setPgroup] = useState("");
  const [pconfact, setPconfact] = useState("");
  const [psupp, setPsupp] = useState("");
  const [atype, setAtype] = useState("");
  const [vadditon, setVadditon] = useState("");
  const [ptype, setPtype] = useState("");
  const [ltype, setLtype] = useState("");
  const [tcode, setTcode] = useState("");
  const [pdetails, setPdetails] = useState("");
  const [pdesc, setPdesc] = useState("");
  const [img, setImage] = useState();

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      const array = JSON.parse(localStorage.getItem("ProductListInventory"));
      console.log("edit Product ", array);
      const i = localStorage.getItem("ProductListInventoryID");
      setPcode(array[i].p_code);
      setBcode(array[i].bar_code);
      setPname(array[i].p_name);
      setPcat(array[i].p_category);
      setPsubcat(array[i].p_sub_cat);
      setBname(array[i].brand_name);
      setPunits(array[i].purchase_units);
      setPgroup(array[i].bar_code);
      setPconfact(array[i].p_conv_factor);
      setPsupp(array[i].p_supplier);
      setAtype(array[i].aquire_ype);
      setVadditon(array[i].value_addition);
      setPtype(array[i].purchase_type);
      setLtype(array[i].life_type);
      setTcode(array[i].tax_code);
      setPdetails(array[i].p_details);
      setPdesc(array[i].p_description);

      setPdesc(array[i].p_description);
    }
  }, []);

  const UpdateProduct = async () => {
    const data = new FormData();
    data.append("status", "active");
    data.append("val", "update");
    data.append("uid", uid);
    data.append("pcode", pcode);
    data.append("bcode", bname);
    data.append("pname", pname);
    data.append("pcat", pcat);
    data.append("psubcat", psubcat);
    data.append("bname", bname);
    data.append("punits", punits);
    data.append("pgroup", pgroup);
    data.append("pconfact", pconfact);
    data.append("psupp", psupp);
    data.append("atype", atype);
    data.append("vadditon", vadditon);
    data.append("ptype", ptype);
    data.append("ltype", ltype);
    data.append("tcode", tcode);
    data.append("pdetails", pdetails);
    data.append("pdesc", pdesc);
    data.append("img", img);

    axios({
      method: "post",
      url: "/pro/inventory/submit_productinfo.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        if (s.data[0].code === "pass") {
          //productslist
          history("/productslist");
        }
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  return (
    <body>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>Add Product Info</h3>
                  </div>

                  {/* Form Start */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-title">
                          <h5>Product Details</h5>
                        </div>
                        <div className="card-body">
                          <div className="basic-elements">
                            <div
                              className="row my-4"
                              style={{
                                height: "100px",
                                border: "1px dotted black",
                              }}
                            >
                              <label className="my-2">
                                Product Feature Image Upload
                              </label>
                              <input
                                onChange={(v) => {
                                  setImage(v.target.files[0]);
                                }}
                                type="file"
                              />
                            </div>
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Product Code </label>
                                <input
                                  value={pcode}
                                  disabled
                                  onChange={(v) => {
                                    setPcode(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Bar Code</label>
                                <input
                                  value={bcode}
                                  disabled
                                  onChange={(v) => {
                                    setBcode(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Product Name </label>
                                <input
                                  value={pname}
                                  onChange={(v) => {
                                    setPname(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Product Category</label>
                                <input
                                  value={pcat}
                                  onChange={(v) => {
                                    setPcat(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Product Sub Category</label>
                                <input
                                  value={psubcat}
                                  onChange={(v) => {
                                    setPsubcat(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Brand Name</label>
                                <input
                                  value={bname}
                                  onChange={(v) => {
                                    setBname(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Purchase Units </label>
                                <input
                                  value={punits}
                                  onChange={(v) => {
                                    setPunits(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Product Group</label>
                                <input
                                  value={pgroup}
                                  onChange={(v) => {
                                    setPgroup(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Product Conversion Factor </label>
                                <input
                                  value={pconfact}
                                  onChange={(v) => {
                                    setPconfact(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Product Supplier</label>
                                <input
                                  value={psupp}
                                  onChange={(v) => {
                                    setPsupp(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Aquire Type </label>
                                <input
                                  value={atype}
                                  onChange={(v) => {
                                    setAtype(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Value Addition</label>
                                <input
                                  value={vadditon}
                                  onChange={(v) => {
                                    setVadditon(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Purchase Type </label>
                                <input
                                  value={ptype}
                                  onChange={(v) => {
                                    setPtype(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Life Type</label>
                                <input
                                  value={ltype}
                                  onChange={(v) => {
                                    setLtype(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Tax Code </label>
                                <input
                                  value={tcode}
                                  onChange={(v) => {
                                    setTcode(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Product Details</label>
                                <input
                                  value={pdetails}
                                  onChange={(v) => {
                                    setPdetails(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 form-group">
                                <label>Product Description </label>
                                <input
                                  value={pdesc}
                                  onChange={(v) => {
                                    setPdesc(v.target.value);
                                  }}
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>

                            <div
                              className="btn btn-primary"
                              onClick={() => {
                                UpdateProduct();
                              }}
                            >
                              Save Product
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
