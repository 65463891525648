import {useState, useEffect} from 'react';
import Nav from '../../../navbar';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
function Mainpage() {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState('');
  const [cid, setCid] = useState('');
  const [name, setname] = useState('');
  const [email, setemail] = useState('');
  const [phone, setphone] = useState('');
  const [allclient, setAllClient] = useState([]);
  const [show, setShow] = useState('');
  const [uid, setUid] = useState('');
  const [sheet, setSheet] = useState([
    {
      id: 1,
      from: '',
      to: '',
      billedto: '',
      challandate: '',
      builtyno: '',
      item: '',
      courier: '',
      piece: '',
      weight: '',
      status: 'normal',
    },
  ]);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      setname(v[0].name);
      getallClient(v[0].id);
    }
  }, []);


  const getallClient = async (uid) => {
    const data = new FormData();
    // data.append('uid', uid);
    data.append("uid", uid);
    data.append("val", "view_client");
    

    axios({
      method: "post",
      url: "/pro/khata/manage_khata.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllClient(s.data);
        console.log(s.data);
        // localStorage.setItem("KhataListClient", JSON.stringify(s.data));
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const adchallan = async () => {
    const data = new FormData();
    data.append('frmid', uid);
    data.append('carray', cid );
    data.append('challan_array', JSON.stringify(sheet));
  
    data.append('img', logo);
    data.append('digisig', sign);
    data.append('val', 'add_challan');
   

    axios({
      method: 'post',
      url: 'pro/khata/manage_challan.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);
       
        navigate('/khatadeliverychallan');
        

      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [logo, setLogo] = useState('');
  const [sign, setSign] = useState('');

  const changeArayquote = (i, changes) => {
    const oldArr = [...sheet];
    const oldObj = oldArr[i];
    oldArr[i] = {...oldObj, ...changes};
    setSheet(oldArr);
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Create Delivery Challan </h4>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="basic-elements">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label>From</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="Bazaid Tahir"
                                      readOnly
                                      value={name}
                                    />

                                  </div>

                                
                  <div className="row">
                    <div className="col-12">
                      {" "}
                      <div>
                        <label className="form-group">Billed To </label>
                        <Link to="/deliveryclient">
                          <button className="btn-primary mx-5 px-2">
                            <i className="ti ti-plus px-2"></i>New Client 
                          </button>
                        </Link>
                        <select
                          value={selectedUser}
                          onChange={(v) => {
                            if (v.target.value == "a") {
                            } else {
                              setSelectedUser(v.target.value);
                              setCid(allclient[v.target.value].cid)
                            
                            

                            }
                          }}
                          className="form-control"
                        >
                          {/* <option>Asad</option> */}
                          <option value={"a"}>Select Client</option>

                          {allclient.map((v, i) => (
                            <option key={i} value={i}>
                              {JSON.parse(v.array)[0].name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                  
                  </div>

                  <div style={{ marginTop: "15px" }} className="row">
                    <div className="col-12">
                      {allclient.map((v, i) => (
                        <>
                          {i === Number(selectedUser) ? (
                            <>
                              {" "}
                              <label className="form-group">Contact No</label>
                              <input
                                className="form-control"
                                type="text"
                                disabled
                                value={JSON.parse(v.array)[0].phone}
                              />
                            </>
                          ) : (
                            <> </>
                          )}
                        </>
                      ))}
                    </div>
                    
                  </div>

                           
                                  <div style={{marginTop:"30px"}} className="form-group">
                                    <label>Challan Date</label>
                                    <input
                                      className="form-control"
                                      type="date"
                                      value={sheet[0].challandate}
                                      onChange={e => {
                                        changeArayquote(0, {
                                          challandate: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Items Included </label>
                                    <input
                                      value={sheet[0].item}
                                      onChange={e => {
                                        changeArayquote(0, {
                                          item: e.target.value,
                                        });
                                      }}
                                      className="form-control"
                                      type="text"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>No of Pieces</label>
                                    <input
                                      className="form-control"
                                      type="number"
                                      value={sheet[0].piece}
                                      onChange={e => {
                                        changeArayquote(0, {
                                          piece: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Approx Weight</label>
                                    <input
                                      className="form-control"
                                      type="number"
                                      value={sheet[0].weight}
                                      onChange={e => {
                                        changeArayquote(0, {
                                          weight: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">

                                <div className="form-group">
                                  <label>Attach Company Logo</label>
                                  <input
                                    className="form-control"
                                    type="file"
                                    onChange={e => {
                                      setLogo(e.target.files[0]);
                                    }}
                                  />
                      </div>
                    <div className="form-group">
                      {allclient.map((v, i) => (
                        <>
                          {i === Number(selectedUser) ? (
                            <>
                              
                              <label className="form-group">Email</label>
                              <input
                                disabled
                                className="form-control"
                                type="text"
                                value={JSON.parse(v.array)[0].email}
                              />
                            </>
                          ) : (
                            <> </>
                          )}
                        </>
                      ))}
                    </div>

                    <div className="form-group">
                      {allclient.map((v, i) => (
                        <>
                          {i === Number(selectedUser) ? (
                            <>
                              {" "}
                              <label className="form-group">Address</label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                value={JSON.parse(v.array)[0].add}
                              />
                            </>
                          ) : (
                            <> </>
                          )}
                        </>
                      ))}
                    </div>
                                  <div className="form-group">
                                    <label>Builty Number</label>
                                    <input
                                      className="form-control"
                                      type="number"
                                      value={sheet[0].builtyno}
                                      onChange={e => {
                                        changeArayquote(0, {
                                          builtyno: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Name of Courier</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      value={sheet[0].courier}
                                      onChange={e => {
                                        changeArayquote(0, {
                                          courier: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Attach Digital Signature</label>
                                    <input
                                      className="form-control"
                                      type="file"
                                      onChange={e => {
                                        setSign(e.target.files[0]);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}>
                                  <button onClick={()=>{adchallan()}} className="btn-primary px-3 py-2 mx-3">
                                    Save
                                  </button>
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
