import React from 'react';
import Nav from '../../../navbar';
import axios from 'axios';
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

function Employee() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [designation, setDesignation] = useState('');
  const [department, setDepartment] = useState('');
  const [date, setDate] = useState('');
  const [tax, setTax] = useState('');
  const [pay, setPay] = useState('');
  const [type, setType] = useState('Full Time Employee');
  const [uid, setUid] = useState('');
  const [empid, setEmpid] = useState('');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
    }
  }, []);

  const addemployee = async () => {
    const data = new FormData();
    data.append('val', 'in');
    data.append('name', name);
    data.append('desig', designation);
    data.append('dep', department);
    data.append('joindate', date);
    data.append('pay', pay);
    data.append('uid', uid);
    data.append('emptype', type);
    data.append('emp_id',empid);
    data.append('tax', tax);
    data.append('status', 'active');

    axios({
      method: 'post',
      url: 'pro/office/manage_employe.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);
        navigate('/employeelist');
        //if (s.data[0].code === 'pass') {
        // alert('thanks');
        //}
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  return (
    <>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Add Employees</h4>
                  </div>

                  <div className="card-body">
                    <div className="row ">
                      <div className="col-6 form-group">
                        <label>Employee ID</label>
                        <input
                          value={empid}
                          onChange={v => {
                            setEmpid(v.target.value);
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Employee Name </label>
                        <input
                          value={name}
                          onChange={v => {
                            setName(v.target.value);
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 form-group">
                        <label>Designation </label>
                        <input
                          value={designation}
                          onChange={v => {
                            setDesignation(v.target.value);
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Department </label>
                        <input
                          value={department}
                          onChange={v => {
                            setDepartment(v.target.value);
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 form-group">
                        <label>Monthly Salary </label>
                        <input
                          value={pay}
                          onChange={v => {

                            setPay(v.target.value)

                          }}
                          type="number"
                          className="form-control"
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Tax Amount (%) </label>
                        <input
                          value={tax}
                          onChange={v => {
                            setTax(v.target.value);
                          }}
                          type="number"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 form-group">
                        <label>Employment Type </label>
                        <select
                          value={type}
                          onChange={v => {
                            setType(v.target.value);
                          }}
                          className="form-control">
                          <option>Full Time Employee</option>
                          <option>Part Time Employee</option>
                          <option>Intern</option>
                        </select>
                      </div>
                      <div className="col-6 form-group">
                        <label>Joining Date </label>
                        <input
                          value={date}
                          onChange={v => {
                            setDate(v.target.value);
                          }}
                          type="date"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div>
                      <center>
                        <button
                          onClick={() => {
                            addemployee();
                          }}
                          className="bg-success px-4 py-1 mt-3">
                          Save
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Employee;
