import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import Nav from "../../../navbar";
import { Link } from "react-router-dom";
import { Navigate,  } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
function Mainpage() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState("");
  const [cardno, setCardno] = useState("");
  const [cvv, setCvv] = useState("");


  const [cardamount, setCardamount] = useState("");
  const [easyaccount, setEasyaccount] = useState("");
  const [easyamount, setEasyamount] = useState("");
  const [jazzaccount, setJazzaccount] = useState("");
  const [jazzamount, setJazzamount] = useState("");
  const [uid, setUid] = useState("");
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      viewProduct(v[0].id);
    }
  }, []);

  const viewProduct = async (id) => {
    const data = new FormData();
    data.append("uid", id);
    data.append("val", "view");

    axios({
      method: "post",
      url: "/pro/inventory/submit_productinfo.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("product list ", s.data);
        const a = s.data;
        setProductList(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="mb-5">Inventory Management</h4>
                      {/* <section id="main-content">
              <div className="card">
                <div
                  style={{cursor: 'pointer'}}
                  className="card-title mx-3 my-4">
                  <h3 className="my-3">Inventory Update </h3>
                  <span>Month :<b>November</b></span>
                </div>

                <div id="main-content">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="card">
                        <div className="stat-widget-one">
                          <div className="stat-icon dib">
                            <i className="ti-wallet color-success border-success"></i>
                          </div>
                          <div
                            style={{cursor: 'pointer'}}
                            className="stat-content dib">
                            <div className="card-title">Total Profit</div>

                            <div className="stat-digit">33</div>
                            <p>Month: <b>November</b> </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="card">
                        <div className="stat-widget-one">
                          <div className="stat-icon dib">
                            <i className="ti-layout-grid2 color-pink border-pink"></i>
                          </div>
                          <div
                            style={{cursor: 'pointer'}}
                            className="stat-content dib">
                            <div className="card-title">Total Sale </div>
                            <div className="stat-digit">22</div>
                            <p>Month: <b>November</b> </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="card">
                        <div className="stat-widget-one">
                          <div className="stat-icon dib">
                            <i className="ti-link color-danger border-danger"></i>
                          </div>
                          <div
                            style={{cursor: 'pointer'}}
                            className="stat-content dib">
                            <div className="card-title">Total Purchase</div>
                            <div className="stat-digit">11</div>
                            <p>Month: <b>November</b> </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

            <section id="main-content">
              <div className="card">
              <div
                  style={{cursor: 'pointer'}}
                  className="card-title mx-3 my-4">
                  <h2 className="my-3">Features of Inventory </h2>
                 
                </div>
              <div className="row">
            <div  className="col-lg-3">
              <div   
              style={{cursor:"pointer"}}
              onClick={() => {
            navigate("/productdef");
              }} className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-stats-up"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Product Definition</div>
                      <div className="stat-text"><br/></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div 
                style={{cursor:"pointer"}}
              onClick={() => {
             navigate("/productpricing");
             }} className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Product Pricing</div>
                      <div className="stat-text"><br/></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div  
                style={{cursor:"pointer"}}
              
              onClick={() => {
              navigate("/stocklevels");
              }}
              className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-stats-up"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Stock Levels</div>
                      <div className="stat-text"><br/></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
              <div className="row">
            <div className="col-lg-3">
              <div 

              style={{cursor:"pointer"}}  
              onClick={() => {
                navigate("/productslist");
              }}
              className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-stats-up"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Proucts List</div>
                      <div className="stat-text"><br/></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div
               style={{cursor:"pointer"}}
               onClick={() => {
                navigate("/sales");
              }}
              className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Sales & Return</div>
                      <div className="stat-text"><br/></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div 
              style={{cursor:"pointer"}}
               onClick={() => {
                navigate("/report");
              }}
              className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-stats-up"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Reports</div>
                      <div className="stat-text"><br/></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
                      {/* <div className="row">
                        <div className="col-lg-3">
                          <div className="card">
                            <div className="stat-widget-five">
                              <div className="stat-icon">
                                <i
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate("/productdef");
                                  }}
                                  className="ti-home bg-primary"
                                ></i>
                              </div>
                              <div className="stat-content">
                                <div className="stat-heading color-primary">
                                  <Link to="/productdef">
                                    {" "}
                                    Product Definition
                                  </Link>
                                </div>
                                <div className="stat-text">
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="card">
                            <div className="stat-widget-five">
                              <div className="stat-icon">
                                <i
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate("/productpricing");
                                  }}
                                  className="ti-file bg-success"
                                ></i>
                              </div>
                              <div className="stat-content">
                                <div className="stat-heading color-success">
                                  <Link to="/productpricing">
                                    Product Pricing
                                  </Link>
                                </div>
                                <div className="stat-text">
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="card">
                            <div className="stat-widget-five">
                              <div className="stat-icon">
                                <i
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate("/stocklevels");
                                  }}
                                  className="ti-info bg-danger"
                                ></i>
                              </div>
                              <div className="stat-content">
                                <div className="stat-heading color-primary">
                                  <Link to="/stocklevels">Stock Levels</Link>
                                </div>
                                <div className="stat-text">
                                  {" "}
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="card">
                            <div className="stat-widget-five">
                              <div className="stat-icon bg-warning">
                                <i
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate("/productslist");
                                  }}
                                  className="ti-world"
                                ></i>
                              </div>
                              <div className="stat-content">
                                <div className="stat-heading color-primary">
                                  <Link to="/productslist"> Proucts List</Link>
                                </div>
                                <div className="stat-text">
                                  {" "}
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="card">
                            <div className="stat-widget-five">
                              <div className="stat-icon bg-info">
                                <i
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate("/sales");
                                  }}
                                  className="ti-world"
                                ></i>
                              </div>
                              <div className="stat-content">
                                <div className="stat-heading color-primary">
                                  <Link to="/sales"> Sales & Return</Link>
                                </div>
                                <div className="stat-text">
                                  {" "}
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="card">
                            <div className="stat-widget-five">
                              <div className="stat-icon bg-primary">
                                <i
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate("/report");
                                  }}
                                  className="ti-world"
                                ></i>
                              </div>
                              <div className="stat-content">
                                <div className="stat-heading color-primary">
                                  <Link to="/report">Reports</Link>
                                </div>
                                <div className="stat-text">
                                  {" "}
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      
</div>
</section>

{/* <section id="main-content">
              <div className="card">
              <div style={{justifyContent:"space-around"}}  className="row ">
          
              <div className="col-5 card">
                <h4 className="text-center">Trending Products</h4>
              <div style={{ height: "350px", overflowY: "auto" }} className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                        <tr>
                        
                            <th>Product Code</th>
                            <th>Product Name</th>
                            <th>Product Category</th>
                           
                            <th>Brand Name</th>
                            <th>Purchase Units</th>
                          
                          
                            <th>Total Units</th>
                            <th>Total Amount</th>
                            <th>Stock Levels</th>
                         
                          </tr>
                        </thead>
                        <tbody  >
                          {productList.length === 0 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={10}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {productList.map((v, i) => (
                                <tr>
                                  {v.level== "very low"?<>
                                 
                                  <td>{v.p_code}</td>
                                  <td>{v.p_name}</td>
                                  <td>{v.p_category}</td>
                                  
                                  <td>{v.brand_name}</td>
                                  <td>{v.purchase_units}</td>
                                 
                                 
                                
                                  <td>{v.tunits}</td>
                                  <td>{v.amx}</td>
                                  <td>
                                    {v.level=="high"
                                    ?  <button className="btn-success">{v.level}</button>: v.level=="low"?<button className="btn-warning">{v.level}</button>:v.level=='medium'?<button className="btn-primary">{v.level}</button>:v.level=="very low"?<button className="btn-danger">{v.level}</button>:<></>
                                    
                                    }
                                    
                                  </td>
                               
                                  </>:<></>
                                  }
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
              
              </div>
              <div className="col-5 card">
                <h4 className="text-center">Stock Alerts</h4>
              <div style={{ height: "350px", overflowY: "auto" }} className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                        <tr>
                        
                            <th>Product Code</th>
                            <th>Product Name</th>
                            <th>Product Category</th>
                           
                            <th>Brand Name</th>
                            <th>Purchase Units</th>
                          
                          
                            <th>Total Units</th>
                            <th>Total Amount</th>
                            <th>Stock Levels</th>
                         
                          </tr>
                        </thead>
                        <tbody  >
                          {productList.length === 0 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={10}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {productList.map((v, i) => (
                                <tr>
                                  {v.level== "very low"?<>
                                 
                                  <td>{v.p_code}</td>
                                  <td>{v.p_name}</td>
                                  <td>{v.p_category}</td>
                                  
                                  <td>{v.brand_name}</td>
                                  <td>{v.purchase_units}</td>
                                 
                                 
                                
                                  <td>{v.tunits}</td>
                                  <td>{v.amx}</td>
                                  <td>
                                    {v.level=="high"
                                    ?  <button className="btn-success">{v.level}</button>: v.level=="low"?<button className="btn-warning">{v.level}</button>:v.level=='medium'?<button className="btn-primary">{v.level}</button>:v.level=="very low"?<button className="btn-danger">{v.level}</button>:<></>
                                    
                                    }
                                    
                                  </td>
                               
                                  </>:<></>
                                  }
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
              
              </div>
            
              </div>
              
                </div>
              </section> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
