import Nav from "../../../navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { Navigate, useNavigate, Link } from "react-router-dom";
import { Label } from "recharts";

import moment from "moment";

function Buyleads() {
  const navigate = useNavigate();
  const [uid, setUid] = useState("");
  const [purchase, setPurchase] = useState([]);
  const [sold, setSold] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      Purchase(v[0].id);
      Inventory0(v[0].id)
      Sold(v[0].id);
    }
  }, []);


  const Inventory = async (t,f) => {
    const data = new FormData();
    data.append("uid", uid);
    data.append("val", "tp_date");
     data.append("to", t)
     data.append("from", f)

    axios({
      method: "post",
      url: "/pro/inventory/manage_reports.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("product Inventory list ", s.data);
        const a = s.data;
        setInventory(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  const Inventory0 = async (id) => {
    const data = new FormData();
    data.append("uid", id);
    data.append("val", "all");

    axios({
      method: "post",
      url: "/pro/inventory/manage_reports.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("product Inventory list ", s.data);
        const a = s.data;
        setInventory(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  const Sold = async (d) => {
    const data = new FormData();
    data.append("uid", d);
    data.append("val", "sale");

    axios({
      method: "post",
      url: "/pro/inventory/manage_reports.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("sale invoices ", s.data);
        const a = s.data;
        setSold(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const Purchase = async (id) => {
    const data = new FormData();
    data.append("uid", id);
    data.append("val", "purchasing");
    axios({
      method: "post",
      url: "/pro/inventory/manage_reports.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("Purchase Product list ", s.data);
        const a = s.data;
        setPurchase(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };


  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}
            
            <div style={{marginLeft:"20px" , marginTop:"10px"}} >
            <Link to="/inventory">
            <button
            className="btn-secondary px-2"  >
            <i style={{paddingRight:"10px"}} className="ti-arrow-left"></i>
              Back
            </button>
            </Link>
            </div>

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">

                <div className="card">
                  <div   style={{ height: "280px", overflowY: "auto" }} className="card-body">
                  <div className="card-title text-center">
                    <h4>Inventory Reports </h4>
                  </div>
                  <div className="text-right">
                  <div >
                  <label className="mx-2" >From:  </label>
                  <input type='date' value={to} onChange={(v)=>{
                    setTo(v.target.value)
                  }}/>
                  </div>
                  <div >
                  <label className="mx-2" >To:  </label>
                  <input type='date' value={from} onChange={(v)=>{
                    setFrom(v.target.value)
                  }}/>
                  </div>
                  <button onClick={()=>{
                    if(to!=="" && from!== ""){
                      Inventory(to,from);
                    }
                     
                  }} className="btn-success">Search</button>
                  </div>
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Sale Amount</th>
                            <th>Sale Units</th>
                            <th>Profit</th>
                            {/* <th>Details</th> */}
                            {/* <th>Total Units</th> */}
                           
                          </tr>
                        </thead>
                        <tbody  style={{backgroundColor:"#343957", color:"white"}}>
                        
                          {inventory.length === 0 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={13}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>
                            </>
                          ) : (
<>
{inventory.map((v,i)=>(
<tr>
<td style={{ color:"white"}}> {i+1}</td>
<td  style={{ color:"white"}} > {v.sale_amount}</td>
<td  style={{ color:"white"}}> {v.sale_units}</td>
<td  style={{ color:"white"}}>  {v.profit}</td>




</tr>

))}
</>


                          )}
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  
                
                  <div   style={{ height: "530px", overflowY: "auto" }} className="card-body">
                  <div className="card-title text-center">
                    <h4> Purchases Products Record</h4>
                  </div>
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>prod. ID</th>
                            <th>Product Name</th>
                            <th>Total Amount</th>
                            <th>Total Units</th>
                            <th>Details</th>
                            {/* <th>Total Units</th> */}
                           
                          </tr>
                        </thead>
                        <tbody>
                        
                          {purchase.length === 0 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={13}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>
                            </>
                          ) : (
<>
{purchase.map((v,i)=>(
<tr>
<td> {v.pid}</td>
<td> {v.pname}</td>
<td> {v.amount}</td>
<td> {v.units}</td>
<td> <button onClick={()=>{
  localStorage.setItem(
    "pid",
    v.pid
  );
  navigate('/purchasereportdetail')
}} className="btn-primary " >Purchase Detail</button></td>

</tr>

))}
</>


                          )}
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  
                
                  <div   style={{ height: "530px", overflowY: "auto" }} className="card-body">
                  <div className="card-title text-center">
                    <h4> Sold Products Record</h4>
                  </div>
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Product Name</th>
                            <th>Total Amount</th>
                            <th>Time</th>
                            <th>Details</th>
                            {/* <th>Total Units</th> */}
                           
                          </tr>
                        </thead>
                        <tbody>
                        
                          {sold.length === 0 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={13}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>
                            </>
                          ) : (
<>
{sold.map((v,i)=>(
<tr>
<td> {v.id}</td>

<td> {v.name}</td>
<td> {v.amount}</td>
<td> {v.time}</td>
<td> <button onClick={()=>{
  localStorage.setItem(
    "sid",
    v.id
  );
  navigate('/salereportdetail')
}} className="btn-primary " >Sell Detail</button></td>



</tr>

))}
</>


                          )}
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
