import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Nav from '../../../navbar';
function Buyleads() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [lead, setLead] = useState([]);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      lead_view_all(v[0].id);
    }
  }, []);

  const checkvalidity = (lead_id) => {
    
    
    localStorage.setItem(
      'leadid',
      lead_id,
    );
    // navigate('/chat');
    navigate('/sendquote');
  }



  const lead_view_all = async id => {
    const data = new FormData();
    data.append('uid', id);

    axios({
      method: 'post',
      url: '/pro/lead_view_all.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('lead list ', s.data);
        const a = s.data;
        setLead(a);
      })
      .catch(s => {
        console.log('here lead_view_all ', s);
      });
  };

  // const viewProduct = async id => {
  //   const data = new FormData();
  //   data.append('uid', id);
  //   data.append('val', 'view');

  //   axios({
  //     method: 'post',
  //     url: '/pro/inventory/submit_productinfo.php',
  //     data: data,
  //     headers: {'Content-Type': 'multipart/form-data'},
  //   })
  //     .then(s => {
  //       console.log('product list ', s.data);
  //       const a = s.data;
  //       if (a[0].code != 'fail') {
  //         setProductList(a);
  //         localStorage.setItem('ProductListInventory', JSON.stringify(s.data));
  //       }
  //     })
  //     .catch(s => {
  //       console.log('here catch =>>', s);
  //     });
  // };

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Product Buy Requests</h4>
                  </div>
                  {/* <div className="row">
                    <div className="text-right">
                      <input
                        style={{borderRadius: '18px', paddingLeft: '8px'}}
                        placeholder="search"
                      />
                      <span>
                        <button
                          style={{borderRadius: '18px', color: 'white'}}
                          className="bg-primary mx-3 px-4 ">
                          Search
                        </button>
                      </span>
                    </div>
                  </div> */}
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>

                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Unit</th>
                            <th>Description</th>
                            <th>Time</th>
                          

                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lead.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {lead.map((v, i) => (
                                <tr>
                                  <td>{v.id}</td>
                                  <td>{JSON.parse(v.sheet)[0].name} </td>
                                  <td>{JSON.parse(v.sheet)[0].qty} </td>
                                  <td>{JSON.parse(v.sheet)[0].price} </td>
                                  <td>{JSON.parse(v.sheet)[0].piece} </td>
                                  <td>{JSON.parse(v.sheet)[0].des} </td>
                                  <td>{v.time} </td>
                                  {/* <td>
                                    <a
                                      href={v.file}
                                      target="_blank"
                                      download={v.lead_id}>
                                      Download{' '}
                                    </a>{' '}
                                  </td> */}
                                 

                                  <td>
                                    <button
                                      style={{borderRadius: '14px'}}
                                      className="btn-primary"
                                      onClick={() => {
                                        checkvalidity( v.lead_id)
                                      
                                      }}>
                                     Send Quote
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
