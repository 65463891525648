import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import { Robot } from 'react-bootstrap-icons';

function Mainpage() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [editid, setEditID] = useState('');
  

  const [name, setName] = useState('');
  const [designation, setdesignation] = useState('');
  const [description, setdescription] = useState('');
  const [alert2, setAlert2] = useState('');

  const [img, setImage] = useState('');
  const [path, setPath] = useState('');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);

   const data= JSON.parse(localStorage.getItem("editdri"));
   console.log(data)
   setEditID(data.id)
   setName(data.name)
   setdesignation(data.designation)
   setdescription(data.description)
   setPath(data.img)
      
      
    }
  }, []);



 
  const EditDirectorWithoutImg = async () => {
    const data = new FormData();
    data.append('did', editid);
    data.append('uid', uid);
    data.append('name', name);
    data.append('designation', designation);
    data.append('description', description);
    data.append('img', "");
    axios({
      method: 'post',
      url: '/pro/profile/update_profile_director.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
       
      
          
        navigate('/director');
        
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const EditDirectorWithImg = async () => {
    const data = new FormData();
    data.append('did', editid);
    data.append('uid', uid);
    data.append('name', name);
    data.append('designation', designation);
    data.append('description', description);
    data.append('img', img);
    axios({
      method: 'post',
      url: '/pro/profile/update_profile_director.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        if (s.data[0].code === 'updated2') {
          navigate('/director');
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <div className="card">
              <div className="card-title">
                <h4>Update Director</h4>
              </div>

              <div className="row">
                {' '}
                <div className="col-6 form-group">
                  <label>Name {editid} </label>
                  <input
                    className="form-control"
                    type="text"
                    value={name}
                    onChange={v => {
                      setName(v.target.value);
                    }}
                  />
                </div>
                <div className="col-6 form-group">
                  <label>Designation</label>
                  <input
                    className="form-control"
                    type="text"
                    value={designation}
                    onChange={v => {
                      setdesignation(v.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 form-group">
                  <label>Description</label>
                  <textarea
                  maxLength={500}
                    value={description}
                    onChange={v => {
                      setdescription(v.target.value);
                    }}
                    className="form-control"
                    rows={4}></textarea>
                </div>

                <div className="col-6 form-group">
                  <label>Image</label>
                  <img style={{margin:10}} height={"100"} width="100" src={path!=""?path:img} />
                  <input
                    className="form-control"
                    onChange={v => {
                      setImage(v.target.files[0]);
                    }}
                    type="file"
                  />
                </div>
              </div>

              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
                className="form-group">
                <button
                  className=" btn btn-primary px-5 mt-4"
                  onClick={() => {
                    if(name.length<2 || name.length > 50)
                                    {
                                      alert("Enter Proper Name. Name Length must be more than 3 and less than 50 characters")
                                    }else if(designation.length<2 || designation.length > 50){
                                      alert("Enter Proper Designation Name. Designation Length must be more than 3 and less than 50 characters")
                                    } else if(description.length<6){
                                      alert("Enter Proper Description")
                                    }else{ 


                                    if(img!='')
                                    {
                                        EditDirectorWithImg()

                                    }else{
                                        EditDirectorWithoutImg()
                                    }


                                    }
                
                  }}>
                  Update Now
                </button>
                {alert2 ? (
                  <>
                    
                    <SweetAlert
                      success
                      confirmBtnText="Thanks !"
                      confirmBtnBsStyle="success"
                      timeout={2000}
                      title="Director Saved"
                      onConfirm={() => {
                        setAlert2(!alert2);
                        
                        
                      }}
                      onCancel={() => {
                        setAlert2(!alert2);
                      }}
                      focusCancelBtn>
                      <br />
                    </SweetAlert>
                  </>
                ) : (
                  <></>
                )}
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
