import React from 'react';
import Nav from '../../../navbar';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

import {useState, useEffect} from 'react';
function Employee() {
  const navigate = useNavigate();
  const [product, setproduct] = useState('');
  const [name, setName] = useState('');
  const [billno, setBillno] = useState('');
  const [amount, setAmount] = useState('');
  const [file, setFile] = useState('');
  const [desc, setDesc] = useState('');
  const [uid, setUid] = useState('');
  const [month, setMonth] = useState('');
  const [date, setDate] = useState('');

  const addpayroll = async () => {
    const data = new FormData();
    data.append('val', 'in');
    data.append('uid', uid);
    data.append('status', 'active');
    data.append('name', name);
    data.append('billno', billno);
    data.append('expam', amount);
    // data.append('file', file);
    data.append('month', month);
    data.append('des', desc);
    data.append('date', date);
    data.append('img', file);

    axios({
      method: 'post',
      url: 'pro/office/manage_expense.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);
        navigate('/expense');
        //if (s.data[0].code === 'pass') {
        // alert('thanks');
        //}
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      getProduct(v[0].id);
    }
  }, []);
  const getProduct = async uid => {
    const data = new FormData();
    data.append('val', 'view');
    data.append('uid', uid);

    axios({
      method: 'post',
      url: 'pro/office/manage_employe.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('product list ', s.data);
        setproduct(s.data);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  return (
    <body>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Add Expense</h4>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-6 form-group">
                        <label>Expense Name</label>
                        <input
                          value={name}
                          onChange={v => {
                            setName(v.target.value);
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Bill No</label>
                        <input
                          value={billno}
                          onChange={v => {
                            setBillno(v.target.value);
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 form-group">
                        <label>Expense Month</label>
                        <select
                          value={month}
                          onChange={v => {
                            setMonth(v.target.value);
                          }}
                          className="form-control">
                          <option>January</option>
                          <option>February</option>
                          <option>March</option>
                          <option>April</option>
                          <option>May</option>
                          <option>June</option>
                          <option>July</option>
                          <option>August</option>
                          <option>September</option>
                          <option>October</option>
                          <option>November</option>
                          <option>December</option>
                        </select>
                      </div>
                      <div className="col-6 form-group">
                        <label>Date</label>
                        <input
                          value={date}
                          onChange={v => {
                            setDate(v.target.value);
                          }}
                          type="date"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-6 form-group">
                        <label>Expense Amount</label>
                        <input
                          type="text"
                          value={amount}
                          onChange={v => {
                            setAmount(v.target.value);
                          }}
                          className="form-control"
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Attach File</label>
                        <input
                          
                          onChange={v => {
                            setFile(v.target.files[0]);
                          }}
                          type="file"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 form-group">
                        <label>Description</label>
                        <input
                          rows={10}
                          value={desc}
                          onChange={v => {
                            setDesc(v.target.value);
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div>
                      <center>
                        <button
                          onClick={() => {
                            addpayroll();
                          }}
                          className="bg-success px-4 py-1 mt-3">
                          {' '}
                          Save
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Employee;
