import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {
  PlusCircleFill,
  Trash,
  Display,
  CircleFill,
} from 'react-bootstrap-icons';
import Nav from '../../../navbar';

function Mainpage(navigation) {
  const navigate = useNavigate();
  const [allclient, setAllClient] = useState([]);
  const [uid, setUid] = useState('');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      getallClient(v[0].id);
    }
  }, []);

  const deletekahat = async pid => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('pid', pid);
    data.append('val', 'delete');

    axios({
      method: 'post',
      url: '/pro/khata/khata_view_clients.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('status list ', s.data.code);
        getallClient(uid);

        //setAllClient(a)
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  // useEffect(() => {
  //   const v = JSON.parse(localStorage.getItem('USER'));
  //   if (v === null) {

  //   } else {
  //     setUid(v[0].id)
  //   }
  // }, []);

  const getallClient = async (uid) => {
    const data = new FormData();
    
    data.append("uid", uid);
    data.append("val", "view_client");
    
    
    axios({
      method: "post",
      url: "/pro/khata/manage_khata.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setAllClient(s.data);
        console.log("history",s.data);
       
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
 
  

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Manage Clients</h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                      {/* <input
                        style={{borderRadius: '18px', paddingLeft: '8px'}}
                        placeholder="search"
                      /> */}
                      <span>
                        <Link to="/clientAddform">
                        <button
                          style={{borderRadius: '18px', color: 'white'}}
                          className="bg-primary mx-3 px-4 ">
                         
                       
                          Add Client
                          
                        </button>
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                        
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>Client Balance</th>
                            
                            <th>Settle Up</th>

                            {/* <th>Controls</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {allclient.length == 0 ? (
                            <tr>
                              <td
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  textAlign: 'center',
                                }}
                                colSpan={10}>
                                No Record to show{' '}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}

                          {allclient.map((v, i) => (
                            <>
                            
{v.balance==0?
 <tr 
                             
 
>
  <td>{i + 1}</td>
  <td>{JSON.parse(v.array)[0].name}</td>
  <td>{JSON.parse(v.array)[0].email}</td>
  <td>{JSON.parse(v.array)[0].phone}</td>
  <td>{JSON.parse(v.array)[0].add}</td>
                                 
   <td>{v.balance}</td>

   <td>
                                  <button
                                    className="btn-primary"
                                    onClick={() => {
                                    
                                      localStorage.setItem('cid', v.cid);
                                      localStorage.setItem('cname', JSON.parse(v.array)[0].name);
                                      // localStorage.setItem(
                                      //   'array',
                                      //   JSON.parse(v.aray)[0].name,
                                      // );
                                      navigate('/addbalance');
                                    }}>
                                    Pay Amount
                                  </button>
                                </td>



  {/* <td>
    {' '}
    <label
      style={{color: 'blue'}}
      onClick={() => {
        localStorage.setItem(
          'KhataListClientID',
          i,
        );
        navigate('/clienteditform');
      }}>
      Edit
    </label>{' '}
    |{' '}
    <label
      style={{color: 'red'}}
      onClick={() => {
        deletekahat(v.id);
      }}>
      Delete
    </label>
  </td> */}
</tr>: v.balance>0?
 <tr style={{backgroundColor:"#dcffdb"}}>
                               <td style={{color:"black"}}>{i + 1}</td>
                               <td style={{color:"black"}}>{JSON.parse(v.array)[0].name}</td>
                               <td style={{color:"black"}}>{JSON.parse(v.array)[0].email}</td>
                               <td style={{color:"black"}}>{JSON.parse(v.array)[0].phone}</td>
                               <td style={{color:"black"}}>{JSON.parse(v.array)[0].add}</td>
                                <td style={{color:"black"}}>{new Intl.NumberFormat('en-pk').format(v.balance)}</td>
                               
                                 
                                <td>
                                  <button
                                    className="btn-success"
                                    onClick={() => {
                                    
                                      localStorage.setItem('cid', v.cid);
                                      localStorage.setItem('cname', JSON.parse(v.array)[0].name);
                                      // localStorage.setItem(
                                      //   'array',
                                      //   JSON.parse(v.aray)[0].name,
                                      // );
                                      navigate('/addbalance');
                                    }}>
                                    Pay Amount
                                  </button>
                                </td>
                             
                             
                               {/* <td>
                                 {' '}
                                 <label
                                   style={{color: 'blue'}}
                                   onClick={() => {
                                     localStorage.setItem(
                                       'KhataListClientID',
                                       i,
                                     );
                                     navigate('/clienteditform');
                                   }}>
                                   Edit
                                 </label>{' '}
                                 |{' '}
                                 <label
                                   style={{color: 'red'}}
                                   onClick={() => {
                                     deletekahat(v.id);
                                   }}>
                                   Delete
                                 </label>
                               </td> */}
                             </tr>:v.balance<0?<>
                              <tr style={{backgroundColor:"#ffb9b6", }}
                             
 
                              >
                                <td style={{color:"black"}}>{i + 1}</td>
                                <td style={{color:"black"}}>{JSON.parse(v.array)[0].name}</td>
                                <td style={{color:"black"}}>{JSON.parse(v.array)[0].email}</td>
                                <td style={{color:"black"}}>{JSON.parse(v.array)[0].phone}</td>
                                <td style={{color:"black"}}>{JSON.parse(v.array)[0].add}</td>
                                 <td style={{color:"black"}}>{new Intl.NumberFormat('en-pk').format(v.balance)}</td>
                                 <td onClick={()=>{
                                  localStorage.setItem('cid', v.cid);
                                  navigate('/history')}} style={{color:"black", cursor:"pointer"}}><i className='ti ti-time'></i></td>
                               
                                 <td>
                                  <button
                                    className="btn-secondary"
                                    onClick={() => {
                                    
                                      localStorage.setItem('cid', v.cid);
                                      localStorage.setItem('cname', JSON.parse(v.array)[0].name);
                                      // localStorage.setItem(
                                      //   'array',
                                      //   JSON.parse(v.aray)[0].name,
                                      // );
                                      navigate('/addbalance');
                                    }}>
                                    Pay Amount
                                  </button>
                                </td>
                              
                              
                                {/* <td>
                                  {' '}
                                  <label
                                    style={{color: 'blue'}}
                                    onClick={() => {
                                      localStorage.setItem(
                                        'KhataListClientID',
                                        i,
                                      );
                                      navigate('/clienteditform');
                                    }}>
                                    Edit
                                  </label>{' '}
                                  |{' '}
                                  <label
                                    style={{color: 'red'}}
                                    onClick={() => {
                                      deletekahat(v.id);
                                    }}>
                                    Delete
                                  </label>
                                </td> */}
                              </tr>
                              </>:<></>
                              
}
      
                            
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
