import {useState} from 'react';
import {Card} from 'react-bootstrap';
import Nav from '../../../navbar';
import {Link} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
function Mainpage() {
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="mb-5">Office Management</h4>

                      <div className="row">
                        <div className="col-lg-3">
                          <div className="card">
                            <div className="stat-widget-five">
                              <div className="stat-icon">
                                <i className="ti-home bg-primary"></i>
                              </div>
                              <div className="stat-content">
                                <div className="stat-heading color-primary">
                                  <Link to="/employee">Manage Employee</Link>
                                </div>
                                <div className="stat-text">
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="card">
                            <div className="stat-widget-five">
                              <div className="stat-icon">
                                <i className="ti-file bg-success"></i>
                              </div>
                              <div className="stat-content">
                                <div className="stat-heading color-success">
                                  <Link to="/productprice">
                                    Leaves & Attendance
                                  </Link>
                                </div>
                                <div className="stat-text">
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="card">
                            <div className="stat-widget-five">
                              <div className="stat-icon">
                                <i className="ti-info bg-danger"></i>
                              </div>
                              <div className="stat-content">
                                <div className="stat-heading color-primary">
                                  <Link to="/stocklevel">
                                    Bills & Expenditures
                                  </Link>
                                </div>
                                <div className="stat-text">
                                  {' '}
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="card">
                            <div className="stat-widget-five">
                              <div className="stat-icon bg-warning">
                                <i className="ti-world"></i>
                              </div>
                              <div className="stat-content">
                                <div className="stat-heading color-primary">
                                  <Link to="/payroll"> Payroll Record </Link>
                                </div>
                                <div className="stat-text">
                                  {' '}
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="card">
                            <div className="stat-widget-five">
                              <div className="stat-icon bg-info">
                                <i className="ti-world"></i>
                              </div>
                              <div className="stat-content">
                                <div className="stat-heading color-primary">
                                  <Link to="/salesreturns">
                                    {' '}
                                    Sales & Return
                                  </Link>
                                </div>
                                <div className="stat-text">
                                  {' '}
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="card">
                            <div className="stat-widget-five">
                              <div className="stat-icon bg-primary">
                                <i className="ti-world"></i>
                              </div>
                              <div className="stat-content">
                                <div className="stat-heading color-primary">
                                  <Link to="/reports">Reports</Link>
                                </div>
                                <div className="stat-text">
                                  {' '}
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
