import {useState} from 'react';
import Nav from '../../../navbar';
import SweetAlert from 'react-bootstrap-sweetalert';
function Mainpage() {
  const [alert1, setAlert1] = useState('');
  const [cardno, setCardno] = useState('');
  const [cvv, setCvv] = useState('');
  const [cardamount, setCardamount] = useState('');
  const [easyaccount, setEasyaccount] = useState('');
  const [easyamount, setEasyamount] = useState('');
  const [jazzaccount, setJazzaccount] = useState('');
  const [jazzamount, setJazzamount] = useState('');
  const [msg, setMsg] = useState('');
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="mb-5">Add Money </h4>
                      {/* <!-- Nav tabs --> */}
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          {' '}
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#home"
                            role="tab">
                            <span className="hidden-sm-up">
                              <i className="ti-home"></i>
                            </span>{' '}
                            <span className="hidden-xs-down">JazzCash</span>
                          </a>{' '}
                        </li>
                        <li className="nav-item">
                          {' '}
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#profile"
                            role="tab">
                            <span className="hidden-sm-up">
                              <i className="ti-user"></i>
                            </span>{' '}
                            <span className="hidden-xs-down">EasyPaisa</span>
                          </a>{' '}
                        </li>
                        <li className="nav-item">
                          {' '}
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#messages"
                            role="tab">
                            <span className="hidden-sm-up">
                              <i className="ti-email"></i>
                            </span>{' '}
                            <span className="hidden-xs-down">Master Card</span>
                          </a>{' '}
                        </li>
                      </ul>
                      {/* <!-- Tab panes --> */}
                      <div className="tab-content tabcontent-border">
                        <div className="tab-pane active" id="home" role="tabpanel">
                          <div className="p-20">
                            <div className="content-wrap">
                              <div className="main">
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="card">
                                        <div className="card-title">
                                          <h4>By JazzCash</h4>
                                        </div>
                                        <div className="card-body">
                                          <div className="basic-form">
                                            <div className="form-group">
                                              <label>
                                                Jazzcash Account No.
                                              </label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter Account Number"
                                                value={jazzaccount}
                                                onChange={v => {
                                                  setJazzaccount(
                                                    v.target.value,
                                                  );
                                                }}
                                              />
                                            </div>
                                            <div className="form-group">
                                              <label>Amount</label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter Amount"
                                                value={jazzamount}
                                                onChange={v => {
                                                  setJazzamount(v.target.value);
                                                }}
                                              />
                                            </div>

                                            <button 
                                            onClick={()=>{
                                              if (jazzaccount === '' || jazzamount === ''){
                                                alert("Please Fill the Exact Form")
                                              }else {
                                                alert("please Attach the Bank API")
                                              }
                                            }}
                                              type="submit"
                                              className="btn btn-primary">
                                              Send
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane  p-20"
                          id="profile"
                          role="tabpanel">
                          <div className="content-wrap">
                            <div className="main">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="card">
                                      <div className="card-title">
                                        <h4>By EasyPaisa</h4>
                                      </div>
                                      <div className="card-body">
                                        <div className="basic-form">
                                          <div className="form-group">
                                            <label>Easypaisa Account No.</label>
                                            <input
                                              type="number"
                                              className="form-control"
                                              placeholder="Enter Account Number"
                                              value={easyaccount}
                                              onChange={v => {
                                                setEasyaccount(v.target.value);
                                              }}
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label>Amount</label>
                                            <input
                                              type="number"
                                              className="form-control"
                                              placeholder="Enter Amount"
                                              value={easyamount}
                                              onChange={v => {
                                                setEasyamount(v.target.value);
                                              }}
                                            />
                                          </div>

                                          <button

onClick={()=>{
  if (easyaccount === '' || easyamount === ''){
    alert("Please Fill the Exact Form")
  }else {
    alert("please Attach the Bank API")
  }
}}
                                            type="submit"
                                            className="btn btn-primary">
                                            Send
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane p-20"
                          id="messages"
                          role="tabpanel">
                          <div className="content-wrap">
                            <div className="main">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="card">
                                      <div className="card-title">
                                        <h4>By Master card</h4>
                                      </div>
                                      <div className="card-body">
                                        <div className="basic-form">
                                          <div className="form-group">
                                            <label>Card Number</label>
                                            <input
                                              type="number"
                                              className="form-control"
                                              placeholder="Enter Card Number"
                                              value={cardno}
                                              onChange={v => {
                                                setCardno(v.target.value);
                                              }}
                                            />
                                          </div>
                                          <div className="row">
                                            <div className="col-6 form-group">
                                              <label>Cvv</label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter Cvv"
                                                value={cvv}
                                                onChange={v => {
                                                  setCvv(v.target.value);
                                                }}
                                              />
                                            </div>
                                            <div className="col-6 form-group">
                                              <label>Amount</label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter Amount"
                                                value={cardamount}
                                                onChange={v => {
                                                  setCardamount(v.target.value);
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <button

onClick={()=>{
  if (cardno === '' || cvv === ''  || cardamount === ''){
    alert("Please Fill the Exact Form")
  }else {
  alert("Please Attach Bank Api")
  }}}
                                            type="submit"
                                            className="btn btn-primary">
                                            Send
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <!-- row -->
							<!-- row --> */}

            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Top up History</h4>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Bank Name</th>
                            <th>Account Number</th>
                            <th>Account Title</th>
                            <th>Amount</th>
                            <th>Description</th>

                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {' '}
                            <td
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                              }}
                              colSpan={10}>
                              No Record to show{' '}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
