import React, { useState } from "react";
import axios from "axios";
import Nav from "../../../../components/landingpage/navbar";
import Footer from "../../../../components/landingpage/footer";
import { useEffect, useRef } from "react";
import { json, useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue, onValueChange } from "firebase/database";

// import database from '@react-native-firebase/database';
// import { firebase } from '@react-native-firebase/database';
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

function App() {
  const navigate = useNavigate();
  const [uid, setUid] = useState("");
  const [msg, setMsg] = useState("");
  const [allviewMsg, setAllViewMsg] = useState([]);
  const [file, setFile] = useState('');

  const [chat, setChat] = useState([]);
  const [chatid, setChatid] = useState("");
  const [roll, setroll] = useState("");
  const [senderid, setsenderid] = useState("");
  const [uiduser, setuiduser] = useState("");
  const [Chatid_NotficationRCV, setChatid_NotficationRCV] = useState("");
  // Firebase Work

  const firebaseConfig = {
    apiKey: "AIzaSyCNThPGDzsQTuxrGZYBuG-H8yCK4CTg1Jo",
    authDomain: "alahdeen-24173.firebaseapp.com",
    databaseURL: "https://alahdeen-24173-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "alahdeen-24173",
    storageBucket: "alahdeen-24173.appspot.com",
    messagingSenderId: "298890559548",
    appId: "1:298890559548:web:6fda9c54ae5e295d868339",
    measurementId: "G-6Q2NLSNF9T"
  };
  const app = initializeApp(firebaseConfig);

  const db = getDatabase(app);

  const saveToFirebaseDatabse = async (msg) => {
    const chatid = msg[0].cid;
    const id = msg[0].id;
    const send = msg[0].sendby;
    const mess = msg[0].mesg;
    const date = msg[0].date;
    const status = msg[0].status;
    const time = msg[0].time;
    const type = msg[0].type;

    const db = getDatabase(app);
    set((ref(db, '/chat/' + chatid + '/' + id)), {
      id: id,
      chat_id: chatid,
      sent_by: send,
      message: mess,
      date: date,
      status: status,
      time: time,
      type: type,
    });

  }

  const LoadChat = async (as) => {
    const data = new FormData();
    data.append("status", 'view');
    data.append("cid", as);

    axios({
      method: "post",
      url: "/mainsite/chatbox/chat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("chat list ", s.data);
        const a = s.data;
        if (a[0].code === "pass") {
          //setMessages(json)
        }
      })
      .catch((s) => {
        if (s.message == "Network Error") {
          console.log("network");

        } else {
          console.log("network");
        }
      });
  };
  // const reference = firebase
  // .app()
  // .database('https://alahdeen-24173-default-rtdb.asia-southeast1.firebasedatabase.app/')
  // .ref('/chat');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));

    if (v === null) {
    } else {
      setUid(v[0].id);
      const uiduser = localStorage.getItem("chatuserid");
      const chatid = localStorage.getItem("piduser");
      if (chatid === "") {
        // alert("null");
      } else {
        // alert(puiduser);
        setAllViewMsg([]);
        setuiduser(uiduser);
        viewchat(v[0].id, uiduser);
        Viewchatbox(v[0].id);
        setroll(v[0].roll);
        getFirebasechat(chatid);
      }
      console.log(uiduser);
    }
  }, []);

  const viewchat = async (id, i) => {
    const data = new FormData();
    data.append("one", id);
    data.append("two", i);

    axios({
      method: "post",
      url: "/pro/chatbox/chatbox.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("chat list ", s.data);
        const a = s.data;
        if (a[0].code == "pass") {
          // setProductList(a);
          localStorage.setItem("List abc", JSON.stringify(s.data));
        }
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  const getFirebasechat = (id) => {
    setAllViewMsg([]);
    const db = getDatabase(app);

    const chatref = ref(db, `/chat/${id}`);
    onValue(chatref, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists() && data != null) {
        const as = Object.values(snapshot.val());

        setAllViewMsg(as.reverse());
        console.log("firebase chat", Object.values(snapshot.val()));
      } else {
        console("No Chat found with that client...");
      }
      //updateStarCount(postElement, data);
    });
  }

  const Viewchatbox = async (id) => {
    const data = new FormData();
    data.append("uid", id);

    axios({
      method: "post",
      url: "/mainsite/chatbox/view_inbox.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("chatbox user List view ", s.data);
        const a = s.data;
        setChat(a);

        if (a.length > 0) {
          console.log("chatid testing viewbox ", a[0].chat)
          getFirebasechat(a[0].chat);
          setChatid(a[0].chat);
        }


      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const chatinbox = async (chatid) => {
    setMsg("");
    const data = new FormData();
    data.append("uid", uid);
    data.append("status", "in");
    data.append("cid", chatid);
    data.append("msg", msg);
    data.append("type", "message");

    axios({
      method: "post",
      url: "/mainsite/chatbox/chat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("chatbox message ", s.data);
        const a = s.data;
        saveToFirebaseDatabse(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const ChatboxImage = async (image) => {
    setMsg("");
    const data = new FormData();
    data.append("uid", uid);
    data.append("status", "in");
    data.append("cid", chatid);
    data.append("msg", "message");
    data.append("type", "image");
    data.append("img", image);

    axios({
      method: "post",
      url: "/mainsite/chatbox/chat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("chatbox view ", s.data);

        const a = s.data;
        if (a[0].code == "pass") {
          saveToFirebaseDatabse(a);
        }

      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  // const viewinbox = async (chatid) => {
  //   const data = new FormData();
  //   data.append("status", "view");
  //   data.append("cid", chatid);
  //   axios({
  //     method: "post",
  //     url: "/pro/chatbox/chat.php",
  //     data: data,
  //     headers: { "Content-Type": "multipart/form-data" },
  //   })
  //     .then((s) => {
  //       console.log("chatbox view ", s.data);
  //       setAllViewMsg(s.data);
  //       console.log(chatid);
  //     })
  //     .catch((s) => {
  //       console.log("here catch =>>", s);
  //     });
  // };

  return (
    <>
      <Nav />
      {/* <Nav /> */}
      {/* <div className="content-wrap">
        <div className="main">
          <div className="container-fluid"> */}
      <section

        style={{ marginTop: "10px", marginBottom: "50px", }}
        id="main-content"
      >
        <div className=" container">
          <div
            style={{
              border: "1px solid black",
              borderRadius: "15px",
              padding: "2px",
            }}
            className="row"
          >
            <div className="col-md-4 col-lg-5 col-xl-4 mb-4 mb-md-0">
              <h5 className="font-weight-bold mb-3 text-center text-lg-start">
                <div
                  onClick={() => {
                    // navigate("/");
                  }}
                  className="col-xl-2 col-lg-3 col-md-12 col-sm-4"
                >ChatBox
                </div>
                <hr />
              </h5>
              <div   >
                <div data-bs-spy="scroll" data-bs-target="#navbar-example3" data-bs-offset="0" tabindex="0" className="card-body">
                  <ul className="list-unstyled mb-0">
                    <li
                      style={{ backgroundColor: "#303347" }}
                      className=" p-2 border-bottom"
                    >
                      <SimpleBar
                        style={{
                          maxHeight: 200,
                          paddingRight: "20px",
                          cursor: "pointer",
                        }}
                      >
                        {chat.map((v, i) => (
                          <>
                            <span
                              onClick={() => {
                                // viewinbox(v.chat);
                                getFirebasechat(v.chat)
                                setChatid(v.chat);
                                setChatid_NotficationRCV(v.chat);
                                setsenderid(v.senderid);
                              }}
                              className="d-flex justify-content-between"
                              style={{
                                "marginTop": "5px"
                              }}
                            >
                              <div className="d-flex flex-row" >
                                <span
                                  style={{ "backgroundColor": 'white' }}
                                  src="assets/images/logos/logo.png"
                                  alt="avatar"
                                  className="rounded-circle d-flex align-self-center me-4 "
                                  width="100"
                                />
                                <img
                                  style={{ "backgroundColor": 'white', }}
                                  src="assets/images/logos/logo.png"
                                  alt="avatar"

                                  className="rounded-circle d-flex align-self-center me-4"
                                  width="80"
                                  height="80"
                                />
                                <div className="pt-1">
                                  {
                                    chatid == v.chat ? <>
                                      <span style={{ color: "#ffffff" }} >{v.name}</span>
                                    </> : <>
                                      <span style={{ color: "#969696" }} >{v.name}</span>
                                    </>
                                  }

                                  {/* <p style={{color:"white"}} className="fw-bold mb-0 text-uppercase">{v.name}</p> */}


                                  <hr />
                                  <br />

                                </div>
                              </div>
                            </span>
                          </>
                        ))}
                      </SimpleBar>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-8 col-lg-7 col-xl-8">
              <div style={{ backgroundImage: `url("https://alahdeen.com/pro/image/222.jpg")`, }} className="card">
                {/* <div style={{ height: "80px" }}></div> */}
                {/* <div className="col-md-6 col-lg-7 col-xl-8"> */}
                <ul
                  // style={{
                  //   height: '800px',
                  //   scrollBehavior: 'auto',
                  //   scrollBehavior: 'smooth',
                  // }}
                  style={{ height: "290px", scrollBehavior: "smooth", }}
                  className="list-unstyled"
                >
                  <SimpleBar style={{ maxHeight: 250, paddingRight: "0px" }}>
                    {allviewMsg.map((v, i) => (
                      <>
                        {v.sent_by == uid ? (
                          <>
                            <li className="d-flex justify-content-between mb-4">
                              <img
                                src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp"
                                alt="avatar"
                                className="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
                                width="50px"

                              />
                              <div style={{ backgroundColor: "#e6e6e6", paddingTop: "0px", paddingBottom: "0px" }} className="card w-100">
                                <div className="card-body">
                                  {
                                    v.type == "image" ? <>
                                      <a download={v.message} href={v.message} target="_blank" title="ImageName">
                                        <img src={v.message} height="20px" width="200px" />
                                      </a>
                                    </> : <> <p style={{ color: "black" }} className="mb-0">{v.message}</p></>
                                  }

                                </div>
                              </div>
                            </li>
                          </>
                        ) : (
                          <>
                            <li className="d-flex justify-content-between mb-4">
                              <div style={{ backgroundColor: "#d9ead3", paddingTop: "0px", paddingBottom: "0px" }} className="card w-100">
                                <div className="card-body">
                                  {
                                    v.type == "image" ? <>
                                      <a download={v.message} href={v.message} target="_blank" title="ImageName">
                                        <img src={v.message} height="20px" width="200px" />
                                      </a>
                                    </> : <> <p style={{ color: "black" }} className="mb-0">{v.message}</p></>
                                  }
                                </div>
                              </div>
                              <img
                                src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-5.webp"
                                alt="avatar"
                                className="rounded-circle d-flex align-self-start ms-3 shadow-1-strong"
                                width="60"
                                height="60"
                              />
                            </li>
                          </>
                        )}
                      </>
                    ))}
                  </SimpleBar>

                  <div
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      float: "right",
                      width: "100%",
                    }}
                    className="row"
                  >
                    <div style={{ backgroundColor: "primary" }} className="col-2">
                      <div className=" mb-3">
                        <div class="file-upload-wrapper">


                          <input
                            type="file" id="input-file-now" className="file-upload"
                            // style={{color:"transparent"}}
                            onChange={v => {

                              setFile(v.target.files[0])
                              ChatboxImage(v.target.files[0])

                            }}

                          // className="form-control"

                          />

                        </div>
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="bg-white mb-3">
                        <div className="form-outline">
                          {/* <label className="form-label" for="textAreaExample2">
                      Message
                    </label> */}

                          <input
                            placeholder="Enter Message Here"
                            onChange={(v) => {
                              setMsg(v.target.value);
                            }}
                            value={msg}
                            className="form-control"
                            id="textAreaExample2"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-1">
                      <button
                        type="button"
                        onClick={(v) => {
                          chatinbox(chatid);
                          getFirebasechat(chatid);
                          setChatid(chatid);
                          // Viewchatbox(uid);
                        }}
                        className="btn btn-info btn-rounded  "
                      >
                        Send
                      </button>
                    </div>
                    <div className="col-2">
                      <button
                        type="button"
                        onClick={(v) => {
                          chatinbox(chatid);
                          // viewchat();
                          getFirebasechat(chatid);
                          setChatid(chatid);
                          if (chatid != "") {
                            //localStorage.setItem("List abc", JSON.stringify(s.data));
                            if (roll == "seller") {
                              localStorage.setItem("invoicechatid", chatid);
                              navigate("/chatboxinvoice");
                            } else {
                              localStorage.setItem("invoicechatid", chatid);
                              navigate("/companydetail?business=" + senderid);
                            }
                          } else {

                          }
                        }}
                        className="btn btn-info btn-rounded"
                      >
                        <i className="ti ti-plus mx-1"></i>
                        {
                          roll == "seller" ? <>Invoice</> : <>Get Quote</>
                        }
                      </button>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            {/* <hr /> */}
          </div>
        </div>
      </section>
      {/* </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
}

export default App;
