import React from "react";
import Nav from "./navbar";
import { Carousel } from "react-bootstrap";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Whatsapp from "./whatsapp";
function App() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [file, setFile] = useState();

  const [warehouse, setWarehouse] = useState();

  const [uid, setUid] = useState();
  const [id, setId] = useState();
  const [rentpermonth, setRentpermonth] = useState();

  const [type, setType] = useState();
  const [locality, setLocality] = useState();
  const [city, setCity] = useState();

  const [areasqft, setAreasqft] = useState();
  const [warehouseId, setWarehouseId] = useState();
  const [contact, setContact] = useState();
  const [name, setName] = useState();
  const [room, setRomm] = useState();
  const [imagea, setImagea] = useState();
  const [imageb, setImageb] = useState();
  const [imagec, setImagec] = useState();
  const [imaged, setImaged] = useState();
  const [ownerid, setOwnerid] = useState();
  const [warehouseType, setWarehouseType] = useState([]);

  const warehousebytype = async (warehouseType) => {
    const data = new FormData();

    data.append("wtype", warehouseType);

    axios({
      method: "post",
      url: "/mainsite/warehouseby_types.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        const a = s.data;
        // console.log("S.data ",a)
        setWarehouseType(a);
        
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  useEffect(() => {
   
      const array = JSON.parse(localStorage.getItem("ware"));
      // console.log("warehouse ", array);
      const i = localStorage.getItem("wareitem");
      setType(array[i].type);
      warehousebytype(array[i].type)
      setLocality(array[i].locality);
      setCity(array[i].city);
      setAreasqft(array[i].areasqft);
      setWarehouseId(array[i].id);
      setRomm(array[i].room);
      setImagea(array[i].imagea);
      setImageb(array[i].imageb);
      setImagec(array[i].imagec);
      setImaged(array[i].imaged);
      setContact(array[i].contact);
      setRentpermonth(array[i].rentpermonth);
      setId(array[i].id);
      setOwnerid(array[i].uid);
    
  }, []);

  return (
    <body>
      <Nav />
      <Whatsapp />
      <div className="card container ">
        <div className="row">
          <div className="col-7 mx-3 my-2">
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  height={400}
                  src={imagea}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  height={400}
                  src={imageb}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  height={400}
                  className="d-block w-100"
                  src={imagec}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  height={400}
                  className="d-block w-100"
                  src={imaged}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
          <div
            style={{
              borderLeft: "2px solid black",
            }}
            className="col-4 px-5 my-4 mx-4"
          >
             <h5 className="mb-4 text-uppercase "> Warehouse Type: <b> {type}</b></h5>
            <p ><h5>Rent Per Month: {rentpermonth}</h5> </p>
            <p ><h6> Warehouse ID: {warehouseId}</h6> </p>
            <p className="text-muted"> City: {city} </p>
            <p className="text-muted"> Address: {locality}</p>
            <p className="text-muted"> Area Sqft: {areasqft}</p>
            <p className="text-muted"> No. of Rooms : {room}</p>
            {/* <p className="text-muted">Contact No: {contact} </p> */}
            <div className="row">
              {/* <button className="col-5 btn-success my-3 px-2 py-2">
                Send Message
              </button> */}
              <button
                onClick={() => {
                  localStorage.setItem("id", id);
                  localStorage.setItem("rent", rentpermonth);
                  localStorage.setItem("ownerid", ownerid);
                  localStorage.setItem("uid", uid);
                  navigate("/book");
                }}
                className="col-5 btn-success my-3 px-2 py-2 mx-4"
              >
                Book Now
              </button>
            </div>
          </div>
        </div>

        {/* <h4 className="mx-2 mt-5"> Warehouse Description </h4>
        <div className="row mx-5">
          <p>{locality}</p>
        </div> */}

        {/* <div className="row mx-2 my-4">
          <h3 style={{ height: "180px" }}> Location Details</h3>
        </div> */}

        {/* Explore More */}
        <h3 style={{marginTop:"100px"}}> Explore More Warehouse</h3>
        <div className="row my-4">
          {
            warehouseType.map((v,i)=>(
              <div
              style={{ cursor: "pointer" }}
              className="col-md-3 col-sm-6 card-product-grid card-sm"
            >
              <article className="card card-post">
                <img
                  heigt={100}
                  width={100}
                  src={v.imagea}
                  className="card-img-top"
                />
                <div className="card-body">
                  <h6 className="title">{v.type} </h6>
                  <p className="small text-muted">
                  {v.locality} <br />
                      {v.city} <br /> Area:{v.areasqft}{" "}
                    <span className="text-right text-muted mx-5">   {v.room} Rooms{" "} </span>
                  </p>
                  <p className="small text-uppercase "> Rent: {v.rentpermonth}</p>
                  <div className="text-center my-1">
                    <button
                      style={{ borderRadius: "15px" }}
                      className="btn-success px-3 "
                      onClick={() => {
                        localStorage.setItem("wareitem", i);
                        // console.log(i);
                        navigate("/single");
                      }}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </article>
            </div>

            ))
          }
       
          
          
         
        </div>

        {/* End Explore */}
      </div>
    </body>
  );
}
export default App;
