import Nav from "../../../navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";



function Buyleads() {

  const [pid, setPid] = useState("");
  const [uid, setUid] = useState("");
  const [tam, setTam] = useState("");
  const [purchase, setpurchase] = useState([]);
  const [tamount, setTamount] = useState("");

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
        const i = localStorage.getItem("pid");
        //alert(i);
        setPid(i);
      setUid(v[0].id);
      Purchase(v[0].id,i);
    }
  }, []);


  const Purchase = async (id,pid) => {
    const data = new FormData();
    data.append("uid", id);
    data.append("pid", pid);
    data.append("val", "tp_product");

    axios({
      method: "post",
      url: "/pro/inventory/manage_reports.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
    
        console.log("Single purchase list ", s.data);
        const a = s.data;
        setTam(a.tamount)
        setpurchase(a);
        totalamount(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  const totalamount = async (am)=>{
    am.map((v,i)=>{
      setTamount(v.tamount)
    })
   }
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Inventory Reports</h4>
                  </div>
                
                  <div className="card-body">
                  <div className="card-title text-center">
                    <h4> Purchases Record</h4>
                  </div>
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th
                            style={{
                              justifyContent: "start",
                              alignItems: "start",
                              textAlign: "start",
                            }}
                            >Id</th>
                            <th
                            style={{
                              justifyContent: "start",
                              alignItems: "start",
                              textAlign: "start",
                            }}
                            >Product Name</th>
                            <th
                            style={{
                              justifyContent: "start",
                              alignItems: "start",
                              textAlign: "start",
                            }}
                            >Price Per Unit</th>
                            <th
                            style={{
                              justifyContent: "start",
                              alignItems: "start",
                              textAlign: "start",
                            }}
                            >Units</th>
                            <th
                            style={{
                              justifyContent: "start",
                              alignItems: "start",
                              textAlign: "start",
                            }}
                            >Amount</th>
                            <th
                            style={{
                              justifyContent: "start",
                              alignItems: "start",
                              textAlign: "start",
                            }}
                            >Date</th>
                           
                            {/* <th>Total Units</th> */}
                           
                          </tr>
                        </thead>
                        <tbody >
                        
                          {purchase.length === 0 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "end",
                                    alignItems: "end",
                                    textAlign: "end",
                                  }}
                                  colSpan={13}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>
                            </>
                          ) : (
<>
{purchase.map((v,i)=>(
  
<tr>
<td> {v.id}</td>
<td> {v.pname}</td>
<td> {v.pp_unit}</td>
<td> {v.units}</td>
<td> {v.amount}</td>
<td
style={{
  justifyContent: "start",
  alignItems: "start",
  textAlign: "start",
}}
> {v.time}</td>

</tr>

))}
</>
                          )}
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div style={{marginLeft: '10' }}>
            <h6> Total Purchase Amount:  {tamount}
              {tam}
            </h6>
            
            </div>
            
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
