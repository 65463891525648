import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {Link} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert-te';

function Mainpage() {

  const [uid, setUid] = useState('');
  const [view, setView] = useState('0');
  const [aboutimg, setAboutImg] = useState();

  const [name, setName] = useState('');
  const [cnic, setcnic] = useState('');
  const [dob, setDob] = useState('');
  const [email, setEmail] = useState('');
  const [License, setLicense] = useState();
  const [licenseNumber, setLicenseNumber] = useState('');
  const [dateExpire, setDateExpire] = useState('');

  const [selfi, setSelfi] = useState();
  const [nameid, setNameID] = useState('');
  const [front,setFornt] = useState();
  const [back, setBack] = useState();
  const [cnicExpire, setcnicExpire] = useState('');
  const [adres, setAddress] = useState('');

  const [vehiclephoo, setVehiclePhoto] = useState();
  const [regphoto, setRegphoto] = useState();
  const [companyName, setCompanyName] = useState('');
  const [numberplat, setnumberplat] = useState('');
  const [VehicleType, setVehicleType] = useState('')

  const [city, setCity] = useState('');
  const [listcities, setCitiesList] = useState([]);
  
  const [postId, setPostId] = useState('');
  const [alret, setAlret] = useState(false);
  const [alret2, setAlret2] = useState(false);
  const [alret2msg, setAlret2Msg] = useState("");
  
  
  const [uidu, setUidu] = useState("");

  const [allinner, setallinner] = useState([]);
  const [allinetroutofcity, setallinetroutofcity] = useState([]);
  
  
  
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      
      setUid(v[0].id)
      getCities();
      getcheckDriver(v[0].id)
      
     
    }
  }, []);
  const getCities = async () => {
    const data = new FormData();
  
        axios({
          method: "post",
          url: "/pro/auth/cities_list.php",
          data: data,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((s) => {
            setCitiesList(s.data)
          })
          .catch((s) => {
            //navigate("/")
          });
      
    
  };
  const addDriver = async ()=> {

    const data = new FormData();
    data.append('uid', uid);
    data.append('fname', name);
    data.append('cnic_no', cnic);
    data.append('dob', dob);
    data.append('email', email);
    data.append('license_no', licenseNumber);
    data.append('expdate',dateExpire );
    data.append('cnic_name',nameid );
    data.append('cnicexpdate', cnicExpire);
    data.append('cert_img', regphoto);
    data.append('address', adres);
    data.append('cname', companyName);
    data.append('num_plate', numberplat);
    data.append('p_img', aboutimg);
    data.append('license_img', License);
    data.append('selfie_img', selfi);
    data.append('cnicf_img', front);
    data.append('cnicb_img', back);
    data.append('vehicle_img', vehiclephoo);
    data.append('vname', VehicleType);

    
        axios({ 
          method: 'post',
          url: '/pro/logistic/driver_reg.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            console.log("Driver list ",s.data)
            if(s.data[0].code=="new")
            {
              alert("Save")
              

            }else{
              alert(s.data[0].code)
            }
            
                  })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };
  const getcheckDriver = async (id)=> {
    const data = new FormData();
    data.append('uid', id);
        axios({ 
          method: 'post',
          url: '/pro/logistic/checkuser.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            
            if(s.data[0].code=="old")
            {
              const v = JSON.parse(localStorage.getItem('USER'));

              setView(s.data[0].status)

              getInnerCity(v[0].city,"Inner City")
              
            }else if(s.data[0].code=="new"){
              setView("1")
            }
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

  const applyPost = async (fare,postid, uidu)=> {
    console.log("fare",fare)
    console.log("userid user", uidu)
    console.log("postid",postid)
    const data = new FormData();
    data.append('pid', postid);
    data.append('did', uid);
    data.append('fare', fare);
    data.append('uid', uidu);

        axios({ 
          method: 'post',
          url: '/pro/logistic/bid_offers.php',
          data: data,
          headers: {'Content-Type':'multipart/form-data'},
        })
          .then(s => {
            console.log("applyPost ",s.data)
            if(s.data[0].code=="new")
            {
              setAlret(false)
              setAlret2Msg("Thanks For Biding Wait For Approval !")
              setAlret2(true)
            }else{
              setAlret(false)
              setAlret2Msg("Thanks For Re-Biding Wait For Approval !")
              setAlret2(true)

            }
            
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

 
  const getInnerCity = async (city,type)=> {
    const data = new FormData();
    data.append('status', "city");
    data.append('city_name',city );
    data.append('type', type); 
        axios({ 
          method: 'post',
          url: '/pro/logistic/view_requests.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            getouterCity(city,"Inter City")
          
            if(s.data[0].code==="all")
            {
              setallinner(s.data)
              
              console.log("posid",s.data)
              
              
            }
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

  const getouterCity = async (city,type)=> {
    const data = new FormData();
    data.append('status', "city");
    data.append('city_name',city );
    data.append('type', type); 
        axios({ 
          method: 'post',
          url: '/pro/logistic/view_requests.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
          
            if(s.data[0].code==="all")
            {
              setallinetroutofcity(s.data)
              console.log("setallinetroutofcity",s.data)
              
              
            }
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>Driver  </h3>

                  </div>
{
  alret?<>
  <SweetAlert
    input
    showCancel
    cancelBtnBsStyle="default"
    title="Apply to Bid"
    inputType="number"
    placeholder="Bid Amount"
    onConfirm={(v)=>{
      if(uidu!==""){
      applyPost(v,postId,uidu)
      }else{
        
      }
      


    }}
    onCancel={()=>(setAlret(false))}
>
    
</SweetAlert>

</>:<></>
}
{
  alret2?<><SweetAlert title={alret2msg} onConfirm={setAlret2(false)} /></>:<></>
}

                  {/* Form Start */}
               
                  {
                    view=="1"?<>
                       <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-title">
                          <h5>About</h5>
                        </div>
                        <div className="card-body">
                          <div className="basic-elements">
                            <div
                              className="row my-4"
                              style={{
                                height: '100px',
                                border: '1px dotted black',
                              }}>
                              <label className="my-2">Add Image</label>
                              {
                               
                              }
                              <input type="file"   onChange={v => {
                                                  setAboutImg(v.target.files[0]);
                                                }} />
                            </div>
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>First Name</label>
                                <input className="form-control" type="text" 
                               value={name} onChange={(v)=>{setName(v.target.value)  }} />
                              </div>
                              <div className="col-6 form-group">
                                <label>CNIC No. </label>
                                <input className="form-control" type="text"   value={cnic} onChange={(v)=>{setcnic(v.target.value)  }}  />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Date Of Birth</label>
                                <input className="form-control" type="date" value={dob}  onChange={(v)=>{setDob(v.target.value)  }} />
                              </div>
                              <div className="col-6 form-group">
                                <label>Email (Optional)</label>
                                <input className="form-control" type="email" value={email} onChange={(v)=>{setEmail(v.target.value)  }} />
                              </div>
                            </div>
                            <div  onClick={()=>{
                              setView("2")
                            }} className="btn btn-success my-3 mx-3 ">
                              Next
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </>:<></>
                  }

                
                  {
                    view=="2"?<>
                      <div className="col-lg-12">
                    <div className="card">
                      <div className="card-title">
                        <h5>Driver License</h5>
                      </div>
                      <div className="card-body">
                        <div className="basic-elements">
                          <div className="col-lg-12">
                            <div
                              className="row my-4"
                              style={{
                                height: '100px',
                                border: '1px dotted black',
                              }}>
                              <label className="my-2">Add License</label>
                              <input type="file"     onChange={v => {
                                                  setLicense(v.target.files[0]);

                                                  
                                                }} />
                            </div>
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>License Number</label>
                                <input className="form-control" type="text"   value={licenseNumber} onChange={(v)=>{setLicenseNumber(v.target.value)  }} />
                              </div>
                              <div className="col-6 form-group">
                                <label>Date of Expiration</label>
                                <input className="form-control" type="date"   value={dateExpire} onChange={(v)=>{setDateExpire(v.target.value)  }} />
                              </div>
                            </div>

                            <div className="btn btn-success my-3" onClick={()=>{
                              setView("1")
                            }} >
                              Previous
                            </div>
                            <div onClick={()=>{
                              setView("3")
                            }} className="btn btn-success my-3 mx-3 ">
                              Next
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </>:<></>
                  }
                  {
                    view=="3"?<>
                        {/* 3rd Page Start */}
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-title">
                        <h5>ID Confirmation</h5>
                      </div>
                      <div className="card-body">
                        <div className="basic-elements">
                          <div className="col-lg-12">
                            <div
                              className="row my-4"
                              style={{
                                height: '130px',
                                border: '1px dotted black',
                              }}>
                              <label className="my-2">Add Selfie</label>
                              <label style={{color: 'red'}}>
                                <b>
                                  Bring the driver license in front of you and
                                  take a photo
                                </b>
                              </label>

                              <input type="file"    onChange={v => {
                                                  setSelfi(v.target.files[0]);
                                                  
                                                }} />
                            </div>
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>ID Name</label>
                                <input className="form-control" type="text"  value={nameid} onChange={(v)=>{setNameID(v.target.value)  }} />
                              </div>
                            </div>

                            <div className="btn btn-success my-3 " onClick={()=>{
                              setView("2")
                            }} >
                              Previous
                            </div>
                            <div onClick={()=>{
                              setView("4")
                            }} className="btn btn-success my-3 mx-3 ">
                              Next
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 3rd Page End */}
                    </>:<></>
                  }
                  {
                    view=="4"?<>
                    <div className="col-lg-12">
                    <div className="card">
                      <div className="card-title">
                        <h5>CNIC</h5>
                      </div>
                      <div className="card-body">
                        <div className="basic-elements">
                          <div className="col-lg-12">
                            <div
                              className="row my-4"
                              style={{
                                height: '100px',
                                border: '1px dotted black',
                              }}>
                              <label className="my-2">
                                Add Front Side Photo
                              </label>
                              <input type="file"    onChange={v => {
                                                  setFornt(v.target.files[0]);
                                                  
                                                }} />
                            </div>
                            <div
                              className="row my-4"
                              style={{
                                height: '100px',
                                border: '1px dotted black',
                              }}>
                              <label className="my-2">
                                Add Back Side Photo
                              </label>
                              <input type="file"   onChange={v => {
                                                  setBack(v.target.files[0]);
                                                  
                                                }} />
                            </div>
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Date of Expiration</label>
                                <input className="form-control" type="date"  value={cnicExpire} onChange={(v)=>{setcnicExpire(v.target.value)  }} />
                              </div>
                              <div className="col-6 form-group">
                                <label>Address</label>
                                <input className="form-control" type="text"  value={adres} onChange={(v)=>{setAddress(v.target.value)  }} />
                              </div>
                            </div>

                            <div onClick={()=>{
                              setView("3")
                            }} className="btn btn-success my-3 ">
                              Previous
                            </div>
                            <div onClick={()=>{
                              setView("5")
                            }} className="btn btn-success my-3 mx-3 ">
                              Next
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </>:<></>
                  }
                  {
                    view=="5"?<>
                                    {/* 4th page Start */}
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-title">
                        <h5>Vehicle Information</h5>
                      </div>
                      <div className="card-body">
                        <div className="basic-elements">
                          <div className="col-lg-12">
                            <div
                              className="row my-4"
                              style={{
                                height: '100px',
                                border: '1px dotted black', 

                              }}>
                              <label className="my-2">Add Vehicle Photo</label>
                              <input type="file"     onChange={v => {
                                                  setVehiclePhoto(v.target.files[0]);  }} />
                            </div>
                            <div
                              className="row my-4"
                              style={{
                                height: '100px',
                                border: '1px dotted black',
                              }}>
                              <label className="my-2">
                                Add Registeration Certificate
                              </label>
                              <input type="file"   onChange={v => {
                                                  setRegphoto(v.target.files[0]);
                                                }} />
                            </div>
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Company Name</label>
                                <input className="form-control" type="text" value={companyName} onChange={(v)=>{setCompanyName(v.target.value)  }}  />
                              </div>
                              <div className="col-6 form-group">
                                <label>Number Plates</label>
                                <input className="form-control" type="text" value={numberplat} onChange={(v)=>{setnumberplat(v.target.value)  }}  />
                              </div>
                            </div>

                            <div onClick={()=>{
                              setView("4")
                            }} className="btn btn-success my-3 ">
                              Previous
                            </div>
                            <div onClick={()=>{
                              addDriver()
                            }} className="btn btn-success my-3 mx-3 ">
                              Submit
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                    </>:<></>
                  }

                 {
                   view=='pending'?<><h3>Your Registeration Request is Pending.</h3></>:<></> 
                 }

                 {
                   view=='active'?<>




<nav>
  <div className="nav nav-tabs" id="nav-tab" role="tablist">
    <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Inner City</button>
    <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Inter City </button>
   
  </div>
</nav>
<div className="tab-content" id="nav-tabContent">
  <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
{/* tab 1 start */}

<section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div>
{
  view=="active"?<>
                    <table >
                    <tr>
                      <th><label>Select City </label></th>
                      </tr>
                      <tr>
                      <th> <select className="form-control" value={city}  onChange={(v)=>(setCity(v.target.value))} >
                      {
                        listcities.map((v,i)=>(
                          <option>{v.city}</option>

                        ))
                      }
                      </select>
                      
                      </th>
                      
                      <th>   <input type="submit" value={"Get Request"} className="btn btn-primary mx-2" onClick={()=>( getInnerCity(city,"Inner City"))}  /> </th>
                    </tr>
                  </table>
                  </>:<></>
}
              
                     
                    
                      
                </div>
              <div className="table-responsive">
                
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Sr.</th>
                            <th>From </th>
                            <th>Distance</th>
                            <th>Vehicle type</th>
                            <th>Weight</th>
                            <th>Volume</th>
                            <th>Depart Date</th>
                            <th>Depart Time</th>
                            <th>comments</th>
                            <th>Fare</th>
                     
                            
                            <th>Apply</th>
                          </tr>

                        </thead>


{
  view=="active"?<>
                          <tbody>
  
                          {allinner.map((v,i)=>(
                           <>
                           <tr>
                             <td>{i+1}</td>
                             <td>{v.frm_city}</td>
                             <td>{v.distance}</td>
                             <td>{v.vehicle_type}</td>
                             <td>{v.con_weight}</td>
                             <td>{v.con_volume}</td>
                             <td>{v.depart_date}</td>
                             <td>{v.dapart_time}</td>
                             <td>{v.comments}</td>
                             <td>{v.fare}</td>
                           
                             

                             
                             <td> <input type="submit" value={"Bid"} className="btn btn-primary mx-2" onClick={()=>{setPostId(v.rid); setUidu(v.uid); setAlret(true)}}  /></td>
                           
                           </tr>
                           </>
                         ))}
                        </tbody>
                        </>:<></>
}
                      </table>
                    </div>
              </div>
            </section>



{/* tab 1 end */}



  </div>
  <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">


{/* tab 2 start */}



       <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
              {
  view=="active"?<>
                    <table >
                    <tr>
                      <th> <label>Select City </label></th>
                      </tr>
                      <tr>
                      <th> <select className="form-control" value={city}  onChange={(v)=>(setCity(v.target.value))} >
                      {
                        listcities.map((v,i)=>(
                          <option>{v.city}</option>

                        ))
                      }
                      </select>
                      
                      </th>
                      
                      <th>   <input type="submit" value={"Get Request"} className="btn btn-primary mx-2" onClick={()=>( getInnerCity(city,"Inner City"))}  /> </th>
                    </tr>
                  </table>
                  </>:<></>
}
              <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                        <tr>
                           <th>ID</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Distance</th>
                            <th>Vehicle</th>
                            <th>Vehicle type</th>
                            <th>Weight</th>
                            <th>Volume</th>
                            <th>Depart Date</th>
                            <th>Depart Time</th>
                            <th>comments</th>
                            <th>Fare</th>
                            <th>Time</th>
                            <th>Controls</th>
                          </tr>
                        </thead>
                        {
  view=="active"?<>
                          <tbody>
                          {allinetroutofcity.map((v,i)=>(
                           <>
                           <tr>
                             <td>{i+1}</td>
                             <td>{v.frm_city}</td>
                             <td>{v.to_city}</td>
                            
                             <td>{v.distance}</td>
                             <td>{v.vehicle}</td>
                             <td>{v.vehicle_type}</td>
                             <td>{v.con_weight}</td>
                             <td>{v.con_volume}</td>
                             <td>{v.depart_date}</td>
                             <td>{v.dapart_time}</td>
                             <td>{v.comments}</td>
                             <td>{v.fare}</td>
                             <td>{v.time}</td>
                             

                             
                             <td> <input type="submit" value={"Bid"} className="btn btn-primary mx-2" onClick={()=>{setPostId(v.rid); setUidu(v.uid); setAlret(true)}}  /></td>
                           
                           </tr>
                           </>
                         ))}
                        </tbody>
                        </>:<></>
}
                      </table>
                    </div>
              </div>
            </section>

{/* tab 2 end */}

  </div>
 
</div>



                  
                  
                   
                    </>:<></> 
                 }
  </div>
              </div>
            </section>

            
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
