import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import { Robot } from 'react-bootstrap-icons';

function Mainpage() {
  const navigate = useNavigate();
  const [type, setType] = useState('');
  const [typeList, settypeList] = useState([]);

  const [uid, setUid] = useState('');

  const [array, setArray] = useState([]);
  const [designation, setdesignation] = useState('');
  const [description, setdescription] = useState('');
  const [alert2, setAlert2] = useState('');

  const [img, setImage] = useState('');
  const [status, setStaus] = useState("1");
  const [imgupdated, setImageUpdated] = useState("");
  const [IMG, setIMG] = useState();
  const [d, setD] = useState("");
  const [typeupdated, setTypeUpdated] = useState("");
  const [updateid, setupdateid] = useState("");


  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
        setUid(v[0].id);
        addImgCompanyType();
        ViewImgCompany (v[0].id);

    }
  }, []);

  const addImgCompanyType = async () => {
    const data = new FormData();
   
    axios({
      method: 'post',
      url: '/pro/profile/profile_type_images.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data)

       settypeList(s.data)
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const UpdateImgCompany = async () => {

    console.log(uid,updateid,typeupdated,IMG)
    const data = new FormData();
    data.append('uid', uid);
    data.append('pid', updateid);
    data.append('type', typeupdated);
    if(d=="")
    {
      data.append('img', "");
    }else{
      data.append('img', IMG);
    }
    
    data.append('val', "updatebyid");
    axios({
      method: 'post',
      url: 'pro/profile/manage_profile_images.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        setStaus("1")
        console.log("seeeee ",s.data)
        ViewImgCompany(uid)
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const addImgCompany = async () => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('type', type);
    data.append('img', img);
    data.append('val', "in");
    axios({
      method: 'post',
      url: 'pro/profile/manage_profile_images.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        if (s.data[0].code === 'pass') {
          setAlert2(!alert2);
          
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  const ViewImgCompany = async (uid) => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('val', "view");
    axios({
      method: 'post',
      url: 'pro/profile/manage_profile_images.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        setArray(s.data)
        console.log("compny images",s.data)
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  const DeleteImgCompany = async (id) => {
    const data = new FormData();
    data.append('pid', id);
    data.append('val', "delete");
    axios({
      method: 'post',
      url: 'pro/profile/manage_profile_images.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        setArray(s.data)
        console.log("compny images",s.data)
        ViewImgCompany(uid)
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  //.......data

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
           
            {
              status=="1"?
              <div className="card">
              <div className="card-title">
                <h4>New Comapny Images</h4>
              </div>

            
              <div className="row">
                 <div className="col-6 form-group">
                  <label> Type </label>
                  <select   className="form-control"
                  value={type}
                  onChange={v => {
                    setType(v.target.value);
                  }}>
                    {
                        typeList.map((v)=>(
                            <option>
                            {
                                v
                            }
                            </option>
                        ))
                    }
                    
                  </select>
                  
                </div>
                 <div className="col-6 form-group">
                  <label>Image</label>
                  <input
                    className="form-control"
                    onChange={v => {
                      setImage(v.target.files[0]);
                    }}
                    type="file"
                  />
                </div>
              </div>

              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
                className="form-group">
                <button
                  className=" btn btn-primary px-5 mt-4"
                  onClick={() => {
                    if(type.length=="")
                                    {
                                      alert("Select Type")
                                    }else{
                                      addImgCompany();
                                    }
                    //
                   // setAlert(!alert);
                  }}>
                  Save
                </button>


               



                {alert2 ? (
                  <>
                    {' '}
                    <SweetAlert
                      success
                      confirmBtnText="Thanks !"
                      confirmBtnBsStyle="success"
                      timeout={2000}
                      title="Photo Saved"
                      onConfirm={() => {
                        setAlert2(!alert2);
                        
                        //navigate('/director');
                      }}
                      onCancel={() => {
                        setAlert2(!alert2);
                      }}
                      focusCancelBtn>
                      <br />
                    </SweetAlert>
                  </>
                ) : (
                  <></>
                )}
           
              </div>
          
            </div>:
            <div className="card">
            <div className="card-title">
              <h4>Update Comapny Images</h4>
            </div>

          
            <div className="row">
               <div className="col-12 form-group">
                <label> Type </label>
                <select   className="form-control"
                value={typeupdated}
                onChange={v => {
                  setTypeUpdated(v.target.value);
                }}>
                  {
                      typeList.map((v)=>(
                          <option>
                          {
                              v
                          }
                          </option>
                      ))
                  }
                  
                </select>
                
              </div>
               <div className="col-12 form-group">
                
                <img
                  height={200}
                  width={200}
                  src={imgupdated}
                 
                />
              </div>


               <div className="col-12 form-group">
                <label>Image</label>
                <input
                  className="form-control"
                  onChange={v => {
                    setIMG(v.target.files[0]);
                    setD("as")
                  }}
                  type="file"
                />
              </div>
            </div>

            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
              className="form-group">
              <button
                className=" btn btn-primary px-5 mt-4"
                onClick={() => {
                  if(typeupdated.length=="")
                                  {
                                    alert("Select Type");
                                  }else{
                                    
                                    UpdateImgCompany();
                                  }
                  //
                 // setAlert(!alert);
                }}>
                Update Now
              </button>


             



              {alert2 ? (
                <>
                  {' '}
                  <SweetAlert
                    success
                    confirmBtnText="Thanks !"
                    confirmBtnBsStyle="success"
                    timeout={2000}
                    title="Photo Saved"
                    onConfirm={() => {
                      setAlert2(!alert2);
                      
                      //navigate('/director');
                    }}
                    onCancel={() => {
                      setAlert2(!alert2);
                    }}
                    focusCancelBtn>
                    <br />
                  </SweetAlert>
                </>
              ) : (
                <></>
              )}
         
            </div>
        
          </div>
            }
            


            <div className="container-fluid">
            <section id="main-content">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Comapny Images </h4>
                  </div>
               

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Type</th>
                            <th>Photo</th>
                            <th>Control</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          {array.length == 0 ? (
                            <>
                              {' '}
                              <tr>
                                <td
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                  colSpan={10}>
                                  No Record to show{' '}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                          {array.map((v, i) => (
                            <>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{v.type}</td>
                                <td> <img src={v.image} style={{height:"200px",width:"200px"}} /> </td>
                                <td > <button style={{margin:"5px"}} onClick={()=>{
                                  setTypeUpdated(v.type)
                                  setImageUpdated(v.image)
                                  setupdateid(v.id)
                                  setStaus("2")
                                }}  className='btn btn-primary'><li className='ti-pencil'></li></button> 
                                 <button style={{margin:"5px"}} onClick={()=>{
                                  DeleteImgCompany(v.id)
                                  
                                  
                                }}  className='btn btn-primary'><li className='ti-trash'></li></button> </td>
                                  
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
