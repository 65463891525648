import Nav from "../../../navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";



function Mainpage() {
  const navigate = useNavigate();
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const id = params.get("id");


      if(id!=="")
      {
        ViewInvoice(id)

      }else{
       navigate("/")
      }
      
      
    }
  }, []);


  const [invoice, setInvoice] = useState([]);
  const [name, setName] = useState("");

  const [totalAmount, settotalAmount] = useState("");
  const [gsttotal, setgsttotal] = useState("");
  const [totalTax, settotalTax] = useState("");
  const [GrandTotal, setGrandTotal] = useState("");


  const [duedate, setDuedate] = useState("");

  const [uid, setUid] = useState("");
  const [deldate, setDeldate] = useState("");
  const [invoicedate, setinvoicedate] = useState("");
  const [invoiceno, setInvoiceno] = useState("");

  const [comments, setComments] = useState("");

  const [whatsapp, setWhatsapp] = useState("");
  const [phone, setPhone] = useState("");
 const [trans, settrans] = useState([]);

 const [invoiceClient,setInvoiceClient] = useState([]);
 const [invoiceUid,setInvoiceuid] = useState([]);
 

  const ViewInvoice = async (id) => {
   

    const data = new FormData();
    data.append("id", id);

    axios({
      method: "post",
      url: "/pro/khata/print.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("khata print  ", s.data);
        const a = s.data;
        if(a.length<1)
        {
          navigate("/")
        }else{
          setWhatsapp(a[0].whatsapp)
          setinvoicedate(a[0].invoicedate)
          setInvoiceno(a[0].iid)

          setDuedate(a[0].duedate)
          setDeldate(a[0].deldate)
          setComments(a[0].comments)

          setInvoice(JSON.parse(a[0].array))
          settrans(JSON.parse(a[0].array_term))

          setInvoiceuid(a[0].ruid)
          setInvoiceClient(a[0].rcid)
          
          
          const ss=JSON.parse(a[0].array);
          const oldArr = [...ss];
          const subTotalgst = oldArr.reduce((n, { gstamount }) => n + gstamount, 0);
          const subTotalamount = oldArr.reduce((n, { amount }) => n + amount, 0);
          const subTotal = oldArr.reduce((n, { total }) => n + total, 0);
          const subTotaltax = oldArr.reduce((n, { tax }) => n + Number(tax), 0);

          settotalTax(subTotaltax);
          setGrandTotal(subTotal);
          settotalAmount(subTotalamount);
          setgsttotal(subTotalgst);

          

        }
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };




  return (
    <body>
      

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <center>
                      <h2 className="my-4">Invoice </h2>
                    </center>
                  </div>
                  {/* <div className="container"> */}
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Invoice Number</label>
                        <input
                          value={invoiceno}
                         disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Invoice Date</label>
                        <input
                          type="text"
                          id="fname"
                          disabled
                          className="form-control form-control-sm"
                          value={invoicedate}
                          
                        />{" "}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Payment Due Date</label>
                        <input
                          type="text"
                          disabled
                          id="fname"
                          className="form-control form-control-sm"
                          value={duedate}
                          
                        />{" "}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Delivery Date</label>
                        <input
                          value={deldate}
                         disabled
                          type="date"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  {/* </div> */}

                  {/* from */}
                  <div className="row">
                    <div className="col-6">
                      {" "}
                      <div>
                        <h4 className="form-group">Billed From </h4>
                        
                        
                              <input
                                disabled
                                className="form-control"
                                type="text"
                                value={invoiceUid.length==0?"":invoiceUid[0].nameb}
                              />
                      </div>
                    </div>

                    <div className="col-6">
                    <label className="form-group">Email</label>
                              <input
                                disabled
                                className="form-control"
                                type="text"
                                value={invoiceUid.length==0?"":invoiceUid[0].emailb}
                              />
                    </div>
                  </div>

                  <div style={{ marginTop: "15px" }} className="row">
                    <div className="col-6">
                    <label className="form-group">Contact No</label>
                              <input
                                className="form-control"
                                type="text"
                                disabled
                                value={invoiceUid.length==0?"":invoiceUid[0].phoneb}
                              />
                    </div>
                    <div className="col-6">
                    <label className="form-group">Address</label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                value={invoiceUid.length==0?"":invoiceUid[0].addb}
                              />
                    </div>
                  </div>
                  {/* from */}

                  {/* To */}
                  <div style={{marginTop:"20px"}} className="row">
                    <div className="col-6">
                      {" "}
                      <div>
                        <h4 className="form-group"> Billed To </h4>
                        
                        
                              <input
                                disabled
                                className="form-control"
                                type="text"
                                value={invoiceClient.length==0?"":invoiceClient[0].name}
                              />
                      </div>
                    </div>

                    <div className="col-6">
                    <label className="form-group">Email</label>
                              <input
                                disabled
                                className="form-control"
                                type="text"
                                value={invoiceClient.length==0?"":invoiceClient[0].email}
                              />
                    </div>
                  </div>

                  <div style={{ marginTop: "15px" }} className="row">
                    <div className="col-6">
                    <label className="form-group">Contact No</label>
                              <input
                                className="form-control"
                                type="text"
                                disabled
                                value={invoiceClient.length==0?"":invoiceClient[0].phone}
                              />
                    </div>
                    <div className="col-6">
                    <label className="form-group">Address</label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                value={invoiceClient.length==0?"":invoiceClient[0].add}
                              />
                    </div>
                  </div>
                  {/* To */}


                  <div style={{ marginTop: "50px" }} className="card-body">
                    <h5>Product Details</h5>
                    <br />
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Product Name</th>
                            <th>Product code</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Amount</th>
                           
                            <th>GST Amount</th>
                            <th>Tax</th>
                            <th>Total</th>
                          
                          </tr>
                        </thead>
                        <tbody>
                          {invoice.map((v, i) => (
                            <>
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                {v.name}
                                </td>
                                <td>
                                 {v.code}
                                   
                                  
                                </td>
                                <td>
                                {v.quantity}
                                    
                                  
                                </td>
                                <td>
                                  {v.rate}
                                   
                                 
                                </td>
                                <td>
                                 {v.amount}
                                 
                                </td>
                               
                                <td>
                                  {v.gstamount}
                                    
                                   
                                  
                                </td>
                                <td>
                                  {v.tax}
                                   
                                  
                                </td>
                                <td>
                                  {v.total}
                                
                                </td>

                               
                              </tr>
                              {invoice.length == i + 1 ? (
                                <>
                                  <th > <li style={{cursor:"pointer"}}>
                      
                      </li></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                
                                
                                  <th>
                                    Total Amount <br /> {totalAmount}
                                  </th>
                                  <th></th>
                                  <th>
                                    Total GST <br /> {(Number(gsttotal))}
                                  </th>
                                  <th>
                                    Total Tax <br /> {totalTax}
                                  </th>
                                  <th>
                                    Grand Total <br />
                                    {GrandTotal}
                                  </th>
                                </>
                              ) : (
                                <> </>
                              )}
                            </>
                          ))}
                        </tbody>
                      </table>
                   
                    </div>
                  </div>
                  <div className="card-title">
                    <h4> Terms and Conditions </h4>
                  </div>
                  <div className="card-body">
                    {trans.map((v, i) => (
                      <>
                        <tr>
                          <th  > <p style={{margin:"10px"}} >{i + 1}</p></th>
                          <td >
                          <p style={{margin:"10px"}} >{v.value}</p> 
                             
                          </td>
                         
                        </tr>
                    
                      </>
                    ))}
                  </div>
               
                  <center>
                    <h5>
                      Write Comments:
                    </h5>
                    <p> {comments}</p>
                  </center>
                  
             
                
                 
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
