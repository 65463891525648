import Nav from "../../../navbar";
import { Link, useNavigate } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert-te";
import { useState, useEffect } from "react";
import axios from "axios";

function Mainpage() {
  const [currentid, setCurrentId] = useState("");
  const [catid, setCatid] = useState("");
  const [catlog, setAllCatlog] = useState([]);
  const [alert2, setAlert2] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
      navigate("/login");
    } else {
      setCurrentId(v[0].id);
      getAllCatLog(v[0].id);
    }
  }, []);
  const getAllCatLog = async (id) => {
    const data = new FormData();
    data.append("uid", id);
    axios({
      method: "post",
      url: "/pro/allcatlog.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        if (s.data[0].msg === "pass") {
          setAllCatlog(s.data);
          console.log(s.data);
        }
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  const deleteCalatalog = async (catid) => {
    const data = new FormData();
    data.append("cid", catid);
    data.append("uid", currentid);

    axios({
      method: "post",
      url: "/pro/del_catlog.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log(s.data);
        // alert("Catalog Deleted Successfully")
        setAllCatlog("")
        getAllCatLog(currentid)
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Catalogs List</h4>
                  </div>
                  <div className="row">
                    <div className="text-right">
                    
                      <span>
                        <button
                          style={{ borderRadius: "18px", color: "white" }}
                          className="bg-primary mx-3 px-4 "
                        >
                          <Link style={{ color: "white" }} to="/adcatalog">
                            Add Catalog
                          </Link>
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Title</th>
                       
                            <th>Created At</th>
                            <th>Date</th>
                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                        {catlog.length === 0 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                  colSpan={10}>
                                  No Record to show{' '}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                          {catlog.map((v, i) => (
                            <tr>
                              <td>{v.id}</td>
                              <td>{v.title}</td>
                  
                              <td>{v.time}</td>
                              <td>
                                <a
                                  href={v.catlog}
                                  target="_blank"
                                  download={v.pdf}
                                >
                                  View{" "}
                                </a>{" "}
                              </td>
                             
                              <td
                                onClick={() => {
                                  setCatid(v.catid);
                                  //deleteCalatalog(v.catid);
                                  setAlert2(true)
                                }}
                              >
                                <i
                                  style={{ cursor: "pointer", color: "red" }}
                                  className="ti-trash"
                                ></i>
                              </td>
                            </tr>
                          ))}
                          </>
                          )
                              }
                          {alert2 ? (
                <>
                  {" "}
                  <SweetAlert
                    warning
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="success"
                    title="Confirm Delete"
                    allowEscape = {false}
                    onConfirm={() => {
                      deleteCalatalog(catid);
                      setAlert2(!alert2);
                    }}
                    onCancel={() => {
                      setAlert2(!alert2);
                    }}
                    
                    focusCancelBtn
                  >
                    <br />
                  </SweetAlert>
                </>
              ) : (
                <></>
              )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
