import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Nav from "../../../navbar";

function Buyleads() {
  const navigate = useNavigate();
  const [uid, setUid] = useState("");
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      setUid(v[0].id);
      viewProduct(v[0].id);
    }
  }, []);

  const viewProduct = async (id) => {
    const data = new FormData();
    data.append("uid", id);
    data.append("val", "view");

    axios({
      method: "post",
      url: "/pro/inventory/submit_productinfo.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("product list ", s.data);
        const a = s.data;
        setProductList(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  return (
    <body>
      <Nav />
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}
              <div style={{marginLeft:"20px", marginTop:"10px"}} >
            <Link to="/inventory">
            <button
            className="btn-secondary px-2"  >
            <i style={{paddingRight:"10px"}} className="ti-arrow-left"></i>
              Back
            </button>
            </Link>
            </div>
              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Inventory Stock Levels</h4>
                  </div>
                  <div className="row">
                    {/* <div className="col-lg-9 col-sm-1"></div> */}
                    <div className="text-right">
                      <input
                        style={{ borderRadius: "18px", paddingLeft: "8px" }}
                        placeholder="search"
                      />
                      <span>
                        <button
                          style={{ borderRadius: "18px", color: "white" }}
                          className="bg-primary mx-3 px-4 "
                        >
                          Search
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Product Code</th>
                            <th>Product Name</th>
                            <th>Product Category</th>
                            <th>Product Sub Category</th>
                            <th>Brand Name</th>
                            <th>Purchase Units</th>
                            <th>Product Group</th>
                            <th>Product Supplier</th>
                            <th>Tax Code</th>
                            <th>Total Units</th>
                            <th>Total Amount</th>
                            <th>Stock Levels</th>
                            <th>control</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productList.length === 0 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={10}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {productList.map((v, i) => (
                                <tr>
                                  <td>{v.counter}</td>
                                  <td>{v.p_code}</td>
                                  <td>{v.p_name}</td>
                                  <td>{v.p_category}</td>
                                  <td>{v.p_sub_cat}</td>
                                  <td>{v.brand_name}</td>
                                  <td>{v.purchase_units}</td>
                                  <td>{v.product_group}</td>
                                  <td>{v.p_supplier}</td>
                                  <td>{v.tax_code}</td>
                                  <td>{v.tunits}</td>
                                  <td>{v.amx}</td>
                                  <td>
                                    {v.level=="high"
                                    ?  <button className="btn-success">{v.level}</button>: v.level=="low"?<button className="btn-warning">{v.level}</button>:v.level=='medium'?<button className="btn-primary">{v.level}</button>:v.level=="very low"?<button className="btn-danger">{v.level}</button>:<></>
                                    
                                    }
                                    
                                  </td>
                                  <td>
                                   <button onClick={()=>{
                                    navigate('/productpricing')
                                   }} className="btn-primary ">Purchase Stock</button>
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
