import React from "react";
import Footer from "./footer";
import Nave from "./navbar";
import axios from "axios";
import { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import { Textarea } from "react-bootstrap-icons";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./styles.css"
function Companypage() {
  
  const navigate = useNavigate();
  const [sheet, setSheet] = useState([
    {
      id: 1,
      name: "",
      quantity: "",
      piece: "",
      price: "",
      des: "",
      status: "normal",
    },
  ]);
const [alert1, setAlert1]= useState(false)
const [uid, setUid]= useState("")
  const [user, setUser] = useState([]);
  const [allUID, setAllUID] = useState([]);
  const [profile, setProfile] = useState([]);
  const [company, setCompany] = useState([]);
  const [director, setDirector] = useState([]);
  const[view, setView] = useState(1);
  const[cname, setCname] = useState("");

  useEffect(() => {
    const aa = localStorage.getItem("USER");
    if (aa != null) {
      const as = JSON.parse(aa);

      console.log("ttsss", as);
      
      setUser(as);

    } else {
      // console.log("Login Please");
    }

    get();

  }, []);

  const get = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("business");

    if(id==null)
    { if(user.length>0)
      {
      
        getProductbyuid(user[0].id)
        CompanyProfileView(user[0].id)
        Director(user[0].id)
      }else{
        navigate("/")
      }
      

    }else{
  
      getProductbyuid(id)
      CompanyProfileView(id)
      Director(id)
    }

 

  }

  const add_lead = async () => {

    const data = new FormData();

    data.append("uid", uid);
    data.append("sheet", JSON.stringify(sheet));
    data.append("cat", "cat");
    //data.append("img", "");

    axios({
      method: "post",
      url: "/pro/lead_add.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        
        setAlert1(!alert1);
        navigate('/product-buy-request')
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
 
};
  const changeArayquote = (i, changes) => {
    const oldArr = [...sheet];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    setSheet(oldArr);
  };


  const getProductbyuid = async (id) => {
    const data = new FormData();

    data.append("uid", id);

    axios({
      method: "post",
      url: "/mainsite/productbyuid.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        
        setAllUID(s.data);
    
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const CompanyProfileView = async (id) => {
   
    const data = new FormData();

    data.append("uid", id);

    axios({
      method: "post",
      url: "/pro/profile/profile_view_company.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
    
     const dd= JSON.parse(s.data[0].aray_pprofile);
      setProfile(dd);
     const d= JSON.parse(s.data[0].aray_com);
     setCompany(d);
     setCname(profile[0].companyname)
    console.log("company Profile", d);
   
    // console.log("Personal Profile", dd);
 
})
.catch((s) => {
          
        console.log("here catch =>>", s);
      });
  };

 
  const Director = async (uid) => {
    const data = new FormData();

    data.append("uid", uid);

    axios({
      method: "post",
      url: "/pro/profile/profile_view_director.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        setDirector(s.data)
        // console.log("Director List", s.data);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };



 


  return (
    <body>
      <Nave />
    
     
    
      <div class="row">
        <div class="col-md-12">
<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
<div class="">
   <div class="row">
      <div class="col-md-12">
         <div id="content" class="content content-full-width">
            {/* <!-- begin profile --> */}
            <div class="profile">
               <div 
               style= {{backgroundImage: `url("https://alahdeen.com/pro/image/bgcover.png")`,backgroundSize:"auto", backgroundRepeat:"no-repeat", height:"350px", opacity:"0.7"}} 

               class="profile-header">
                  {/* <!-- BEGIN profile-header-cover --> */}
                  <div  class="profile-header-cover">
                  <h1 style={{position: "relative", top: "40%",}} className="text-center text-white">{cname}'s Marketplace</h1>
                  
                  </div>
                  {/* <!-- END profile-header-cover --> */}
                  {/* <!-- BEGIN profile-header-content --> */}
                  <div class="profile-header-content">
                
                     {/* <!-- BEGIN profile-header-img --> */}
                     {/* <div class="profile-header-img">
                        <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt=""/>
                     </div> */}
                     {/* <!-- END profile-header-img --> */}
                     {/* <!-- BEGIN profile-header-info --> */}
                     {/* <div class="profile-header-info">
                        <h4 class="m-t-10 m-b-5">John Doe</h4>
                        <p class="m-b-10">Web And Frontend Developer</p>
                        <a href="#" class="btn btn-xs btn-success">Edit Profile</a>
                     </div> */}
                     {/* <!-- END profile-header-info --> */}
                  </div>
                  {/* <!-- END profile-header-content --> */}
                  {/* <!-- BEGIN profile-header-tab --> */}
                  {/* <ul class="profile-header-tab nav nav-tabs">
                     <li onClick={()=>{setView(1)}} class="nav-item"><a  class="nav-link_ pointer">HOME</a></li>
                     <li onClick={()=>{setView(2)}} class="nav-item"><a  class="nav-link_ pointer">ABOUT</a></li>
                     <li onClick={()=>{setView(3)}} class="nav-item"><a  class="nav-link_ pointer">PRODUCTS</a></li>
                     <li onClick={()=>{setView(4)}} class="nav-item"><a  class="nav-link_ pointer">CONTACT</a></li>
                      <li class="nav-item"><a href="https://www.bootdey.com/snippets/view/bs4-profile-friend-list" target="__blank" class="nav-link_ active show"></a></li> 
                 
                  </ul> */}
                  {/* <!-- END profile-header-tab --> */}
               </div>
            </div>
      
         </div>
      </div>
   </div>
</div>
    	</div>
	</div>
   
  <div  className="container">

  <Navbar bg="light" expand="lg">
      <Container>
        {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={()=>{setView(1)}}>Home</Nav.Link>
            <Nav.Link onClick={()=>{setView(2)}}>About</Nav.Link>
            <Nav.Link onClick={()=>{setView(3)}}>Products</Nav.Link>
            <Nav.Link onClick={()=>{setView(4)}}>Contact</Nav.Link>
         
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


{
  view== 1?
  <>
                                        
 <section className="padding-bottom-sm ">
          
          <>
            <div className="row  mt-4">
              <div className="   col-lg-7 col-md-12 col-sm-12 ">
                <div  className=" row">
                <section>
      <div className="container">
        <div className="carousel">
          <input type="radio" name="slides" defaultChecked="checked" id="slide-1" />
          <input type="radio" name="slides" id="slide-2" />
          <input type="radio" name="slides" id="slide-3" />
          <input type="radio" name="slides" id="slide-4" />
          <input type="radio" name="slides" id="slide-5" />
          <input type="radio" name="slides" id="slide-6" />
         
          <ul  className="carousel__slides">
          {
allUID.map((v,i)=>(

<>
            <li  className="carousel__slide">
              <figure>
                <div>
                  <img src="https://picsum.photos/id/1041/800/450" alt="" />
                </div>
             
              </figure>
            </li>
            <li className="carousel__slide">
              <figure>
                <div>
                  <img src="https://picsum.photos/id/1043/800/450" alt="" />
                </div>
              
              </figure>
            </li>
            <li className="carousel__slide">
              <figure>
                <div>
                  <img src="https://picsum.photos/id/1044/800/450" alt="" />
                </div>
                
              </figure>
            </li>
            <li className="carousel__slide">
              <figure>
                <div>
                  <img src="https://picsum.photos/id/1045/800/450" alt="" />
                </div>
              
              </figure>
            </li>
            <li className="carousel__slide">
              <figure>
                <div>
                  <img src="https://picsum.photos/id/1049/800/450" alt="" />
                </div>
              
              </figure>
            </li>
            <li className="carousel__slide">
              <figure>
                <div>
                  <img src="https://picsum.photos/id/1052/800/450" alt="" />
                </div>
              
              </figure>
            </li>
            </>
))}
          </ul>    
          <ul className="carousel__thumbnails">
            <li>
              <label htmlFor="slide-1"><img src="https://picsum.photos/id/1041/150/150" alt="" /></label>
            </li>
            <li>
              <label htmlFor="slide-2"><img src="https://picsum.photos/id/1043/150/150" alt="" /></label>
            </li>
            <li>
              <label htmlFor="slide-3"><img src="https://picsum.photos/id/1044/150/150" alt="" /></label>
            </li>
            <li>
              <label htmlFor="slide-4"><img src="https://picsum.photos/id/1045/150/150" alt="" /></label>
            </li>
            <li>
              <label htmlFor="slide-5"><img src="https://picsum.photos/id/1049/150/150" alt="" /></label>
            </li>
            <li>
              <label htmlFor="slide-6"><img src="https://picsum.photos/id/1052/150/150" alt="" /></label>
            </li>
          </ul>
        </div>
      </div>
    </section>
                  {/* <div className="mx-3 px-2 py-5 col-5 col-md-5 col-lg-5 col-sm-10 ">
                    <p style={{ color: "black" }}>
                      <b style={{ color: "black" }}>Company Name:</b> {v.barcode}
                    </p>
                    <p style={{ color: "black" }}>
                      <u>
                        {" "}
                        <b> NTN No. </b>
                      </u>
                      : {v.delivery}
                    </p>
                    <p style={{ color: "black" }}>
                      <u>
                        {" "}
                        <b> Minimum Quantity </b>
                      </u>
                      {v.minimum_quantity}
                    </p>
                    <p style={{ color: "black" }}>
                      <u>
                        {" "}
                        <b>{v.pro == "yes"?<>Promotion Price:</>:<>Price:</>} </b>{" "}
                      </u>
                     { v.pro == "yes"?<>
                  {v.pro_price} <del><span style={{color:'red'}}> {v.price}</span></del></>:<> {v.price}</>}
                    </p>
                    <p style={{ height: "160px", overflowY: "auto" }}>
                      <u>
                        <b style={{ color: "black" }}>Product Description </b>{" "}
                      </u>
                      <br />
                      {v.des}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="col-xl-5">
              <div className="card">
                <div className="card-body">
                  <div className="dropdown float-end">
                    <a href="#" className="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="mdi mdi-dots-vertical" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-end">
                      {/* item*/}
                      <a href="javascript:void(0);" className="dropdown-item">Edit</a>
                      {/* item*/}
                      <a href="javascript:void(0);" className="dropdown-item">Delete</a>
                      {/* item*/}
                      <a href="javascript:void(0);" className="dropdown-item">Block</a>
                    </div>
                  </div>
                  <div className="d-flex align-items-start">
                    <img src="https://bootdey.com/img/Content/avatar/avatar1.png" className="rounded-circle avatar-lg img-thumbnail" alt="profile-image" />
                    <div className="w-100 ms-3">
                      <h4 className="my-0">Geneva McKnight</h4>
                      <p className="text-muted">@webdesigner</p>
                      <button type="button" className="btn btn-primary btn waves-effect mb-2 waves-light">Contact Now</button>
                      {/* <button type="button" className="btn btn-soft-success btn-xs waves-effect mb-2 waves-light">Message</button> */}
                    </div>
                  </div>
                  <div className="mt-3">
                    <h4 className="font-13 text-uppercase">About Me :</h4>
                    <p className="text-muted font-13 mb-3">
                      Hi I'm Johnathn Deo,has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.
                    </p>
                    <p className="text-muted mb-2 font-13"><strong>Full Name :</strong> <span className="ms-2">Geneva D. McKnight</span></p>
                    <p className="text-muted mb-2 font-13"><strong>Mobile :</strong><span className="ms-2">(123) 123 1234</span></p>
                    <p className="text-muted mb-2 font-13"><strong>Email :</strong> <span className="ms-2">user@email.domain</span></p>
                    <p className="text-muted mb-1 font-13"><strong>Location :</strong> <span className="ms-2">USA</span></p>
                  </div>                                    
                  <ul className="social-list list-inline mt-3 mb-0">
                    <li className="list-inline-item">
                      <a href="javascript: void(0);" className="social-list-item text-center border-primary text-primary"><i className="mdi mdi-facebook" /></a>
                    </li>
                    <li className="list-inline-item">
                      <a href="javascript: void(0);" className="social-list-item text-center border-danger text-danger"><i className="mdi mdi-google" /></a>
                    </li>
                    <li className="list-inline-item">
                      <a href="javascript: void(0);" className="social-list-item text-center border-info text-info"><i className="mdi mdi-twitter" /></a>
                    </li>
                    <li className="list-inline-item">
                      <a href="javascript: void(0);" className="social-list-item text-center border-secondary text-secondary"><i className="mdi mdi-github" /></a>
                    </li>
                  </ul>   
                </div>                                 
              </div> {/* end card */}
              <div className="card">
                <div className="card-body text-center">
                  <div className="row">
                    <div className="col-4 border-end border-light">
                      <h5 className="text-muted mt-1 mb-2 fw-normal">Applied</h5>
                      <h2 className="mb-0 fw-bold">116</h2>
                    </div>
                    <div className="col-4 border-end border-light">
                      <h5 className="text-muted mt-1 mb-2 fw-normal">Reviewed</h5>
                      <h2 className="mb-0 fw-bold">87</h2>
                    </div>
                    <div className="col-4">
                      <h5 className="text-muted mt-1 mb-2 fw-normal">Contacted</h5>
                      <h2 className="mb-0 fw-bold">98</h2>
                    </div>
                  </div>
                </div>
              </div>
        
            
            </div>
              {/* <div className="col-md-4 col-lg-4 col-12 col-sm-12">
                <div className="card card-body">
                  <h4 className="title py-3 text-center">About Us</h4>



                <p> Company Name:   <b> {profile.length>0?profile[0].companyname:""}</b></p> 
                <p> Address:   <b>{profile.length>0?profile[0].address:""} </b></p> 
                <p> Contact Number:   <b>{profile.length>0?profile[0].phone:""} </b></p> 
                <p> Company Gmail:   <b> {profile.length>0?profile[0].email:""}</b></p> 
                <p> Description:   <b> {company.length>0?company[0].description:""} </b></p> 
                

                 
                 <h6
                 
                 >Badges</h6>
                  <div style={{flexDirection:"row"}} className='row'>
                 


                  <div className='col-3'>
                    <img height="75px"  src='https://alahdeen.com/pro/images/bike.png'/>
                 <br/>
                 <p className="text-center" >Name</p>
                    </div>

                  <div className='col-3'>
                    <img height="75px"  src='https://alahdeen.com/pro/images/bike.png'/>
                 <br/>
                 <p className="text-center" >Name</p>
                    </div>

                  <div className='col-3'>
                    <img height="75px"  src='https://alahdeen.com/pro/images/bike.png'/>
                 <br/>
                 <p className="text-center" >Name</p>
                    </div>

                  <div className='col-3'>
                    <img height="75px"  src='https://alahdeen.com/pro/images/bike.png'/>
                 <br/>
                 <p className="text-center" >Name</p>
                    </div>

                 
                 
            
              
                </div>
                </div>
              </div> */}
            </div>
          </>
       
      </section>



      <section className="padding-bottom">
        <header className="section-heading heading-line">
          <h4 className="title-section text-uppercase">
            Request for Quotation
          </h4>
        </header>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-lg-8">
            <div
              className="card-banner banner-quote overlay-gradient"
              style={{
                backgroundImage: "url('assets/images/banners/banner9.jpg')",
              }}>
              <div className="card-img-overlay white">
                <h3 className=" text-light mx-4 my-4 ">Want to Get Quotations?</h3>
                <p className="text-light mx-4">
                  Your Product is only one click away. Click and Get your
                  desired Product
                </p>
                <a href="" className="btn btn-primary rounded-pill mx-4">
                  Learn more
                </a>
              </div>
            </div>
          </div>
          <div className=" col-lg-4 col-md-12 col-sm-12">
                <aside className="special-home-right">
                  <div className=" card-body">
                    <h5 className="pb-2  text-center text-uppercase">
                      Get Quote
                    </h5>

                    <div className="form-group">
                      <input
                        style={{ color: "black" }}
                        className="form-control"
                        placeholder="Name of Product"
                        type="text"
                        value={sheet[0].name}
                        onChange={(e) => {
                          changeArayquote(0, { name: e.target.value });
                        }}
                      />
                    </div>
                    <div  className="form-group">
                      <div  className="input-group">
                        <input
                      
                          className="form-control"
                          placeholder="Quantity"
                          type="number"
                          value={sheet[0].qty}
                          onChange={(e) => {
                            changeArayquote(0, { qty: e.target.value });
                          }}
                        />
<div className="form-control">
{/* <i className="ti ti-plus"></i> */}
                        <select
                        style={{borderColor:"transparent", fontSize:"16px"}}
                          value={sheet[0].piece}
                          onChange={(e) => {
                            changeArayquote(0, { piece: e.target.value });
                          }}
                          className=" mx-3 "
                        >
                          {/* <option>Unit</option> */}

                          <option >Units</option>
                          <option>Pieces</option>
                          <option>Grams</option>
                          <option>Kilograms</option>
                          <option>Tons</option>
                          <option>Litres</option>
                        </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 form-group">
                        <input
                          className="form-control"
                          placeholder="Approx Price Rs"
                          type="number"
                          value={sheet[0].price}
                          onChange={(e) => {
                            changeArayquote(0, { price: e.target.value });
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <textarea

                        className="form-control"
                        placeholder="Requirement Description"
                        maxLength={100}
                        
                        onChange={(e) => {
                          changeArayquote(0, { des: e.target.value });
                        }}
                      >{sheet[0].des}</textarea>
                    </div>

                    {/* <div className="form-group text-muted">
                      <div className="form-group">
                        <label>Attachment</label>
                        <input
                          type="file"
                          onChange={e => {
                            setFile(e.target.files[0]);
                          }}
                        />
                      </div>
</div> */}

                    {/* <div className="form-group">
                      <input type="checkbox" />
                      <label className="mx-1">
                        I Accept the terms & Conditions
                      </label>
                    </div> */}
                    <div
                      onClick={() => {

                        sheet[0].name===''  ?alert('Please Fill the Exact Get Quote Form') :
                        add_lead();
                        //alert(uid)
                        
                      }}
                      className="form-group text-center"
                    >
                      {/* <button className="btn btn-primary">Get quote</button> */}

                      <button
                        className="btn btn-primary"
                        // onClick={() => {
                          // setAlert(!alert);
                        // }}
                      >
                        Get Quote
                      </button>
                      {alert1 ? (
                        <>
                          {" "}
                          <SweetAlert
                            success
                            confirmBtnText="Thanks !"
                            confirmBtnBsStyle="primary"
                            timeout={2000}
                            title="Your Requirement Successfully Sent to the Seller"
                            onConfirm={() => {
                              setAlert1(!alert1);
                            }}
                            onCancel={() => {
                              setAlert1(!alert1);
                            }}
                            focusCancelBtn
                          >
                            <br />
                          </SweetAlert>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </aside>
              </div>
        
        </div>
      </section>

      <h4 className="text-center">About Company</h4>
      <hr/>

      <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="stat-widget-four">
                <div className="stat-icon">
                  <i className="ti-server"></i>
                </div>
                <div className="stat-content">
                  <div className="text-left dib">
                    <div className="stat-heading">Income Tax No</div>
                    <div className="stat-text">{company.length>0?company[0].taxno:""}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="stat-widget-four">
                <div className="stat-icon">
                  <i className="ti-user"></i>
                </div>
                <div className="stat-content">
                  <div className="text-left dib">
                    <div className="stat-heading">NTN No</div>
                    <div className="stat-text">{company.length>0?company[0].ntnno:""}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="stat-widget-four">
                <div className="stat-icon">
                  <i className="ti-stats-up"></i>
                </div>
                <div className="stat-content">
                  <div className="text-left dib">
                    <div className="stat-heading">Import Export No</div>
                    <div className="stat-text">{company.length>0?company[0].impexpno:""}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="stat-widget-four">
                <div className="stat-icon">
                  <i className="ti-pulse"></i>
                </div>
                <div className="stat-content">
                  <div className="text-left dib">
                    <div className="stat-heading">Production Capacity</div>
                    <div className="stat-text">{company.length>0?company[0].productionunits:""}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="stat-widget-four">
                <div className="stat-icon">
                  <i className="ti-server"></i>
                </div>
                <div className="stat-content">
                  <div className="text-left dib">
                    <div className="stat-heading">ARN No.</div>
                    <div className="stat-text">{company.length>0?company[0].arnno:""}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="stat-widget-four">
                <div className="stat-icon">
                  <i className="ti-user"></i>
                </div>
                <div className="stat-content">
                  <div className="text-left dib">
                    <div className="stat-heading">Timings</div>
                    <div className="stat-text">{company.length>0?company[0].fromtime:""}-{company.length>0?company[0].totime:""}</div>
                 
                     </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="stat-widget-four">
                <div className="stat-icon">
                  <i className="ti-stats-up"></i>
                </div>
                <div className="stat-content">
                  <div className="text-left dib">
                    <div className="stat-heading">Days</div>
                    <div className="stat-text">{company.length>0?company[0].fromday:""}-{company.length>0?company[0].today:""}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="stat-widget-four">
                <div className="stat-icon">
                  <i className="ti-pulse"></i>
                </div>
                <div className="stat-content">
                  <div className="text-left dib">
                    <div className="stat-heading">Affliate Membership</div>
                    <div className="stat-text">{company.length>0?company[0].affiliatemembership:""}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <section className="padding-bottom-sm my-5">
        <header className=" section-heading heading-line">
          <h4 className=" title-section text-uppercase">Our Products</h4>
        </header>
        <div   style={{ cursor: "pointer", borderRadius:"30px" }} className="row mx-3 my-4">
          {allUID.map((v, i) => (
            <>
              <div
                style={{ cursor: "pointer", borderRadius:"20px" }}
                className="col-md-4 col-sm-6 col-lg-3  card-product-grid card-sm"
              >
                <a href={"product?id=" + v.id + "s=" + v.name}>
                  <article className="card card-post">
                    <img
                  
                      heigt={100}
                      width={100}
                      src={v.img}
                      className="card-img-top"
                    />

                    <div className="card-body">
                      <h6
                        style={{ height: "20px", overflowY: "auto" }}
                        className=" text-center"
                      >
                        {v.name}
                      </h6>
                      <p className="text-center">{v.price}</p>
                      <div
                        style={{ marginTop: "10px" }}
                      
                        className="form-group text-center"
                      >
                        <button className="btn btn-primary">Get quote</button>
                      </div>
                    </div>
                  </article>
                </a>
              </div>
            </>
          ))}
        </div>
      </section>
   {
    director.length== 1 ?<>
    <section id="main-content">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
            <h3 className="mb-3">Company's Director </h3>
              <div className="card-body">
                <div className="user-profile">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="user-photo m-b-30">
                        <img className="img-fluid" src={director[0].img} alt="" />
                      </div>
                     
                    </div>
                    <div className="col-lg-8">
                      <div className="user-profile-name">{director[0].name}</div>
                      {/* <div className="user-Location">
                        <i className="ti-location-pin"></i> Designation</div> */}
                      <div className="user-job-title">Designation</div>
                   
                  
                      <div className="custom-tab user-profile-tab">
                        <ul className="nav nav-tabs" role="tablist">
                          <li role="presentation" className="active">
                            <a href="#1" aria-controls="1" role="tab" data-toggle="tab">With supporting text below as a natural lead-in to additional content,<br/>ext below as a natural lead-in to additional content </a>
                          </li>
                        </ul>
                    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
   
      
      </section>
    </>:<>
    
    <section className="pt-5 pb-5">
  <div className="container">
      <div className="row">
          <div className="col-6">
              <h3 className="mb-3">Company's Director </h3>
          </div>
          <div className="col-6 text-right">
              <a className="btn btn-primary mb-3 mr-1" href="#carouselExampleIndicators2" role="button" data-slide="prev">
                  <i className="fa fa-arrow-left"></i>
              </a>
              <a className="btn btn-primary mb-3 " href="#carouselExampleIndicators2" role="button" data-slide="next">
                  <i className="fa fa-arrow-right"></i>
              </a>
          </div>
          <div className="col-12">
              <div id="carouselExampleIndicators2" className="carousel slide" data-ride="carousel">

                  <div className="carousel-inner">
                      <div className="carousel-item active">
                          <div className="row">
{director.map((v,i)=>(
<>
<div className="col-md-4 mb-3">
                                  <div className="card">
                                      <img className="img-fluid" alt="100%x280" src={v.img}/>
                                      <div className="card-body">
                                          <h3 className="card-title text-center">{v.designation}</h3>
                                          <h4 className="card-title">{v.name}</h4>
                                          <p className="card-text">{v.description}</p>

                                      </div>

                                  </div>
                              </div>

</>


))}
                             
                            

                          </div>
                      </div>
                  
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
    </>
    }  
      

  </>:
  view==2?
  <>
   <header 
  
  // style="background-image: https://alahdeen.com/pro/images/bg.png"
   className="jumbotron">
    <div className="container">
      <h1 className="display-4">About Us</h1>
      <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat nibh nulla, eget bibendum risus dapibus sit amet.</p>
    </div>
  </header>

  {/* <!-- About Section --> */}
  <section id="about" className="container">
    <div className="row">
      <div className="col-md-6">
        <img src="https://via.placeholder.com/500x300" alt="Product Vendor" className="img-fluid"/>
      </div>
      <div className="col-md-6">
        <h2>About Product Vendor</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat nibh nulla, eget bibendum risus dapibus sit amet. Donec euismod pharetra sapien ut lobortis. Aliquam id lacinia leo, eget viverra erat.</p>
        <p>Praesent lobortis, elit sit amet finibus vehicula, tortor elit lacinia magna, a laoreet lorem nulla vel ipsum. Fusce dictum venenatis velit eu pharetra.</p>
      </div>
    </div>
  </section>
  </>:
  view==3?
  <>
                     <section className="padding-bottom-sm my-5">
        <header className=" section-heading heading-line">
          <h4 className=" title-section text-uppercase">Our Products</h4>
        </header>
        <div className="row mx-3 my-4">
          {allUID.map((v, i) => (
            <>
              <div
                style={{ cursor: "pointer",  }}
                className="col-md-3 col-sm-6 card-product-grid card-sm "
              >
                <a href={"product?id=" + v.id + "s=" + v.name}>
                  <article className="card card-post">
                    <img
                      heigt={100}
                      width={100}
                      src={v.img}
                      className="card-img-top"
                    />

                    <div className="card-body">
                      <h6
                        style={{ height: "30px", overflowY: "auto" }}
                        className=" text-center"
                      >
                        {v.name}
                      </h6>
                      <p className="text-center">{v.price}</p>
                      <div
                        style={{ marginTop: "10px" }}
                      
                        className="form-group text-center"
                      >
                        <button className="btn btn-primary">Get quote</button>
                      </div>
                    </div>
                  </article>
                </a>
              </div>
            </>
          ))}
        </div>
      </section>
  </>:
  
  view==4?
  <>
   <section className="padding-bottom">
        <header className="section-heading heading-line">
          <h4 className="title-section text-uppercase">
            Request for Quotation
          </h4>
        </header>

        <div className="row">
          <div className="col-md-8">
            <div
              className="card-banner banner-quote overlay-gradient"
              style={{
                backgroundImage: "url('assets/images/banners/banner9.jpg')",
              }}>
              <div className="card-img-overlay white">
                <h3 className=" text-light mx-4 my-4 ">Want to Get Quotations?</h3>
                <p className="text-light mx-4">
                  Your Product is only one click away. Click and Get your
                  desired Product
                </p>
                <a href="" className="btn btn-primary rounded-pill mx-4">
                  Learn more
                </a>
              </div>
            </div>
          </div>
          <div className=" col-md-4">
          <aside className="special-home-right">
                    <div className=" card-body">
                      <h5 className="pb-2  text-center text-uppercase">
                        Get Quote
                      </h5>

                      <div className="form-group">
                        <input
                          style={{ color: "black" }}
                          className="form-control"
                          placeholder="Name of Product"
                          type="text"
                          value={sheet[0].name}
                          onChange={(e) => {
                            changeArayquote(0, { name: e.target.value });
                          }}
                        />
                      </div>
                      <div  className="form-group">
                        <div  className="input-group">
                          <input
                        
                            className="form-control"
                            placeholder="Quantity"
                            type="number"
                            value={sheet[0].qty}
                            onChange={(e) => {
                              changeArayquote(0, { qty: e.target.value });
                            }}
                          />
<div className="form-control">
{/* <i className="ti ti-plus"></i> */}
                          <select
                          style={{borderColor:"transparent", fontSize:"16px"}}
                            value={sheet[0].piece}
                            onChange={(e) => {
                              changeArayquote(0, { piece: e.target.value });
                            }}
                            className=" mx-1 "
                          >
                            {/* <option>Unit</option> */}

                            <option >Units</option>
                            <option>Pieces</option>
                            <option>Grams</option>
                            <option>Kilograms</option>
                            <option>Tons</option>
                            <option>Litres</option>
                          </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 form-group">
                          <input
                            className="form-control"
                            placeholder="Approx Price Rs"
                            type="number"
                            value={sheet[0].price}
                            onChange={(e) => {
                              changeArayquote(0, { price: e.target.value });
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <textarea

                          className="form-control"
                          placeholder="Requirement Description"
                          maxLength={100}
                          
                          onChange={(e) => {
                            changeArayquote(0, { des: e.target.value });
                          }}
                        >{sheet[0].des}</textarea>
                      </div>

                      {/* <div className="form-group text-muted">
                        <div className="form-group">
                          <label>Attachment</label>
                          <input
                            type="file"
                            onChange={e => {
                              setFile(e.target.files[0]);
                            }}
                          />
                        </div>
</div> */}

                      {/* <div className="form-group">
                        <input type="checkbox" />
                        <label className="mx-1">
                          I Accept the terms & Conditions
                        </label>
                      </div> */}
                      <div
                        onClick={() => {

                          sheet[0].name===''  ?alert('Please Fill the Exact Get Quote Form') :
                          add_lead();
                          //alert(uid)
                          
                        }}
                        className="form-group text-center"
                      >
                        {/* <button className="btn btn-primary">Get quote</button> */}

                        <button
                          className="btn btn-primary"
                          // onClick={() => {
                            // setAlert(!alert);
                          // }}
                        >
                          Get Quote
                        </button>
                        {alert1 ? (
                          <>
                            {" "}
                            <SweetAlert
                              success
                              confirmBtnText="Thanks !"
                              confirmBtnBsStyle="primary"
                              timeout={2000}
                              title="Your Requirement Successfully Sent to the Seller"
                              onConfirm={() => {
                                setAlert1(!alert1);
                              }}
                              onCancel={() => {
                                setAlert1(!alert1);
                              }}
                              focusCancelBtn
                            >
                              <br />
                            </SweetAlert>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </aside>
              </div>
        
        </div>
      </section>
  </>:<></>
}
</div>

        
<div>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.css" integrity="sha256-NAxhqDvtY0l4xn+YVa6WjAcmd94NNfttjNsDmNatFVc=" crossOrigin="anonymous" />
        
          
      </div>




     
   


      <Footer />
    </body>
  );
}

export default Companypage;
