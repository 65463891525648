import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

function Mainpage() {
  const history = useNavigate();
  const [uid, setUid] = useState('');
  const [requirment, setRequirment] = useState('');
  const [frequency, setfrequency] = useState('');
  const [quantity, setQuantity] = useState('');
  const [unit, setunit] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
    }
  }, []);

  const addIntrest = async () => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('req', requirment);
    data.append('fre', frequency);
    data.append('unit', unit);
    data.append('qu', quantity);

    axios({
      method: 'post',
      url: '/pro/profile/profile_productofinterest.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        if (s.data[0].code === 'inserted') {
          alert('thanks');
        } else {
          alert('Internet issue');
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <div className="card">
              <div className="card-title">
                <h4>Add Products of Interest</h4>
              </div>
              <div className="col-6 form-group">
                <label>Requirement</label>
                <input
                  className="form-control"
                  type="text"
                  value={requirment}
                  onChange={v => {
                    setRequirment(v.target.value);
                  }}
                />
              </div>
              <div className="col-6 form-group">
                <label>Frequency</label>
                <input
                  className="form-control"
                  type="text"
                  value={frequency}
                  onChange={v => {
                    setfrequency(v.target.value);
                  }}
                />
              </div>
              <div className="col-6 form-group">
                <label>Quantity</label>
                <textarea
                  className="form-control"
                  rows={4}
                  value={quantity}
                  onChange={v => {
                    setQuantity(v.target.value);
                  }}></textarea>
              </div>
              <div className="col-6 form-group">
                <label>Quantity Unit</label>
                <select
                  className="form-control"
                  value={unit}
                  onChange={v => {
                    setunit(v.target.value);
                  }}>
                  <option>Select Unit</option>
                  <option>Kg</option>
                  <option>Tones</option>
                  <option>Pieces</option>
                </select>
              </div>

              {/* <button onClick={addIntrest} className="col-1 bg-primary px-4 my-4">Add Now</button> */}
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
                className="form-group">
                <button
                  className=" btn btn-primary px-5 mt-4"
                  onClick={() => {
                    addIntrest();
                    setAlert(!alert);
                  }}>
                  Add Now
                </button>
                {alert ? (
                  <>
                    {' '}
                    <SweetAlert
                      success
                      confirmBtnText="Thanks"
                      confirmBtnBsStyle="success"
                      timeout={2000}
                      title="Product Saved"
                      onConfirm={() => {
                        setAlert(!alert);
                        history('/interest');
                      }}
                      onCancel={() => {
                        setAlert(!alert);
                      }}
                      focusCancelBtn>
                      <br />
                    </SweetAlert>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
