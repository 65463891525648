import { Link } from "react-router-dom";

function Services() {
  return (
    <section  >
      {/* <h2 className="title-section text-center"> Our Services</h2><hr /> */}
      <div className=" card-deal">
        <div className="row  items-wrap">
        <div className="col-md-6 col-lg-2 col-sm-6 col-6 ">
            <figure className="card-product-grid ">
              <a href="/services/logistics.html" >
                <img
                  style={{ marginTop: "38px", height: "150px" }}
                  src="assets/images/logos/logistics.png"
                />
              </a>
              <div className="text-wrap p-1 ">
                <a
                  href="/services/logistics.html"
                  style={{ cursor: "pointer" }}
                >
                  Logistics{" "}
                </a>
              </div>
            </figure>
          </div>
          <div className="col-md-6  col-lg-2 col-sm-6 col-6">
            <figure className="card-product-grid">
              <a href="/services/ewallet.html" >
                <img
                  style={{ marginTop: "52px", height: "110px" }}
                  src="assets/images/logos/wallet.png"
                />
              </a>
              <div className="text-wrap p-3">
                <a href="/services/ewallet.html" style={{ cursor: "pointer" }}>
                  Pay With Us
                </a>
              </div>
            </figure>
          </div>
          <div className="col-md-6  col-lg-2 col-sm-6  col-6 ">
            <figure className="card-product-grid">
              <a href="/ware" >
                <img
                  style={{ marginTop: "55px", height: "110px" }}
                  src="assets/images/logos/warehouse.png"
                />
              </a>
              <div className="text-wrap p-3">
                <a
                  href="/ware"
                  style={{ cursor: "pointer" }}
                >
                  Warehouse{" "}
                </a>
              </div>
            </figure>
          </div>

          <div className="col-md-6  col-lg-2 col-sm-6 col-6">
            <figure className="card-product-grid">
              <a href="/services/inventory.html" >
                <img className="responsive"
                  style={{ marginTop: "35px", height: "130px" }}
                  src="assets/images/icons/photoi.jpg"
                />
              </a>
              <div className="text-wrap ">
                <a
                  href="/services/inventory.html"
                  style={{ cursor: "pointer" }}
                >
                  Inventory Management{" "}
                </a>
              </div>
            </figure>
          </div>
          <div className="col-md-6  col-lg-2 col-sm-6 col-6">
            <figure className="card-product-grid">
              <a href="/services/office.html" >
                <img
                  style={{ marginTop: "43px", height: "130px" }}
                  src="assets/images/icons/photoo.jpg"
                />
              </a>
              <div className="text-wrap p-3">
                <a href="/services/office.html" style={{ cursor: "pointer" }}>
                  Office Management{" "}
                </a>
              </div>
            </figure>
          </div>
         
         
          <div className="col-md-6  col-lg-2 col-sm-6  col-6">
            <figure className="card-product-grid">
              <a href="/services/adon.html" >
                <img
                  style={{ marginTop: "58px", height: "100px" }}
                  src="assets/images/logos/ad.png"
                />
              </a>
              <div className="text-wrap p-3">
                <a href="/services/adon.html" style={{ cursor: "pointer" }}>
                  Advertise With Us{" "}
                </a>
              </div>
            </figure>
          </div>
        
        </div>
      </div>
    </section>
  );
}
export default Services;
