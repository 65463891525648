import Nav from "../../../navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";



function Buyleads() {

  const [pid, setPid] = useState("");
  const [uid, setUid] = useState("");
  const [purchase, setpurchase] = useState([]);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
        const i = localStorage.getItem("sid");
        setPid(i);
      setUid(v[0].id);
      Sold(i);
    }
  }, []);


  const Sold = async (pid) => {
    const data = new FormData();
    data.append("iid", pid);
    data.append("val", "invoice_sale");

    axios({
      method: "post",
      url: "/pro/inventory/manage_reports.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
    
        console.log("Single Sold list ", s.data);
        const a = s.data;
        setpurchase(a);
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };
  
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Inventory Sale Reports</h4>
                  </div>
                
                  <div className="card-body">
                  <div className="card-title text-center">
                    <h4> Sale Record</h4>
                  </div>
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Product Name</th>
                            <th>PP unit</th>
                            <th>Units</th>
                            <th>Sale Amount</th>
                            <th>Time</th>
                           
                            {/* <th>Total Units</th> */}
                           
                          </tr>
                        </thead>
                        <tbody >
                        
                          {purchase.length === 0 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                  colSpan={13}
                                >
                                  No Record to show{" "}
                                </td>
                              </tr>
                            </>
                          ) : (
<>
{purchase.map((v,i)=>(
<tr>
<td> {v.id}</td>
<td> {v.name}</td>
<td> {v.ppu}</td>
<td> {v.units}</td>
<td> {v.amount}</td>
<td> {v.time}</td>




</tr>

))}
</>


                          )}
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Buyleads;
