import Nav from '../../../navbar';
function Mainpage() {
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>My Products & Services</h4>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 form-group">
                        <label>Select Primary Business Type</label>
                        <select className="form-control">
                          <option>Manfacturer</option>
                          <option>Supplier</option>
                          <option>WholeSaler</option>
                          <option>Retailer</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 form-group">
                        <label>Main Products</label>
                        <input className="form-control" />
                      </div>
                    </div>
                    <div style={{marginTop: '18px'}} className="form-group">
                      <button type="submit" className="btn btn-success mx-2">
                        Submit
                      </button>
                      <button type="reset" className="btn btn-danger">
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
