import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';

function Mainpage() {
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [logo, setLogo] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [client, setClient] = useState([
    {
      id: 1,
      name: '',
      business: '',
      email: '',
      phone: '',
      city: '',
      status: 'disable',
    },
  ]);

  const changeArayClient = (i, changes) => {
    const oldArr = [...client];
    const oldObj = oldArr[i];
    oldArr[i] = {...oldObj, ...changes};
    setClient(oldArr);
  };
  const [uid, setUid] = useState('');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
    }
  }, []);

  const addClicent = async () => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('name', name);
    data.append('email', email);
    data.append('phone', phone);
    data.append('status', 'active');
    data.append('img', logo);
    data.append('name', '');

    axios({
      method: 'post',
      url: 'pro/khata/khata_companyadd.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);
        // navigate('/newinvoice');
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">{/* <h3>Add Client</h3> */}</div>

                  {/* Form Start */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-title">
                          <h5>Add Company Information </h5>
                        </div>
                        <div className="card-body">
                          <div className="basic-elements">
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Company Name</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={name}
                                  onChange={e => {
                                    setName(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Company logo</label>
                                <input
                                  style={{border: 'none'}}
                                  type="file"
                                  className="form-control"
                                  onChange={e => {
                                    setLogo(e.target.files[0]);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Email</label>
                                <input
                                  className="form-control"
                                  type="email"
                                  value={email}
                                  onChange={e => {
                                    setEmail(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Phone</label>
                                <input
                                  className="form-control"
                                  type="number"
                                  value={phone}
                                  onChange={e => {
                                    setPhone(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <center>
                              <div
                                onClick={() => addClicent()}
                                className="btn btn-primary my-4">
                                Save
                              </div>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
