import {Link, useNavigate} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';
import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';

function Mainpage() {
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
      navigate('/login');
    } else {
      seWallet(v[0].id);
      getBlance(v[0].id);
    }
  }, []);

  const [debit, setDebit] = useState('');
  const [datedebit, setDateDebit] = useState('');
  const [credit, setCredit] = useState('');
  const [creditdate, setCreditdate] = useState('');
  const [blance, setBlance] = useState('');
  const [date, setDate] = useState('');
  const [wallet, seWallet] = useState('');

  const navigate = useNavigate();
  const getBlance = async id => {
    const data = new FormData();

    // localStorage.setItem("LOCAL_KEY", JSON.stringify(json));
    data.append('id', id);

    axios({
      method: 'post',
      url: '/pro/wallet_last.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);
        if (s.data[0].code === 'pass') {
          setBlance(s.data[0].blance);
          setDate(s.data[0].date);

          setDebit(s.data[0].debit);
          setDateDebit(s.data[0].datedebit);

          setCredit(s.data[0].credit);
          setCreditdate(s.data[0].creditdate);
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="card">
                <div
                  style={{cursor: 'pointer'}}
                  className="card-title mx-3 my-4">
                  <h2 className="my-3">E-Wallet </h2>
                  <span>Wallet ID:{wallet}</span>
                </div>

                <div id="main-content">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="card">
                        <div className="stat-widget-one">
                          <div className="stat-icon dib">
                            <i className="ti-wallet color-success border-success"></i>
                          </div>
                          <div
                            style={{cursor: 'pointer'}}
                            className="stat-content dib">
                            <div className="card-title">Available Balance</div>

                            <div className="stat-digit">{new Intl.NumberFormat('en-pk').format(blance)}</div>
                            <p>{date}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="card">
                        <div className="stat-widget-one">
                          <div className="stat-icon dib">
                            <i className="ti-layout-grid2 color-pink border-pink"></i>
                          </div>
                          <div
                            style={{cursor: 'pointer'}}
                            className="stat-content dib">
                            <div className="card-title">Last Debit</div>
                            <div className="stat-digit">{new Intl.NumberFormat('en-pk').format(debit)}</div>
                            <p>{datedebit}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="card">
                        <div className="stat-widget-one">
                          <div className="stat-icon dib">
                            <i className="ti-link color-danger border-danger"></i>
                          </div>
                          <div
                            style={{cursor: 'pointer'}}
                            className="stat-content dib">
                            <div className="card-title">Last Credit</div>
                            <div className="stat-digit">{new Intl.NumberFormat('en-pk').format(credit)}</div>
                            <p>{creditdate}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            

            <section id="main-content">
              <div className="card">
                <h4 className="my-4"> Features of E-Wallet</h4>
                <div className="row">
                  <div className="col-lg-3">
                    <Link to="/wallethistory">
                      <div className="card bg-primary">
                        <div className="stat-widget-six">
                          <div className="stat-icon">
                            <i className="ti-stats-up"></i>
                          </div>
                          <div className="stat-content">
                            <div className="text-left dib">
                              <div
                                style={{marginTop: '10px'}}
                                className="stat-heading">
                                Transaction History
                              </div>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-3">
                    <Link to="/topup">
                      <div className="card bg-success">
                        <div className="stat-widget-six">
                          <div className="stat-icon">
                            <i className="ti-stats-up"></i>
                          </div>
                          <div className="stat-content">
                            <div className="text-left dib">
                              <div
                                style={{marginTop: '10px'}}
                                className="stat-heading">
                                Add Money
                              </div>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
              
                  <div className="col-lg-3">
                    <Link to="/withdraw">
                      <div className="card bg-danger">
                        <div className="stat-widget-six">
                          <div className="stat-icon">
                            <i className="ti-stats-up"></i>
                          </div>
                          <div className="stat-content">
                            <div className="text-left dib">
                              <div
                                style={{marginTop: '10px'}}
                                className="stat-heading">
                                Send Money
                              </div>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-3">
                    <Link to="/beni">
                      <div className="card" style={{backgroundColor: '#693599'}}>
                        <div className="stat-widget-six">
                          <div className="stat-icon">
                            <i className="ti-bolt-alt text-white"></i>
                          </div>
                          <div className="stat-content">
                            <div className="text-left dib">
                              <div
                                style={{marginTop: '10px'}}
                                className="stat-heading text-white">
                                Beneficiary
                              </div>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            
            <section id="main-content">
              <div className="card">
                <div className="row">
                  <img
                    height={250}
                    src="https://alahdeen.com/pro/image/b1.png"
                  />
                </div>
              </div>
            </section>
            <section id="main-content">
              <div style={{backgroundColor: '#C0D1FF'}}>
                <h4 className="text-center py-4">Investment Plans</h4>
                <div className="container ">
                  <Accordion className="py-5">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <i className="ti-stats-up mx-3"></i>
                        Pakistan Stock Exchange
                      </Accordion.Header>
                      <Accordion.Body>
                        Pakistan Stock Exchange is the first plan based on newly
                        launched Fund of Funds Scheme, Meezan Strategic
                        Allocation Fund (MSAF). The plan has an initial term of
                        two years and will use active asset allocation strategy
                        for providing competitive returns on your investment.
                        The plan is open for subscription till December 02,
                        2016. Investment Objective & Philosophy The objective of
                        Meezan Strategic Allocation Plan – I (MSAP-I) is to
                        actively allocate its portfolio between the equity
                        schemes and fixed income/money market schemes based on
                        the macroeconomic outlook on such asset classes.
                        <br />
                        <button
                          className="btn-primary my-2"
                          onClick={() => {
                           alert("comming soon")
                           
                          }}>
                          Apply Now
                        </button>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <i className="ti-stats-up mx-3"></i>
                        Standard Chartered Debt Securities{' '}
                      </Accordion.Header>
                      <Accordion.Body>
                        Standard Chartered Debt Securities is the first plan
                        based on newly launched Fund of Funds Scheme, Meezan
                        Strategic Allocation Fund (MSAF). The plan has an
                        initial term of two years and will use active asset
                        allocation strategy for providing competitive returns on
                        your investment. The plan is open for subscription till
                        December 02, 2016. Investment Objective & Philosophy The
                        objective of Meezan Strategic Allocation Plan – I
                        (MSAP-I) is to actively allocate its portfolio between
                        the equity schemes and fixed income/money market schemes
                        based on the macroeconomic outlook on such asset
                        classes.
                        <br />
                        <button
                          className="btn-primary my-2"
                          onClick={() => {
                            alert("comming soon")
                          }}>
                          Apply Now
                        </button>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <i className="ti-stats-up mx-3"></i>
                        Meezan Security Fund
                      </Accordion.Header>
                      <Accordion.Body>
                        Meezan Strategic Allocation Plan-I (MSAP-I) is the first
                        plan based on newly launched Fund of Funds Scheme,
                        Meezan Strategic Allocation Fund (MSAF). The plan has an
                        initial term of two years and will use active asset
                        allocation strategy for providing competitive returns on
                        your investment. The plan is open for subscription till
                        December 02, 2016. Investment Objective & Philosophy The
                        objective of Meezan Strategic Allocation Plan – I
                        (MSAP-I) is to actively allocate its portfolio between
                        the equity schemes and fixed income/money market schemes
                        based on the macroeconomic outlook on such asset
                        classes.
                        <br />
                        <button
                          className="btn-primary my-2"
                          onClick={() => {
                            alert("comming soon")
                          }}>
                          Apply Now
                        </button>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <i className="ti-stats-up mx-3"></i>
                        Real Estate Investment
                      </Accordion.Header>
                      <Accordion.Body>
                        Real Estate Investment is the first plan based on newly
                        launched Fund of Funds Scheme, Meezan Strategic
                        Allocation Fund (MSAF). The plan has an initial term of
                        two years and will use active asset allocation strategy
                        for providing competitive returns on your investment.
                        The plan is open for subscription till December 02,
                        2016. Investment Objective & Philosophy The objective of
                        Meezan Strategic Allocation Plan – I (MSAP-I) is to
                        actively allocate its portfolio between the equity
                        schemes and fixed income/money market schemes based on
                        the macroeconomic outlook on such asset classes.
                        <br />
                        <button
                          className="btn-primary my-2"
                          onClick={() => {
                            alert("comming soon")
                          }}>
                          Apply Now
                        </button>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <i className="ti-stats-up mx-3"></i>
                        Industrial Constructions Investment
                      </Accordion.Header>
                      <Accordion.Body>
                        Real Estate Investment is the first plan based on newly
                        launched Fund of Funds Scheme, Meezan Strategic
                        Allocation Fund (MSAF). The plan has an initial term of
                        two years and will use active asset allocation strategy
                        for providing competitive returns on your investment.
                        The plan is open for subscription till December 02,
                        2016. Investment Objective & Philosophy The objective of
                        Meezan Strategic Allocation Plan – I (MSAP-I) is to
                        actively allocate its portfolio between the equity
                        schemes and fixed income/money market schemes based on
                        the macroeconomic outlook on such asset classes.
                        <br />
                        <button
                          className="btn-primary my-2"
                          onClick={() => {
                            alert("comming soon")
                          }}>
                          Apply Now
                        </button>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        <i className="ti-stats-up mx-3"></i>
                        Stock Exchange Investment Plans
                      </Accordion.Header>
                      <Accordion.Body>
                        Real Estate Investment is the first plan based on newly
                        launched Fund of Funds Scheme, Meezan Strategic
                        Allocation Fund (MSAF). The plan has an initial term of
                        two years and will use active asset allocation strategy
                        for providing competitive returns on your investment.
                        The plan is open for subscription till December 02,
                        2016. Investment Objective & Philosophy The objective of
                        Meezan Strategic Allocation Plan – I (MSAP-I) is to
                        actively allocate its portfolio between the equity
                        schemes and fixed income/money market schemes based on
                        the macroeconomic outlook on such asset classes.
                        <br />
                        <button
                          className="btn-primary my-2"
                          onClick={() => {
                            alert("comming soon")
                          }}>
                          Apply Now
                        </button>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        <i className="ti-stats-up mx-3"></i>
                        Bank Al Habib Investment Plans
                      </Accordion.Header>
                      <Accordion.Body>
                        Real Estate Investment is the first plan based on newly
                        launched Fund of Funds Scheme, Meezan Strategic
                        Allocation Fund (MSAF). The plan has an initial term of
                        two years and will use active asset allocation strategy
                        for providing competitive returns on your investment.
                        The plan is open for subscription till December 02,
                        2016. Investment Objective & Philosophy The objective of
                        Meezan Strategic Allocation Plan – I (MSAP-I) is to
                        actively allocate its portfolio between the equity
                        schemes and fixed income/money market schemes based on
                        the macroeconomic outlook on such asset classes.
                        <br />
                        <button
                          className="btn-primary my-2"
                          onClick={() => {
                            alert("comming soon")
                          }}>
                          Apply Now
                        </button>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </section>

            {/* <section id="main-content">
              <div className="card">
                <div className="container mx-4 row">
                  <div className="my-4 col-5">
                    <h3>How It Works</h3>
                    <br />
                    <p>Please note that these are the samples </p>
                  </div>

                  <div className="col-2">
                    <img
                      height={250}
                      src="https://alahdeen.com/pro/image/b.png"
                    />
                  </div>
                </div>
              </div>
            </section> */}
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
