import Nav from "../../../navbar";
import { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  PlusCircleFill,
  PlusCircleDotted,
  Trash,
  Trash3,
} from "react-bootstrap-icons";
import { set } from "firebase/database";

function Mattersheetdetails() {
  
  

  const [uid, setUid] = useState("");
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    
    if (v === null) {
    } else {
      setUid(v[0].id);
      console.log("All " ,v)
     
     setName(v[0].name);
     setCname(v[0].cname);
     
     setPhone(v[0].phone);
     setEmail(v[0].email);
     setCityName(v[0].city);
     setCnic(v[0].cnic);
     setAddress(v[0].add);
      
     
      getCities();
     
    }
  }, []);

      
    
  const navigate = useNavigate();
  const [passShow, setpassShow] = useState(false);
  const [passShow2, setpassShow2] = useState(false);
  const [error, seterror] = useState("");

  const [name, setName] = useState("");
  const [cname, setCname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cnic, setCnic] = useState("");
  const [pass, setPass] = useState("");
  const [pas2, setPass2] = useState("");

  const [cityName, setCityName] = useState("Karachi");
  const [cityIndex, setCityIndex] = useState();
 
  const [province, setProvince] = useState();
  
  const [listcities, setCitiesList] = useState([]);
 
  const [address, setAddress] = useState("");

  const [status, setStatus] = useState("1");
  const [otp, setOTP] = useState("");


  




  const ValidateEmail=(inputText)=>
  {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if(inputText.match(mailformat))
  {
  
  
  return true;
  }
  else
  {
  
  return false;
  }
  }
  const getCities = async () => {
    const data = new FormData();
  
        axios({
          method: "post",
          url: "/pro/auth/cities_list.php",
          data: data,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((s) => {
            setCitiesList(s.data)
          })
          .catch((s) => {
          
          });
      
    
  };
 
  
  const sendotpVerify=(uid)=>{
    const data = new FormData();

    data.append("val", "verifyotp");
    data.append("otp",otp);
    data.append("uid",uid);

    data.append("name", name);
    data.append("cname", cname);
    data.append("email", email);
  
    data.append("address", address);
     data.append("cnic", cnic);

    axios({
      method: "post",
      url: "/pro/auth/manage_user.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("signup response",s.data)
        if (s.data[0].code == "pass") {

          setStatus("1")
          
          localStorage.setItem("USER", JSON.stringify(s.data));

        } else {
          alert("Worng OTP! Try again?");
        }
      })
      .catch((s) => {
        
      });
  }


  const sendotp=(uid)=>{
    const data = new FormData();

    data.append("val", "sendotp");
    data.append("uid",uid);

    axios({
      method: "post",
      url: "/pro/auth/manage_user.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("signup response",s.data)
        if (s.data[0].code == "pass") {

          setStatus("2")
        } else {
          alert("something went wrong.Try again.");
        }
      })
      .catch((s) => {
        
      });
  }


  const SigupReady=()=>{
    const data = new FormData();
    data.append("name", name);
    data.append("cname", cname);
    data.append("email", email);
    data.append("city", cityName);
    data.append("address", address);
    data.append("province", province);
    data.append("img", "");
    data.append("cnic", pass);

    data.append("val", "phone");

    axios({
      method: "post",
      url: "/pro/auth/manage_user.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("signup response",s.data)
        if (s.data[0].code == "pass") {
          
        } else if (s.data[0].code == "already") {
          

         
        } else {
          alert("something went wrong.Try again.");
        }
      })
      .catch((s) => {
        
      });
  }

  const SignUpNow = async () => {

      if (name == "") 
      {
        seterror("name")
        alert("Enter Name");
      }
      else if (cname == ""){
        seterror("cname")
        alert("Enter Company Name");
      }else if(cityName == ""){
        seterror("city")
        alert("Select City");
      } else {

        if(email=="")
        {
          sendotp(uid)

        }else{
          if(ValidateEmail(email))
          {
            sendotp(uid)
          }else{
    alert("Enter valid Email")
    seterror("email")
          }
        }



     
      }
    
  };

  return (
    <body>
      <Nav />
     
      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
           
            
          <div  className="unix-login">
        <div className="container-fluid">
         <div className="card">
         <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="login-content">
                <div className="login-logo">
                  {/* <a href="index.html">
                    <span>Alahdeen</span>
                  </a> */}
                </div>
                <div  className="login-form">
              
                  


                  <div className="row">
                  <div className= {error =="name"?"form-group col-6 has-error":"form-group col-6"}  >
                    <label> Full Name </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name"
                      value={name}
                      onChange={(v) => {
                        setName(v.target.value);
                      }}
                    />
                  </div>
                  <div className= {error =="cname"?"form-group col-6 has-error":"form-group col-6"} >
                    <label>Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Company Name"
                      value={cname}
                      onChange={(v) => {
                        setCname(v.target.value);
                      }}
                    />
                  </div>
                  </div>
               
                  <div className="row">
                 
                  <div  className= {error =="phone"?"form-group col-6 has-error":"form-group col-6"} >
                  <div class="col-auto">
                  <label>Phone No.</label>
                  <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroupPrepend">+92</span>
        </div>
        <input disabled type="text"  placeholder="Phone No." aria-describedby="inputGroupPrepend" className="form-control" value={phone}   onChange={(v) => {
          if(v.target.value.length<=10)
          {
            setPhone(v.target.value);
          }else{
            alert("Enter valid Phone Number")
          }
                        

                      }} />
                       
       
      </div>
</div>

     
                  
                  </div>

                  <div  className= {error =="email"?"form-group col-6 has-error":"form-group col-6"} >
                    <label>Email (optional) </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                     
                      onChange={(v) => {
                        setEmail(v.target.value);
                      }}
                    />
                  </div>

                  </div>
                  

                  

                <div className="row">
                  <div  className= {error =="city"?"form-group col-6 has-error":"form-group col-6"} >
                    <label>City</label>
                    <select
                    disabled
                      value={cityName}
                      onChange={(v) => {

                          
                          setCityName(listcities[v.target.value].city)
                          setProvince(listcities[v.target.value].admin_name)

                      }}
                      className="form-control"
                    >
                  {
                  listcities.map((v,i)=>(
<>
<option value={v.city} >{v.city}</option>
</>
                  ))
                  }
                    </select>
                  </div>


                    <div  className= {error =="add"?"form-group col-6 has-error":"form-group col-6"} >
                      <label>Address (optional)</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Address"
                        value={address}
                        onChange={(v) => {
                          setAddress(v.target.value);
                        }}
                      />
                    </div>
                    </div>


                         
                  {/* <div className="form-group">
                    <label>Add Image</label>y
                    <br />
                    <input
                      className="form-control"
                      type="file"
                      onChange={(v) => {
                        setImg(v.target.files[0]);
                      }}
                    />
                  </div> */}

                  {/* <div className="checkbox">
                    <label>
                      <input type="checkbox" /> Agree the terms and policy
                    </label>
                  </div> */}



<div className="form-group">
                    <label style={{color:"red"}}><b>CNIC No.</b></label>
                    <input
                    type="text"
                    className="form-control"
                      placeholder="CNIC No."
                      pattern="^[0-9]*[.,]?[0-9]*$"
                      value={cnic}
                      onChange={(v) => {
                        const a = v.target.value;

                        if (a.length === 5) {
                          setCnic(a + "-");
                        } else if (a.length === 13) {
                          setCnic(a + "-");
                        } else if (a.length == 16) {
                        } else {
                          setCnic(v.target.value);
                        }
                      }}
                    />

{
  status=="2"?<>
  <div className="form-group">
                    <label >OTP</label>
                    <input
                    type="text"
                    className="form-control"
                      placeholder="OTP"
                      pattern="^[0-9]*[.,]?[0-9]*$"
                      value={otp}
                      onChange={(v) => {
                        const a = v.target.value;

                        if (a.length <7) {
                          setOTP(a);
                        } 
                      }}
                    />
                  </div>
                  </>:<></>
}

                  </div>

                  <button
                    type="submit"
                    onClick={() => {
                    if(status=="1")
                    {
                      SignUpNow(); 
                    }
                   else if(status=="2")
                    {
                      setOTP("")
                      sendotpVerify(uid)
                    }
                      
                    }}
                    className="btn btn-primary btn-flat m-b-30 m-t-30"
                  >
                    Update
                  </button>

                  <div className="register-link m-t-15 text-center">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
         </div>
        </div>
      </div>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mattersheetdetails;
