import Nav from "./navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";


function Mainpage() {
  const navigate = useNavigate();
  const [allviewMsg, setAllViewMsg] = useState([]);
  const [chat, setChat] = useState([]);
  const [chatid, setChatid] = useState("");
  const [uid, setUid] = useState("");
  const [lead, setLead] = useState([]);
  const [uiduser, setuiduser] = useState("");

  const [debit, setDebit] = useState('');
  const [datedebit, setDateDebit] = useState('');
  const [credit, setCredit] = useState('');
  const [creditdate, setCreditdate] = useState('');
  const [balance, setBalance] = useState('');
  //03039965869 
  const [date, setDate] = useState('');
  const [userType, setUserType] = useState('');


  useEffect(() => {
    // const search = window.location.search;
    // const params = new URLSearchParams(search);

    // console.log(params.get('name'))
    // console.log(params.get('fname'))
    // console.log(params.get('age'))
    // console.log(params.get('address'))

    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
    } else {
      // if (userType=="")
      const ti= v[0].roll;
      if (ti=== ""){
        navigate('/profile');
      }
      else{

      }
      
      setUid(v[0].id);
      setUserType(v[0].roll)
      lead_view_all(v[0].id,v[0].cat);
      const uiduser = localStorage.getItem("chatuserid");
      // console.log(uiduser);
      setuiduser(uiduser);
      viewchat(v[0].id, uiduser);
      Viewchatbox(v[0].id);
      getBlance(v[0].id);
    }
  }, []);


  const checkvalidity = (lead_id) => {   
    localStorage.setItem(
      'leadid',
      lead_id,
    );
    // navigate('/chat');
    navigate('/sendquote');
  }


  const lead_view_all = async (id,cat) => {
    const data = new FormData();
    data.append("uid", id);
    data.append("cat", cat);

    axios({
      method: "post",
      url: "/pro/lead_view_all.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        console.log("lead list ", s.data);
        const a = s.data;
        setLead(a);
      })
      .catch((s) => {
        // console.log("here lead_view_all ", s);
      });
  };

  const viewchat = async (id, i) => {
    const data = new FormData();
    data.append("one", id);
    data.append("two", i);

    axios({
      method: "post",
      url: "/pro/chatbox/chatbox.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("chat list ", s.data);
        const a = s.data;
        if (a[0].code == "pass") {
          // setProductList(a);

          localStorage.setItem("ProductListInventory", JSON.stringify(s.data));
        }
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const Viewchatbox = async (id) => {
    const data = new FormData();
    data.append("uid", id);

    axios({
      method: "post",
      url: "/pro/chatbox/view_inbox.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("chatbox view ", s.data);
        const a = s.data;
        setChat(a);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const viewinbox = async (chatid) => {
    const data = new FormData();
    data.append("status", "view");
    data.append("cid", chatid);

    axios({
      method: "post",
      url: "/pro/chatbox/chat.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log("chatbox view ", s.data);
        setAllViewMsg(s.data);
        // console.log(chatid);
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };
  const getBlance = async id => {
    const data = new FormData();

    // localStorage.setItem("LOCAL_KEY", JSON.stringify(json));
    data.append('id', id);

    axios({
      method: 'post',
      url: '/pro/wallet_last.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);
        if (s.data[0].code === 'pass') {
          setBalance(s.data[0].blance);
          setDate(s.data[0].date);

          setDebit(s.data[0].debit);
          setDateDebit(s.data[0].datedebit);

          setCredit(s.data[0].credit);
          setCreditdate(s.data[0].creditdate);
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
           
          {userType==""?<>
          
          </>:<>
          <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>Dashboard</h3>
                    <br />
               <h4> E-Wallet ID : {uid}</h4>   
               <br /> 
                    <div className="row">
            <div className="col-lg-3">
              <div className="card bg-primary">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-stats-up"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Available Balance</div>
                      <div className="stat-text">{new Intl.NumberFormat('en-pk').format(balance)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-danger">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-bolt-alt"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Last Debit</div>
                      <div className="stat-text">{new Intl.NumberFormat('en-pk').format(debit)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-success">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti-stats-up"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Last Credit </div>
                      <div className="stat-text">{new Intl.NumberFormat('en-pk').format(credit)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
            <Link to="/topup">
              <div className="card bg-success">
                <div className="stat-widget-six">
                  <div className="stat-icon">
                    <i className="ti ti-plus"></i>
                  </div>
                  <div className="stat-content">
                    <div className="text-left dib">
                      <div className="stat-heading">Add Money</div>
                      <div className="stat-text"><br/></div>
                    </div>
                  </div>
                </div>
              </div>
              </Link>
            </div>
          
          </div>
          <br/>
          <br/>
                  </div>
                

             

                  <section id="main-content">
                    <div className="row">
                      <div
                       
                        className="card col-lg-12 col-sm-12 col-md-12"
                      >
                        <center>
                          <h4 className="my-2">Latest Buy Requests</h4>
                        </center>{" "}
                      
                        <div  style={{ height: "630px", overflowY: "auto" }} className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>#</th>

                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Unit</th>
                            <th>Description</th>
                            <th>Time</th>
                          

                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lead.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {lead.map((v, i) => (
                              
                                <tr>
                                  <td>{v.id}</td>
                                  <td>{JSON.parse(v.sheet)[0].name} </td>
                                  <td>{JSON.parse(v.sheet)[0].qty} </td>
                                  <td>{JSON.parse(v.sheet)[0].price} </td>
                                  <td>{JSON.parse(v.sheet)[0].piece} </td>
                                  <td>{JSON.parse(v.sheet)[0].des} </td>
                                  <td>{v.time} </td>
                           
                                  <td>
                                    <button
                                      style={{borderRadius: '14px'}}
                                      className="btn-primary"
                                      onClick={() => {
                                        checkvalidity( v.lead_id)
                                      
                                      }}>
                                     Send Quote
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                      
                      
                  



                      </div>
                      {/* <div className=" col-md-3 col-lg-5 col-xl-3 mb-4 mb-md-0">
                        <div className="card">
                          <center>
                            <h5 className="font-weight-bold mb-3  text-lg-start">
                              Inbox
                            </h5>
                          </center>
                          <div className="">
                            <div  style={{ height: "635px", overflowY: "auto" }} className="card-body">
                              <ul
                               
                                className="list-unstyled mb-0"
                              >
                                <li
                                  style={{ backgroundColor: "#eee" }}
                                  className="card p-2 border-bottom"
                                >
                                  {chat.map((v, i) => (
                                    <>
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          navigate("/chat");
                                        }}
                                        className="d-flex justify-content-between"
                                      >
                                        <div className="d-flex flex-row">
                                          <img
                                            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-8.webp"
                                            alt="avatar"
                                            className="rounded-circle d-flex align-self-center me-3 "
                                            width="60"
                                          />
                                          <div className="pt-1">
                                            <p className="fw-bold mb-0">
                                              {v.name}
                                            </p>
                                            <p className="small text-muted">
                                              Hello, Are you there?
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                      <hr />
                                    </>
                                  ))}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </section>
                  {/* Premium Services Start */}

                  {/* Premium Services End */}
                </div>
              </div>
            </section></>}
            
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
