import React from "react";
import Footer from "./footer";
import Nav from "./navbar";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { months } from "moment";
function App() {
  const navigate = useNavigate();

  const [alert, setAlert] = useState();
  const [uid, setUid] = useState();
  const [id, setId] = useState();
  const [rent, setRent] = useState();
  const [ownerid, setOwnerid] = useState();
  const [tamount, setTamount] = useState();
  const [month, setMonth] = useState();
  const [balance, setBlance] = useState();

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("USER"));
    if (v === null) {
      navigate('/login')
    } else {
      setUid(v[0].id);
      const id = localStorage.getItem("id");
      const userId = localStorage.getItem("uid");
      const rent = localStorage.getItem("rent");
      const ownerid = localStorage.getItem("ownerid");
      // console.log("userid ", id);
      getBlance(v[0].id)
      setId(id);
      setRent(rent);
      setOwnerid(ownerid);
    }
  }, []);

  const getBlance = async d => {
    const data = new FormData();
    // localStorage.setItem("LOCAL_KEY", JSON.stringify(json));
    data.append('id', d);
    axios({
      method: 'post',
      url: '/pro/wallet_last.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        // console.log(s.data);
        if (s.data[0].code === 'pass') {
          setBlance(s.data[0].blance);
        }
      })
      .catch(s => {
        // console.log('here catch =>>', s);
      });
  };
  const getMonths = async (d1,d2) => {
    // console.log(d1)
    let dt1 = new Date(d1);
    let dt2 = new Date(d2);
    let diff =(dt2.getTime() - dt1.getTime()) / 1000;
    (diff /= (60 * 60 * 24 * 7 * 4));
    const months = Math.abs(Math.round(diff))
    if(months>=1){
      setMonth(months)
      let v = (rent*months);
      setTamount(v);
      
      //console.log(v)
    }else{

    }  
  }
  
  const warehouse_book = async (v) => {
    const data = new FormData();

    data.append("val", "in");
    data.append("bookedby", uid);
    data.append("wid", id);
    data.append("sdate", start);
    data.append("edate", end);
    data.append("amount", v);
    data.append("ownerid", ownerid);

    // data.append('array', JSON.stringify(client));

    axios({
      method: "post",
      url: "/mainsite/warehouse_requests.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log(s.data);
        if(s.data[0].code =="pass"){
          setAlert(!alert);
        }
        
        //navigate("/ware");
      })
      .catch((s) => {
        // console.log("here catch =>>", s);
      });
  };

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  //   const [uid, setUid] = useState('');
  return (
    <>
      <Nav />

      <div className="container">
        <section
          style={{ marginTop: "30px", marginBottom: "140px" }}
          className="padding-bottom-sm "
        >
          {/* <h3>Warehouse Booking Form</h3> */}

          <div className="p-5 card">
            <header className="section-heading heading-line">
              <h4 className="title-section text-uppercase">
                Warehouse Booking
              </h4>
            </header>
            <div className="row mt-3">
              <div className="col-6">
                <label className="form-group">Warehouse ID </label>
                <input
                  value={id}
                  className="form-control"
                  disabled
                  type="number"
                />
              </div>
              <div className="col-6">
                <label className="form-group">Rent Per Month </label>
                <input
                  value={rent}
                  className="form-control"
                  disabled
                  type="number"
                />
              </div>
            </div>
            <div className="my-3 row">
              <div className="col-6">
                <label className="form-group">Start Date</label>
                <input
                  value={start}
                  onChange={(v) => {
                    setStart(v.target.value);
                  }}
                  className="form-control"
                  type="date"
                />
              </div>
              <div className="col-6">
                <label className="form-group">End Date</label>
                <input
                  value={end}
                  onChange={(v) => {
                    setEnd(v.target.value);
                    if(start!=""){
                      getMonths(start,v.target.value);
                    }
                  }}
                  className="form-control"
                  type="date"
                />
              </div>
            </div>
            <div className="row mt-3">
            <div className="col-6">
                <label className="form-group">Total E-Wallet Balance :</label>
                <input
                  value={balance}
                  className="form-control"
                  disabled
                  type="number"
                />
                
              </div>
              <div className="col-6">
                <label className="form-group">Total Month :</label>
                <input
                  value={month}
                  className="form-control"
                  disabled
                  type="number"
                />
              </div>
            </div>
            <div className="row mt-3">
            <div className="col-12">
                <label className="form-group">Total Amount to be Paid :</label>
                <input
                  value={tamount}
                  className="form-control"
                  disabled
                  type="number"
                />
                <label className="form-group">After booking request, total amount will be deducted from your account. Amount will be refund if not accepted from owner within 7 days.</label>
              </div>
              </div>
            <center>
              {/* <button
                onClick={() => {
                  warehouse_book();
                }}
                className="btn-success py-2 px-3 my-5"
              >
                Book Now
              </button> */}

              <button
                className=" btn btn-success mt-5 px-5"
                onClick={() => {
                  if(month!=""){
                    warehouse_book(tamount)
                  }else{
                    // console.log("empty")
                  }
                }}
              >
                Confirm Request
              </button>
              {alert ? (
                <>
                  {" "}
                  <SweetAlert
                    success
                    confirmBtnText="Thanks !"
                    confirmBtnBsStyle="success"
                    timeout={100000}
                    title="Warehouse Request Sent"
                    onConfirm={() => {
                      setAlert(!alert);
                      navigate("/requestedbooking");
                    }}
                    onCancel={() => {
                      setAlert(!alert);
                      navigate("/ware");
                    }}
                    focusCancelBtn
                  >
                    <br/>
                  </SweetAlert>
                </>
              ) : (
                <></>
              )}
            </center>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default App;
