import Nav from '../../../navbar';
import {Link, useNavigate} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert-te';
import {useState, useEffect} from 'react';
import Papa from "papaparse";
import axios from 'axios';
import { Alert } from 'bootstrap';

function Mainpage() {
  
  const [uid, setuid] = useState('');
  const [cat, setCat] = useState('cat');
  const [name, setName] = useState('');
  const [video, setVideo] = useState('');
  const [price, setPrice] = useState('');
  const [code, setCode] = useState('');
  const [min, setMin] = useState('');
  const [delivery, setDelivery] = useState('');
  const [desc, setDesc] = useState('');

  const [vkey, setVkey] = useState('');
  const [extra, setExtra] = useState([]);
  const [dkey, setDkey] = useState('');

  const [pdf, setPdf] = useState();
  const [img, setImage] = useState();
  const [imga, setImage_a] = useState();
  const [imgb, setImage_b] = useState();

  const [msg, setMsg] = useState('');
  const [alertt, setAlert] = useState(false);

  const [all, setAll] = useState([]);

  const navigate = useNavigate();
const addProduct = async ()=> {

    const data = new FormData();
    data.append('name', name);
    data.append('link', video);
    data.append('price', price);
    data.append('code', code);
    data.append('des', desc);
    data.append('qty', min);
    data.append('dry', delivery);
    data.append('extra', JSON.stringify(extra));
    data.append('cat', cat);
    data.append('uid', uid);
    data.append('vkey', vkey);
    data.append('dkey', dkey);
    data.append('pdf', pdf);
    data.append('img', img);
    data.append('img_a', imga);
    data.append('img_b', imgb);
    
        axios({ 
          method: 'post',
          url: '/pro/add_product.php',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'},
        })
          .then(s => {
            

            if (s.data[0].code==='pass') {
              alert("thanks")
              
            }
      
          })
          .catch(s => {
            console.log('here catch =>>', s);
          });
      };

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
      navigate('/login');
    } else {
      setuid(v[0].id);
    }
  }, []);

  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
          {alertt ? (
                        <>
                          <SweetAlert
                            show={alertt}
                            title={msg}
                            text="SweetAlert in React"
                            onConfirm={() => {
                              setAll([]);
                              setAlert(false);
                             
                              
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                
                  

                  {/* Form Start */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-title"></div>
                        <div className="card-body">
                          <div className="basic-elements">
                      {
                        all.length===0?<>   <div className="row">
                           
                        <div className="py-1 px-3col-12 form-group">
                          <label>Select Only CSV File</label>
                          <input className="form-control"
type="file"
accept=".csv"
onChange={(e) => {
  const files = e.target.files;
  
  console.log(files);



  if (files) {
    
  if(files[0].type=="text/csv")
  {
    console.log(files[0]);
    
    Papa.parse(files[0], {
      complete: function(results) {


        console.log("Finished:", results.data);
        setAll(results.data)
      }}
    )
  }else{
    
    setMsg("Select Only csv file")
    setAlert(true)
  }
  }


}}
/>
                        </div>
                      </div></>:<>    <button onClick={(t)=>{
                          setAll([]) }} className="btn btn-primary my-4">
                          Rest
                        </button>
                       
                        
                        
                        </>
                      }
                

                         {
                          all.map((obj)=>(
                            <div className="row">
                            <div className="col-lg-12">
                              <div className="card">
                                <div className="card-title">
                                  <h5>Product Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="basic-elements">
                                    <div
                                      className="row my-4"
                                      style={{
                                        height: '100px',
                                        border: '1px dotted black',
                                      }}>
                                      <label className="my-2">Feature Image Upload</label>
                                      <input  onChange={v => {
                                setImage(v.target.files[0]);
                              }}  type="file" />
                                    </div>
                                    <div className="row">
                                      <div className="col-6 form-group">
                                        <label>Product Name </label>
                                        <input
                                          value={obj[0]}
                                          
                                          className="form-control"
                                          type="text"
                                        />
                                      </div>
                                      <div className="col-6 form-group">
                                        <label>Product Video Link</label>
                                        <input
                                          value={obj[1]}
                                          onChange={v => {
                                            setVideo(v.target.value);
                                          }}
                                          className="form-control"
                                          type="text"
                                        />
                                      </div>
                                    </div>
        
                                    <div className="row">
                                      <div className="col-6 form-group">
                                        <label>Product Price</label>
                                        <input
                                          value={obj[2]}
                                          onChange={v => {
                                            setPrice(v.target.value);
                                          }}
                                          className="form-control"
                                          type="text"
                                        />
                                      </div>
                                      <div className="col-6 form-group">
                                        <label>Product Code</label>
                                        <input
                                          value={obj[3]}
                                          onChange={v => {
                                            setCode(v.target.value);
                                          }}
                                          className="form-control"
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 form-group">
                                        <label>File Pdf</label>
                                        <input className="form-control"  onChange={v => {
                                setPdf(v.target.files[0]);
                              }}  type="file" />
                                      </div>
                                    </div>
        
                                    <div className="row">
                                      <div className="col-12 form-group">
                                        <label>Select Main Category</label>
                                        <select className="form-control">
                                          <option>Drugs & Pharmaceuticals</option>
                                          <option>Hospital Accessories</option>
                                          <option>IT</option>
                                          <option>Apparell</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="mx-2row">
                                      <div className="col-12 form-group">
                                        <label>Select Sub Category</label>
                                        <select className="form-control">
                                          <option>Drugs & Pharmaceuticals</option>
                                          <option>Hospital Accessories</option>
                                          <option>IT</option>
                                          <option>Apparell</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="mx-4 row">
                                      <div className="col-12 form-group">
                                        <label>Select Micro Category</label>
                                        <select className="form-control">
                                          <option>Drugs & Pharmaceuticals</option>
                                          <option>Hospital Accessories</option>
                                          <option>IT</option>
                                          <option>Apparell</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-6 form-group">
                                        <label>Minimum Order Qty</label>
                                        <input
                                          value={obj[5]}
                                          onChange={v => {
                                            setMin(v.target.value);
                                          }}
                                          className="form-control"
                                          type="text"
                                        />
                                      </div>
                                      <div className="col-6 form-group">
                                        <label>Estimated Delivery Time</label>
                                        <input
                                          value={obj[6]}
                                          onChange={v => {
                                            setDelivery(v.target.value);
                                          }}
                                          className="form-control"
                                          type="text"
                                        />
                                      </div>
                                    </div>
        
                                    <div className="row">
                                      <label>Product Description</label>
                                      <textarea
                                       value={obj[4]}
                                        onChange={v => {
                                          setDesc(v.target.value);
                                        }}
                                        className="form-control"
                                        rows={6}
                                      />
                                    </div>
                                    <div className=" my-4 row">
                                      <div className="col-6 form-group">
                                        <label>Product Detail Key</label>
                                        <input
                                        value={obj[7]}
                                          onChange={v => {
                                            setDkey(v.target.value);
                                          }}
                                          className="form-control"
                                          type="text"
                                        />
                                      </div>
                                      <div className="col-6 form-group">
                                        <label>Product Key Value</label>
                                        <input
                                          value={obj[8]}
                                          onChange={v => {
                                            setVkey(v.target.value);
                                          }}
                                          className="form-control"
                                          type="text"
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 form-group">
                                        <label>More Details</label>
                                        <input className="form-control"  onChange={v => {
                                setImage_a(v.target.files[0]);
                              }}  type="file" />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 form-group">
                                        
                                        <input className="form-control"  onChange={v => {
                                setImage_b(v.target.files[0]);
                              }}  type="file" />
                                      </div>
                                    </div>
                                    
                                      <div className="btn btn-primary" onClick={
                                        ()=>{
                                          addProduct()
                                        }
                                      } >
                                        Save Product
                                      </div>
                                
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          ))
                        }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
