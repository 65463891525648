import Nav from '../../../navbar2';
import {Link, useNavigate} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import {useState, useEffect} from 'react';
import axios from 'axios';
function Mainpage() {
  const [alert, setAlert] = useState('');
  const [walletid, setwalletid] = useState('');
  const [walletamount, setWalletamount] = useState('');
  const [accountno, setAccountno] = useState('');
  const [bankname, setBankname] = useState('');
  const [bankamount, setBankamount] = useState('');
  const [name, setname] = useState('');
  const navigate = useNavigate();

  const getAccount = async id => {
    const data = new FormData();

    data.append('uid', id);

    axios({
      method: 'post',
      url: '/pro/getaccount.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);

        if (s.data[0].code === 'pass') {
          setname(s.data[0].name);
        }
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  const sendPayment = async id => {
    const data = new FormData();

    data.append('uidd', walletid);
    data.append('uid', id);
    data.append('amount', walletamount);

    axios({
      method: 'post',
      url: '/pro/send_payment.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);

        if (s.data == '') {
          alert('issue');
        } else {
          if (s.data[0].code === 'pass') {
            alert('payment Send');
          } else {
            alert('Balance Low');
          }
        }
      })
      .catch(s => {
        // console.log('here catch =>>', s);
        alert('Low Balnce');
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            <section id="main-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="mb-5">Withdraw Amount</h4>
                      {/* <!-- Nav tabs --> */}
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          {' '}
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#home"
                            role="tab">
                            <span className="hidden-sm-up">
                              <i className="ti-home"></i>
                            </span>{' '}
                            <span className="hidden-xs-down">
                              Direct Transaction
                            </span>
                          </a>{' '}
                        </li>

                        <li className="nav-item">
                          {' '}
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#messages"
                            role="tab">
                            <span className="hidden-sm-up">
                              <i className="ti-home"></i>
                            </span>{' '}
                            <span className="hidden-xs-down">
                              {' '}
                              Indirect Transaction
                            </span>
                          </a>{' '}
                        </li>
                      </ul>
                      {/* <!-- Tab panes --> */}
                      <div className="tab-content tabcontent-border">
                        <div className="tab-pane active" id="home" role="tabpanel">
                          <div className="p-20">
                            <div className="content-wrap">
                              <div className="main">
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="card">
                                        <div className="card-title">
                                          <h4>E-Wallet to E-Wallet</h4>
                                        </div>
                                        <div className="card-body">
                                          <div className="basic-form">
                                            <div className="form-group">
                                              <label>E-Wallet ID</label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter E-Wallet ID"
                                                value={walletid}
                                                onChange={v => {
                                                  setwalletid(v.target.value);
                                                }}
                                              />
                                            </div>
                                            {name == '' ? (
                                              <></>
                                            ) : (
                                              <>
                                                {' '}
                                                <div className="form-group">
                                                  <label>E-Wallet Name</label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    disabled
                                                    value={name}
                                                  />
                                                </div>
                                              </>
                                            )}
                                            <div className="form-group">
                                              <label>Amount</label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter Amount"
                                                value={walletamount}
                                                onChange={v => {
                                                  setWalletamount(
                                                    v.target.value,
                                                  );
                                                }}
                                              />
                                            </div>

                                            <button
                                              type="submit"
                                              onClick={() => {
                                                if (name === '') {
                                                  getAccount(walletid);
                                                } else {
                                                  const v = JSON.parse(
                                                    localStorage.getItem(
                                                      'USER',
                                                    ),
                                                  );

                                                  sendPayment(v[0].id);
                                                }
                                              }}
                                              className="btn btn-primary">
                                              {name == '' ? 'send' : 'Confirm'}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane p-20"
                          id="messages"
                          role="tabpanel">
                          <div className="content-wrap">
                            <div className="main">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="card">
                                      <div className="card-title">
                                        <h4>E-Wallet to Bank</h4>
                                      </div>
                                      <div className="card-body">
                                        <div className="basic-form">
                                          <form>
                                            <div className="form-group">
                                              <label>
                                                Enter Account Number
                                              </label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter Account Number"
                                                value={accountno}
                                                onChange={v => {
                                                  setAccountno(v.target.value);
                                                }}
                                              />
                                            </div>
                                            <div className="row">
                                              <div className="col-6 form-group">
                                                <label>Bank Name</label>
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  placeholder="Enter Bank Name"
                                                  value={bankname}
                                                  onChange={v => {
                                                    setBankname(v.target.value);
                                                  }}
                                                />
                                              </div>
                                              <div className="col-6 form-group">
                                                <label>Amount</label>
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  placeholder="Enter Amount"
                                                  value={bankamount}
                                                  onChange={v => {
                                                    setBankamount(
                                                      v.target.value,
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <button
                                              type="submit"
                                              className="btn btn-primary">
                                              Send
                                            </button>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4>Withdrawal History</h4>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Bank Name</th>
                            <th>Account Number</th>
                            <th>Account Title</th>
                            <th>Action</th>
                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Alahdeen</td>
                            <td>01212122</td>
                            <td>Ahmed Ali</td>
                            <td>
                              <button
                                style={{borderRadius: '16px'}}
                                className="px-4 btn-primary"
                                onClick={() => {
                                  setAlert(!alert);
                                }}>
                                Pay
                              </button>
                              {alert ? (
                                <>
                                  {' '}
                                  <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText="Confirm!"
                                    confirmBtnBsStyle="primary"
                                    title="Are you sure?"
                                    onConfirm={() => {
                                      setAlert(!alert);
                                    }}
                                    onCancel={() => {
                                      setAlert(!alert);
                                    }}
                                    focusCancelBtn>
                                    You will not be able to recover this
                                    imaginary file!
                                  </SweetAlert>
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Bank Al Habib</td>
                            <td>5535443021293</td>
                            <td>Ali Ahmed</td>
                            <td>
                              <button
                                style={{borderRadius: '16px'}}
                                className="px-4 btn-primary"
                                onClick={() => {
                                  setAlert(!alert);
                                }}>
                                Pay
                              </button>
                              {alert ? (
                                <>
                                  {' '}
                                  <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText="Confirm!"
                                    confirmBtnBsStyle="primary"
                                    title="Are you sure?"
                                    onConfirm={() => {
                                      setAlert(!alert);
                                    }}
                                    onCancel={() => {
                                      setAlert(!alert);
                                    }}
                                    focusCancelBtn>
                                    You will not be able to recover this
                                    imaginary file!
                                  </SweetAlert>
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>1</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
