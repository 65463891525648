import Nav from '../../../navbar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';

function Mainpage() {
  const navigate = useNavigate();
  const [client, setClient] = useState([
    {
      id: 1,
      name: '',
      business: '',
      email: '',
      phone: '',
      city: '',
      status: 'disable',
    },
  ]);

  const changeArayClient = (i, changes) => {
    const oldArr = [...client];
    const oldObj = oldArr[i];
    oldArr[i] = {...oldObj, ...changes};
    setClient(oldArr);
  };
  const [uid, setUid] = useState('');
  const [pname, setPname] = useState('');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));

    if (v === null) {
    } else {
      setUid(v[0].id);
      const array = JSON.parse(localStorage.getItem('KhataListClient'));
      console.log('edit Product ', array);
      const i = localStorage.getItem('KhataListClientID');

      //setBcode(array[i].bar_code)
      //setPname(array[i].p_name)
      //setPcat(array[i].p_category)
    }
  }, []);

  const addClicent = async () => {
    const data = new FormData();
    data.append('uid', uid);
    data.append('array', JSON.stringify(client));

    axios({
      method: 'post',
      url: '/pro/khata/khata_client_add.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);
        navigate('/khataclient');
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h3>Edit Client</h3>
                  </div>

                  {/* Form Start */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-title">
                          <h5>Edit Current Client</h5>
                        </div>
                        <div className="card-body">
                          <div className="basic-elements">
                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Full Name</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={client[0].name}
                                  onChange={e => {
                                    changeArayClient(0, {name: e.target.value});
                                  }}
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Business Name</label>
                                <input
                                  className="form-control"
                                  value={client[0].business}
                                  onChange={e => {
                                    changeArayClient(0, {
                                      business: e.target.value,
                                    });
                                  }}
                                  type="text"
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 form-group">
                                <label>Email</label>
                                <input
                                  className="form-control"
                                  type="email"
                                  value={client[0].email}
                                  onChange={e => {
                                    changeArayClient(0, {
                                      email: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                              <div className="col-6 form-group">
                                <label>Phone</label>
                                <input
                                  className="form-control"
                                  type="number"
                                  value={client[0].phone}
                                  onChange={e => {
                                    changeArayClient(0, {
                                      phone: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <label>Select City</label>
                              <select
                                value={client[0].city}
                                onChange={e => {
                                  changeArayClient(0, {city: e.target.value});
                                }}
                                className="form-control mx-2">
                                <option>Lahore</option>
                                <option>Gujranwala</option>
                                <option>Karachi</option>
                                <option>Peshawar</option>
                              </select>
                            </div>

                            <div
                              onClick={() => addClicent()}
                              className="btn btn-primary my-4">
                              Save
                            </div>
                            <div
                              onClick={e => {
                                setClient([
                                  {
                                    id: 1,
                                    name: '',
                                    business: '',
                                    email: '',
                                    phone: '',
                                    city: '',
                                    status: 'disable',
                                  },
                                ]);
                              }}
                              className="btn btn-primary my-4 mx-3">
                              Reset
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
